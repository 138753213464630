import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const HomesItem = styled(Link)`
  display: flex;
  width: 90%;
`;

export const HomeName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HouseEmptyGlyphContainer = styled.div`
  margin-right: 5px;
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const StarIconContainer = styled.div`
  cursor: pointer;
  align-self: center;
`;

export const HomeRow = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  flex: 1;
`;

export const HomeRow2 = styled.div`
  margin-top: 3px;
  @media (max-width: 468px) {
    flex-direction: column;
  }
`;

export const LoaderContainer = styled.div`
  margin-block: 10px;
`;

export const HomeInfoContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 8px 10px;
  background-color: #f6f6f6;
`;

export const HomeActivePill = styled.div`
  width: 64px;
  height: 25px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d6eff6;
  color: #2e4ea1;
  font-size: 14px;
  font-weight: 600;
`;

export const HomeForBonusPill = styled.div`
  width: 138px;
  height: 25px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe8fd;
  color: #a52d83;
  font-size: 14px;
  font-weight: 600;
`;
