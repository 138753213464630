/* eslint-disable prefer-destructuring */
import { Global } from '@emotion/react';
import { portalRootId } from 'features/app/constants/modal';
import { HOMES_PATH } from 'features/app/constants/navigation';
import {
  QUERY_TOKEN,
  REFRESH_TOKEN,
  TOKEN,
} from 'features/app/constants/token';
import BrokenLinkPage from 'features/app/pages/BrokenLinkPage';
import {
  authWithTokenAction,
  saveRefreshToken,
} from 'features/app/store/auth/actions';
import { auth0Client } from 'features/app/utils/auth0';
import { bugsnag } from 'features/app/utils/bugsnag';
import { identifySL } from 'features/app/utils/smartlook';
import flatten from 'flat';
import { Suspense, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Dispatch } from 'redux';
import AppLocale from '../../locales';
import AuthenticatedApp from './AuthenticatedApp';
import ErrorBoundary from '../Commons/ErrorBoundary';

import Login from '../LoginView';
import { CustomLoading, globalStyles } from './App.styles';

const App: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const navigate = useNavigate();
  const { data, loading, error, passwordLess } = useSelector(
    (state: RootStateOrAny) => state.auth
  );
  const [oneCall, setOneCall] = useState(false);

  const [token, setToken] = useState<string | undefined>();
  const { hash, search } = window.location;

  useEffect(() => {
    if (search) {
      setToken(search.split(QUERY_TOKEN)[1]);
      window.localStorage.setItem(TOKEN, search.split(QUERY_TOKEN)[1]);
    } else if (hash) {
      auth0Client.parseHash({ hash }, (err, authResult) => {
        if (err) {
          throw new Error(err.errorDescription);
        }
        if (authResult?.accessToken) {
          setToken(authResult.accessToken);
          window.localStorage.setItem(TOKEN, authResult.accessToken);
        }
      });
    }
  }, [hash, search]);

  useEffect(() => {
    const localToken = window.localStorage.getItem(TOKEN);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);
    if (!oneCall) {
      if (localToken) {
        setToken(localToken);
        if (localToken.split(' ')[0] === 'Bearer' && refreshToken) {
          dispatch(saveRefreshToken(refreshToken));
        } else {
          dispatch(authWithTokenAction(localToken));
        }
      } else if (token) {
        setToken(token);
        dispatch(authWithTokenAction(token));
      }
      setOneCall(true);
    }
  }, [oneCall, dispatch, hash, token]);

  useEffect(() => {
    if (error) {
      localStorage.removeItem(TOKEN);
    } else if (data) {
      localStorage.setItem(TOKEN, data.token);
    }
  }, [data, error, navigate]);

  useEffect(() => {
    if (data && search.split(QUERY_TOKEN)[1]) {
      const { vendor } = data;
      bugsnag.setUser(vendor?.id, vendor?.contactEmail, vendor?.name);
      navigate(`${HOMES_PATH}/${data.property.id}`);
    }
  }, [data, navigate, passwordLess.refreshToken, search]);

  useEffect(() => {
    if (data) {
      identifySL(data.vendor.id, {
        name: `${data.vendor.name} (${data.vendor.contactName})`,
        email: data.vendor.contactEmail,
        token: data?.token,
      });
    }
  }, [data]);

  const appLocale: { [index: string]: any } = AppLocale.en;

  if (error) {
    return (
      <IntlProvider locale="en" messages={flatten(appLocale.messages)}>
        <BrokenLinkPage />
      </IntlProvider>
    );
  }

  if (loading) {
    return <CustomLoading />;
  }

  return (
    <IntlProvider locale="en" messages={flatten(appLocale.messages)}>
      <div id={portalRootId} />
      <Global styles={globalStyles} />
      <ErrorBoundary>
        <Suspense fallback={<CustomLoading />}>
          {data ? <AuthenticatedApp /> : <Login />}
        </Suspense>
      </ErrorBoundary>
    </IntlProvider>
  );
};

export default App;
