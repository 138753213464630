import { useTheme } from '@emotion/react';
import { DownChevronGlyph } from 'assets/glyphs';
import CalendarIcon from 'assets/svgs/CalendarIcon';
import { format } from 'date-fns';
import { ON_SELECT_DATES } from 'features/app/store/projectLog/projectLogActions';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { WSpacer } from '../../Spacer.styles';
import {
  DatePickerWrapper,
  CalendarWrapper,
  Input,
  Label,
  InputWrapper,
} from './DatePicker.styles';

interface DatePickerProps {
  onChange: (date: Date[]) => void;
  valueDate: Date[];
}

const DatePickerInput: React.FC<DatePickerProps> = ({
  onChange,
  valueDate,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const startDate: Date | undefined =
    valueDate.length > 0 ? valueDate[0] : undefined;
  const endDate: Date | undefined =
    valueDate.length > 0 ? valueDate[1] : undefined;
  const dispatch = useDispatch();

  const onPeriodChange = (dates: Date[] | null) => {
    if (dates) {
      const [start, end] = dates;
      dispatch(ON_SELECT_DATES([start, end]));
      onChange([start, end]);
      if (start && end) setOpen(false);
    }
  };

  const formattedValue = () => {
    if (startDate && endDate) {
      return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
    }
    return formatMessage({ id: 'filter.select' });
  };

  return (
    <DatePickerWrapper>
      <Label>
        <CalendarIcon width={16} height={16} color={theme.color.font} />
        <WSpacer width={2} />
        <FormattedMessage id="filter.periodLabel" />
      </Label>
      <InputWrapper onClick={() => setOpen(!open)}>
        <Input
          value={formattedValue()}
          empty={!(startDate && endDate)}
          readOnly
        />
        <DownChevronGlyph className="right-icon" color={theme.color.font} />
      </InputWrapper>
      {open && (
        <CalendarWrapper onClick={(e) => e.stopPropagation()}>
          <DatePicker
            selected={startDate || undefined}
            startDate={startDate || undefined}
            endDate={endDate || undefined}
            onChange={(dates: any) => onPeriodChange(dates)}
            selectsRange
            inline
          />
        </CalendarWrapper>
      )}
    </DatePickerWrapper>
  );
};

export default DatePickerInput;
