/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';
import { MobileSideMenuProps } from './MobileSideMenu';

export const MobileSideMenuWrapper = styled.div<MobileSideMenuProps>`
  position: fixed;
  top: 0;
  left: ${({ isLeft }) => (isLeft ? 0 : '')};
  right: ${({ isLeft }) => (isLeft ? '' : 0)};
  width: 90vw;
  height: 100%;
  background-color: ${({ isLeft }) => (isLeft ? 'blue' : 'red')};
  z-index: 10;
  transition: 0.5s ease-in-out;
  -webkit-transition: 0.5s ease-in-out;
  transform: translateX(
    ${({ show, isLeft }) => (!show ? (!isLeft ? '110%' : '-110%') : '0%')}
  );
  -webkit-transform: translateX(
    ${({ show, isLeft }) => (!show ? (!isLeft ? '110%' : '-110%') : '0%')}
  );
`;
