import { useTheme } from '@emotion/react';
import BrokenLinkWOIcon from 'assets/svgs/BrokenLinkWOIcon';
import ChevronDownIcon from 'assets/svgs/ChevronDownIcon';
import ChevronUpIcon from 'assets/svgs/ChevronUpIcon';
import MoreInfoIcon from 'assets/svgs/MoreInfoIcon';
// import MoreInfoIcon from 'assets/svgs/MoreInfoIcon';
import ResultsIcon from 'assets/svgs/Results';
import { format } from 'date-fns';
import TableLoader from 'features/app/components/Commons/Loaders/TableLoader';
import Modal from 'features/app/components/Commons/Modals/Modal';
import {
  getStatus,
  GetStatusChip,
  handleWorkOrderLink,
} from 'features/app/components/ProjectLogView/helper';
import { CANCELED } from 'features/app/constants/lineItemStatus';
import { useIsDesktop, useIsMobile } from 'features/app/hooks/screen';
import { LineItem } from 'features/app/utils/types/lineItems';
import { centsToDollars } from 'helpers/centsToDollars';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';
import { HSpacer, WSpacer } from '../../../../../Commons/Spacer.styles';
import {
  TextBase,
  TextLg,
  TextSm,
  TextXl,
} from '../../../../../Commons/Text/Text';
import {
  ColumnTitle,
  EmptyTableResults,
  LineInfoModal,
  ModalLinkErrorWrapper,
  MoreInfoModalWrapper,
  MoreInfoWrapper,
  TableStyled,
  TableTd,
  TableTdDescription,
  TableTdWO,
  TableTh,
  TableTr,
  TableWrapper,
} from './Table.styles';

interface TableProps {
  data?: LineItem[];
  loading?: boolean;
  refetch?: boolean;
  propId?: any;
}

const Table: React.FC<TableProps> = ({ data, loading, refetch, propId }) => {
  const isDesktop = useIsDesktop();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const auth = useSelector((state: RootStateOrAny) => state.auth);
  const [orderBy, setOrderBy] = useState<Number | undefined>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const formatDate = (date?: string) =>
    date ? format(new Date(date), 'MM/dd/yyyy') : '---';
  const headers = [
    { id: 1, name: 'paylog.woId' },
    { id: 2, name: 'paylog.lineItem' },
    { id: 3, name: 'paylog.info' },
    { id: 4, name: 'paylog.invoiceId' },
    { id: 5, name: 'paylog.amount' },
    { id: 6, name: 'paylog.markedComplete' },
    { id: 7, name: 'paylog.paymentDate' },
    { id: 8, name: 'paylog.status' },
  ];

  const lineItemForModal = useRef<LineItem | undefined>();

  const [isOpenMoreInfo, setIsOpenMoreInfo] = useState(false);

  const handleOpenModalMoreInfo = (item: LineItem) => {
    lineItemForModal.current = item;
    setIsOpenMoreInfo(!isOpenMoreInfo);
  };

  const handleCloseModalMoreInfo = () => {
    setIsOpenMoreInfo(!isOpenMoreInfo);
  };

  /* START ERROR MODAL LOGIC */
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };
  /* FINISH ERROR MODAL LOGIC */

  useEffect(() => {
    if (refetch) {
      setOrder('asc');
      setOrderBy(undefined);
    }
  }, [refetch]);

  const getData = () => {
    if (orderBy === headers[0].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => (a.workOrderId > b.workOrderId ? 1 : -1));
      }
      return data?.sort((a, b) => (a.workOrderId < b.workOrderId ? 1 : -1));
    }
    if (orderBy === headers[1].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => a.name.localeCompare(b.name));
      }
      return data?.sort((a, b) => b.name.localeCompare(a.name));
    }
    if (orderBy === headers[2].id) {
      const arrSor: any[] = [];
      if (order === 'asc') {
        data?.forEach((el) => {
          if (el?.section === null) {
            arrSor.push(el);
          } else {
            arrSor.unshift(el);
          }
        });
        return arrSor;
      }
      data?.forEach((el) => {
        if (el?.section === null) {
          arrSor.unshift(el);
        } else {
          arrSor.push(el);
        }
      });
      return arrSor;
    }
    if (orderBy === headers[3].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => {
          if (!a?.payment) {
            return 1;
          }
          if (!b?.payment) {
            return -1;
          }
          return a.payment.id > b.payment.id ? 1 : -1;
        });
      }
      return data?.sort((a, b) => {
        if (!a?.payment) {
          return 1;
        }
        if (!b?.payment) {
          return -1;
        }
        return a.payment.id < b.payment.id ? 1 : -1;
      });
    }
    if (orderBy === headers[4].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => (a.costCents > b.costCents ? 1 : -1));
      }
      return data?.sort((a, b) => (a.costCents < b.costCents ? 1 : -1));
    }
    if (orderBy === headers[5].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => {
          if (!a?.approvedAt) {
            return 1;
          }
          if (!b?.approvedAt) {
            return -1;
          }
          return new Date(a.approvedAt) > new Date(b.approvedAt) ? 1 : -1;
        });
      }
      return data?.sort((a, b) => {
        if (!a?.approvedAt) {
          return 1;
        }
        if (!b?.approvedAt) {
          return -1;
        }
        return new Date(a.approvedAt) < new Date(b.approvedAt) ? 1 : -1;
      });
    }
    if (orderBy === headers[6].id) {
      if (order === 'asc') {
        return data?.sort((a, b) => {
          if (!a?.payment?.completedAt) {
            return 1;
          }
          if (!b?.payment?.completedAt) {
            return -1;
          }
          return new Date(a?.payment?.completedAt) >
            new Date(b?.payment?.completedAt)
            ? 1
            : -1;
        });
      }
      return data?.sort((a, b) => {
        if (!a?.payment?.completedAt) {
          return 1;
        }
        if (!b?.payment?.completedAt) {
          return -1;
        }
        return new Date(a?.payment?.completedAt) <
          new Date(b?.payment?.completedAt)
          ? 1
          : -1;
      });
    }
    if (orderBy === headers[7].id) {
      if (order === 'asc') {
        return data?.sort((a, b) =>
          a?.frontendStatus.localeCompare(b?.frontendStatus)
        );
      }
      return data?.sort((a, b) =>
        b?.frontendStatus.localeCompare(a?.frontendStatus)
      );
    }
    return data;
  };

  const onSort = (id: number) => {
    if (orderBy === id) {
      setOrder(order === 'asc' ? 'desc' : 'asc');
    } else if (orderBy !== id) {
      setOrder('asc');
      setOrderBy(id);
    }
  };

  if (loading) {
    return <TableLoader />;
  }

  if (data?.length === 0) {
    return (
      <EmptyTableResults>
        <ResultsIcon width="50px" height="50px" />
        <HSpacer />
        <TextSm color={theme.color.lightFont}>
          <FormattedMessage id="paylog.empty" />
        </TextSm>
      </EmptyTableResults>
    );
  }

  return (
    <TableWrapper>
      <TableStyled fixed={!isDesktop}>
        <TableTr>
          {headers.map(({ id, name }) => (
            <TableTh key={id}>
              <ColumnTitle onClick={() => onSort(id)}>
                <TextBase color={theme.color.lightFont}>
                  {name && (
                    <FormattedMessage id={name}>
                      {(msg) => (
                        <TextSm
                          style={{ whiteSpace: 'nowrap' }}
                          color={theme.color.disabledFont}
                          weight={theme.fontWeight.regMedium}
                        >
                          {msg}
                        </TextSm>
                      )}
                    </FormattedMessage>
                  )}
                </TextBase>
                <WSpacer width={5} />
                {orderBy === id && order === 'asc' && (
                  <ChevronUpIcon
                    width={18}
                    height={18}
                    color={theme.color.disabledFont}
                  />
                )}
                {orderBy === id && order === 'desc' && (
                  <ChevronDownIcon
                    width={18}
                    height={18}
                    color={theme.color.disabledFont}
                  />
                )}
              </ColumnTitle>
            </TableTh>
          ))}
        </TableTr>
        {getData()?.map((item) => (
          <tbody key={item.id}>
            <TableTr>
              {/* Work order id */}
              <TableTdWO
                textDecoration={
                  item?.workOrderId.toString() !== 'N/A' &&
                  getStatus(item) !== CANCELED
                }
                fixed={!!isMobile}
                cursor={
                  item?.workOrderId.toString() !== 'N/A' &&
                  getStatus(item) !== CANCELED
                    ? 'pointer'
                    : 'null'
                }
                onClick={(e: any) => e.stopPropagation()}
              >
                <TextSm
                  color={theme.color.font}
                  weight={theme.fontWeight.medium}
                  onClick={() => {
                    if (
                      item?.workOrderId.toString() !== 'N/A' &&
                      getStatus(item) !== CANCELED
                    ) {
                      handleWorkOrderLink(
                        propId,
                        item?.workOrderId,
                        auth.data.token,
                        setIsOpen
                      );
                    }
                  }}
                >
                  {item?.workOrderId.toString() !== 'N/A'
                    ? `#${item?.workOrderId}`
                    : 'No WO'}
                </TextSm>
              </TableTdWO>
              {/* Line Item name */}
              <TableTdDescription>
                <TextSm color={theme.color.lightFont}>{item.name}</TextSm>
              </TableTdDescription>
              {/* +Info */}
              <TableTd>
                {item.section && (
                  <MoreInfoWrapper
                    onClick={() => handleOpenModalMoreInfo(item)}
                  >
                    <MoreInfoIcon width={18} height={18} />
                  </MoreInfoWrapper>
                )}
              </TableTd>
              {/* Invoice id */}
              <TableTd>
                <TextSm
                  color={theme.color.font}
                  weight={theme.fontWeight.medium}
                >
                  {item?.payment?.id ? `#${item?.payment?.id}` : '---'}
                </TextSm>
              </TableTd>
              {/* Total amount */}
              <TableTd>
                <TextSm
                  color={theme.color.lightFont}
                  weight={theme.fontWeight.bold}
                >
                  {centsToDollars(item?.costCents)}
                </TextSm>
              </TableTd>
              {/* Marked Complete */}
              <TableTd>
                <TextSm color={theme.color.lightFont}>
                  {formatDate(item?.approvedAt)}
                </TextSm>
              </TableTd>
              {/* Payment Approve */}
              <TableTd>
                <TextSm color={theme.color.lightFont}>
                  {formatDate(item?.payment?.completedAt)}
                </TextSm>
              </TableTd>
              {/* Status */}
              <TableTd>
                <TextSm color={theme.color.lightFont}>
                  {GetStatusChip(item)}
                </TextSm>
              </TableTd>
            </TableTr>
          </tbody>
        ))}
      </TableStyled>
      {isOpen && (
        <Modal visibility={isOpen} setVisibility={closeModal}>
          <ModalLinkErrorWrapper>
            <BrokenLinkWOIcon width={40} height={40} />
            <TextXl color={theme.color.black}>
              Work Order Link Unavailable
            </TextXl>
            <TextSm color={theme.color.black}>
              <FormattedMessage id="broken.woErrorText" />
            </TextSm>
          </ModalLinkErrorWrapper>
        </Modal>
      )}
      {isOpenMoreInfo && (
        <Modal visibility setVisibility={handleCloseModalMoreInfo}>
          <MoreInfoModalWrapper>
            <TextLg color={theme.color.black} style={{ fontWeight: 600 }}>
              <FormattedMessage id="paylog.infoTitle" />
            </TextLg>
            <LineInfoModal />
            <TextLg
              color={theme.color.black}
              style={{ fontWeight: 600, fontSize: 18, paddingBottom: 10 }}
            >
              <FormattedMessage id="paylog.infoDescription" />
            </TextLg>
            <TextSm color={theme.color.black}>
              {lineItemForModal.current?.name}
            </TextSm>
            <TextLg
              color={theme.color.black}
              style={{
                fontWeight: 600,
                fontSize: 18,
                paddingTop: 30,
                paddingBottom: 10,
              }}
            >
              <FormattedMessage id="paylog.infoLocation" />
            </TextLg>
            <TextSm color={theme.color.black}>
              {lineItemForModal.current?.section}
            </TextSm>
          </MoreInfoModalWrapper>
        </Modal>
      )}
    </TableWrapper>
  );
};

export default Table;
