import { HomeTotalsActions, HOME_TOTALS_TYPES } from './homeTotalsTypes';

const initialState = {
  dataHome: [],
  loading: false,
};

export const HomeTotalsReducer = (
  state = initialState,
  action: HomeTotalsActions
) => {
  switch (action.type) {
    case HOME_TOTALS_TYPES.SET_HOME_INFORMATION:
      return {
        ...state,
        dataHome: action.payload,
        loading: false,
      };
    case HOME_TOTALS_TYPES.LOADING_HOME_INFORMATION:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
