import styled from '@emotion/styled';

export const ZipCityFilterWrapper = styled.div`
  height: 34px;
  width: 100%;
  border-radius: 5px;
  /* min-width: 286px; */
  display: flex;
`;

export const ZipCitySelect = styled.select`
  padding-left: 10px;
  width: 30%;
  height: 100%;
  background-color: #e9e9e9;
  font-size: 14px;
  outline: none;
  border: none;

  @media (max-width: 1025px) {
    width: 25%;
  }
`;

export const ZipCityInputContainer = styled.div`
  position: relative;
  width: 70%;
  height: 100%;

  @media (max-width: 1025px) {
    width: 75%;
  }
`;

export const ZipCityInput = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background-color: white;

  &::placeholder {
    text-align: right;
    padding-right: 10px;
  }

  &:focus {
    outline: none;
  }
`;

export const PreviousSearchList = styled.div`
  position: absolute;
  z-index: 1;
  top: 120%;
  right: 0;
  box-sizing: border-box;
  padding: 14px 16px;
  width: 100%;
  min-width: 200px;
  height: fit-content;
  max-height: 160px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  color: ${({ theme }) => theme.color.gray700};
  background-color: ${({ theme }) => theme.color.white};
`;

export const PreviousSearchItem = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: 300;
  color: ${({ theme }) => theme.color.gray700};
`;

export const ClearZipCityFilterWrapper = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;
