import { Text } from 'features/app/components/Commons/Text/Text';
import { TooltipContainer } from './AddressTooltip.styles';

export interface AdressTooltipProps {
  text: string;
}

export default function AdressTooltip({ text }: AdressTooltipProps) {
  return (
    <TooltipContainer>
      <Text color="#FFFFFF" weight="700" fontSize="15">
        {text}
      </Text>
    </TooltipContainer>
  );
}
