import styled from '@emotion/styled';

export const StyledButton = styled.button`
  all: unset;
  display: inline-block;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  background: ${({ theme }) => theme.color.altFont};
  color: ${({ theme }) => theme.color.white};
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1.0)};
  border-radius: 8px;
`;
