import CloseMenuMobileIcon from 'assets/svgs/CloseMenuMobileIcon';
import VendorInfo from 'features/app/components/Commons/MenuRightSideBar/components/VendorInfo';
import { SET_RIGHT_NAVBAR_FILTER } from 'features/app/store/homesFilters/homeFiltersActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  getBodyToDispatchStatusBy,
  helperSelectFilter,
  sortByField,
} from '../../../Filters/helper';
import EarnThisMonthCard from '../../../MenuRightSideBar/components/EarnThisMonthCard';
import FutureWorkCard from '../../../MenuRightSideBar/components/FutureWorkCard';
import PendingPaymentCard from '../../../MenuRightSideBar/components/PendingPaymentCard';
import { rightSideBarTypes } from '../../../MenuRightSideBar/types';
import { SideMenuProps } from '../SideMenuLeft/SideMenuLeft';

import {
  SideMenuRightWrapper,
  VendorInfoPlusCloseMenu,
} from './SideMenuRight.styles';

const SideMenuRight: React.FC<SideMenuProps> = ({ onClose }) => {
  const { rightNavBarSelection, sortBy, status } = useSelector(
    (state: RootStateOrAny) => state.homeFilters
  );
  const {
    data: { token },
  } = useSelector((store: RootStateOrAny) => store.auth);
  const dispatch = useDispatch();

  const onClick = (value: string) => {
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(status);
    const sortByToDispatch = sortByField(sortBy);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByToDispatch,
      value,
      token
    );
    dispatch(SET_RIGHT_NAVBAR_FILTER(value));
    onClose();
  };

  const onCancel = () => {
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(status);
    const sortByToDispatch = sortByField(sortBy);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByToDispatch,
      '',
      token
    );
    dispatch(SET_RIGHT_NAVBAR_FILTER(''));
  };

  return (
    <SideMenuRightWrapper>
      <VendorInfoPlusCloseMenu onClick={onClose}>
        <CloseMenuMobileIcon width={40} height={40} />
        <VendorInfo />
      </VendorInfoPlusCloseMenu>
      <PendingPaymentCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.pendingPayment)}
        isSelected={rightNavBarSelection === rightSideBarTypes.pendingPayment}
      />
      <FutureWorkCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.futureWork)}
        isSelected={rightNavBarSelection === rightSideBarTypes.futureWork}
      />
      {/* <BonusCard /> */}
      <EarnThisMonthCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.earnThisMonth)}
        isSelected={rightNavBarSelection === rightSideBarTypes.earnThisMonth}
      />
    </SideMenuRightWrapper>
  );
};

export default SideMenuRight;
