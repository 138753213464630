import styled from '@emotion/styled';

interface HSpacerProps {
  height?: number;
}

interface WSpacerProps {
  width?: number;
}

export const HSpacer = styled.div<HSpacerProps>`
  height: ${({ height }) => height || 20}px;
`;

export const WSpacer = styled.div<WSpacerProps>`
  width: ${({ width }) => width || 20}px;
`;
