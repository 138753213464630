import styled from '@emotion/styled';

export const ModalCardWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  top: 52px;
  right: 20px;
  min-width: 150px;
  min-height: 92px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  background-color: white;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 0px 15px rgb(184 196 208 / 25%);
`;

export const ModalRowContainer = styled.div`
  cursor: pointer;
`;
