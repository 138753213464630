export const HOME_BY_ID_TYPES = {
  GET_HOME_BY_ID: 'GET_HOME_BY_ID',
  SET_HOME_BY_ID: 'SET_HOME_BY_ID',
  LOADING_HOME_BY_ID: 'LOADING_HOME_BY_ID',
};

export type getHomeById = {
  type: typeof HOME_BY_ID_TYPES.GET_HOME_BY_ID;
  payload: any;
};

export type setHomeById = {
  type: typeof HOME_BY_ID_TYPES.SET_HOME_BY_ID;
  payload: any;
};

export type loadingHomeById = {
  type: typeof HOME_BY_ID_TYPES.LOADING_HOME_BY_ID;
  payload: boolean;
};

export type HomeByIdActions = getHomeById | setHomeById | loadingHomeById;
