import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useIsMobile } from 'features/app/hooks/screen';
import { useTheme } from '@emotion/react';
import { getHomeIdPath } from 'helpers/path';
import { DownChevronGlyph } from 'assets/glyphs';
import { LineItem } from 'features/app/utils/types/lineItems';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  CSV_KEY,
  exportToCsv,
  exportToPDF,
  exportToXlsx,
  PDF,
  XLSX_KEY,
} from 'features/app/utils/export';
import { useLocation } from 'react-router-dom';
import { OnChageLogItems } from 'features/app/store/projectLog/projectLogActions';
import Card from 'features/app/components/HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import { TextSm, Title } from '../../../../../Commons/Text/Text';
import { HeaderWrapper, HomeLogWrapper } from './Log.styles';
import Table from '../Table';
import List from '../List';
import Filter from '../Filter';
import Pagination from '../Pagination';
import { formatDataToExport } from '../../../../helper';
import DropdownButton from '../../../../../Commons/Buttons/DropdownButton';
import { WSpacer } from '../../../../../Commons/Spacer.styles';
import GroupBy from '../List/GroupBy';
import {
  getStatusAndPaymentStatusFilter,
  OptionsSelect,
} from '../Filter/Filter';

interface ProjectLogProps {
  fullLineItems: LineItem[];
}

const ProjectLog: React.FC<ProjectLogProps> = ({ fullLineItems }) => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const dispatch = useDispatch();
  const {
    logItems,
    loading,
    groupBy,
    page,
    limitPage,
    dates,
    search,
    multiselect,
  } = useSelector((state: RootStateOrAny) => state.projectLog);
  const isMobile = useIsMobile();
  const location = useLocation();
  const theme = useTheme();
  const [refetch, setRefetch] = useState(false);
  const [homeId, setHomeId] = useState<number | undefined>(
    getHomeIdPath(location)
  );
  const exportOptions = [
    { key: CSV_KEY, value: 'CSV' },
    { key: XLSX_KEY, value: 'XLSX' },
    { key: PDF, value: 'PDF' },
  ];
  useEffect(() => {
    const groupByData = groupBy.map((item: OptionsSelect) => item.value);
    const auxToDispatch = getStatusAndPaymentStatusFilter(multiselect);
    dispatch(
      OnChageLogItems({
        id: getHomeIdPath(location),
        body: {
          token: data?.token,
          startDate: dates.length > 0 ? dates[0] : undefined,
          endDate: dates.length > 0 ? dates[1] : undefined,
          page,
          limit: limitPage,
          name: search,
          status: auxToDispatch,
          group_by: groupByData.length > 0 ? groupByData[0] : '',
        },
      })
    );
  }, [
    data?.token,
    dispatch,
    location,
    page,
    limitPage,
    groupBy,
    multiselect,
    dates,
    search,
  ]);

  useEffect(() => {
    if (getHomeIdPath(location) !== homeId) {
      setHomeId(getHomeIdPath(location));
      setRefetch(true);
    }
  }, [location, homeId]);

  useEffect(() => {
    if (refetch) setRefetch(false);
  }, [refetch]);

  const onExportSelect = (key: string) => {
    const exportFileName = 'full_project_log';
    if (key === XLSX_KEY) {
      exportToXlsx(exportFileName, formatDataToExport(fullLineItems));
    }
    if (key === CSV_KEY) {
      exportToCsv(exportFileName, formatDataToExport(fullLineItems));
    }
    if (key === PDF) {
      exportToPDF(exportFileName, formatDataToExport(fullLineItems));
    }
  };
  const getTableData = () => logItems?.lineItems;
  return (
    <Card customPadding={0}>
      <HomeLogWrapper>
        <HeaderWrapper>
          <Title>
            <FormattedMessage id="paylog.title" />
          </Title>
          <DropdownButton items={exportOptions} onClick={onExportSelect}>
            <TextSm color={theme.color.white}>
              <FormattedMessage id="common.exportAs" />
            </TextSm>
            <WSpacer width={5} />
            <DownChevronGlyph color={theme.color.white} size={14} />
          </DropdownButton>
        </HeaderWrapper>
        <Filter page={page} pageLimit={limitPage} />
        {isMobile ? (
          <>
            {groupBy.length > 0 ? (
              <GroupBy
                groupName={groupBy[0].label}
                data={getTableData()}
                loading={loading}
                propId={data?.property?.sfr3Id}
              />
            ) : (
              <List
                data={getTableData()}
                loading={loading}
                propId={data?.property?.sfr3Id}
              />
            )}
          </>
        ) : (
          <>
            {groupBy.length > 0 ? (
              <GroupBy
                groupName={groupBy[0].label}
                data={getTableData()}
                loading={loading}
                propId={data?.property?.sfr3Id}
              />
            ) : (
              <Table
                data={getTableData()}
                loading={loading}
                refetch={refetch}
                propId={data?.property?.sfr3Id}
              />
            )}
          </>
        )}
      </HomeLogWrapper>
      <Pagination
        totalItems={logItems?.pagy?.count || 0}
        totalPages={logItems?.pagy?.pages || 0}
        lastPage={logItems?.pagy?.last || 0}
      />
    </Card>
  );
};

export default ProjectLog;
