import styled from '@emotion/styled';

interface EtasProps {
  isVendorEta?: boolean;
}

export const Wrapper = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;

export const HeadlineWrapper = styled.div`
  white-space: nowrap;
  max-width: 600px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    white-space: normal;
    padding: 0 0 20px 0;
  }
`;

export const DivisorLine = styled.div`
  height: 1px;
  background-color: ${({ theme }) => theme.color.divisorLine};
  width: 100%;
  margin-top: 25px;
`;

export const EtasContactWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const EtasWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Eta = styled.div<EtasProps>`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  background-color: ${({ theme, isVendorEta }) =>
    isVendorEta ? theme.color.etaCard : 'transparent'};
  margin-left: ${({ isVendorEta }) => (isVendorEta ? 15 : 0)}px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    text-align: center;
    padding: 5px 10px;
    margin-left: 0;
  }
`;
