import { IconPropTypes } from './types';

const FutureHomeIcon = ({ width, height }: IconPropTypes) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    xmlSpace="preserve"
    height={height || '100%'}
    width={width || '100%'}
  >
    <style>{'.st1{fill:#eaf7ff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        className="st1"
        d="M65.2 47.7 48.4 31.4c-.1-.1-.3-.2-.4-.3-.2-.1-.3-.1-.5-.1s-.3 0-.5.1-.3.2-.4.3L29.8 47.7c-1.1 1-1.1 2.9.5 4.1.2.2.6.3.9.3h2.3V65h28.3V52.1h1.8c.5 0 1-.1 1.5-.4.4-.3.8-.7 1-1.1.2-.5.2-1 .1-1.5-.4-.6-.6-1-1-1.4zM50.7 62.2h-6.3v-7.5c0-.3.1-.7.4-.9s.6-.4.9-.4h3.7c.3 0 .7.1.9.4s.4.6.4.9v7.5zM58.5 13.2c.2-.1.3-.2.4-.4.1-.2.1-.3.1-.5s0-.4-.1-.5c-.1-.2-.2-.3-.4-.4L43.8.9c-.2-.1-.4-.2-.6-.2-.2 0-.4 0-.6.1-.2.1-.3.2-.4.4-.2.2-.2.4-.2.6v20.8c0 .2.1.4.2.6.1.2.3.3.4.4.2.1.4.1.6.1.2 0 .4-.1.6-.2l14.7-10.3z"
      />
      <path
        d="M71 19.8c7 5.5 10.4 10.5 12.7 18.9 2.3 8.4 1.5 17.4-2.2 25.3-3.7 7.9-10.1 14.2-18 17.8S46.7 86 38.3 83.6c-8.3-2.4-15.6-7.7-20.3-15-4.8-7.3-6.8-16-5.8-24.7s5.2-16.6 11.6-22.5 14.7-9.3 23.4-9.6"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 8,
          strokeLinecap: 'round',
        }}
      />
    </g>
  </svg>
);

export default FutureHomeIcon;
