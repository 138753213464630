import { IconPropTypes } from './types';

const PerformanceIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M12.5995 16.2912C16.7967 16.2912 20.1991 12.8795 20.1991 8.67099C20.1991 4.46246 16.7967 1.05078 12.5995 1.05078C8.40243 1.05078 5 4.46246 5 8.67099C5 12.8795 8.40243 16.2912 12.5995 16.2912Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.48356 15.0832L7.16992 25.0003L12.5982 21.7345L18.0264 25.0003L16.7128 15.0723"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6641 4.64258V12.9071"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5424 6.14468H11.725C11.3763 6.14468 11.0418 6.28321 10.7953 6.52978C10.5487 6.77636 10.4102 7.11078 10.4102 7.45949C10.4102 7.8082 10.5487 8.14263 10.7953 8.3892C11.0418 8.63578 11.3763 8.7743 11.725 8.7743H13.6033C13.952 8.7743 14.2864 8.91282 14.533 9.1594C14.7795 9.40597 14.9181 9.7404 14.9181 10.0891C14.9181 10.4378 14.7795 10.7722 14.533 11.0188C14.2864 11.2654 13.952 11.4039 13.6033 11.4039H10.4102"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PerformanceIcon;
