import { EventProps } from '../components/CalendarView/Components/Calendar/types';
import {
  BACKEND_STATUS,
  CANCELED,
  COMPLETED,
  PAY_PROCESSING,
  WORK_REMAINING,
} from '../constants/lineItemStatus';
import { LineItem } from '../utils/types/lineItems';

export const renameDates = (events: any[]) => {
  const newEvents: EventProps[] = [];
  events.forEach((event) => {
    const tmpEvent = { ...event };
    tmpEvent.start = event.startDate;
    tmpEvent.end = event.endDate;
    delete tmpEvent.startDate;
    delete tmpEvent.endDate;
    newEvents.push(tmpEvent);
  });
  return newEvents;
};

export const getStatus = (lineItems: LineItem[]) => {
  for (let i = 0; i < lineItems.length; i += 1) {
    if (lineItems[i].payment) {
      if (
        lineItems[i].payment?.status === BACKEND_STATUS.approval_needed ||
        lineItems[i].payment?.status === BACKEND_STATUS.pending
      ) {
        lineItems[i].frontendStatus = PAY_PROCESSING;
      }
      if (lineItems[i].payment?.status === BACKEND_STATUS.completed) {
        lineItems[i].frontendStatus = COMPLETED;
      }
      if (lineItems[i].payment?.status === BACKEND_STATUS.cancelled) {
        lineItems[i].frontendStatus = CANCELED;
      }
    } else {
      if (lineItems[i].status === BACKEND_STATUS.cancelled) {
        lineItems[i].frontendStatus = CANCELED;
      }
      if (
        lineItems[i].status === BACKEND_STATUS.unassigned ||
        lineItems[i].status === BACKEND_STATUS.assigned ||
        lineItems[i].status === BACKEND_STATUS.rejected ||
        lineItems[i].status === BACKEND_STATUS.provisional
      ) {
        lineItems[i].frontendStatus = WORK_REMAINING;
      }
      if (
        lineItems[i].status === BACKEND_STATUS.completed ||
        lineItems[i].status === BACKEND_STATUS.approved
      ) {
        lineItems[i].frontendStatus = PAY_PROCESSING;
      }
    }
  }
  return lineItems;
};
