/* eslint-disable react/no-array-index-key */
import LogoWithLetter from 'assets/svgs/LogoWithLetter';
import {
  OnHelpModalVisibilityChange,
  OnUserModalVisibilityChange,
} from 'features/app/store/modals/modalsActions';
import { useEffect, useRef } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import InfoButton from '../Buttons/InfoButton';
import ModalHelpCard from '../Buttons/InfoButton/components/ModalHelpCard';
import ModalUserCard from '../Buttons/InfoButton/components/ModalUserCard';
import { Text } from '../Text/Text';
import VendorInformation from './components/VendorInformation/VendorInformation';
import {
  BreadCrumbs,
  ContainerHeaderDesktop,
  FirstColumn,
  LineDivisor,
  SecondColumn,
  WrapperHeaderDesktop,
  ModalHelpContainer,
  InfoButtonContainer,
} from './HeaderDesktop.styles';

function HeaderDesktop() {
  const dispatch = useDispatch();
  const modalHelpRef = useRef<HTMLDivElement>(null);
  const modalUserRef = useRef<HTMLDivElement>(null);

  const { helpInfoModalVisibility, userInfoModalVisibility } = useSelector(
    (state: RootStateOrAny) => state.modals
  );

  const windowLocation = window.location.pathname;
  const {
    dataHomeInformation: { property },
    loading,
  } = useSelector((store: RootStateOrAny) => store.homeById);
  const getBreadCrumbs = (): any[] => {
    const pathNameAux = windowLocation.replace('/', '').split('/');
    if (pathNameAux[0].length > 0) {
      return pathNameAux;
    }
    return [];
  };

  const actualPage = () => {
    if (!loading) {
      const auxArrBreadCrumbs = getBreadCrumbs();
      if (auxArrBreadCrumbs[0] === 'homes') {
        return property?.address;
      }
      if (auxArrBreadCrumbs.length > 1) {
        return auxArrBreadCrumbs[auxArrBreadCrumbs.length - 1];
      }
    }
    return '';
  };

  const handleOpenHelpModal = () => {
    dispatch(OnHelpModalVisibilityChange(true));
  };

  const handleOpenUserModal = () => {
    dispatch(OnUserModalVisibilityChange(true));
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        modalHelpRef.current &&
        !modalHelpRef.current.contains(event.target)
      ) {
        dispatch(OnHelpModalVisibilityChange(false));
      }
      if (
        modalUserRef.current &&
        !modalUserRef.current.contains(event.target)
      ) {
        dispatch(OnUserModalVisibilityChange(false));
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <WrapperHeaderDesktop>
      <ContainerHeaderDesktop>
        <FirstColumn>
          <LogoWithLetter />
          <BreadCrumbs>
            {getBreadCrumbs().map((item: string, index: number) => {
              if (getBreadCrumbs().length - 1 === index) {
                return (
                  <Text key={index} fontSize="12" weight="600" color="black">
                    &nbsp;{actualPage()}
                  </Text>
                );
              }
              return (
                <Text fontSize="12" key={index} weight="300" color="#777777">
                  {item?.charAt(0).toUpperCase() + item?.slice(1)} {'/'}{' '}
                </Text>
              );
            })}
          </BreadCrumbs>
        </FirstColumn>
        <SecondColumn>
          <InfoButtonContainer onClick={handleOpenHelpModal}>
            <InfoButton />
          </InfoButtonContainer>
          <LineDivisor />
          <VendorInformation onClick={handleOpenUserModal} />
        </SecondColumn>
      </ContainerHeaderDesktop>
      {/* MODALS */}
      {helpInfoModalVisibility && (
        <ModalHelpContainer>
          <ModalHelpCard elRef={modalHelpRef} />
        </ModalHelpContainer>
      )}
      {userInfoModalVisibility && (
        <ModalHelpContainer>
          <ModalUserCard elRef={modalUserRef} />
        </ModalHelpContainer>
      )}
    </WrapperHeaderDesktop>
  );
}

export default HeaderDesktop;
