import { loginWithCodeProps } from 'features/app/api/auth';
import { User } from 'features/app/db/schemas/user';
import { CLEAR_REDUCERS } from 'features/app/utils/globalTypes';

import types, {
  SetLoginLoadingAction,
  AuthWithToken,
  AuthSuccessAction,
  LogoutAction,
  LogoutSuccessAction,
  AuthErrorAction,
  sendCodeAction,
} from './types';

export const setLoginLoadingAction = (
  loading: boolean
): SetLoginLoadingAction => ({
  type: types.SET_LOGIN_LOADING,
  payload: {
    error: null,
    loading,
  },
});

export const authWithTokenAction = (token: string): AuthWithToken => ({
  type: types.AUTH_WITH_TOKEN,
  payload: {
    token,
  },
});

export const authSuccessAction = (
  data: User,
  token: string
): AuthSuccessAction => ({
  payload: {
    token,
    data,
  },
  type: types.AUTH_SUCCESS,
});

export const authErrorAction = (error: any): AuthErrorAction => ({
  payload: {
    error,
    loading: false,
  },
  type: types.AUTH_ERROR,
});

export const logoutAction = (): LogoutAction => ({
  type: types.LOGOUT,
});

export const logoutSuccess = (): LogoutSuccessAction => ({
  type: CLEAR_REDUCERS,
});

export const sendCode = (payload: string): sendCodeAction => ({
  type: types.SEND_CODE,
  payload,
});

export const loginWithCode = (payload: loginWithCodeProps) => ({
  type: types.LOGIN_WITH_CODE,
  payload,
});

export const SET_LOADING_PASSWORDLESS = (payload: boolean) => ({
  type: types.SET_LOADING_PASSWORDLESS,
  payload,
});

export const SET_ERROR_PASSWORDLESS = (payload: any) => ({
  type: types.SET_ERROR_PASSWORDLESS,
  payload,
});

export const verificationPage = (payload: boolean) => ({
  type: types.VERIFICATION_PAGE,
  payload,
});

export const saveRefreshToken = (payload: string) => ({
  type: types.SAVE_REFRESH_TOKEN,
  payload,
});
