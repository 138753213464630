import styled from '@emotion/styled';

export const SideMenuLeftWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
`;
export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.navBar};
`;

export const ThemeToggleWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  height: fit-content;
  margin-top: 30vh;
`;

export const CloseBtnSideMenuLeftWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  width: fit-content;
  height: fit-content;
`;

export const VendorWrapper = styled.div`
  box-sizing: border-box;
  height: 110px;
  width: 100%;
  padding-left: 30px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

export const VendorHelpInfoContainer = styled.div`
  padding: 0 0 20px 30px;
`;

export const VendorHelpInfoTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const VendorPlusLinksContainer = styled.div``;
