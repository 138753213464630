import styled from '@emotion/styled';

interface ListProps {
  open: boolean;
}

interface ItemProps {
  start: boolean;
  end: boolean;
}

export const Wrapper = styled.div`
  position: relative;
  z-index: 2;
`;

export const Button = styled.button`
  unset: all;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.color.blue500};
  color: ${({ theme }) => theme.color.white};
  height: 40px;
  width: 100%;
  border-radius: 10px;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    padding: 0 8px;
  }
`;

export const List = styled.ul<ListProps>`
  display: ${({ open }) => (open ? 'block' : 'none')};
  max-height: ${({ open }) => (open ? 200 : 0)}px;
  position: absolute;
  text-align: left;
  width: 100%;
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  top: 40px;
  right: 0;
  transition: max-height 1s ease-in-out;
`;

export const Item = styled.li<ItemProps>`
  padding: 10px;
  cursor: pointer;
  color: ${({ theme }) => theme.color.font};
  border-top-right-radius: ${({ start }) => (start ? 10 : 0)}px;
  border-top-left-radius: ${({ start }) => (start ? 10 : 0)}px;
  border-bottom-right-radius: ${({ end }) => (end ? 10 : 0)}px;
  border-bottom-left-radius: ${({ end }) => (end ? 10 : 0)}px;
  &:hover {
    background-color: ${({ theme }) => theme.color.divisorLine};
  }
`;
