import { HOME_BY_ID_TYPES } from './homeByIdTypes';

export interface HomeByIdProps {
  id: number;
  token: string;
}

export const GET_HOME_BY_ID = (payload: HomeByIdProps) => ({
  type: HOME_BY_ID_TYPES.GET_HOME_BY_ID,
  payload,
});

export const SET_HOME_BY_ID = (payload: any) => ({
  type: HOME_BY_ID_TYPES.SET_HOME_BY_ID,
  payload,
});

export const LOADING_HOME_BY_ID = (payload: boolean) => ({
  type: HOME_BY_ID_TYPES.LOADING_HOME_BY_ID,
  payload,
});
