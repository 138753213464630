import { ItemProp } from 'features/app/utils/types/payments';

export const orderPayments = (payments?: ItemProp[]) =>
  payments?.sort((a, b) => {
    if (a.completedAt && b.completedAt) {
      const aDate = new Date(a.completedAt);
      const bDate = new Date(b.completedAt);
      return bDate.getTime() - aDate.getTime();
    }
    return 0;
  });
