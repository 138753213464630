import { LogoPropTypes } from './types';

const FutureIcon = ({ width, height }: LogoPropTypes) => (
  <svg
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    width={width || '100%'}
    height={height || '100%'}
  >
    <style>{'.st1{fill:#eaf7ff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        className="st1"
        d="M72.7 44.5c0 .7-.3 1.5-.8 2s-1.2.8-2 .8l-22.4.2c-.7 0-1.5-.3-2-.8s-.8-1.2-.8-2 .3-1.5.8-2 1.2-.8 2-.8l22.4-.2c.7 0 1.5.3 2 .8s.8 1.2.8 2z"
      />
      <path
        className="st1"
        d="m50.3 44.7.2 22.4c0 1.9-.9 2.8-2.8 2.8-1.8.1-2.7-.9-2.7-2.7l-.2-22.4c0-1.9.9-2.8 2.8-2.8 1.8-.1 2.7.8 2.7 2.7zM59.3 14.9c.2-.1.3-.2.4-.4.1-.2.1-.4.1-.5 0-.2 0-.4-.1-.5-.1-.2-.2-.3-.4-.4L44.5 2.6c-.2-.1-.4-.2-.6-.2-.2 0-.4 0-.6.1-.2.1-.3.3-.4.5-.1.2-.2.4-.2.6v20.9c0 .2.1.4.2.6.1.2.3.3.4.4.2.1.4.1.6.1.2 0 .4-.1.6-.2l14.8-10.5z"
      />
      <path
        d="M71.9 21.5c7.1 5.5 10.5 10.6 12.8 19C87 49 86.2 58 82.5 65.9c-3.7 7.9-10.1 14.2-18.1 17.9-8 3.6-17 4.3-25.4 1.8-8.4-2.4-15.7-7.8-20.5-15.1-4.8-7.3-6.9-16.1-5.8-24.8C13.8 37 18 29 24.4 23.1c6.5-5.9 14.8-9.3 23.5-9.6"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 8,
          strokeLinecap: 'round',
        }}
      />
    </g>
  </svg>
);

export default FutureIcon;
