import styled from '@emotion/styled';

export const CalendarViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5rem;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const CalendarViewHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  justify-content: center;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const DivisorLine = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.color.divisorLine};
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
`;

export const CardIconWrapper = styled.div`
  margin: 0px 20px 20px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    margin: 0px 10px 10px 0px;
  }
`;
