import { Theme } from '@emotion/react';

const theme: Theme = {
  borderRadius: {
    none: '0',
    sm: '2px',
    base: '4px',
    md: '6px',
    lg: '8px',
    xl: '12px',
    xxl: '14px',
    full: '9999px',
  },
  boxShadow: {
    indigo: '0 0 1.5rem 0.1rem rgba(102, 126, 234, 0.6)',
    deepButton: '0px 12px 25px -5px rgba(0, 160, 223, 0.4)',
    input: '0px 2px 4px rgba(9, 75, 140, 0.135)',
    none: 'none',
    staticCards: '0px 0px 15px rgba(184, 196, 208, 0.25)',
    searchBar: '0px 2px 2px rgba(184, 196, 208, 0.15)',
    menu: '0px 0px 15px 2px rgba(184, 196, 208, 0.25)',
    modals: '0px 0px 15px 2px rgba(184, 196, 208, 0.25)',
    dropdowns: '0px 0px 15px 2px rgba(184, 196, 208, 0.25)',
    buttons: '0px 0px 15px 2px rgba(184, 196, 208, 0.25)',
    navBar: '0px 4px 4px rgba(0, 0, 0, 0.15)',
    homeCards: ' 0px 2px 15px rgba(0, 0, 0, 0.15)',
  },
  color: {
    backgroundLogin: '#E7F3FB',
    loginButton: '#00A0DF',
    logoutButton: '#F2F2F2',
    progressCircle: '#47F08B',
    appBackground: '#FAFBFC',
    expandCardButtons: '#2251A3',
    expandCardButtonsDisabled: '#767676',
    searchInputBackground: '#f3f3f3',
    searchInputFont: '#7a7a7a',
    contactIcons: '#2251A3',
    sideMenuFont: '#767676',
    sideMenuFontOnFocus: '#2251A3',
    selectedRightSideMenuItem: '#F4F4F4',
    etaCard: '#676767',
    etaCardDisabled: '#2251A3',
    icons: '#373737',
    table: {
      background: '#F9F9F9',
      border: '#E0E0E0',
      clear: '#757575',
    },
    chipsFont: '#181818',
    lightFont: '#666666',
    font: '#373737',
    altFont: '#2251A3',
    disabledFont: '#767676',
    success: '#3D8F4B',
    error: '#E53E3E',
    warning: '#FEBF00',
    buttons: '#0027B2',
    black: '#000000',
    white: '#FFFFFF',
    divisorLine: '#E6ECF1',
    progressBar: {
      base: '#E2E2E2',
      primary: '#47F08B',
      secondary: '#009FDD',
    },
    workStatus: {
      paid: '#2E7F2E',
      paidBg: '#E4F4E4',
      payProcessing: '#007FB0',
      payProcessingBg: '#D4EFFF',
      canceled: '#C71924',
      canceledBg: '#FFE5EA',
      workRemaining: '#6D6D6D',
      workRemainingBg: '#EAEAEA',
      paidDot: '#8CD28C',
      workRemainingDot: '#CBCBCB',
      canceledDot: '#FE7690',
      payProcessingDot: '#76CAFA',
    },
    calendar: {
      lightGreen: '#EAFFED',
      darkGreen: '#9EE6CF',
      lightBlue: '#F1FAFF',
      darkBlue: '#0052CC',
      lightPurple: '#E5E5FF',
      darkPurple: '#A8A8E5',
      extraDarkPurple: '#5A417B',
    },
    loader: {
      base: '#E53E3E',
      highlight: '#FAFBFC',
    },
    green700: '#3CA38B',
    blue800: '#2251A3',
    blue600: '#0093CC',
    blue500: '#009FDD',
    gray300: '#EAEAEA',
    gray700: '#212121',
    indigo600: '#5252CC',
  },
  fontSize: {
    xs: '12px',
    sm: '14px',
    base: '16px',
    lg: '18px',
    xl: '20px',
    xxl: '24px',
    x3l: '30px',
    x4l: '32px',
    x5l: '36px',
    x6l: '48px',
    x7l: '64px',
    x8l: '96px',
    x9l: '180px',
  },
  fontWeight: {
    light: '100',
    regular: '400',
    regMedium: '500',
    medium: '600',
    bold: '700',
  },
  fontFamily: {
    name: "'Inter', sans-serif",
  },
  height: {
    header: '4.5rem',
    sideBarItem: '103px;',
  },
  width: {
    menuNav: 300,
    mobileNav: 320,
    sideBar: 120,
    sideBarRight: 350,
  },
  breakpoints: {
    mobile: {
      min: '320px',
      max: '480px',
    },
    tablet: {
      min: '481px',
      max: '1024px',
    },
    desktop: {
      min: '1025px',
    },
  },
  letterSpacing: {
    tighter: '-0.05rem',
    tight: '-0.025rem',
    normal: '0',
    wide: '0.025rem',
    wider: '0.05rem',
    widest: '0.1rem',
  },
  zIndex: {
    navBar: 100,
    menu: 130,
    blur: 120,
    modal: {
      background: 200,
      content: 210,
    },
    dropdown: {
      background: 230,
      content: 240,
    },
    toast: {
      background: 110,
      content: 120,
    },
  },
};

export default theme;
