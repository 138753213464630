import styled from '@emotion/styled';

export const ProgressWrapper = styled.div`
  max-width: 140px;
  max-height: 140px;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    max-width: 120px;
    max-height: 120px;
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const NumberWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
`;

export const CircularProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    flex-direction: row;
    justify-content: space-evenly;
  }
`;
