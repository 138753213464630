export const APPROVAL_NEEDED = 'approval_needed';
export const COMPLETED = 'paid';
export const ASSIGNED = 'assigned';
export const COMPLETED_PROJECT_LOG = 'completed';
export const PENDING = 'pending';
export const PAY_PROCESSING = 'payment_processing';
export const WORK_REMAINING = 'work_remaining';
export const CANCELED = 'cancelled';

export const BACKEND_STATUS = {
  provisional: 'provisional',
  rejected: 'rejected',
  unassigned: 'unassigned',
  assigned: 'assigned',
  completed: 'completed',
  approved: 'approved',
  cancelled: 'cancelled',
  approval_needed: 'approval_needed',
  pending: 'pending',
  failed: 'failed',
};

export const GROUP_BY_OPTIONS = {
  PAYMENTS: 'payment',
  WORK_ORDERS: 'work_order',
};
