import { HOME_MILESTONES_TYPES } from './homeMolestonesTypes';

export interface getHomeMilestonesProps {
  homeMilestones: [];
}

export interface HomeMilestonesData {
  id: number;
  token: string;
}

export const GET_HOME_MILESTONES = (payload: getHomeMilestonesProps | any) => ({
  type: HOME_MILESTONES_TYPES.GET_HOME_MILESTONES,
  payload,
});

export const SET_LOADING_HOME_MILESTONES = (payload: boolean) => ({
  type: HOME_MILESTONES_TYPES.SET_LOADING_HOME_MILESTONES,
  payload,
});

export const SET_HOME_MILESTONES = (payload: any) => ({
  type: HOME_MILESTONES_TYPES.SET_HOME_MILESTONES,
  payload,
});
