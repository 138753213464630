import { IconPropTypes } from './types';

const CompletedIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    viewBox="0 0 20 20"
    width={width || '100%'}
    height={height || '100%'}
    xmlSpace="preserve"
  >
    <path
      d="M10.2 1C5.1 1 1 5.1 1 10.2s4.1 9.2 9.2 9.2 9.2-4.1 9.2-9.2C19.3 5.1 15.2 1 10.2 1zm3.9 7.6c.1-.1.1-.2.2-.3V8c0-.1 0-.2-.1-.3-.1-.1-.1-.2-.2-.3-.1-.1-.2-.1-.3-.2h-.3c-.1 0-.2 0-.3.1-.1.1-.2.1-.3.2l-3.6 4.3L7.4 10c-.1-.2-.3-.2-.6-.2-.2 0-.4.1-.6.2-.1.2-.2.4-.2.6 0 .2.1.4.2.6l2.5 2.5c.1.1.2.1.3.2.1 0 .2.1.3.1.1 0 .2 0 .3-.1.1-.1.2-.1.3-.2l4.2-5.1z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: color || '#0b9962',
      }}
    />
  </svg>
);

export default CompletedIcon;
