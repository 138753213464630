import { IconPropTypes } from './types';

const LeftIconMenu = ({ width, height }: IconPropTypes) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="5" fill="#E7E7E7" />
    <path
      d="M10 17H24"
      stroke="#454545"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11H24"
      stroke="#454545"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 23H24"
      stroke="#454545"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LeftIconMenu;
