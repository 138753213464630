import { useTheme } from '@emotion/react';
import ChevronEndLeftIcon from 'assets/svgs/ChevronEndLeftIcon';
import ChevronEndRightIcon from 'assets/svgs/ChevronEndRightIcon';
import ChevronLeftIcon from 'assets/svgs/ChevronLeftIcon';
import ChevronRightIcon from 'assets/svgs/ChevronRightIcon';
import { useIsMobile } from 'features/app/hooks/screen';
import {
  SET_LIMIT_PAGE,
  SET_PAGE,
} from 'features/app/store/projectLog/projectLogActions';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../../../Commons/Inputs/Dropdown';
import { WSpacer } from '../../../../../Commons/Spacer.styles';
import { TextSm } from '../../../../../Commons/Text/Text';
import {
  Arrows,
  Limit,
  Pages,
  PaginationWrapper,
  Numbers,
  Button,
} from './Pagination.styles';

interface PaginationProps {
  totalItems: number;
  totalPages: number;
  lastPage: number;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems,
  totalPages,
  lastPage,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { limitPage, page } = useSelector(
    (store: RootStateOrAny) => store.projectLog
  );
  const isMobile = useIsMobile();
  const itemsPerPage = [
    { id: '50', name: '50' },
    { id: '100', name: '100' },
    { id: '150', name: '150' },
    { id: '200', name: '200' },
  ];

  const onPageLimitChange = (value: string) => {
    dispatch(SET_LIMIT_PAGE(parseInt(value, 10)));
  };

  const onPageChange = (direction: string) => {
    if (direction === 'next' && page < totalPages) {
      dispatch(SET_PAGE(page + 1));
    }
    if (direction === 'prev' && page > 1) {
      dispatch(SET_PAGE(page - 1));
    }
    if (direction === 'first' && page !== 1) {
      dispatch(SET_PAGE(1));
    }
    if (direction === 'last' && page !== lastPage) {
      dispatch(SET_PAGE(lastPage));
    }
  };

  return (
    <PaginationWrapper>
      <Pages>
        <Limit>
          {!isMobile && (
            <>
              <TextSm>
                <FormattedMessage id="paylog.linesPerPage" />
              </TextSm>
              <WSpacer width={5} />
            </>
          )}
          <Dropdown
            options={itemsPerPage}
            backgroundColor={theme.color.white}
            color={theme.color.font}
            preValue={{ id: limitPage, name: `${limitPage}` }}
            onChange={onPageLimitChange}
            borderRadius={theme.borderRadius.sm}
            width="70px"
            arrowSize={12}
          />
        </Limit>
        <Numbers>
          <TextSm>
            {page} - {totalPages}
          </TextSm>
          <WSpacer width={5} />
          <TextSm color={theme.color.disabledFont}>
            <FormattedMessage id="paylog.of" /> {totalItems}
          </TextSm>
        </Numbers>
        <Arrows>
          <Button onClick={() => onPageChange('first')} disabled={page === 1}>
            <ChevronEndLeftIcon
              width={24}
              height={24}
              color={page === 1 ? theme.color.disabledFont : theme.color.font}
            />
          </Button>
          <Button onClick={() => onPageChange('prev')} disabled={page === 1}>
            <ChevronLeftIcon
              width={22}
              height={22}
              color={page === 1 ? theme.color.disabledFont : theme.color.font}
            />
          </Button>
          <Button onClick={() => onPageChange('next')}>
            <ChevronRightIcon
              width={22}
              height={22}
              color={
                page === lastPage ? theme.color.disabledFont : theme.color.font
              }
            />
          </Button>
          <Button onClick={() => onPageChange('last')}>
            <ChevronEndRightIcon
              width={24}
              height={24}
              color={
                page === lastPage ? theme.color.disabledFont : theme.color.font
              }
            />
          </Button>
        </Arrows>
      </Pages>
    </PaginationWrapper>
  );
};

export default Pagination;
