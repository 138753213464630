import ContentLoader from 'react-content-loader';

export const CalendarLoader: React.FC = () => (
  <ContentLoader width="100%" height="100%" viewBox="0 0 500 500">
    <path d="M179.767,10c0,-2.208 -1.792,-4 -4,-4l-168.761,0c-2.208,0 -4,1.792 -4,4l0,8c0,2.208 1.792,4 4,4l168.761,0c2.208,0 4,-1.792 4,-4l0,-8Z" />
    <path d="M69.611,44.32c-0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.805,-0c-9.329,-0 -16.903,7.574 -16.903,16.902l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M70.759,117.433c0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.805,0c-9.329,0 -16.903,7.574 -16.903,16.903l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M140.856,44.32c0,-9.328 -7.573,-16.902 -16.902,-16.902l-33.806,-0c-9.329,-0 -16.902,7.574 -16.902,16.902l-0,36.082c-0,9.329 7.573,16.903 16.902,16.903l33.806,-0c9.329,-0 16.902,-7.574 16.902,-16.903l0,-36.082Z" />
    <path d="M142.005,117.433c-0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.806,0c-9.329,0 -16.902,7.574 -16.902,16.903l-0,36.082c-0,9.329 7.573,16.903 16.902,16.903l33.806,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M212.102,44.32c0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.805,-0c-9.329,-0 -16.903,7.574 -16.903,16.902l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M213.25,117.433c0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.805,0c-9.329,0 -16.903,7.574 -16.903,16.903l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M283.348,44.32c-0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.806,-0c-9.329,-0 -16.902,7.574 -16.902,16.902l-0,36.082c-0,9.329 7.573,16.903 16.902,16.903l33.806,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M284.496,117.433c-0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.806,0c-9.328,0 -16.902,7.574 -16.902,16.903l-0,36.082c-0,9.329 7.574,16.903 16.902,16.903l33.806,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M354.593,44.32c0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.805,-0c-9.329,-0 -16.903,7.574 -16.903,16.902l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M355.741,117.433c0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.805,0c-9.329,0 -16.903,7.574 -16.903,16.903l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M425.839,44.32c-0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.806,-0c-9.328,-0 -16.902,7.574 -16.902,16.902l-0,36.082c-0,9.329 7.574,16.903 16.902,16.903l33.806,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M426.987,117.433c-0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.806,0c-9.328,0 -16.902,7.574 -16.902,16.903l-0,36.082c-0,9.329 7.574,16.903 16.902,16.903l33.806,-0c9.329,-0 16.903,-7.574 16.903,-16.903l-0,-36.082Z" />
    <path d="M497.084,44.32c0,-9.328 -7.574,-16.902 -16.903,-16.902l-33.805,-0c-9.329,-0 -16.903,7.574 -16.903,16.902l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M498.232,117.433c0,-9.329 -7.574,-16.903 -16.903,-16.903l-33.805,0c-9.329,0 -16.903,7.574 -16.903,16.903l0,36.082c0,9.329 7.574,16.903 16.903,16.903l33.805,-0c9.329,-0 16.903,-7.574 16.903,-16.903l0,-36.082Z" />
    <path d="M252.514,9.75c0,-2.07 -1.68,-3.75 -3.75,-3.75l-40.714,0c-2.07,0 -3.75,1.68 -3.75,3.75l-0,7.5c-0,2.07 1.68,3.75 3.75,3.75l40.714,0c2.07,0 3.75,-1.68 3.75,-3.75l0,-7.5Z" />
  </ContentLoader>
);

export default CalendarLoader;
