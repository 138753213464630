export const MODALS_TYPES = {
  ON_BONUS_MODAL_VISIBILITY_CHANGE: 'OnBonusModalVisibilityChange',
  ON_HELP_MODAL_VISIBILITY_CHANGE: 'ON_HELP_MODAL_VISIBILITY_CHANGE',
  ON_USER_MODAL_VISIBILITY_CHANGE: 'ON_USER_MODAL_VISIBILITY_CHANGE',
};

export type OnBonusModalChange = {
  type: typeof MODALS_TYPES.ON_BONUS_MODAL_VISIBILITY_CHANGE;
  payload: any;
};

export type OnHelpModalChange = {
  type: typeof MODALS_TYPES.ON_HELP_MODAL_VISIBILITY_CHANGE;
  payload: any;
};

export type OnUserModalChange = {
  type: typeof MODALS_TYPES.ON_USER_MODAL_VISIBILITY_CHANGE;
  payload: any;
};

export type ModalsActions =
  | OnBonusModalChange
  | OnHelpModalChange
  | OnUserModalChange;
