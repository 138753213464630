import CloseMenuMobileIcon from 'assets/svgs/CloseMenuMobileIcon';
import FilterIcon from 'assets/svgs/FilterIcon';
import theme from 'theme';
import { Text } from '../../../Text/Text';

import {
  SideMenuCardWrapper,
  SideMenuDataContainer,
  SideMenuFloatActionBtn,
} from './SideMenuCard.styles';

interface SideMenuCardProps {
  amount: String;
  title: String;
  description: String;
  icon: React.ReactNode;
  onClick: () => void;
  isSelected: boolean;
  onCancel: () => void;
}

const SideMenuCard: React.FC<SideMenuCardProps> = ({
  title,
  description,
  icon,
  amount,
  onClick,
  isSelected,
  onCancel,
}) => (
  <SideMenuCardWrapper onClick={!isSelected ? onClick : undefined}>
    {icon}
    <SideMenuDataContainer>
      <Text weight={theme.fontWeight.bold} fontSize="16">
        {title}
      </Text>
      <Text
        weight={theme.fontWeight.regular}
        fontSize="12"
        color={isSelected ? '#212121' : '#717070'}
      >
        {description}
      </Text>
      <Text
        weight={theme.fontWeight.bold}
        fontSize="26"
        style={{ marginTop: '2px' }}
      >
        {amount}
      </Text>
    </SideMenuDataContainer>
    {/* float btn */}
    {isSelected ? (
      <SideMenuFloatActionBtn onClick={onCancel}>
        <CloseMenuMobileIcon width={20} height={20} />
      </SideMenuFloatActionBtn>
    ) : (
      <SideMenuFloatActionBtn>
        <FilterIcon width={20} height={20} />
      </SideMenuFloatActionBtn>
    )}
  </SideMenuCardWrapper>
);

export default SideMenuCard;
