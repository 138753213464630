import styled from '@emotion/styled';

export const Container = styled.div`
  position: relative;
`;

export const Circle = styled.circle`
  fill: transparent;
  stroke: ${({ theme }) => theme.color.searchInputBackground};
  stroke-linecap: round;
`;

export const FilledCircle = styled(Circle)`
  stroke: ${({ theme }) => theme.color.progressCircle};
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.5s ease-out;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.color.font};
  display: flex;
  justify-content: center;
`;

export const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
