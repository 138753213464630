import Content from 'features/app/components/Commons/Content.styles';
import HomeView from '../components/ProjectLogView';

interface HomePageProps {
  hideLayout: (active: boolean) => void;
}

const HomePage: React.FC<HomePageProps> = ({ hideLayout }) => {
  hideLayout(false);
  return (
    <Content>
      <HomeView />
    </Content>
  );
};

export default HomePage;
