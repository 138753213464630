import styled from '@emotion/styled';

export const HomesList = styled.div`
  width: 100%;
  padding-right: 2%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;

  @media (max-width: 768px) {
    padding: 0;
    grid-template-columns: repeat(auto-fit, 100%);
  }

  @media (min-width: 769px) and (max-width: 1439px) {
    padding: 0;
    grid-template-columns: repeat(2, 1fr);
  }
`;
