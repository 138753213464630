import { IconPropTypes } from './types';

const CalendarIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      d="M19 4H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2ZM16 2v4M8 2v4M3 10h18"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalendarIcon;
