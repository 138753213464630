import { rightSideBarTypes } from 'features/app/components/Commons/MenuRightSideBar/types';
import { Text } from 'features/app/components/Commons/Text/Text';
import { centsToDollars } from 'helpers/centsToDollars';
import { InfoCardProps, LineItemsScopeProps } from '../Card/HomeCardTypes';
import { WrapperTotalScope } from './TotalScope.styles';

export interface TotalScopeProps {
  total: number;
  lineItemsScope: LineItemsScopeProps;
  infoCard: InfoCardProps;
  rightNavBarSelection: string;
}

function TotalScope({
  total,
  lineItemsScope,
  infoCard,
  rightNavBarSelection,
}: TotalScopeProps) {
  const getTextTitle = () => {
    if (rightNavBarSelection === rightSideBarTypes.pendingPayment) {
      return 'Pending Payment';
    }
    if (rightNavBarSelection === rightSideBarTypes.futureWork) {
      return 'Due This Week';
    }
    if (rightNavBarSelection === rightSideBarTypes.earnThisMonth) {
      if (lineItemsScope.approved < lineItemsScope.totalScope) {
        return 'Due This Week';
      }
      if (
        lineItemsScope.approved === lineItemsScope.totalScope &&
        infoCard.pendingPaymentsAmount > 0
      ) {
        return 'Pending Payment';
      }
      return 'You did it!';
    }
    if (lineItemsScope.approved === lineItemsScope.totalScope) {
      return 'You did it!';
    }
    return 'Total scope';
  };

  const getSubtitleText = () => {
    if (rightNavBarSelection === rightSideBarTypes.pendingPayment) {
      return centsToDollars(infoCard.pendingPaymentsAmount);
    }
    if (rightNavBarSelection === rightSideBarTypes.futureWork) {
      return centsToDollars(infoCard.futurePayableJobsAmount);
    }
    if (rightNavBarSelection === rightSideBarTypes.earnThisMonth) {
      if (lineItemsScope.approved < lineItemsScope.totalScope) {
        return centsToDollars(infoCard.futurePayableJobsAmount);
      }
      if (
        lineItemsScope.approved === lineItemsScope.totalScope &&
        infoCard.pendingPaymentsAmount > 0
      ) {
        return centsToDollars(infoCard.pendingPaymentsAmount);
      }
      return 'Congrats!';
    }
    if (lineItemsScope.approved === lineItemsScope.totalScope) {
      return 'Congrats!';
    }
    return centsToDollars(infoCard.total);
  };

  return (
    <WrapperTotalScope>
      <Text color="#373737" weight="400" fontSize="14">
        {getTextTitle()}
      </Text>
      <Text color="#373737" fontSize="15" weight="700">
        {getSubtitleText()}
      </Text>
    </WrapperTotalScope>
  );
}

export default TotalScope;
