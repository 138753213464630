import styled from '@emotion/styled';

interface TextProps {
  fontSize?: string;
  withBold?: boolean;
  overrideColor?: string;
}

interface NumberProps {
  overrideColor?: string;
}

interface CardSubContainerProps {
  customMargin?: number;
  isSafariUnder14?: boolean;
}

interface HeaderContainerProps {
  hasIcon?: boolean;
}

interface NumberWrapperProps {
  alignRight?: boolean;
}

interface ButtonProps {
  showButton?: boolean;
}

export const Text = styled.p<TextProps>`
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.base};
  color: ${({ theme, overrideColor }) => overrideColor || theme.color.font};
  font-weight: ${({ theme, withBold }) =>
    withBold ? theme.fontWeight.bold : theme.fontWeight.regular};
`;

export const CardSubContainer = styled.div<CardSubContainerProps>`
  display: ${({ isSafariUnder14 }) => (isSafariUnder14 ? 'block' : 'flex')};
  margin: ${({ customMargin }) => customMargin || 0}px;
  justify-content: space-around;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  height: 100%;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    margin-left: 0;
  }
`;

export const HeaderContainer = styled.div<HeaderContainerProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  z-index: 1;
  text-align: start;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    grid-template-columns: 1fr 40px;
    grid-template-rows: 1fr;
    flex-direction: row;
    align-items: center;
  }
`;

export const IconWrapper = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  z-index: 0;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    position: inherit;
    padding: 0;
    height: auto;
  }
`;

export const IconContainer = styled.div`
  height: 120px;
  width: 120px;
  z-index: 0;
  padding: 15px 15px 15px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    height: 35px;
    width: 35px;
    padding: 0;
  }
`;

export const CardButton = styled.button<ButtonProps>`
  all: unset;
  align-items: flex-start;
  cursor: ${({ showButton }) => (showButton ? 'pointer' : 'auto')};
  z-index: 2;
  opacity: ${({ showButton }) => (showButton ? 1 : 0)};

  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    text-align: center;
    padding: 5px;
  }
  &:focus {
    outline: none;
  }
`;

export const NumberWrapper = styled.div<NumberWrapperProps>`
  text-align: ${({ alignRight }) => (alignRight ? 'end' : 'start')};
  right: 0px;
  z-index: 1;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    position: relative;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }
`;

export const Number = styled.div<NumberProps>`
  font-family: ${({ theme }) => theme.fontFamily.name};
  color: ${({ theme, overrideColor }) => overrideColor || theme.color.font};
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.x5l};
  line-height: 46px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.x3l};
  }
`;
