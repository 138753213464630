import { getHomeMilestones } from 'features/app/api/homes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  SET_LOADING_HOME_MILESTONES,
  SET_HOME_MILESTONES,
  HomeMilestonesData,
  getHomeMilestonesProps,
} from './homeMilestonesActions';
import { HOME_MILESTONES_TYPES } from './homeMolestonesTypes';

function* getHomeMilestonesApi({ payload }: HomeMilestonesData | any) {
  yield put(SET_LOADING_HOME_MILESTONES(true));
  try {
    const data: getHomeMilestonesProps = yield call(
      getHomeMilestones,
      payload.id,
      payload.token
    );
    yield put(SET_HOME_MILESTONES(data));
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
}

export default function* getHomeMilestoneDataSaga(): any {
  yield all([
    yield takeLatest(
      HOME_MILESTONES_TYPES.GET_HOME_MILESTONES,
      getHomeMilestonesApi
    ),
  ]);
}
