import styled from '@emotion/styled';

interface MenuNavPropTypes {
  showMenu?: boolean;
}

export const DesktopMenuSideBar = styled.div<MenuNavPropTypes>`
  display: flex;
  position: fixed;
  height: 100%;
  flex-direction: column;
  width: ${({ theme }) => theme.width.sideBar}px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const Items = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: scroll;
  z-index: ${({ theme }) => theme.zIndex.navBar};
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SFR3LogoWrapper = styled.div`
  margin-bottom: 30px;
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SFR3BrandName = styled.p`
  width: 100%;
  color: ${({ theme }) => theme.color.blue800};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-align: center;
  font-size: 25px;
`;

export const ThemeToggleWrapper = styled.div`
  width: 100%;
  height: fit-content;
  margin-top: 30vh;
`;
