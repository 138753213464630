import styled from '@emotion/styled';

interface CardStylesProps {
  customPadding?: number;
  maxWidth?: string;
  overrideColor?: string;
  customMargin?: number;
  textOverflow?: string;
  width?: string;
}
export const CardStyles = styled.div<CardStylesProps>`
  margin: ${({ customMargin }) => customMargin || 0}px;
  position: relative;
  box-shadow: ${({ theme }) => theme.boxShadow.staticCards};
  padding: ${({ customPadding }) => customPadding}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${({ width }) => width || '100%'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};

  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme, overrideColor }) =>
    overrideColor || theme.color.white};
  overflow: ${({ textOverflow }) => textOverflow || 'inherit'};
`;
