import { Route, Routes } from 'react-router-dom';

import {
  SUMMARY_PATH,
  HOMES_PATH,
  HOMES_KEY,
  SUMMARY_KEY,
  REFERRALS_KEY,
  REFERRALS_PATH,
  HOME_PATH,
  PERFORMANCE_KEY,
  PERFORMANCE_PATH,
} from 'features/app/constants/navigation';
import HomesPage from 'features/app/pages/HomesPage';
import HomePage from 'features/app/pages/HomePage';
import SummaryPage from 'features/app/pages/SummaryPage';
import CalendarPage from 'features/app/pages/CalendarPage';
import NoMatchPage from 'features/app/pages/NoMatchPage';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { navigationFlags } from 'features/app/utils/flags';
import MainLayout from 'features/app/layouts/MainLayout';
import PerformancePage from 'features/app/pages/PerformancePage';

interface RouteProps {
  id: string;
  element: JSX.Element;
  path: string;
}

interface flagsProps {
  summary?: boolean;
  calendar?: boolean;
  response: boolean;
  alreadySet?: boolean;
}

const AuthenticatedApp = () => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const [routesKeys, setRoutesKeys] = useState([HOMES_KEY]);
  const [disableLayout, setDisableLayout] = useState(false);
  const [flag, setFlag] = useState<flagsProps>({
    response: false,
    alreadySet: false,
  });

  useEffect(() => {
    const getFlag = async () => {
      const flags = await navigationFlags(
        data?.vendor?.id,
        data?.vendor?.markets
      );
      setFlag(flags);
    };

    if (!flag.response) getFlag();
  });

  useEffect(() => {
    if (flag.response && !flag.alreadySet) {
      const navigationKeysTmp = routesKeys;

      // CHECK REDIRECT
      // if (flag.summary) navigationKeysTmp.push(SUMMARY_KEY);
      // if (flag.calendar) navigationKeysTmp.push(CALENDAR_KEY);
      // if (flag.calendar) navigationKeysTmp.push(CALENDAR_KEY);
      setRoutesKeys([...navigationKeysTmp]);
      setFlag({ ...flag, alreadySet: true });
    }
  }, [routesKeys, flag]);

  const handleLayout = (state: boolean) => {
    setDisableLayout(state);
  };

  const routes = [
    {
      id: SUMMARY_KEY,
      element: <SummaryPage hideLayout={handleLayout} />,
      path: SUMMARY_PATH,
    },
    {
      id: REFERRALS_KEY,
      element: <CalendarPage hideLayout={handleLayout} />,
      path: REFERRALS_PATH,
    },
    {
      id: HOMES_KEY,
      element: <HomesPage hideLayout={handleLayout} />,
      path: HOME_PATH,
    },
    {
      id: HOMES_KEY,
      element: <HomePage hideLayout={handleLayout} />,
      path: `${HOMES_PATH}/:id`,
    },
    {
      id: PERFORMANCE_KEY,
      element: <PerformancePage hideLayout={handleLayout} />,
      path: PERFORMANCE_PATH,
    },
  ];

  const navigationFeature = () => {
    const newRoutes: RouteProps[] = [];
    routes.forEach((route) => {
      if (routesKeys?.includes(route.id)) {
        newRoutes.push(route);
      }
    });
    // if (!routesKeys.includes(SUMMARY_KEY)) {
    //   newRoutes.push({
    //     id: SUMMARY_KEY,
    //     element: <SummaryPage hideLayout={handleLayout} />,
    //     path: SUMMARY_PATH,
    //   });
    // }
    // if (!routesKeys.includes(HOMES_KEY)) {
    //   newRoutes.push({
    //     id: HOMES_KEY,
    //     element: <HomesPage hideLayout={handleLayout} />,
    //     path: HOMES_PATH,
    //   });
    // }
    return newRoutes;
  };

  return (
    <MainLayout disable={disableLayout}>
      <Routes>
        {navigationFeature().map(({ element, path, id }) => (
          <Route key={id} path={path} element={element} />
        ))}
        <Route path="*" element={<NoMatchPage hideLayout={handleLayout} />} />
      </Routes>
    </MainLayout>
  );
};

export default AuthenticatedApp;
