import { useEffect, useState } from 'react';

const getWindowSize = () => {
  if (!window) return null;

  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export const useIsMobile = () => {
  const windowSize = useWindowSize();
  return windowSize && windowSize.width >= 320 && windowSize.width <= 480;
};

export const useIsTablet = () => {
  const windowSize = useWindowSize();
  return windowSize && windowSize.width > 480 && windowSize.width < 1025;
};

export const useIsDesktop = () => {
  const windowSize = useWindowSize();
  return windowSize && windowSize.width > 1024;
};

export const useShowRightNavBar = () => {
  const { href } = window.location;
  return (
    href.split('/').filter((item: string) => item === 'homes').length === 0
  );
};
