import MoneyIcon from 'assets/svgs/MoneyIcon';

import { usePaymentsSummary } from 'features/app/hooks/data';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CardWrapperRightNavBar } from '../BonusCard/BonusCard.styles';
import SideMenuCard from '../SideMenuCard';

interface EarnThisMonthCardProps {
  onClick: () => void;
  isSelected: boolean;
  onCancel: () => void;
}

const EarnThisMonthCard: React.FC<EarnThisMonthCardProps> = ({
  onClick,
  isSelected,
  onCancel,
}) => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  const paymentSummary = usePaymentsSummary(data?.token);

  return (
    <CardWrapperRightNavBar isSelected={isSelected}>
      <SideMenuCard
        onCancel={onCancel}
        title="Earn this month"
        description="Money you've received"
        amount={paymentSummary.data?.currentMonthCompletedPaymentsAmount}
        icon={<MoneyIcon width={40} height={42} />}
        onClick={onClick}
        isSelected={isSelected}
      />
    </CardWrapperRightNavBar>
  );
};

export default EarnThisMonthCard;
