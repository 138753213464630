import { HomesListActions, listOfHomesTypes } from './ListOfHomestypes';

const initialState = {
  homes: [],
  loadingHomes: true,
  page: 1,
  totalOfPages: 1,
};

const ListOfHomesReducer = (state = initialState, action: HomesListActions) => {
  switch (action.type) {
    case listOfHomesTypes.LOADING_HOMES:
      return {
        ...state,
        loadingHomes: action.payload,
      };
    case listOfHomesTypes.SET_INFORMATION_HOME:
      return {
        ...state,
        homes: action.payload.data.properties,
        totalOfPages: action.payload.headers.totalPages,
      };
    case listOfHomesTypes.SET_PAGE_HOME:
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};

export default ListOfHomesReducer;
