import styled from '@emotion/styled';

export const TotalOfPagesWrapper = styled.div`
  background-color: white;
  padding-inline: 16px;
  border-top: 1px;
  border-bottom: 1px;
  border-color: #d9d9d9;
  border-style: solid;
  align-items: center;
  display: flex;
`;
