import { LogoPropTypes } from './types';

const MoreInfoIcon = ({ width, height }: LogoPropTypes) => (
  <svg
    viewBox="0 0 20 20"
    width={width || '100%'}
    height={height || '100%'}
    fill="none"
  >
    <path
      d="M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.6V10"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 6.3999H10.009"
      stroke="#212121"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MoreInfoIcon;
