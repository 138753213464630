import { Text } from 'features/app/components/Commons/Text/Text';
import { WarningWrapper } from './WarningTooltip.styles';

const warningsOptions = {
  deliveredOutOfDate: 'delivered_out_of_date',
  closeToDeliveryDate: 'close_to_delivery_date',
};

type WarningTooltipProps = {
  text: string;
  // eslint-disable-next-line react/require-default-props
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
};

export default function WarningTooltip({ text, ref }: WarningTooltipProps) {
  return (
    <WarningWrapper ref={ref}>
      <Text color="white" fontSize="15" weight="700">
        {text === warningsOptions.deliveredOutOfDate
          ? 'Delivered out of date'
          : 'Close to Delivery date'}
      </Text>
    </WarningWrapper>
  );
}
