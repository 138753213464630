import styled from '@emotion/styled';

export const SearchResultsWrapper = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.height.header};
  left: 0;
  width: 100%;
  height: calc(100% - ${({ theme }) => theme.height.header});
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: ${({ theme }) => theme.color.white};
`;

export const ItemResult = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  flex-direction: column;
  margin: 2px;
  padding: 2px;
  &:hover {
    background-color: ${({ theme }) => theme.color.searchInputBackground};
  }
`;

export const TextSpan = styled.div`
  display: flex;
  flex-direction: row;
`;

export const NoResult = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

export const EmptySearch = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  height: 100%;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
`;
