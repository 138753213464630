import ReactTooltip from 'react-tooltip';
import './TooltipClassName.css';

export interface TooltipInterfaceProps {
  id?: string;
  clickeable?: boolean;
  Content?: any;
  ref?: React.LegacyRef<ReactTooltip> | undefined;
}

export default function Tooltip({
  id,
  clickeable,
  Content,
  ref,
}: TooltipInterfaceProps) {
  return (
    <ReactTooltip
      className="opaque"
      clickable={false}
      event={clickeable ? 'click' : undefined}
      effect="solid"
      backgroundColor="#686464"
      id={id}
      getContent={() => Content}
      ref={ref}
    />
  );
}
