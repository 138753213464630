import styled from '@emotion/styled';

export const SearchResults = styled.div`
  position: absolute;
  top: 40px;
  background: ${({ theme }) => theme.color.white};
  padding: 10px;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  width: 420px;
  box-shadow: ${({ theme }) => theme.boxShadow.menu};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet.max}) {
    width: 100%;
    left: 0;
  }
`;

export const NoResult = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  flex-direction: column;
  margin: 5px;
  padding: 5px;
`;

export const ItemResult = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxl};
  margin: 2px;
  padding: 2px;
  &:hover {
    background-color: ${({ theme }) => theme.color.searchInputBackground};
  }
`;
