import { all, fork } from 'redux-saga/effects';
import authSaga from './auth/sagas';
import futureWorkPaymentsDataSaga from './futureWorkPayments/futureWorkPaymentsSagas';
import HomeByIdDataSaga from './homeById/homeByIdSagas';
import getHomeMilestoneDataSaga from './homeMilestones/homeMilestonesSagas';
import HomeTotalsDataSaga from './homeTotals/homeTotalsSagas';
import getHomesVendor from './listOfHomes/ListOfHomesSagas';
import getProjectLogDataSaga from './projectLog/projectLogSagas';
import getVendorEtaDataSagas from './vendorEta/vendorEtaSagas';

export default function* rootSaga() {
  yield all([
    fork(authSaga),
    getProjectLogDataSaga(),
    futureWorkPaymentsDataSaga(),
    HomeTotalsDataSaga(),
    HomeByIdDataSaga(),
    getHomeMilestoneDataSaga(),
    getVendorEtaDataSagas(),
    getHomesVendor(),
  ]);
}
