import { VENDOR_ETA_TYPES } from './vendorEtaTypes';

export interface getVendorEtaProps {
  eta: string;
}

export interface VendorEtaData {
  id: number;
  token: string;
}

export const ON_GET_VENDOR_ETA = (payload: getVendorEtaProps | any) => ({
  type: VENDOR_ETA_TYPES.ON_GET_VENDOR_ETA,
  payload,
});

export const ON_LOADING_VENDOR_ETA = (payload: boolean) => ({
  type: VENDOR_ETA_TYPES.ON_LOADING_VENDOR_ETA,
  payload,
});

export const ON_SET_VENDOR_ETA = (payload: any) => ({
  type: VENDOR_ETA_TYPES.ON_SET_VENDOR_ETA,
  payload,
});
