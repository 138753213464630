import ContentLoader from 'react-content-loader';

export const SummaryPaylogLoader: React.FC = () => (
  <ContentLoader width="100%" height="100%" viewBox="0 0 800 250">
    <path d="M794.133,30.5c0,-9.107 -7.393,-16.5 -16.5,-16.5l-754,0c-9.106,0 -16.5,7.393 -16.5,16.5l0,33c0,9.107 7.394,16.5 16.5,16.5l754,0c9.107,0 16.5,-7.393 16.5,-16.5l0,-33Z" />
    <path d="M794.133,108.345c0,-9.107 -7.393,-16.5 -16.5,-16.5l-754,-0c-9.106,-0 -16.5,7.393 -16.5,16.5l0,33c0,9.106 7.394,16.5 16.5,16.5l754,-0c9.107,-0 16.5,-7.394 16.5,-16.5l0,-33Z" />
    <path d="M794.133,186.189c0,-9.106 -7.393,-16.5 -16.5,-16.5l-754,0c-9.106,0 -16.5,7.394 -16.5,16.5l0,33c0,9.107 7.394,16.5 16.5,16.5l754,0c9.107,0 16.5,-7.393 16.5,-16.5l0,-33Z" />
  </ContentLoader>
);

export default SummaryPaylogLoader;
