import HomeMoneyIcon from 'assets/svgs/HomeMoneyIcon';

import { usePaymentsSummary } from 'features/app/hooks/data';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CardWrapperRightNavBar } from '../BonusCard/BonusCard.styles';
import SideMenuCard from '../SideMenuCard';

interface FutureWorkCardProps {
  onClick: () => void;
  isSelected: boolean;
  onCancel: () => void;
}

const FutureWorkCard: React.FC<FutureWorkCardProps> = ({
  onClick,
  isSelected,
  onCancel,
}) => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  const paymentSummary = usePaymentsSummary(data?.token);

  return (
    <CardWrapperRightNavBar isSelected={isSelected}>
      <SideMenuCard
        onCancel={onCancel}
        title="Future Work"
        description="Need to get done by next Friday"
        amount={paymentSummary.data?.futurePayableJobsAmount}
        icon={<HomeMoneyIcon width={40} height={42} />}
        onClick={onClick}
        isSelected={isSelected}
      />
    </CardWrapperRightNavBar>
  );
};

export default FutureWorkCard;
