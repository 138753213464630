import {
  useIsDesktop,
  useIsMobile,
  useIsTablet,
} from 'features/app/hooks/screen';
import { GetHomesFromHomeView } from 'features/app/store/listOfHomes/ListOfHomesActions';
import { SET_PAGE } from 'features/app/store/projectLog/projectLogActions';
import { OnSetSearchValue } from 'features/app/store/search/searchActions';
import { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import SortByFilter from '../Commons/Filters/SortByFilter';
import StatusFilter from '../Commons/Filters/StatusFilter';
import ZipCityFilter from '../Commons/Filters/ZipCityFilter';
import SearchBar from '../Commons/SearchBar';
import { HSpacer } from '../Commons/Spacer.styles';
import Loader from '../Homes/components/Loader/Loaders';
import ProjectBonusModal from '../Homes/components/ProjectBonusModal/ProjectBonusModal';
import FilterButton from './Components/FilterButton';
import ClearButton from './Components/FilterButton/ClearButton/ClearButton';
import HeaderHome from './Components/Header';

import {
  getStatusFilterWithOutNumber,
  SortByFilterTypes,
  StatusFilterTypes,
} from './Components/HomesCardsList/helper';
import HomeList from './Components/HomesCardsList/HomeList';
import PaginationHome from './Components/PaginationHome/PaginationHome';
import {
  BottomContainer,
  ButtonFilterIndividual,
  ButtonsFiltersWrapper,
  Content,
  GrayLineDivider,
  HeaderWrapper,
  HomeFilterContainer,
  HomeFiltersWrapper,
  HomeSearchAndFilterButtonWrapper,
  HomesWrapper,
  Selector,
} from './Homes.styles';

const Homes = () => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const { status, showFilters, sortBy, rightNavBarSelection } = useSelector(
    (state: RootStateOrAny) => state.homeFilters
  );
  const { bonusModalVisibility } = useSelector(
    (state: RootStateOrAny) => state.modals
  );

  const { loadingHomes, homes, totalOfPages } = useSelector(
    (store: RootStateOrAny) => store.ListOfHomes
  );

  const { homesSearch } = useSelector((state: RootStateOrAny) => state.search);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();

  useEffect(() => {
    dispatch(SET_PAGE(1));
    dispatch(GetHomesFromHomeView({ token: data?.token, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = (value: string) => {
    dispatch(OnSetSearchValue(value));
  };
  const optionsStatus = [`All`, `Active`, `Completed`, `With warnings`];
  const optionsSortBy = [
    'None',
    'Most recent activity',
    'Largest Scope size first',
    'Most Progress first',
    'Closest Due Date',
  ];

  const getTotalOfFiltersSelected = () => {
    let total = 0;
    if (sortBy.length > 0 && sortBy !== SortByFilterTypes.None) {
      total += 1;
    }
    if (
      status.length > 0 &&
      getStatusFilterWithOutNumber(status[0]) !== StatusFilterTypes.All
    ) {
      total += status.length;
    }
    return total;
  };

  return (
    <Content>
      <HeaderWrapper>
        <HeaderHome />
      </HeaderWrapper>
      <Selector>
        <HomeSearchAndFilterButtonWrapper>
          <SearchBar
            onSearch={onSearch}
            placeholder="Search address"
            value={homesSearch}
          />

          <ButtonsFiltersWrapper>
            <ButtonFilterIndividual>
              {getTotalOfFiltersSelected() > 0 && isDesktop && (
                <ClearButton firstOptionStatus={optionsStatus[0]} />
              )}
            </ButtonFilterIndividual>
            <ButtonFilterIndividual>
              <FilterButton
                selectedItems={getTotalOfFiltersSelected()}
                showFilters={showFilters}
              />
            </ButtonFilterIndividual>
          </ButtonsFiltersWrapper>
        </HomeSearchAndFilterButtonWrapper>
      </Selector>
      <HomeFiltersWrapper open={showFilters}>
        <HomeFilterContainer>
          <ZipCityFilter />
        </HomeFilterContainer>
        <HomeFilterContainer>
          <StatusFilter
            token={data?.token}
            sortByOption={sortBy}
            selectedItems={status}
            options={optionsStatus}
            paymentCriteria={rightNavBarSelection}
          />
        </HomeFilterContainer>
        <HomeFilterContainer>
          <SortByFilter
            selectedItem={sortBy}
            options={optionsSortBy}
            paymentCriteria={rightNavBarSelection}
            statusSelected={status}
            token={data?.token}
          />
        </HomeFilterContainer>
        {getTotalOfFiltersSelected() > 0 && (isMobile || isTablet) && (
          <ClearButton firstOptionStatus={optionsStatus[0]} />
        )}
      </HomeFiltersWrapper>
      <GrayLineDivider />
      <HSpacer height={30} />
      <HomesWrapper>
        {loadingHomes ? Loader() : HomeList(homesSearch, 'all', homes)}
      </HomesWrapper>
      {totalOfPages > 1 && (
        <BottomContainer>
          <GrayLineDivider />
          <PaginationHome totalOfPages={totalOfPages} token={data?.token} />
        </BottomContainer>
      )}
      {bonusModalVisibility && <ProjectBonusModal />}
    </Content>
  );
};

export default Homes;
