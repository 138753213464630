export interface SortByProps {
  selectedItem: string;
  options: string[];
  paymentCriteria: string;
  statusSelected: string[];
  token: string;
}

export const sortByOptionsApi = {
  none: 'none',
  mostRecentActivity: 'recent_activity',
  largestScopeSize: 'scope_size',
  mostProgress: 'progress',
  dueDate: 'due_date',
};
