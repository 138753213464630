import styled from '@emotion/styled';

export const WrapperHeaderDesktop = styled.div`
  background-color: white;
  position: fixed;
  width: 100%;
  height: 60px;
  z-index: 200;
  box-shadow: 0 2px 2px -2px gray;
`;

export const ContainerHeaderDesktop = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding-inline: 17px;
`;

export const FirstColumn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const BreadCrumbs = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
`;

export const SecondColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const LineDivisor = styled.div`
  height: 32px;
  width: 1px;
  background-color: #d8d8d8;
`;
export const ModalHelpContainer = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
`;

export const InfoButtonContainer = styled.div``;
