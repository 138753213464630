import { useTheme } from '@emotion/react';
import Broken from 'assets/svgs/BrokenLink';
import FullLogo from 'assets/svgs/FullLogo';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../Commons/Buttons/PrimaryButton/PrimaryButton';
import { HSpacer } from '../Commons/Spacer.styles';
import { Headline, TextBase, TextSm } from '../Commons/Text/Text';
import {
  BrokenContent,
  BrokenLinkWrapper,
  IllustrationWrapper,
  LogoWrapper,
  ButtonWrapper,
} from './BrokenLink.styles';

const BrokenLink: React.FC<{}> = () => {
  const theme = useTheme();

  return (
    <BrokenLinkWrapper>
      <BrokenContent>
        <IllustrationWrapper>
          <Broken />
        </IllustrationWrapper>
        <Headline>
          <FormattedMessage id="broken.invalid" />
        </Headline>
        <HSpacer height={10} />
        <TextBase color={theme.color.lightFont}>
          <FormattedMessage id="broken.message" />
        </TextBase>
        <HSpacer height={40} />
        <ButtonWrapper>
          <PrimaryButton onClick={() => window.location.reload()}>
            <TextSm color={theme.color.white}>
              <FormattedMessage id="broken.reload" />
            </TextSm>
          </PrimaryButton>
        </ButtonWrapper>
      </BrokenContent>
      <LogoWrapper>
        <FullLogo />
      </LogoWrapper>
    </BrokenLinkWrapper>
  );
};

export default BrokenLink;
