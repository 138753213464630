import { useTheme } from '@emotion/react';
import { DownArrowGlyph } from 'assets/glyphs';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { WorkOrder } from 'features/app/utils/types/workorder';
import { HomeProp } from 'features/app/utils/types/homes';
import { useIsMobile } from 'features/app/hooks/screen';
import { centsToDollars } from 'helpers/centsToDollars';
import ResultsIcon from 'assets/svgs/Results';
import { useGetLineItemsByHomeId } from 'features/app/hooks/data';
import { Title, TextBase, TextSm } from '../../../Commons/Text/Text';
import {
  HeaderWrapper,
  HomeCard,
  HomeCardHeader,
  SummaryPaylogContainer,
  CardWrapper,
  AmountWrapper,
  ArrowWrapper,
  TableWrapper,
  RightWrapper,
  DropdownWrapper,
} from './SummaryPaylog.styles';
import Table from './SummaryPaylogTable';
import { HSpacer, WSpacer } from '../../../Commons/Spacer.styles';
import { EmptyTableResults } from '../Table/Table.styles';

import { groupLineItemsPerWorkOrder } from './helper';
import Card from '../../../HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import TableLoader from '../../../Commons/Loaders/TableLoader';
import SummaryPaylogLoader from '../../../Commons/Loaders/SummaryPaylogLoader';

interface SummaryPaylogProps {
  addresses: HomeProp[];
  start?: Date;
  end?: Date;
  from?: String;
}

const SummaryPaylog: React.FC<SummaryPaylogProps> = ({
  addresses,
  start,
  end,
  from,
}) => {
  const theme = useTheme();
  const [refetch, setRefetch] = useState(false);
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>();
  const homes = useGetLineItemsByHomeId(addresses, data?.token, refetch);
  const [open, setOpen] = useState(-1);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
      setWorkOrders(undefined);
    }
  }, [refetch]);

  const onHomeClick = (id: number) => {
    if (open === id) {
      setOpen(-1);
    } else {
      setOpen(id);
      setWorkOrders(groupLineItemsPerWorkOrder(homes.data, id));
    }
  };
  const onClickOutsidePicker = () => {
    if (openPicker) setOpenPicker(false);
  };

  return (
    <SummaryPaylogContainer onClick={() => onClickOutsidePicker()}>
      <HeaderWrapper>
        <Title>
          <FormattedMessage id="paylog.title" />
        </Title>
      </HeaderWrapper>
      <DropdownWrapper>
        {!isMobile && (
          <TextSm overflow="unset">
            <FormattedMessage id="common.period" />:
          </TextSm>
        )}
      </DropdownWrapper>
      {homes.loading && <SummaryPaylogLoader />}
      {!homes.loading && homes.data.length === 0 && (
        <EmptyTableResults>
          <ResultsIcon width="50px" height="50px" />
          <HSpacer />
          <TextSm color={theme.color.lightFont}>
            <FormattedMessage id="paylog.empty" />
          </TextSm>
        </EmptyTableResults>
      )}
      {!homes.loading &&
        homes.data.map((home: any) => (
          <CardWrapper key={home.id}>
            <Card>
              <HomeCard
                onClick={() => onHomeClick(home.id)}
                open={open === home.id}
              >
                <HomeCardHeader>
                  <TextBase weight={theme.fontWeight.bold} fullWidth>
                    {home.address}
                  </TextBase>
                  <RightWrapper>
                    <AmountWrapper>
                      <TextBase>
                        <FormattedMessage id="common.total" />
                      </TextBase>
                      <WSpacer width={10} />
                      <TextBase weight={theme.fontWeight.bold}>
                        {centsToDollars(home.total.cents)}
                      </TextBase>
                    </AmountWrapper>
                    <ArrowWrapper open={open === home.id}>
                      <DownArrowGlyph
                        color={theme.color.sideMenuFont}
                        size={12}
                        viewBoxHeight={24}
                        viewBoxWidth={24}
                      />
                    </ArrowWrapper>
                  </RightWrapper>
                </HomeCardHeader>
                {open === home.id && (
                  <TableWrapper onClick={(e) => e.stopPropagation()}>
                    {homes.loading ? (
                      <TableLoader />
                    ) : (
                      <Table data={workOrders} />
                    )}
                  </TableWrapper>
                )}
              </HomeCard>
            </Card>
          </CardWrapper>
        ))}
    </SummaryPaylogContainer>
  );
};

export default SummaryPaylog;
