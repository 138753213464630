import { useEffect, useState } from 'react';
import { useIsMobile, useIsTablet } from 'features/app/hooks/screen';
import { Location } from 'react-router-dom';
import { getHomeIdPath } from 'helpers/path';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { ItemPropsTypes } from '../../types';
import { Item, ItemIcon, ItemLink, ItemText } from './ItemsSideBar.styles';

interface ItemsSideBarProps {
  items: ItemPropsTypes[];
  location: Location;
  onPathChange?: () => void;
}

const ItemsSideBar: React.FC<ItemsSideBarProps> = ({
  items,
  location,
  onPathChange,
}) => {
  const theme = useTheme();
  const [hover, setHover] = useState<string | null>(null);
  const [firstLoad, setFirstLoad] = useState(true);
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  const getColor = (item: ItemPropsTypes) =>
    location.pathname === item.path
      ? theme.color.black
      : theme.color.sideMenuFont;

  const isSelected = (item: ItemPropsTypes) => location.pathname === item.path;

  useEffect(() => {
    if (firstLoad) {
      const homeId = getHomeIdPath(location);
      if (homeId) {
        setFirstLoad(false);
      }
    }
  }, [location, data, firstLoad]);

  return (
    <>
      {items.map((item: any) => (
        <Item
          key={item.id}
          isSelected={isSelected(item)}
          onMouseEnter={() => setHover(item.id)}
          onMouseLeave={() => setHover(null)}
        >
          <ItemLink
            to={item.path}
            onClick={onPathChange}
            isMobile={isMobile || isTablet}
          >
            <ItemIcon>{item.icon(getColor(item))}</ItemIcon>
            <ItemText
              isSelected={isSelected(item)}
              color={getColor(item)}
              isOver={hover === item.id}
              isMobile={isMobile}
            >
              {item.name}
            </ItemText>
          </ItemLink>
        </Item>
      ))}
    </>
  );
};

export default ItemsSideBar;
