import {
  SET_ZIP_CITY_SEARCH_VAL,
  SET_ZIP_CITY,
  SET_NEW_ZIP_RECENT_SEARCH,
  SET_NEW_CITY_RECENT_SEARCH,
} from 'features/app/store/homesFilters/homeFiltersActions';
import { useEffect } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
// import ClockIcon from 'assets/svgs/ClockIcon';
import CloseSimpleIcon from 'assets/svgs/CloseSimpleIcon';

import {
  ClearZipCityFilterWrapper,
  // PreviousSearchItem,
  // PreviousSearchList,
  ZipCityFilterWrapper,
  ZipCityInput,
  ZipCityInputContainer,
} from './ZipCityFilter.styles';
import SelectFilter from '../../Select/SelectFilter/SelectFilter';

const ZipCityFilter: React.FC = () => {
  const dispatch = useDispatch();
  const { zipCitySelect, zipCitySearchVal } = useSelector(
    (states: RootStateOrAny) => states.homeFilters
  );
  const optionsData = ['ZIP', 'CITY'];
  // const [showOnFocus, setShowOnFocus] = useState(false);

  useEffect(() => {
    zipCitySelect === '' && dispatch(SET_ZIP_CITY(optionsData[0]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCitySelect]);

  const handleInputValue = (val: string) => {
    dispatch(SET_ZIP_CITY_SEARCH_VAL(val));
  };

  const handleSelect = (val: string | any) => {
    dispatch(SET_ZIP_CITY(val));
  };

  const handleFocus = () => {
    // setShowOnFocus(true);
  };

  const handleOnBlur = () => {
    // setShowOnFocus(false);

    zipCitySearchVal !== '' &&
      zipCitySelect === optionsData[0] &&
      dispatch(SET_NEW_ZIP_RECENT_SEARCH(zipCitySearchVal));

    zipCitySearchVal !== '' &&
      zipCitySelect === optionsData[1] &&
      dispatch(SET_NEW_CITY_RECENT_SEARCH(zipCitySearchVal));
  };

  const clearValue = () => {
    dispatch(SET_ZIP_CITY_SEARCH_VAL(''));
  };

  return (
    <ZipCityFilterWrapper>
      <SelectFilter
        width={82}
        border={false}
        selected={zipCitySelect}
        isDown
        options={optionsData}
        onSelect={(item) => handleSelect(item)}
        fullHeight
      />
      <ZipCityInputContainer>
        <ZipCityInput
          placeholder={
            optionsData[0] === zipCitySelect
              ? 'e.g.: 33130'
              : 'e.g.: Orlando, FL'
          }
          value={zipCitySearchVal}
          onChange={(e) => handleInputValue(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleOnBlur}
        />
        {/* {showOnFocus &&
          zipCitySelect === optionsData[0] &&
          recentZipSearch.length > 0 && (
            <PreviousSearchList>
              {recentZipSearch.map((e: string) => (
                <PreviousSearchItem key={e}>
                  <ClockIcon width={16} height={16} />
                  {e}
                </PreviousSearchItem>
              ))}
            </PreviousSearchList>
          )}
        {showOnFocus &&
          zipCitySelect === optionsData[1] &&
          recentCitySearch.length > 0 && (
            <PreviousSearchList>
              {recentCitySearch.map((e: string) => (
                <PreviousSearchItem key={e}>
                  <ClockIcon width={16} height={16} />
                  {e}
                </PreviousSearchItem>
              ))}
            </PreviousSearchList>
          )} */}
        {zipCitySearchVal !== '' && (
          <ClearZipCityFilterWrapper onClick={clearValue}>
            <CloseSimpleIcon width={18} height={18} />
          </ClearZipCityFilterWrapper>
        )}
      </ZipCityInputContainer>
    </ZipCityFilterWrapper>
  );
};

export default ZipCityFilter;
