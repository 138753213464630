import styled from '@emotion/styled';

export const ModalCardWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  top: 52px;
  right: 170px;
  min-width: 330px;
  min-height: 75px;
  background-color: white;
  border-radius: 5px;
  z-index: 10;
  box-shadow: 0px 0px 15px rgb(184 196 208 / 25%);
  cursor: pointer;
`;

export const ModalTextContainer = styled.div``;
