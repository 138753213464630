import { getListOfHomesProps, listOfHomesTypes } from './ListOfHomestypes';

export const SET_PAGE_HOME = (payload: number | string) => ({
  type: listOfHomesTypes.SET_PAGE_HOME,
  payload,
});

export const LOADING_HOMES = (payload: boolean) => ({
  type: listOfHomesTypes.LOADING_HOMES,
  payload,
});

export const SET_INFORMATION_HOME = (payload: any) => ({
  type: listOfHomesTypes.SET_INFORMATION_HOME,
  payload,
});

export const GetHomesFromHomeView = (payload: getListOfHomesProps | any) => ({
  type: listOfHomesTypes.GET_HOMES_FROM_HOME_VIEW,
  payload,
});
