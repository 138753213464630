import { format } from 'date-fns';
import { HomeProp } from 'features/app/utils/types/home';
import { useIntl } from 'react-intl';
import { ContactProps } from './Components/ContactInformation/types';

export const formatContacts = (property: HomeProp) => {
  const contacts: ContactProps[] = [
    {
      ...property?.mdProfile,
      shortRole: 'contact.shortRoleMd',
      role: 'contact.roleMd',
    },
  ];
  if (property?.rmProfile?.onHold && property?.renoDirector) {
    contacts.push({
      id: property?.renoDirector?.id,
      user: { ...property?.renoDirector },
      shortRole: 'contact.shortRoleRd',
      role: 'contact.roleRd',
    });
  } else {
    contacts.push({
      ...property?.rmProfile,
      shortRole: 'contact.shortRoleRm',
      role: 'contact.roleRm',
    });
  }
  return contacts;
};

export const FormatDate = (date?: string) => {
  const { formatMessage } = useIntl();
  if (!date) return formatMessage({ id: 'common.noDate' });
  return format(new Date(`${date}T00:00:00`), 'MMM d, yyyy');
};
