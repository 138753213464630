import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { logoutAction } from 'features/app/store/auth/actions';
import { TOKEN, REFRESH_TOKEN } from 'features/app/constants/token';
// import { useIsMobile, useIsTablet } from 'features/app/hooks/screen';
import { Text } from '../../Text/Text';

import LogoutIcon from '../../../../../../assets/svgs/LogoutIcon';

import { LogoutBtnIconWrapper, LogoutBtnMobile } from './LogoutButton.styles';

function LogoutButton() {
  const dispatch = useDispatch<Dispatch>();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  // const mobile = useIsMobile();
  // const tablet = useIsTablet();

  const handleLogout = () => {
    const localToken = window.localStorage.getItem(TOKEN);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (data.token || localToken) {
      localStorage.removeItem(TOKEN);
      if (refreshToken) localStorage.removeItem(REFRESH_TOKEN);

      dispatch(logoutAction());
    }
  };

  return (
    <>
      <LogoutBtnMobile onClick={handleLogout}>
        <LogoutBtnIconWrapper>
          <LogoutIcon width="23px" height="23px" />
        </LogoutBtnIconWrapper>
        <Text fontSize="15" weight="500">
          Logout
        </Text>
      </LogoutBtnMobile>
    </>
  );
}

export default LogoutButton;
