import styled from '@emotion/styled';

export const VendorIconContainer = styled.div`
  background-color: #cccccc;
  align-self: center;
  border-radius: 5px;
  padding-inline: 10px;
  padding-block: 5px;
  cursor: pointer;
`;
