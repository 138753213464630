import { vendorEtaActions, VENDOR_ETA_TYPES } from './vendorEtaTypes';

const initialState = { eta: '', loading: false };

export const vendorEtaReducers = (
  state = initialState,
  action: vendorEtaActions
) => {
  switch (action.type) {
    case VENDOR_ETA_TYPES.ON_LOADING_VENDOR_ETA:
      return {
        ...state,
        loading: action.payload,
      };
    case VENDOR_ETA_TYPES.ON_SET_VENDOR_ETA:
      return {
        ...state,
        eta: action.payload,
      };
    default:
      return state;
  }
};
