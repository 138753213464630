import styled from '@emotion/styled';

interface ButtonProps {
  isselected: boolean;
}

export const Content = styled.div`
  position: relative;
  min-height: inherit;
  padding: 1rem;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    padding: 1rem;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Headline = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.x5l};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.name};
  color: ${({ theme }) => theme.color.font};
`;

export const Selector = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 78px;
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  border-radius: 5px;
  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
    flex-direction: column;
    height: fit-content;
  }
`;

export const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Button = styled.button<ButtonProps>`
  all: unset;
  padding: 8px 16px;
  cursor: pointer;
  border-radius: 5px;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.light};
  background-color: ${({ theme, isselected }) =>
    isselected ? theme.color.blue600 : 'transparent'};
  color: ${({ theme, isselected }) =>
    isselected ? theme.color.white : theme.color.font};
  box-shadow: ${({ theme }) => theme.boxShadow.homeCards};

  &:focus {
    border: none;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Option = styled.option`
  width: 100%;
`;

export const HomeSelect = styled.select`
  width: 100%;
  height: 34px;
  padding: 0 10px;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: capitalize;
  color: #7a7a7a;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  display: none;

  @media (max-width: 768px) {
    display: inherit;
  }
`;

export const Underline = styled.div`
  width: 80%;
  height: 2px;
  background-color: ${({ theme }) => theme.color.altFont};
  margin-left: 10%;
  margin-top: -1.5px;
`;

export const HomesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HomeTotal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    p {
      margin-right: 5px;
    }
  }
`;

export const ChevronContainer = styled.div`
  position: absolute;
  right: -7px;
`;

export const HeaderContainer = styled.div`
  width: 98%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 1439px) {
    width: 100%;
    flex-direction: column;
    gap: 37px;
  }
`;

export const HomeTitlesContainer = styled.div`
  display: flex;
`;

export const TitlesContainer = styled.div`
  width: fit-content;
  padding-left: 22px;
`;

export const InfoCardsContainer = styled.div`
  margin-left: 22px;
  width: 100%;
  height: fit-content;
  display: grid;
  justify-content: flex-end;
  grid-template-columns: repeat(4, 1fr);
  align-items: center;
  gap: 15px;

  @media (max-width: 374px) {
    margin: 0;
    grid-template-columns: repeat(auto-fit, 100%);
  }

  @media (min-width: 375px) and (max-width: 1023px) {
    margin: 0;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fit, 48%);
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    justify-content: space-between;
    margin: 0;
    grid-template-columns: repeat(auto-fit, 47.9%);
  }
`;

export const HomeIconWrapper = styled.div``;

export const HomeSearchAndFilterButtonWrapper = styled.div`
  right: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 0;
    margin-top: 10px;
    position: relative;
    gap: 8px;
  }
`;

export const ButtonsFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ButtonFilterIndividual = styled.div`
  margin-left: 10px;
`;

export const FilterButtonContainer = styled.div``;

export interface FilterProps {
  open: boolean;
}

export const HomeFiltersWrapper = styled.div<FilterProps>`
  width: 100%;
  height: ${(props) => (props.open ? `fit-content` : `${0}px`)};
  padding-right: 20px;
  margin-bottom: 20px;
  display: flex;
  gap: 16px;
  opacity: ${(props) => (props.open ? 1 : 0)};
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  transition: all 0.1s ease-in;
  @media (max-width: ${({ theme }) => theme.breakpoints.tablet.max}) {
    padding: 0;
    margin-top: 20px;
    flex-direction: column;
  }
`;

export const HomeFilterContainer = styled.div`
  width: 100%;
  max-width: 286px;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet.max}) {
    max-width: 100%;
  }
`;

export const BottomContainer = styled.div`
  margin-top: 30px;
  gap: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

export const GrayLineDivider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d9d9d9;
`;
