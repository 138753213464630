import { IconPropTypes } from './types';

const DoneIcon = ({ width, height }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 17 12"
    fill="none"
  >
    <path
      d="M15.247 1L5.45219 10.7139L1 6.29848"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DoneIcon;
