import { ReactChild } from 'react';
import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { TextBase, TextSm } from 'features/app/components/Commons/Text/Text';
import { isSafariUnder14 } from 'helpers/deviceDetect';
import {
  CardButton,
  CardSubContainer,
  HeaderContainer,
  IconContainer,
  NumberWrapper,
  Number,
  IconWrapper,
} from './CardIcon.styles';
import Card from '../../../Card';

interface CardIconProps {
  overrideColor?: string;
  title: string;
  icon?: ReactChild;
  buttonMessage: string;
  number?: string | ReactChild;
  subtitle: string;
  date?: string;
  onClick?: (event: any) => void;
  customMargin?: number;
  alignRight?: boolean;
}

const CardIcon: React.FC<CardIconProps> = ({
  overrideColor,
  title,
  icon,
  buttonMessage,
  number,
  subtitle,
  date,
  onClick,
  customMargin,
  alignRight,
}) => {
  const theme = useTheme();

  return (
    <Card customPadding={18} customMargin={customMargin}>
      <CardSubContainer isSafariUnder14={!!isSafariUnder14}>
        <HeaderContainer>
          <TextBase>
            <FormattedMessage id={title} />
          </TextBase>
          {icon && (
            <IconWrapper>
              <IconContainer>{icon}</IconContainer>
            </IconWrapper>
          )}
        </HeaderContainer>
        <NumberWrapper alignRight={alignRight}>
          <Number overrideColor={overrideColor}>{number}</Number>
          <TextBase>
            <FormattedMessage id={subtitle} values={{ date }} />
          </TextBase>
        </NumberWrapper>
        <CardButton onClick={onClick} showButton={!!onClick}>
          <TextSm color={theme.color.expandCardButtons}>
            <FormattedMessage id={buttonMessage} />
          </TextSm>
        </CardButton>
      </CardSubContainer>
    </Card>
  );
};

export default CardIcon;
