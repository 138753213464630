import { useTheme } from '@emotion/react';
import { DownChevronGlyph, UpChevronGlyph } from 'assets/glyphs';
import { ON_MULTI_SELECT_CHANGE } from 'features/app/store/projectLog/projectLogActions';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { WSpacer } from '../../Spacer.styles';
import {
  Background,
  Input,
  Label,
  MultiSelectWrapper,
  Option,
  Options,
  Select,
} from './MultiSelect.styles';

interface OptionProps {
  label: string;
  value: string;
}

interface MultiSelectProps {
  options: OptionProps[];
  onChange: (options: OptionProps[]) => void;
  label: string;
  icon: React.ReactNode;
  multiSelectValues: any[];
}

const MultiSelect: React.FC<MultiSelectProps> = ({
  onChange,
  label,
  icon,
  options,
  multiSelectValues,
}) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onSelect = (option: any) => {
    if (multiSelectValues.some((x: any) => x.value === option.value)) {
      const remove = multiSelectValues.filter(
        (x: any) => x.value !== option.value
      );
      dispatch(ON_MULTI_SELECT_CHANGE([...remove]));
      onChange([...remove]);
    } else {
      dispatch(ON_MULTI_SELECT_CHANGE([...multiSelectValues, option]));
      onChange([...multiSelectValues, option]);
    }
  };

  const isChecked = (option: any) =>
    !!multiSelectValues.find((select) => select.value === option.value);

  const showSelects = () => {
    if (multiSelectValues.length > 0) {
      return `${multiSelectValues.length} selected`;
    }
    return formatMessage({ id: 'filter.select' });
  };

  return (
    <>
      {open && <Background onClick={() => setOpen(false)} />}
      <MultiSelectWrapper>
        <Label>
          {icon}
          <WSpacer width={2} />
          <FormattedMessage id={label} />
        </Label>
        <Select
          onClick={() => setOpen(!open)}
          empty={multiSelectValues.length === 0}
        >
          {showSelects()}
          {open ? (
            <UpChevronGlyph color={theme.color.font} />
          ) : (
            <DownChevronGlyph color={theme.color.font} />
          )}
        </Select>
        {open && (
          <Options>
            {options.map((option) => (
              <Option key={option.value} onClick={() => onSelect(option)}>
                <Input
                  type="checkbox"
                  checked={isChecked(option)}
                  onChange={() => onSelect(option)}
                />
                <span>{option.label}</span>
              </Option>
            ))}
          </Options>
        )}
      </MultiSelectWrapper>
    </>
  );
};

export default MultiSelect;
