import styled from '@emotion/styled';

export const SummaryViewWrapper = styled.div`
  width: 100%;
  height: 100%;
  text-align: left;
`;

export const DesktopView = styled.div`
  display: flex;
  height: 200px;
`;

export const MobileView = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 25px;
`;

export const Line = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.color.divisorLine};
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
`;
export const CardWrapper = styled.div`
  margin: 0px 5px 0px 5px;
  width: 100%;
  height: 100%;
  display: flex;
`;
export const CardWrapperMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ContactsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
  }
`;
