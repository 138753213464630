import { LoaderContainer, LoaderPercentage } from './Percentage.styles';

export interface PercentageProps {
  percentage: number | any;
  color: string;
}

function Percentage({ percentage, color }: PercentageProps) {
  return (
    <LoaderContainer>
      <LoaderPercentage percentage={percentage} color={color} />
    </LoaderContainer>
  );
}

export default Percentage;
