import { MODALS_TYPES } from './modalsTypes';

export const OnBonusModalVisibilityChange = (payload: boolean) => ({
  type: MODALS_TYPES.ON_BONUS_MODAL_VISIBILITY_CHANGE,
  payload,
});

export const OnHelpModalVisibilityChange = (payload: boolean) => ({
  type: MODALS_TYPES.ON_HELP_MODAL_VISIBILITY_CHANGE,
  payload,
});

export const OnUserModalVisibilityChange = (payload: boolean) => ({
  type: MODALS_TYPES.ON_USER_MODAL_VISIBILITY_CHANGE,
  payload,
});
