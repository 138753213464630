import { CloseGlyph } from 'assets/glyphs';
import { TextSm } from '../Text/Text';
import { ChipWrapper, CloseButton } from './Chip.styles';

interface ChipProps {
  text: string;
  color: string;
  textColor: string;
  weight?: string;
  close: boolean;
  onClose?: () => void;
}

const Chip: React.FC<ChipProps> = ({
  text,
  color,
  textColor,
  weight,
  close,
  onClose,
}) => (
  <ChipWrapper overrideBackgroundColor={color} weight={weight}>
    <TextSm style={{ whiteSpace: 'nowrap' }} weight="400" color={textColor}>
      {text}
    </TextSm>
    {close && (
      <CloseButton>
        <CloseGlyph
          onClick={onClose}
          size={18}
          viewBoxHeight={36}
          viewBoxWidth={36}
        />
      </CloseButton>
    )}
  </ChipWrapper>
);

export default Chip;
