import styled from '@emotion/styled';

export const PaylogCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    padding-bottom: 10px;
  }
`;

export const Payments = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 15px 15px 0 15px;
`;

export const PaymentStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: flex-start;
`;

export const StatusTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.font};
`;

export const Amounts = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-end;
  justify-content: space-around;
`;

export const Amount = styled.span`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.color.font};
`;
