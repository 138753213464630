import { helperSelectFilter } from 'features/app/components/Commons/Filters/helper';
import { sortByOptionsApi } from 'features/app/components/Commons/Filters/SortByFilter/types';
import { Text } from 'features/app/components/Commons/Text/Text';
import { CLEAR_ALL_FILTERS } from 'features/app/store/homesFilters/homeFiltersActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { FilterButtonWrapper } from '../FilterButton.styles';

export interface ClearButtonProps {
  firstOptionStatus: string;
}

export default function ClearButton({ firstOptionStatus }: ClearButtonProps) {
  const dispatch = useDispatch();
  const {
    data: { token },
  } = useSelector((store: RootStateOrAny) => store.auth);
  const clearAllFilters = () => {
    dispatch(CLEAR_ALL_FILTERS(firstOptionStatus));
    helperSelectFilter(dispatch, [], sortByOptionsApi.none, '', token);
  };

  return (
    <FilterButtonWrapper onClick={clearAllFilters}>
      <Text
        fontSize="14"
        weight="300"
        color="#212121"
        style={{ margin: '0 auto' }}
      >
        Clear all
      </Text>
    </FilterButtonWrapper>
  );
}
