import { useTheme } from '@emotion/react';
import ResultsIcon from 'assets/svgs/Results';
import ListLoader from 'features/app/components/Commons/Loaders/ListLoader';
import { HSpacer } from 'features/app/components/Commons/Spacer.styles';
import { TextSm } from 'features/app/components/Commons/Text/Text';
import { LineItem } from 'features/app/utils/types/lineItems';
import { FormattedMessage } from 'react-intl';
import CardMobile from './components/CardGroupBy/components/CardMobile/CardMobile';
import { EmptyListResults, Ul, Wrapper } from './List.styles';

interface ListProps {
  data?: LineItem[];
  loading?: boolean;
  propId?: number;
}

const List: React.FC<ListProps> = ({ data, loading, propId }) => {
  const theme = useTheme();

  if (loading) {
    return <ListLoader />;
  }

  if (data?.length === 0) {
    return (
      <EmptyListResults>
        <ResultsIcon width="50px" height="50px" />
        <HSpacer />
        <TextSm color={theme.color.lightFont}>
          <FormattedMessage id="paylog.empty" />
        </TextSm>
      </EmptyListResults>
    );
  }

  return (
    <Wrapper>
      <Ul>
        {data?.map((item: LineItem, index: number) => (
          <CardMobile
            propId={propId}
            key={item.id}
            item={item}
            index={index}
            dataLength={data.length}
          />
        ))}
      </Ul>
    </Wrapper>
  );
};

export default List;
