/* eslint-disable react-hooks/exhaustive-deps */
import DropArrowIcon from 'assets/svgs/DropArrowIcon';
import {
  ADD_STATUS,
  CLEAR_STATUS,
  REMOVE_STATUS,
} from 'features/app/store/homesFilters/homeFiltersActions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from '../../Select/Select';
import { Text } from '../../Text/Text';
import {
  getBodyToDispatchStatusBy,
  helperFilterStatus,
  helperSelectFilter,
  sortByField,
} from '../helper';
import {
  DropArrowIconContainer,
  SelectWrapper,
  StatusFilterWrapper,
  TextWrapper,
} from './StatusFilter.styles';
import { StatusFilterProps } from './types';

const StatusFilter: React.FC<StatusFilterProps> = ({
  selectedItems,
  options,
  sortByOption,
  paymentCriteria,
  token,
}) => {
  const [showSelectOptions, setShowSelectOptions] = useState(false);
  const dispatch = useDispatch();
  const refSelect = useRef<any>();

  useEffect(() => {
    if (selectedItems.length === 0) {
      dispatch(ADD_STATUS(options[0]));
    }
  }, []);

  const handleFilter = (e: string) => {
    if (selectedItems.indexOf(e) === -1) {
      dispatch(ADD_STATUS(e));
      if (selectedItems.indexOf(options[0]) !== -1) {
        dispatch(REMOVE_STATUS(options[0]));
      } else {
        dispatch(REMOVE_STATUS(options[0]));
      }
      if (e === options[0]) {
        dispatch(CLEAR_STATUS());
        dispatch(ADD_STATUS(e));
      }
    } else if (selectedItems.indexOf(e) !== -1) {
      dispatch(REMOVE_STATUS(e));
      if (selectedItems.length === 1) {
        dispatch(ADD_STATUS(options[0]));
      }
    }
    const auxArray = helperFilterStatus(e, selectedItems, options);
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(auxArray);
    const sortByToDispatch = sortByField(sortByOption);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByToDispatch,
      paymentCriteria,
      token
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refSelect.current && !refSelect.current.contains(event.target)) {
        setShowSelectOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <StatusFilterWrapper ref={refSelect}>
      <TextWrapper>
        <Text fontSize="14" weight="300">
          Status
        </Text>
      </TextWrapper>
      <SelectWrapper>
        <Select
          options={options}
          showOptions={showSelectOptions}
          onClick={() => setShowSelectOptions(!showSelectOptions)}
          onSelectOption={(e) => handleFilter(e)}
          selectedValue={selectedItems}
          multipleOption
        />
        <DropArrowIconContainer>
          <DropArrowIcon width={11} height={6} />
        </DropArrowIconContainer>
      </SelectWrapper>
    </StatusFilterWrapper>
  );
};

export default StatusFilter;
