export function centsToDollars(number?: number) {
  if (number) {
    if (isNaN(number)) return '0.00';
    const newNumber = number / 100;
    return newNumber.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return '0.00';
}
