import styled from '@emotion/styled';

export const LogoutBtnIconWrapper = styled.div`
  position: relative;
  left: -2px;
`;

export const LogoutBtnMobile = styled.button`
  box-sizing: border-box;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  /* gap: 5px; */
`;
