import { IconPropTypes } from './types';

const DeleteIcon = ({
  width,
  height,
  color,
  onClick,
  className,
}: IconPropTypes) => (
  <svg
    id="Layer_1"
    width={width || '100%'}
    height={height || '100%'}
    color={color}
    data-name="Layer 1"
    viewBox="0 0 89 89"
    onClick={onClick}
    className={className}
  >
    <defs>
      <style>
        {'.cls-2{fill:none;stroke:#fff;stroke-linecap:round;stroke-width:6px}'}
      </style>
    </defs>
    <title>delete</title>
    <circle
      cx={44.5}
      cy={44.5}
      r={44.5}
      style={{
        fill: color,
      }}
    />
    <path
      className="cls-2"
      d="M60.69 28.93 27.58 62.04M60.77 61.7 27.66 28.58"
    />
  </svg>
);

export default DeleteIcon;
