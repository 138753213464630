import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_RIGHT_NAVBAR_FILTER } from 'features/app/store/homesFilters/homeFiltersActions';
import PendingPaymentCard from './components/PendingPaymentCard';
// import BonusCard from './components/BonusCard';
import FutureWorkCard from './components/FutureWorkCard';
import EarnThisMonthCard from './components/EarnThisMonthCard';

import { MenuRightSideBarWrapper } from './MenuRightSideBar.styles';
import { rightSideBarTypes } from './types';
import {
  getBodyToDispatchStatusBy,
  helperSelectFilter,
  sortByField,
} from '../Filters/helper';

const MenuRightSideBar: React.FC = () => {
  const { rightNavBarSelection, sortBy, status } = useSelector(
    (state: RootStateOrAny) => state.homeFilters
  );
  const {
    data: { token },
  } = useSelector((state: RootStateOrAny) => state.auth);
  const dispatch = useDispatch();

  const onClick = (value: string) => {
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(status);
    const sortByToDispatch = sortByField(sortBy);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByToDispatch,
      value,
      token
    );
    dispatch(SET_RIGHT_NAVBAR_FILTER(value));
  };

  const onCancel = () => {
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(status);
    const sortByToDispatch = sortByField(sortBy);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByToDispatch,
      '',
      token
    );
    dispatch(SET_RIGHT_NAVBAR_FILTER(''));
  };

  return (
    <MenuRightSideBarWrapper>
      <PendingPaymentCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.pendingPayment)}
        isSelected={rightNavBarSelection === rightSideBarTypes.pendingPayment}
      />
      <FutureWorkCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.futureWork)}
        isSelected={rightNavBarSelection === rightSideBarTypes.futureWork}
      />
      {/* <BonusCard /> */}
      <EarnThisMonthCard
        onCancel={onCancel}
        onClick={() => onClick(rightSideBarTypes.earnThisMonth)}
        isSelected={rightNavBarSelection === rightSideBarTypes.earnThisMonth}
      />
    </MenuRightSideBarWrapper>
  );
};

export default MenuRightSideBar;
