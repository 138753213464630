import { getTotals } from 'features/app/api/paylog';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getHomeTotalsProps,
  HomeTotalInformationProps,
  LOADING_HOME_INFORMATION,
  SET_HOME_INFORMATION,
} from './homeTotalsActions';
import { HOME_TOTALS_TYPES } from './homeTotalsTypes';

function* getHomeTotalsApi({ payload }: getHomeTotalsProps | any) {
  yield put(LOADING_HOME_INFORMATION(true));
  try {
    const data: HomeTotalInformationProps = yield call(
      getTotals,
      payload.id,
      payload.body
    );
    yield put(SET_HOME_INFORMATION(data));
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
}

export default function* HomeTotalsDataSaga(): any {
  yield all([
    yield takeLatest(HOME_TOTALS_TYPES.GET_HOME_TOTALS, getHomeTotalsApi),
  ]);
}
