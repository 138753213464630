import styled from '@emotion/styled';

interface SelectProps {
  empty: boolean;
}

export const MultiSelectWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.font};
  margin-bottom: 2px;
`;

export const Select = styled.div<SelectProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px 10px;
  color: ${({ theme, empty }) =>
    empty ? theme.color.disabledFont : theme.color.font};
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const Options = styled.ul`
  position: absolute;
  border-radius: 10px;
  top: 65px;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdown.content};
  background: ${({ theme }) => theme.color.white};
  border-top: 0px;
  box-shadow: ${({ theme }) => theme.boxShadow.dropdowns};
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  color: ${({ theme }) => theme.color.font};
  overflow-y: auto;
`;

export const Input = styled.input`
  margin-right: 10px;
  -webkit-appearance: none;
  border: 1px solid ${({ theme }) => theme.color.table.border};
  padding: 8px;
  border-radius: 3px;
  position: relative;
  display: inline-block;

  &:checked {
    background-color: ${({ theme }) => theme.color.blue500};
    color: ${({ theme }) => theme.color.white};
    border: 1px solid transparent;
  }
`;

export const Option = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: pointer;
  text-align: left;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease;
  background: ${({ theme }) => theme.color.white};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.appBackground};
    > p {
      color: ${({ theme }) => theme.color.font};
    }
  }
  > p {
    transition: color 0.3s ease;
    padding-left: 10px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.dropdown.background};
`;
