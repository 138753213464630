import styled from '@emotion/styled';

export const SideMenuCardWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 16px 13px;
  width: 315px;
  width: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: #f6f6f6;
  overflow: hidden;
`;

export const SideMenuFloatActionBtn = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 10000;
`;

export const SideMenuDataContainer = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
