import { IconPropTypes } from './types';

const DropArrowIcon = ({ width, height }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 11 6"
    fill="none"
  >
    <path
      d="M10 1L5.5 5L1 1"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DropArrowIcon;
