/* eslint-disable no-nested-ternary */
import styled from '@emotion/styled';

export interface SelectContainerProps {
  selected: boolean;
  border?: boolean;
  width: number;
}

export interface OptionsWrapperProps {
  totalPages: number;
  fullHeight: boolean;
  isDown?: boolean;
  width: number;
  hide: boolean;
}

export interface DivisorOptionPageProps {
  width: number;
}

export const SelectPageWrapper = styled.div``;

export const OptionsWrapper = styled.div<OptionsWrapperProps>`
  background-color: white;
  position: absolute;
  border: 1px #d9d9d9 solid;
  border-radius: 5px;
  z-index: 240;
  margin-top: ${({ totalPages, fullHeight, isDown }) =>
    !isDown
      ? totalPages < 5
        ? `${-totalPages * 30 - 40}px`
        : fullHeight
        ? `${-totalPages * 30 - 40}px`
        : `-190px`
      : '10px'};
  max-height: ${({ fullHeight }) => (fullHeight ? '100%' : '150px')};
  overflow-y: ${({ totalPages, fullHeight }) =>
    totalPages > 5 && !fullHeight ? 'scroll' : 'hidden'};
  overflow-x: hidden;
  width: ${({ width }) => `${width}px`};
  display: ${({ hide }) => (hide ? 'none' : undefined)};
`;

export const TextContainerPageSelector = styled.div`
  padding-left: 14px;
  padding-block: 4px;
`;

export const OptionIndividualContainer = styled.div`
  cursor: pointer;
  :hover {
    background-color: #d9d9d9;
  }
`;

export const DivisorOptionPage = styled.div<DivisorOptionPageProps>`
  width: ${({ width }) => `${width}px`};
  height: 1px;
  background-color: #d9d9d9;
`;

export const SelectContainer = styled.div<SelectContainerProps>`
  cursor: pointer;
  display: flex;
  width: ${({ width }) => `${width}px`};
  justify-content: center;
  flex-direction: row;
  height: 100%;
  background-color: ${({ selected, border }) =>
    selected ? '#D9D9D9' : !border ? '#D9D9D9' : 'white'};
  border: ${({ border }) => (border ? '1px #d9d9d9 solid' : null)};
  border-radius: 5px 0px 0px 5px;
  padding-left: 12px;
  padding-right: 7.5px;
  gap: 14px;
  align-items: center;
  padding-block: 4px;
`;
