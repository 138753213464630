import { IconPropTypes } from './types';

const BonusEarningIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 29 44"
    fill="none"
  >
    <path
      d="M14.3636 27.3387C21.7442 27.3387 27.7273 21.4426 27.7273 14.1694C27.7273 6.89612 21.7442 1 14.3636 1C6.98311 1 1 6.89612 1 14.1694C1 21.4426 6.98311 27.3387 14.3636 27.3387Z"
      stroke={color || 'none'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.12836 25.2504L4.81836 42.3894L14.3638 36.7453L23.9093 42.3894L21.5993 25.2316"
      stroke={color || 'none'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.5078 7.69348V21.4416"
      stroke={color || 'none'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6786 10.1932H12.9226C12.334 10.1932 11.7694 10.4237 11.3532 10.8339C10.937 11.244 10.7031 11.8004 10.7031 12.3804C10.7031 12.9605 10.937 13.5168 11.3532 13.927C11.7694 14.3372 12.334 14.5676 12.9226 14.5676H16.0933C16.6819 14.5676 17.2464 14.7981 17.6627 15.2083C18.0789 15.6184 18.3127 16.1748 18.3127 16.7549C18.3127 17.3349 18.0789 17.8913 17.6627 18.3014C17.2464 18.7116 16.6819 18.9421 16.0933 18.9421H10.7031"
      stroke={color || 'none'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BonusEarningIcon;
