import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface ItemPropTypes {
  isSelected: boolean;
}
interface ItemLinkProps {
  isMobile?: boolean | null;
}

interface ItemTextPropTypes {
  isSelected: boolean;
  isOver: boolean;
  color?: string;
  isMobile?: boolean | null;
}

export const Item = styled.div<ItemPropTypes>`
  height: ${({ theme }) => theme.height.sideBarItem};
  padding: 26px 0;
  width: 100%;
  height: fit-content;
  display: flex;
  gap: 12px;
  border-left: ${({ theme, isSelected }) =>
    isSelected
      ? `4px solid ${theme.color.blue500}`
      : `4px solid ${theme.color.white}`};
  background-color: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.color.selectedRightSideMenuItem}`
      : `${theme.color.white}`};

  &:hover {
    cursor: pointer;
  }
  // background-color: red;
`;

export const ItemLink = styled(Link)<ItemLinkProps>`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: ${({ isMobile }) =>
    !isMobile || isMobile === null ? 'column' : 'row'};
  align-items: center;
  justify-content: ${({ isMobile }) =>
    !isMobile || isMobile === null ? 'center' : 'flex-start'};
  gap: ${({ isMobile }) => (!isMobile || isMobile === null ? '0' : '15px')};
  width: 100%;
  padding-left: ${({ isMobile }) =>
    !isMobile || isMobile === null ? '0' : '27px'};
`;

export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const ItemText = styled.div<ItemTextPropTypes>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme, isSelected }) =>
    isSelected ? theme.color.black : theme.color.sideMenuFont};
  font-weight: ${({ isSelected, isOver, theme }) => theme.fontWeight.regMedium};
  text-transform: ${({ isMobile }) =>
    !isMobile || isMobile === null ? '' : 'uppercase'};
  font-size: 14px;
`;
