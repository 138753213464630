import DeleteIcon from 'assets/svgs/DeleteIcon';
import SearchIcon from 'assets/svgs/SearchIcon';
import { useState } from 'react';
import {
  DeleteIconWrapper,
  SearchBarInput,
  SearchBarWrapper,
  SearchIconWrapper,
} from './SearchBar.styles';
import { SearchBarProps } from './types';

const SearchBar: React.FC<SearchBarProps> = ({
  onSearch,
  value,
  placeholder,
}) => {
  const [focus, setFocus] = useState(false);
  const clearChange = () => {
    onSearch('');
  };

  const onChangeSearch = (e: any) => {
    onSearch(e.target.value);
  };

  const focusInput = () => {
    setFocus(true);
  };

  const blurInput = () => {
    setFocus(false);
  };

  return (
    <SearchBarWrapper focus={focus} onBlur={blurInput}>
      <SearchIconWrapper>
        <SearchIcon height={18} width={18} />
      </SearchIconWrapper>
      <SearchBarInput
        onFocus={focusInput}
        onChange={onChangeSearch}
        value={value}
        placeholder={placeholder}
      />
      {value.length > 0 && (
        <DeleteIconWrapper onClick={clearChange}>
          <DeleteIcon height={25} width={25} />
        </DeleteIconWrapper>
      )}
    </SearchBarWrapper>
  );
};

export default SearchBar;
