import styled from '@emotion/styled';

interface TitleProps {
  greenTitle?: boolean;
  spacingTitle?: boolean;
}

interface DetailsProps {
  setBold?: boolean;
  setSpacing?: boolean;
}

export const MilestoneDetailWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 10px;
`;

export const Title = styled.h3<TitleProps>`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: bold;
  color: ${({ theme, greenTitle }) =>
    greenTitle ? theme.color.green700 : theme.color.black};
  margin-right: ${({ spacingTitle }) => (spacingTitle ? 10 : 0)}px;
`;

export const DetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 7px;
`;

export const Details = styled.p<DetailsProps>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ setBold }) => (setBold ? 'bold' : 'normal')};
  margin-right: ${({ setSpacing }) => (setSpacing ? 5 : 0)}px;
`;

export const OneRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
