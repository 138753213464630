import styled from '@emotion/styled';

interface TableTdProps {
  open?: boolean;
  fixed?: boolean;
  margin?: boolean;
  backgroundColor?: string;
  cursor?: string;
  textDecoration?: boolean;
}
interface TableThProps {
  margin?: boolean;
}

interface TableProps {
  fixed?: boolean;
}

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableWrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
`;

export const TableStyled = styled.table<TableProps>`
  border-collapse: collapse;
  border: none;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0 15px;
`;

export const TableTr = styled.tr`
  display: table-row;
`;

export const ColumnTitle = styled.button`
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
  }
`;

export const TableTh = styled.th<TableThProps>`
  border-bottom: 1px solid ${({ theme }) => theme.color.divisorLine};
  position: auto;
  left: auto;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.white};
  text-align: left;
  padding: 8px;
`;

export const TableTd = styled.td<TableTdProps>`
  border-bottom: 1px solid
    ${({ theme, open }) => (open ? 'transparent' : theme.color.divisorLine)};
  text-align: left;
  padding: 8px;
  white-space: nowrap;
  position: auto;
  left: auto;
  z-index: ${({ fixed }) => (fixed ? 2 : 1)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
`;

export const EmptyTableResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 200px;
`;

export const NameWrapper = styled.div`
  max-width: 200px;
`;

export const MoreInfoModalWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 24px;
  width: 500px;
  height: fit-content;
`;

export const LineInfoModal = styled.div`
  margin-top: 22px;
  margin-bottom: 13px;
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
`;

export const MoreInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ModalLinkErrorWrapper = styled.div`
  box-sizing: border-box;
  padding: 25px 25px;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  & > p {
    text-align: center;
  }
`;

export const TableTdDescription = styled.td<TableTdProps>`
  border-bottom: 1px solid
    ${({ theme, open }) => (open ? 'transparent' : theme.color.divisorLine)};
  text-align: left;
  padding: 8px;
  width: 40%;
  left: auto;
  position: auto;
  z-index: ${({ fixed }) => (fixed ? 2 : 1)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
`;

export const TableTdWO = styled.td<TableTdProps>`
  border-bottom: 1px solid
    ${({ theme, open }) => (open ? 'transparent' : theme.color.divisorLine)};
  text-align: left;
  padding: 8px;
  white-space: nowrap;
  position: auto;
  left: auto;
  z-index: ${({ fixed }) => (fixed ? 2 : 1)};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  cursor: ${(props) => props.cursor};
  text-decoration: ${({ textDecoration }) => textDecoration && 'underline'};
  & > p:hover {
    color: ${({ cursor }) => cursor === 'pointer' && '#0076A4'};
    text-decoration: ${({ textDecoration }) => textDecoration && 'underline'};
    text-decoration-color: #0076a4;
  }
`;
