import { IconPropTypes } from './types';

const ChevronEndLeftIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
    <path
      d="m16 7-5 5 5 5M8 17V7"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronEndLeftIcon;
