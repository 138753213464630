import styled from '@emotion/styled';

export interface BonusCardProps {
  isSelected: boolean;
}

export const CardWrapperRightNavBar = styled.div<BonusCardProps>`
  margin: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  border-left: ${({ isSelected }) =>
    isSelected ? '5px solid #009FDD' : '5px solid #f6f6f6'};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0px 2px 25px rgba(0, 0, 0, 0.2)' : ''}; ;
`;
