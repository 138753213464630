import styled from '@emotion/styled';

interface DotProps {
  color: string;
}

interface TableTdProps {
  open?: boolean;
}

interface ArrowWrapperProps {
  open?: boolean;
}

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: none;
`;

export const TableTr = styled.tr`
  width: 100%;
`;

export const TableTdDescription = styled.td`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.divisorLine};
`;

export const DescriptionWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
`;

export const DescriptionContent = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.divisorLine};
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

export const TableTh = styled.th`
  border-bottom: 1px solid ${({ theme }) => theme.color.sideMenuFont};
  text-align: left;
  padding: 8px;
`;

export const TableTd = styled.td<TableTdProps>`
  position: relative;
  border-bottom: 1px solid
    ${({ theme, open }) => (open ? 'transparent' : theme.color.divisorLine)};
  text-align: left;
  padding: 8px;
  width: fit-content;
  white-space: nowrap;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    max-width: 40vw;
  }
`;

export const Dot = styled.div<DotProps>`
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background-color: ${({ color }) => color};
`;

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const TextFlex = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 0;
  padding: 15px 10px 5px 10px;
`;

export const ArrowWrapper = styled.div<ArrowWrapperProps>`
  position: absolute;
  right: 8px;
  top: 11px;
  transform: ${({ open }) => (open ? 'rotate(-180deg)' : 'rotate(0deg)')};
  height: 12px;
  width: 12px;
  transition: transform 0.4s ease-in-out;
`;

export const WorkOrderCostWrapper = styled.div<ArrowWrapperProps>`
  position: absolute;
  right: 30px;
  top: 9px;
  height: fit-content;
  width: fit-content;
  display: flex;
`;

export const EmptyTableResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 200px;
`;
