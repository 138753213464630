/* eslint-disable react-hooks/exhaustive-deps */
import DropArrowIcon from 'assets/svgs/DropArrowIcon';
import { SELECT_SORT_BY } from 'features/app/store/homesFilters/homeFiltersActions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from '../../Select/Select';
import { Text } from '../../Text/Text';
import {
  getBodyToDispatchStatusBy,
  helperSelectFilter,
  sortByField,
} from '../helper';
import {
  SelectWrapper,
  StatusFilterWrapper,
  TextWrapper,
  DropArrowIconContainer,
} from '../StatusFilter/StatusFilter.styles';
import { SortByProps } from './types';

const SortByFilter: React.FC<SortByProps> = ({
  selectedItem,
  options,
  statusSelected,
  paymentCriteria,
  token,
}) => {
  const [showSelectOptions, setShowSelectOptions] = useState(false);
  const dispatch = useDispatch();
  const refSelect = useRef<any>();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refSelect.current && !refSelect.current.contains(event.target)) {
        setShowSelectOptions(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    dispatch(SELECT_SORT_BY(options[0]));
  }, []);

  const handleFilter = (e: string) => {
    selectedItem === e
      ? dispatch(SELECT_SORT_BY(options[0]))
      : dispatch(SELECT_SORT_BY(e));
    const sortByFieldApi = sortByField(e);
    const bodyToDispatchStatus = getBodyToDispatchStatusBy(statusSelected);
    helperSelectFilter(
      dispatch,
      bodyToDispatchStatus,
      sortByFieldApi,
      paymentCriteria,
      token
    );
    setShowSelectOptions(false);
  };

  return (
    <StatusFilterWrapper ref={refSelect}>
      <TextWrapper>
        <Text fontSize="14" weight="300">
          Sort by
        </Text>
      </TextWrapper>
      <SelectWrapper>
        <Select
          options={options}
          showOptions={showSelectOptions}
          onClick={() => setShowSelectOptions(!showSelectOptions)}
          onSelectOption={(e) => handleFilter(e)}
          selectedValue={selectedItem}
          multipleOption={false}
        />
        <DropArrowIconContainer>
          <DropArrowIcon width={11} height={6} />
        </DropArrowIconContainer>
      </SelectWrapper>
    </StatusFilterWrapper>
  );
};

export default SortByFilter;
