import Broken from 'assets/svgs/BrokenLink';
import FullLogo from 'assets/svgs/FullLogo';
import { FormattedMessage } from 'react-intl';
import { HSpacer, WSpacer } from '../Commons/Spacer.styles';
import { Headline, Text } from '../Commons/Text/Text';

import {
  Button,
  Content,
  IconContent,
  IconWrapper,
  LogoWrapper,
  NoMatchWrapper,
  NumberError,
  TextContent,
} from './NoMatch.styles';

const NoMatch = () => (
  <NoMatchWrapper>
    <Content>
      <TextContent>
        <Headline>
          <FormattedMessage id="common.pageNotFoundLegend" />
        </Headline>
        <Text>
          <FormattedMessage id="common.pageNotFoundSubtitle" />
        </Text>
        <HSpacer />
        <Button to="/">
          <FormattedMessage id="broken.goHome" />
        </Button>
      </TextContent>
      <WSpacer width={50} />
      <IconContent>
        <IconWrapper>
          <Broken />
        </IconWrapper>
        <NumberError>
          <FormattedMessage id="common.pageNotFoundTitle" />
        </NumberError>
      </IconContent>
    </Content>
    <LogoWrapper>
      <FullLogo />
    </LogoWrapper>
  </NoMatchWrapper>
);

export default NoMatch;
