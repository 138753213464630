import styled from '@emotion/styled';
import { SearchBarWrapperProps } from './types';

export const SearchBarInput = styled.input`
  width: 100%;
  height: 34px;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  text-transform: capitalize;
  color: #7a7a7a;
  background-color: #e9e9e9;
  padding-inline: 10px;
  &:focus {
    outline: none;
  }
`;

export const SearchBarWrapper = styled.div<SearchBarWrapperProps>`
  display: flex;
  width: 290px;
  flex-direction: row;
  background-color: #e9e9e9;
  border: ${({ focus }) => (focus ? '2px solid #009FDD' : '1px solid #e7e7e7')};
  border-radius: 5px;
  align-items: center;
  padding-inline: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const DeleteIconWrapper = styled.div`
  cursor: pointer;
  margin: 0px;
  padding: 0px;
`;

export const SearchIconWrapper = styled.div``;
