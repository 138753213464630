import { Market } from 'features/app/utils/types/vendor';

export const marketChecker = (
  markets: Market[],
  availableMarkets: string[]
) => {
  const mkt = markets?.filter((market) =>
    availableMarkets?.includes(market.code)
  );
  return mkt?.length > 0;
};
