import styled from '@emotion/styled';

export const HeaderMobileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
  padding: 6px;
  position: fixed;
  z-index: 10;
  background-color: white;
  box-shadow: 0px 0px 15px rgb(184 196 208 / 50%);
`;

export const WrapperIcon = styled.div`
  cursor: pointer;
  align-self: center;
`;
