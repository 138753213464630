import BrokenLinkWOIcon from 'assets/svgs/BrokenLinkWOIcon';

import { CANCELED } from 'features/app/constants/lineItemStatus';
import { formatDate } from 'features/app/utils/date';
import { LineItem } from 'features/app/utils/types/lineItems';
import { centsToDollars } from 'helpers/centsToDollars';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';
import theme from 'theme';
import MoreInfoIcon from 'assets/svgs/MoreInfoIcon';
import {
  TextBase,
  TextLg,
  TextSm,
  TextXl,
} from 'features/app/components/Commons/Text/Text';
import { WSpacer } from 'features/app/components/Commons/Spacer.styles';
import {
  getStatus,
  GetStatusChip,
  handleWorkOrderLink,
} from 'features/app/components/ProjectLogView/helper';
import Modal from 'features/app/components/Commons/Modals/Modal';
import LessIcon from 'assets/svgs/LessIcon';
import PlusIcon from 'assets/svgs/PlusIcon';
import {
  ButtonIcon,
  CostLimit,
  EllipsisWrapper,
  EndLine,
  Group,
  Li,
  MoreInfoModalMobileWrapper,
  MoreInfoWrapperMobile,
  NameLimit,
  Row,
  RowDiv,
  RowDivWO,
} from '../../../../List.styles';
import {
  LineInfoModal,
  ModalLinkErrorWrapper,
} from '../../../../../Table/Table.styles';

export default function CardMobile({
  item,
  index,
  dataLength,
  propId,
}: {
  item: any;
  index: number;
  dataLength: number;
  propId: any;
}) {
  const [showDescription, setShowDescription] = useState(false);
  const refItem: any = useRef(null);
  const showLastRow = (itemRow: LineItem) => !!itemRow?.approvedAt;
  const showDescriptionClick = () => {
    setShowDescription(!showDescription);
  };
  const [ellipsis, setEllipsis] = useState(false);
  const auth = useSelector((state: RootStateOrAny) => state.auth);

  /* START ERROR MODAL LOGIC */
  const [isOpen, setIsOpen] = useState(false);
  const closeModal = () => {
    setIsOpen(false);
  };

  const [isOpenMoreInfo, setIsOpenMoreInfo] = useState(false);

  const lineItemForModal = useRef<LineItem | undefined>();

  const handleModalMoreInfo = (itemInformation: any) => {
    lineItemForModal.current = itemInformation;
    setIsOpenMoreInfo(!isOpenMoreInfo);
  };
  /* FINISH ERROR MODAL LOGIC */

  useEffect(() => {
    if (isEllipsisActive()) {
      setEllipsis(true);
    }
  }, []);

  function isEllipsisActive() {
    return (
      refItem?.current?.offsetHeight < refItem?.current?.scrollHeight ||
      refItem?.current?.offsetWidth < refItem?.current?.scrollWidth
    );
  }

  return (
    <Li key={item.id}>
      <Row header>
        <NameLimit showDescription={showDescription}>
          <TextBase
            ref={refItem}
            textAlign="left"
            weight={theme.fontWeight.regular}
          >
            {item?.name}
          </TextBase>
        </NameLimit>
        <EllipsisWrapper>
          {ellipsis && (
            <CostLimit>
              <ButtonIcon type="button" onClick={showDescriptionClick}>
                {showDescription ? <LessIcon /> : <PlusIcon />}
              </ButtonIcon>
            </CostLimit>
          )}
          <CostLimit alignSelf="center">
            <TextBase weight={theme.fontWeight.bold}>
              {centsToDollars(item?.costCents)}
            </TextBase>
          </CostLimit>
        </EllipsisWrapper>
      </Row>
      <Row last={!showLastRow(item)}>
        <Group>
          <RowDiv>
            <TextBase>
              <FormattedMessage id="paylog.woId" />
            </TextBase>
            <WSpacer width={5} />
            <RowDivWO
              textDecoration={
                item?.workOrderId.toString() !== 'N/A' &&
                getStatus(item) !== CANCELED
              }
            >
              <TextBase
                weight={theme.fontWeight.bold}
                onClick={() => {
                  if (
                    item?.workOrderId.toString() !== 'N/A' &&
                    getStatus(item) !== CANCELED
                  ) {
                    handleWorkOrderLink(
                      propId,
                      item?.workOrderId,
                      auth.data.token,
                      setIsOpen
                    );
                  }
                }}
              >
                {item?.workOrderId.toString() !== 'N/A'
                  ? `#${item?.workOrderId}`
                  : 'No WO'}
              </TextBase>
            </RowDivWO>
            {item.section && (
              <MoreInfoWrapperMobile onClick={() => handleModalMoreInfo(item)}>
                <MoreInfoIcon width={18} height={18} />
              </MoreInfoWrapperMobile>
            )}
          </RowDiv>
        </Group>
        {GetStatusChip(item)}
      </Row>
      {showLastRow(item) && (
        <Row last>
          <Group column>
            <TextBase>
              <FormattedMessage id="paylog.invoiceId" />
            </TextBase>
            <TextBase weight={theme.fontWeight.bold}>
              {item?.payment?.id || '---'}
            </TextBase>
          </Group>
          <Group column>
            <TextBase>
              <FormattedMessage id="paylog.markedComplete" />
            </TextBase>
            <TextBase weight={theme.fontWeight.bold}>
              {formatDate(item?.approvedAt)}
            </TextBase>
          </Group>
          <Group column>
            <TextBase>
              <FormattedMessage id="paylog.paymentDate" />
            </TextBase>
            <TextBase weight={theme.fontWeight.bold}>
              {formatDate(item?.payment?.completedAt)}
            </TextBase>
          </Group>
        </Row>
      )}
      {index !== dataLength - 1 && <EndLine />}
      {isOpen && (
        <Modal visibility setVisibility={closeModal}>
          <ModalLinkErrorWrapper>
            <BrokenLinkWOIcon width={40} height={40} />
            <TextXl color={theme.color.black}>
              Work Order Link Unavailable
            </TextXl>
            <TextSm color={theme.color.black}>
              <FormattedMessage id="broken.woErrorText" />
            </TextSm>
          </ModalLinkErrorWrapper>
        </Modal>
      )}
      {isOpenMoreInfo && (
        <Modal visibility setVisibility={handleModalMoreInfo}>
          <MoreInfoModalMobileWrapper>
            <TextLg color={theme.color.black} style={{ fontWeight: 600 }}>
              <FormattedMessage id="paylog.infoTitle" />
            </TextLg>
            <LineInfoModal />
            <TextLg
              color={theme.color.black}
              style={{ fontWeight: 600, fontSize: 18, paddingBottom: 10 }}
            >
              <FormattedMessage id="paylog.infoDescription" />
            </TextLg>
            <TextSm color={theme.color.black}>
              {lineItemForModal.current?.name}
            </TextSm>
            <TextLg
              color={theme.color.black}
              style={{
                fontWeight: 600,
                fontSize: 18,
                paddingTop: 30,
                paddingBottom: 10,
              }}
            >
              <FormattedMessage id="paylog.infoLocation" />
            </TextLg>
            <TextSm color={theme.color.black}>
              {lineItemForModal.current?.section}
            </TextSm>
          </MoreInfoModalMobileWrapper>
        </Modal>
      )}
    </Li>
  );
}
