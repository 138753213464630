import httpClient from '../services/httpClient';
import {
  LineItemNameProp,
  LineItemProp,
  PaymentItemProp,
  PaymentsProp,
} from '../utils/types/payments';

export interface getPaylogDataProps {
  token: string;
  startDate: string;
  endDate: string;
}

export const getPaylogData = async (id: number, body: getPaylogDataProps) => {
  const { token, startDate, endDate } = body;
  const config = {
    headers: {
      Authorization: token,
    },
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  };

  try {
    const res = await httpClient.get(`/properties/${id}/payments`, config);
    let lineItemsName: LineItemNameProp = [];
    const payments: PaymentsProp[] = [];
    res?.data?.payments?.forEach((item: PaymentItemProp) => {
      lineItemsName = [];
      item.lineItems.map((lineItem: LineItemProp) =>
        lineItemsName.push(lineItem?.name)
      );
      payments.push({ ...item, lineItems: lineItemsName });
    });
    return payments;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getSummaryPaylog = async (body: getPaylogDataProps) => {
  const { token, startDate, endDate } = body;
  const config = {
    headers: {
      Authorization: token,
    },
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  };

  try {
    const res = await httpClient.get('/payments', config);
    let lineItemsName: LineItemNameProp = [];
    const payments: PaymentsProp[] = [];
    res?.data?.payments?.forEach((item: PaymentItemProp) => {
      lineItemsName = [];
      item.lineItems.map((lineItem: LineItemProp) =>
        lineItemsName.push(lineItem?.name)
      );
      payments.push({ ...item, lineItems: lineItemsName });
    });
    return payments;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getTotals = async (id: number, body: getPaylogDataProps) => {
  const { token } = body;
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await httpClient.get(
      `/vendors/payments/info_card?property_id=${id}`,
      config
    );

    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};
