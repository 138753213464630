import styled from '@emotion/styled';

export const WrapperVendorInformation = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

export const VendorInformationText = styled.div`
  padding: 0px;
`;

export const VendorIconContainer = styled.div`
  align-self: center;
`;
