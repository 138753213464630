import { useTheme } from '@emotion/react';
import { CompletedGlyph, InProgressGlyph } from 'assets/glyphs';
import { differenceInDays, format } from 'date-fns';
import { centsToDollars } from 'helpers/centsToDollars';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Details,
  DetailsWrapper,
  OneRowWrapper,
  MilestoneDetailWrapper,
  Title,
  TitleWrapper,
} from './MilestoneDetail.styles';
import { EventProps } from '../../types';

interface MilestoneDetailProps {
  milestone: EventProps;
}

const MileStoneDetail: React.FC<MilestoneDetailProps> = ({ milestone }) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const formatDate = (date: string) => {
    if (date) {
      return format(new Date(`${date}T00:00:00`), 'MMM d, yyyy');
    }
    return formatMessage({ id: 'common.noDate' });
  };

  const etaCalc = () => (
    <OneRowWrapper>
      <Details setBold setSpacing>
        {milestone.start && milestone.end
          ? differenceInDays(
              new Date(`${milestone?.end}T00:00:00`),
              new Date(`${milestone?.start}T00:00:00`)
            )
          : '--'}
      </Details>
      <Details>{`(${formatDate(milestone?.start)} - ${formatDate(
        milestone?.end
      )})`}</Details>
    </OneRowWrapper>
  );

  return (
    <MilestoneDetailWrapper>
      <TitleWrapper>
        <Title spacingTitle>{milestone?.name}</Title>
        <Title greenTitle>{`${centsToDollars(
          milestone?.totalCentCost
        )}`}</Title>
      </TitleWrapper>
      <DetailsWrapper>
        <Details>
          <FormattedMessage id="common.status" />
        </Details>
        <OneRowWrapper>
          {milestone?.done ? (
            <CompletedGlyph
              color={theme.color.green700}
              size={13}
              viewBoxWidth={22}
              viewBoxHeight={22}
            />
          ) : (
            <InProgressGlyph
              color={theme.color.indigo600}
              size={13}
              viewBoxWidth={22}
              viewBoxHeight={22}
            />
          )}
          <Details setBold>
            {milestone?.done ? (
              <FormattedMessage id="common.completed" />
            ) : (
              <FormattedMessage id="common.inProgress" />
            )}
          </Details>
        </OneRowWrapper>
      </DetailsWrapper>
      <DetailsWrapper>
        <Details>
          <FormattedMessage id="calendar.daysToCompletion" />
        </Details>
        {etaCalc()}
      </DetailsWrapper>
      <DetailsWrapper>
        <Details>
          <FormattedMessage id="common.estimated" />
        </Details>
        {etaCalc()}
      </DetailsWrapper>
    </MilestoneDetailWrapper>
  );
};

export default MileStoneDetail;
