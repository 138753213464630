import styled from '@emotion/styled';

export const BrokenLinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const BrokenContent = styled.div`
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  width: 70%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 90%;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  bottom: 10px;
  height: 40px;
`;

export const IllustrationWrapper = styled.div`
  height: 210px;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    height: 150px;
  }
`;
