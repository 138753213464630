/* eslint-disable @typescript-eslint/no-unused-expressions */
import { DownChevronGlyph, UpChevronGlyph } from 'assets/glyphs';
import { WSpacer } from 'features/app/components/Commons/Spacer.styles';
import { OptionsSelect } from 'features/app/components/ProjectLogView/Components/ProjectLogData/Components/Filter/Filter';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import theme from 'theme';
import { SelectWrapper } from '../../Dropdown/Dropdown.styles';
import {
  Background,
  Input,
  Label,
  Option,
  Options,
  Select,
} from '../../MultiSelect/MultiSelect.styles';

export interface FilterSelectProps {
  icon: React.ReactNode;
  label: string;
  groupByData: any[];
  options: any[];
  onChange: (optionSelected: OptionsSelect) => void;
}

const FilterSelect = ({
  icon,
  label,
  groupByData,
  options,
  onChange,
}: FilterSelectProps) => {
  const [open, setOpen] = useState(false);
  const { formatMessage } = useIntl();
  const showSelects = () => {
    if (groupByData.length > 0) {
      return groupByData.map((select) => select.label).join(', ');
    }
    return formatMessage({ id: 'filter.select' });
  };

  const isCheckedOption = (option: OptionsSelect): boolean =>
    groupByData.filter(
      (optionSelected) => optionSelected.label === option.label
    ).length > 0;

  const onSelectOption = (option: OptionsSelect) => {
    setOpen(false);
    onChange(option);
  };
  return (
    <>
      {open && <Background onClick={() => setOpen(false)} />}
      <SelectWrapper>
        <Label>
          {icon}
          <WSpacer width={5} />
          <FormattedMessage id={label} />
        </Label>
        <Select onClick={() => setOpen(!open)} empty>
          {showSelects()}
          {open ? (
            <UpChevronGlyph color={theme.color.font} />
          ) : (
            <DownChevronGlyph color={theme.color.font} />
          )}
        </Select>
        {open && (
          <Options>
            {options.map((option) => (
              <Option key={option.value} onClick={() => onSelectOption(option)}>
                <Input
                  type="checkbox"
                  checked={isCheckedOption(option)}
                  onChange={() => onSelectOption(option)}
                />
                <span>{option.label}</span>
              </Option>
            ))}
          </Options>
        )}
      </SelectWrapper>
    </>
  );
};

export default FilterSelect;
