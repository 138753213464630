import ChevronLeftIcon from 'assets/svgs/ChevronLeftIcon';
import ChevronRightIcon from 'assets/svgs/ChevronRightIcon';
import {
  GetHomesFromHomeView,
  SET_PAGE_HOME,
} from 'features/app/store/listOfHomes/ListOfHomesActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
  ArrowLineDivisor,
  ArrowPaginationWrapper,
  IconContainerPagination,
} from './ArrowsPagination.styles';

export interface ArrowsPaginationProps {
  token: string | any;
}

function ArrowsPagination({ token }: ArrowsPaginationProps) {
  const { page, totalOfPages } = useSelector(
    (store: RootStateOrAny) => store.ListOfHomes
  );
  const dispatch = useDispatch();
  const nextPage = () => {
    if (page < totalOfPages) {
      dispatch(SET_PAGE_HOME(page + 1));
      dispatch(GetHomesFromHomeView({ token, page: page + 1 }));
    }
  };

  const backPage = () => {
    if (page > 1) {
      dispatch(SET_PAGE_HOME(page - 1));
      dispatch(GetHomesFromHomeView({ token, page: page - 1 }));
    }
  };

  return (
    <ArrowPaginationWrapper>
      <IconContainerPagination onClick={backPage}>
        <ChevronLeftIcon height={15} width={15} color="#212121" />
      </IconContainerPagination>
      <ArrowLineDivisor />
      <IconContainerPagination onClick={nextPage}>
        <ChevronRightIcon height={15} width={15} color="#212121" />
      </IconContainerPagination>
    </ArrowPaginationWrapper>
  );
}

export default ArrowsPagination;
