import styled from '@emotion/styled';

export const PaginationWrapper = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.color.gray300};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius.xl};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius.xl};
  padding: 0px 10px;
`;

export const Pages = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

export const Arrows = styled.div`
  display: flex;
  width: 110px;
  align-items: center;
  justify-content: space-evenly;
`;

export const Button = styled.button`
  unset: all;
  &:focus {
    outline: none;
  }
`;

export const Numbers = styled.div`
  display: flex;
  margin-left: 10px;
`;

export const Limit = styled.div`
  display: flex;
`;
