import { useRef, useEffect, useState } from 'react';
import { useIsMobile, useIsTablet } from 'features/app/hooks/screen';
import { CloseGlyph } from 'assets/glyphs';
import { Blur } from 'features/app/components/Commons/Blur.styles';
import Portal from './Portal';
import {
  BackgroundOuter,
  CloseWrapper,
  ModalWrapper,
} from './FreePositionModal.styles';

interface ModalProps {
  setVisibility: (visibility: boolean) => void;
  clientX: number;
  clientY: number;
  maxWidth?: boolean;
  blur?: boolean;
  customWidth?: boolean;
}

const FreePositionModal: React.FC<ModalProps> = ({
  children,
  setVisibility,
  clientX,
  clientY,
  maxWidth,
  blur,
  customWidth,
}) => {
  const backgroundRef = useRef<any>();
  const modalWrapperRef = useRef<any>();
  const [y, setY] = useState(clientY);

  useEffect(() => {
    window.setTimeout(() => {
      if (backgroundRef.current && modalWrapperRef.current) {
        backgroundRef.current.style.opacity = 1;
        modalWrapperRef.current.style.transform = 'translateY(0)';
        window.setTimeout(() => {
          if (modalWrapperRef.current) {
            modalWrapperRef.current.style.transform = 'none';
          }
        }, 1000);
      }
    }, 50);
  }, []);

  const handleScroll = () => {
    const newY = y - window.pageYOffset;
    if (newY > 75) setY(y - window.pageYOffset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const dismiss = () => {
    if (backgroundRef.current && modalWrapperRef.current) {
      backgroundRef.current.style.opacity = null;
      modalWrapperRef.current.style.transform = null;
    }
    setVisibility(false);
  };

  const child = (
    <ModalWrapper
      onClick={(e) => e.stopPropagation()}
      clientX={clientX}
      clientY={y}
      ref={modalWrapperRef}
      isMobile={isMobile || isTablet || false}
      maxWidth={!!maxWidth}
      customWidth={customWidth}
    >
      <CloseWrapper onClick={() => dismiss()}>
        <CloseGlyph size={20} viewBoxHeight={36} viewBoxWidth={36} />
      </CloseWrapper>
      {children}
    </ModalWrapper>
  );

  return (
    <Portal>
      {blur ? (
        <Blur ref={backgroundRef} onClick={() => dismiss()} animation>
          {child}
        </Blur>
      ) : (
        <BackgroundOuter ref={backgroundRef} onClick={() => dismiss()}>
          {child}
        </BackgroundOuter>
      )}
    </Portal>
  );
};

export default FreePositionModal;
