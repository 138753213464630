import styled from '@emotion/styled';

export const MenuRightSideBarWrapper = styled.div`
  position: fixed;
  right: 0;
  z-index: 1;
  height: 100%;
  width: 350px;
  padding: 23px 21px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;
