import { getPaylogDataProps } from 'features/app/api/paylog';
import { HomeTotalInformationProps } from './homeTotalsActions';

export const HOME_TOTALS_TYPES = {
  GET_HOME_TOTALS: 'GET_HOME_TOTALS',
  SET_HOME_INFORMATION: 'SET_HOME_INFORMATION',
  LOADING_HOME_INFORMATION: 'LOADING_HOME_INFORMATION',
};

export type getHomeTotals = {
  type: typeof HOME_TOTALS_TYPES.GET_HOME_TOTALS;
  payload: getPaylogDataProps;
};

export type setHomeInformation = {
  type: typeof HOME_TOTALS_TYPES.SET_HOME_INFORMATION;
  payload: HomeTotalInformationProps;
};

export type loadingHomeInformation = {
  type: typeof HOME_TOTALS_TYPES.LOADING_HOME_INFORMATION;
  payload: boolean;
};

export type HomeTotalsActions =
  | getHomeTotals
  | setHomeInformation
  | loadingHomeInformation;
