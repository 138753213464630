import { IconPropTypes } from './types';

const MoneyIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 52 30"
    fill="none"
  >
    <path
      d="M23.8184 6V20"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27 8.54541H22.2273C21.6366 8.54541 21.07 8.78007 20.6524 9.19776C20.2347 9.61546 20 10.182 20 10.7727C20 11.3634 20.2347 11.9299 20.6524 12.3476C21.07 12.7653 21.6366 13 22.2273 13H25.4091C25.9998 13 26.5663 13.2346 26.984 13.6523C27.4017 14.07 27.6364 14.6365 27.6364 15.2272C27.6364 15.8179 27.4017 16.3845 26.984 16.8021C26.5663 17.2198 25.9998 17.4545 25.4091 17.4545H20"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="1"
      y="1"
      width="46"
      height="24"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M51 5V29H5"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 13H8"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M40 13H34"
      stroke={color || '#009FDD'}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default MoneyIcon;
