import {
  homeMilestonesActions,
  HOME_MILESTONES_TYPES,
} from './homeMolestonesTypes';

const initialState = {
  loading: false,
  homeMilestones: [],
};

export const homeMilestonesReducer = (
  state = initialState,
  action: homeMilestonesActions
) => {
  switch (action.type) {
    case HOME_MILESTONES_TYPES.SET_HOME_MILESTONES:
      return {
        ...state,
        homeMilestones: action.payload,
        loading: false,
      };
    case HOME_MILESTONES_TYPES.SET_LOADING_HOME_MILESTONES:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
