const translations = {
  common: {
    accountSettings: 'Ajustes de cuenta',
    alreadyHaveAnAccount: '¿Ya tienes una cuenta?',
    changePasswordSuccess: 'Tu contraseña ha sido cambiada con éxito',
    confirm: 'Confirmar',
    confirmPassword: 'Confirmar contraseña',
    createAccount: 'Crea una aquí',
    currentPassword: 'Contraseña Actual',
    dontHaveAccountQuestion: '¿No tienes cuenta?',
    earned: 'Ganado',
    email: 'Correo',
    errorBoundaryMessage: 'Ha ocurrido un error',
    errorMessage: 'Ha ocurrido un error',
    firstName: 'Nombre',
    forgotPassword: '¿Olvidaste tu contraseña?',
    forgotPasswordChange: 'Paso 3: Ingresa tu nueva contraseña',
    forgotPasswordEmailResend: '¿No recibiste un código?',
    forgotPasswordEmailSent:
      'Paso 2: Ingresa el código de verificacion que hemos enviado a tu correo',
    forgotPasswordLegend:
      'Paso 1: Ingresa tu correo y te ayudaremos a recuperar tu contraseña',
    goBackHome: 'Volver al inicio',
    invalidEmail: 'Email inválido',
    lastName: 'Apellido',
    lengthToken: 'Debe tener seis dígitos',
    locale: 'Idioma',
    newPassword: 'Nueva Contraseña',
    next: 'Siguiente',
    pageNotFoundLegend: 'Página no encontrada',
    pageNotFoundTitle: '404',
    pageNotFoundSubtitle:
      'La página que estas buscando no existe o ha ocurrido un error. Volver al inicio',
    password: 'Contraseña',
    passwordsDontMatch: 'Las contraseñas no coinciden',
    reportError: 'Reportar error',
    required: 'Obligatorio',
    resetPassword: 'Recuperar Contraseña',
    resetPasswordSuccess: 'Tu contraseña se ha actualizado con éxito',
    selectLanguage: 'Seleccionar idioma',
    selectOption: 'Seleccionar una opción',
    shortPassword:
      'Contraseña demasiado corta, el largo mínimo es de 8 caracteres',
    signIn: 'Iniciar Sesión',
    signOut: 'Cerrar Sesión',
    signUp: 'Crear Cuenta',
    token: 'Código',
    updatePassword: 'Actualizar Contraseña',
    updateSettings: 'Actualizar Ajustes',
    updateSettingsSuccess: 'Tus ajustes han sido actualizados con éxito',
    searchPlaceholder: 'Buscar',
    status: 'Estado',
    estimated: 'Estimado',
    completed: 'Completado',
    inProgress: 'En Progreso',
    total: 'Total',
    period: 'Período',
    address: 'Dirección',
    description: 'Descripción',
    welcome: 'Bienvenido al Portal de Vendors de SFR3',
    youNeedLink:
      'Vas a recibir un link especial para acceder. Si necesitas ayuda podes contactarte a: ',
    helpMail: 'vendors@sfr3.com',
    summary: 'Resumen',
    calendar: 'Actual & Próximos Trabajos',
    homes: 'Casas',
    noDate: 'No hay fecha',
    noPhone: 'Sin teléfono',
    noEmail: 'Sin correo',
    today: 'Hoy',
    milestones: 'Hitos',
    exportAs: 'Exportar como',
  },
  calendar: {
    daysToCompletion: 'Días para finalizar',
    viewMore: 'Ver más',
    dayTask: '{day} Tareas',
    activeHomes: 'Casas Activas',
    milestonesOnGoing: 'Tareas en Progreso',
    futureHomes: 'Casas Futuras',
    futureTasks: 'Tareas Futuras',
    totalProjects: 'Proyectos Totales',
    totalTasks: 'Tareas Totales',
    onGoing: 'En Progreso',
    viewSummary: 'Ver Resumen',
  },
  project: {
    assignedJobsMilestone: 'de trabajos asignados',
    completedMilestones: 'Hitos completados',
    futureWork: 'Próximos trabajos',
    forThisHome: 'Para esta casa',
    pending: 'Pendiente',
    pendingPayment: 'Pago pendiente',
    projectEta: 'ETA del Proyecto',
    thisMonth: 'Este mes',
    viewPayLog: 'Ver registro de pago',
    vendorEta: 'Vendor ETA',
  },
  contact: {
    shortRoleRm: 'PM',
    shortRoleMd: 'RD',
    shortRoleRd: 'RD',
    roleRm: 'Project Manager',
    roleMd: 'Renovation Director',
    roleRd: 'Renovation Director',
    title: 'Contacto',
  },
  homes: {
    title: 'Casas',
    completed: 'Completadas',
    active: 'Activas',
    all: 'Todas',
    noCompleted: 'No hay casas completadas',
    noCompletedSub: 'Aqui podrás ver las casas que ya completaste',
  },
  filter: {
    title: 'Filtros',
    search: 'Buscar por cualquier campo',
    status: 'Estado',
    select: 'Seleccionar',
    clearFilters: 'Limpiar filtros',
    period: 'Período',
    clearFilterMobile: 'Limpiar',
    groupBy: 'Agrupar por',
    periodLabel: 'Payment Period',
  },
  paylog: {
    title: 'Registro del Proyecto',
    task: 'Tarea',
    amount: 'Monto',
    status: 'Estado',
    markedComplete: 'Completada',
    paymentDate: 'Fecha de Pago',
    received: 'Pagado',
    workOrderId: 'Orden de Trabajo #',
    woId: 'OT #',
    invoiceId: 'Invoice #',
    milestoneName: 'Milestone',
    lineItem: 'Line Item',
    pending: 'Pendiente',
    awaitingPayment: 'Esperando Pago',
    searchByTask: 'Buscar por Tarea',
    searchBy: 'Buscar por Tarea o Casa',
    selectAddress: 'Seleccionar Dirección',
    selectPeriod: 'Seleccionar Período',
    empty: 'No hay registros de items',
    linesPerPage: 'Líneas por página:',
    of: 'de',
  },
  paylogCard: {
    title: 'Información de pago',
    paymentProcessing: 'En proceso de pago',
    workRemaining: 'Trabajo restante',
    paid: 'Pagado',
  },
  summary: {
    currentProjects: 'Proyectos en progreso',
    nextPayment: 'Próximo pago: {date}',
    viewPaylog: 'Ver registro del Proyecto',
    viewStatus: 'Ver estado',
    viewMore: 'Ver más',
  },
  broken: {
    invalid: 'Link Inválido',
    message:
      'Parece que el link que estás intentando usar no es válido. Por favor contacta a tu administrador o prueba recargando la página.',
    reload: 'Recargar Página',
  },
  login: {
    welcome: 'Bienvenido a',
    SFR3: 'SFR3 Vendors Portal',
    loginText: 'Logueate completando el',
    below: 'form inferior',
    emailOrPhone: 'Email o número de teléfono',
    continue: 'Continuar',
  },
  verificationLogin: {
    welcome: 'Tu código de verificación',
    sent: 'ha sido enviado!',
    please: 'Por favor, escriba debajo el codigo de',
    code: 'verificación que ha recibido via SMS.',
  },
};

export default translations;
