import NoMatch from '../components/NoMatchView';

interface NoMatchProps {
  hideLayout: (active: boolean) => void;
}

const NoMatchPage: React.FC<NoMatchProps> = ({ hideLayout }) => {
  hideLayout(true);
  return <NoMatch />;
};

export default NoMatchPage;
