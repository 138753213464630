import { useTheme } from '@emotion/react';
import { MagnifyingGlassGlyph } from 'assets/glyphs';
import DeleteIcon from 'assets/svgs/DeleteIcon';
import { useIsMobile } from 'features/app/hooks/screen';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';
import { SearchInput, SearchStyledInput } from './Search.styles';
import SearchResultsDesktop from './SearchResultsDesktop';
import SearchResultsMobile from './SearchResultsMobile';

interface SearchProps {
  placeholder?: string;
  showResults?: boolean;
  clear?: boolean;
  onChange?: (value: string) => void;
  onClear?: () => void;
  onCloseMobile?: (close: boolean) => void;
}

const Search: React.FC<SearchProps> = ({
  placeholder,
  showResults = false,
  clear,
  onCloseMobile,
  onChange,
  onClear,
}) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [showClear, setShowClear] = useState(false);
  const [focus, setFocus] = useState(false);
  const { formatMessage } = useIntl();
  const [value, setValue] = useState('');
  const [foundItems, setFoundItems] = useState<any[]>([]);
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  useEffect(() => {
    if (showResults) {
      if (value) {
        const regex = new RegExp(value.toLowerCase(), 'g');
        const resultsActive = data.activeProperties.filter((x: any) =>
          x.address.toLowerCase().match(regex)
        );
        const resultsComplete = data.completedProperties.filter((x: any) =>
          x.address.toLowerCase().match(regex)
        );
        setFoundItems([...resultsActive, ...resultsComplete]);
      } else {
        setFoundItems([]);
        setValue('');
      }
    }
  }, [
    value,
    showClear,
    data?.activeProperties,
    data?.completedProperties,
    showResults,
  ]);

  useEffect(() => {
    if (value && !showClear) {
      setShowClear(true);
    }
    if (!value && showClear) {
      setShowClear(false);
    }
  }, [value, showClear]);

  useEffect(() => {
    if (onClear && !showClear && !value) onClear();
  }, [value, showClear, onClear]);

  useEffect(() => {
    if (clear) onFocusFalse();
  }, [clear]);

  const onFocusTrue = () => {
    setFocus(true);
  };

  const onFocusFalse = () => {
    setFocus(false);
  };

  const onSelect = () => {
    setFocus(false);
    eraseInputContent();
    if (onCloseMobile) onCloseMobile(false);
  };

  const eraseInputContent = () => {
    setValue('');
    if (isMobile) onFocusFalse();
  };

  const handleChange = (val: string) => {
    setValue(val);
    if (onChange) onChange(val);
  };

  return (
    <SearchStyledInput showClear={showClear} onFocus={onFocusTrue}>
      <SearchInput
        focus={focus}
        onChange={(e) => handleChange(e.target.value)}
        value={value}
        onBlur={(e) => {
          if (
            !e.relatedTarget?.className.includes('navigationLink') &&
            !isMobile
          ) {
            onFocusFalse();
          }
        }}
        placeholder={formatMessage({
          id: placeholder || 'common.searchPlaceholder',
        })}
      />
      <MagnifyingGlassGlyph
        color={theme.color.contactIcons}
        className="left-icon"
        viewBoxWidth={24}
        viewBoxHeight={24}
        size={35}
      />
      <DeleteIcon
        color={theme.color.contactIcons}
        width="45px"
        onClick={eraseInputContent}
        className="right-icon"
      />
      {focus &&
        showResults &&
        (isMobile ? (
          <SearchResultsMobile
            foundItems={foundItems}
            value={value}
            setFocus={onSelect}
          />
        ) : (
          <SearchResultsDesktop
            foundItems={foundItems}
            value={value}
            setFocus={setFocus}
          />
        ))}
    </SearchStyledInput>
  );
};

export default Search;
