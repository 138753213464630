import { rightSideBarTypes } from 'features/app/components/Commons/MenuRightSideBar/types';
import { Text } from 'features/app/components/Commons/Text/Text';
import { centsToDollars } from 'helpers/centsToDollars';
import { InfoCardProps, LineItemsScopeProps } from '../Card/HomeCardTypes';
import { PercentageProgressWrapper, WrapperProgress } from './Progress.styles';

export interface ProgressProps {
  scope: {
    approved: number;
    totalScope: number;
  };
  rightNavBarSelection: string;
  lineItemsScope: LineItemsScopeProps;
  infoCard: InfoCardProps;
}

export default function Progress({
  scope: { approved, totalScope },
  lineItemsScope,
  rightNavBarSelection,
  infoCard,
}: ProgressProps) {
  const getTextTitle = () => {
    if (rightNavBarSelection === rightSideBarTypes.pendingPayment) {
      if (lineItemsScope.approved === lineItemsScope.totalScope) {
        return 'Amount Paid';
      }
      return 'Progress';
    }
    if (rightNavBarSelection === rightSideBarTypes.futureWork) {
      return 'Progress';
    }
    if (rightNavBarSelection === rightSideBarTypes.earnThisMonth) {
      return 'Earned This Month';
    }
    return 'Progress';
  };

  const getSubtitleText = () => {
    if (rightNavBarSelection === rightSideBarTypes.pendingPayment) {
      if (lineItemsScope.approved === lineItemsScope.totalScope) {
        return centsToDollars(infoCard.completedPaymentsAmount);
      }
      return totalScope !== 0
        ? `${((approved / totalScope) * 100).toFixed(0)}%`
        : '0%';
    }
    if (rightNavBarSelection === rightSideBarTypes.futureWork) {
      return totalScope !== 0
        ? `${((approved / totalScope) * 100).toFixed(0)}%`
        : '0%';
    }
    if (rightNavBarSelection === rightSideBarTypes.earnThisMonth) {
      return centsToDollars(infoCard.completedPaymentsAmount);
    }
    return totalScope !== 0
      ? `${((approved / totalScope) * 100).toFixed(0)}%`
      : '0%';
  };
  return (
    <WrapperProgress>
      <Text color="#373737" weight="400" fontSize="14" textAlign="end">
        {getTextTitle()}
      </Text>
      <PercentageProgressWrapper>
        <Text color="#373737" fontSize="15" weight="700">
          {getSubtitleText()}
        </Text>
        {/* <TextContainer>
          <Text color="#373737" weight="200" fontSize="15">
            |
          </Text>
        </TextContainer>
        <Text color="#373737" weight="400" fontSize="15">
          {approved}/{totalScope}
        </Text> 
  */}
      </PercentageProgressWrapper>
    </WrapperProgress>
  );
}
