import { HomeByIdActions, HOME_BY_ID_TYPES } from './homeByIdTypes';

const initialState = {
  dataHomeInformation: [],
  loading: false,
};

export const HomeByIdReducer = (
  state = initialState,
  action: HomeByIdActions
) => {
  switch (action.type) {
    case HOME_BY_ID_TYPES.SET_HOME_BY_ID:
      return {
        ...state,
        dataHomeInformation: action.payload,
        loading: false,
      };
    case HOME_BY_ID_TYPES.LOADING_HOME_BY_ID:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
