import { useTheme } from '@emotion/react';
import CompletedIcon from 'assets/svgs/CompletedIcon';
import OnGoingIcon from 'assets/svgs/OnGoingIcon';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import Card from '../../../../../HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import { WSpacer } from '../../../../../Commons/Spacer.styles';
import { Text, TextSm } from '../../../../../Commons/Text/Text';
import {
  TaskDetailWrapper,
  TaskListItem,
  TaskList,
} from './TasksDetail.styles';
import { EventProps } from '../../types';

interface TaskDetailProps {
  date: Date;
  events: EventProps[];
}

const TasksDetail: React.FC<TaskDetailProps> = ({ events, date }) => {
  const theme = useTheme();
  return (
    <Card>
      <TaskDetailWrapper>
        <Text weight={theme.fontWeight.bold}>
          <FormattedMessage
            id="calendar.dayTask"
            values={{ day: format(new Date(date), 'M/d') }}
          />
        </Text>
        <TaskList>
          {events.map((event) => (
            <TaskListItem key={event.id}>
              {event.done ? (
                <CompletedIcon width="18px" height="18px" />
              ) : (
                <OnGoingIcon width="18px" height="18px" />
              )}
              <WSpacer width={10} />
              <TextSm>{event?.name}</TextSm>
            </TaskListItem>
          ))}
        </TaskList>
      </TaskDetailWrapper>
    </Card>
  );
};

export default TasksDetail;
