import { AxiosResponse } from 'axios';
import { getListOfHomes } from 'features/app/api/homes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { LOADING_HOMES, SET_INFORMATION_HOME } from './ListOfHomesActions';
import { getListOfHomesProps, listOfHomesTypes } from './ListOfHomestypes';

function* getHomesOfVendor({ payload }: getListOfHomesProps | any) {
  yield put(LOADING_HOMES(true));
  try {
    const data: AxiosResponse = yield call(getListOfHomes, payload);
    yield put(SET_INFORMATION_HOME(data));
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  } finally {
    yield put(LOADING_HOMES(false));
  }
}

export default function* getHomesVendor(): any {
  yield all([
    yield takeLatest(
      listOfHomesTypes.GET_HOMES_FROM_HOME_VIEW,
      getHomesOfVendor
    ),
  ]);
}
