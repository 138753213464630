import smartlookClient from 'smartlook-client';

export const initSL = () => {
  const isDev = process.env.NODE_ENV === 'development';
  if (!isDev && process.env.REACT_APP_SMARTLOOK_KEY && !isSLInitialized()) {
    smartlookClient.init(process.env.REACT_APP_SMARTLOOK_KEY);
  }
};

export const logEventSL = (eventName: string, eventProps?: any) => {
  const isDev = process.env.NODE_ENV === 'development';
  if (!isDev) {
    smartlookClient.track(eventName, eventProps);
  }
};

export const isSLInitialized = () => smartlookClient.initialized();

export const identifySL = (userId: number, userData?: any) => {
  smartlookClient.identify(userId, userData);
};
