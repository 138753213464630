import { useTheme } from '@emotion/react';
import Broken from 'assets/svgs/BrokenLink';
import { HOMES_PATH } from 'features/app/constants/navigation';
import { FormattedMessage } from 'react-intl';
import { HSpacer } from '../../Spacer.styles';
import { TextBase } from '../../Text/Text';
import { NavigationLink } from './Search.styles';
import {
  EmptySearch,
  ItemResult,
  NoResult,
  SearchResultsWrapper,
} from './SearchResultsMobile.styles';

interface SearchResultsMobileProps {
  foundItems: any[];
  value: string;
  setFocus: (state: boolean) => void;
}

const SearchResultsMobile: React.FC<SearchResultsMobileProps> = ({
  foundItems,
  value,
  setFocus,
}) => {
  const theme = useTheme();

  return (
    <SearchResultsWrapper>
      {foundItems.length > 0 &&
        value.length > 0 &&
        foundItems.map(({ address, id }) => (
          <ItemResult key={id}>
            <NavigationLink
              to={`${HOMES_PATH}/${id}`}
              onClick={() => setFocus(false)}
            >
              <TextBase>{address}</TextBase>
            </NavigationLink>
          </ItemResult>
        ))}
      {foundItems.length === 0 && value.length > 0 && (
        <NoResult>
          <HSpacer />
          <Broken height={100} width={100} />
          <HSpacer />
          <TextBase weight="bold">
            <FormattedMessage id="search.notFound" />
          </TextBase>
          <TextBase color={theme.color.lightFont}>
            <FormattedMessage id="search.emptyState" />
          </TextBase>
        </NoResult>
      )}
      {foundItems.length === 0 && value.length === 0 && (
        <EmptySearch>
          <TextBase color={theme.color.lightFont}>
            <FormattedMessage id="search.emptyState" />
          </TextBase>
        </EmptySearch>
      )}
    </SearchResultsWrapper>
  );
};

export default SearchResultsMobile;
