import { MagnifyingGlassGlyph } from 'assets/glyphs';
import { WSpacer } from 'features/app/components/Commons/Spacer.styles';
import { ON_SEARCH_VALUE } from 'features/app/store/projectLog/projectLogActions';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Label, SearchInput, SearchWrapper } from './FilterSearch.styles';

interface SearchProps {
  placeholder?: string;
  onChange: (value: string) => void;
  valueSearch: string;
}

const FilterSearch: React.FC<SearchProps> = ({
  placeholder,
  onChange,
  valueSearch,
}) => {
  const [focus, setFocus] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onFocusFalse = () => {
    setFocus(false);
  };

  const handleChange = (val: string) => {
    dispatch(ON_SEARCH_VALUE(val));
    onChange(val);
  };

  return (
    <SearchWrapper>
      <Label>
        <MagnifyingGlassGlyph viewBoxWidth={24} viewBoxHeight={24} size={16} />
        <WSpacer width={2} />
        <FormattedMessage id="filter.search" />
      </Label>
      <SearchInput
        focus={focus}
        onChange={(e: any) => handleChange(e.target.value)}
        value={valueSearch}
        onBlur={() => onFocusFalse()}
        placeholder={formatMessage({ id: placeholder })}
      />
    </SearchWrapper>
  );
};

export default FilterSearch;
