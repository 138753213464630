import { LegacyRef } from 'react';
import { Text } from 'features/app/components/Commons/Text/Text';
// import { RootStateOrAny, useSelector } from 'react-redux';
import { ModalCardWrapper, ModalTextContainer } from './ModalHelpCard.styles';

interface ModalHelpCardProps {
  elRef?: LegacyRef<HTMLDivElement> | undefined;
}

const ModalHelpCard: React.FC<ModalHelpCardProps> = ({ elRef }) => (
  <ModalCardWrapper ref={elRef}>
    <ModalTextContainer>
      <Text fontSize="12" style={{ textTransform: 'uppercase' }}>
        Need help? Contact your VM Advocate
      </Text>
    </ModalTextContainer>
    <ModalTextContainer style={{ display: 'flex', gap: '10px' }}>
      <Text fontSize="12" weight="600">
        Mark Twain
      </Text>
      <Text fontSize="12" style={{ opacity: '.5   ' }}>
        mark.twain@sfr3.com 987 654 3210
      </Text>
    </ModalTextContainer>
  </ModalCardWrapper>
);

export default ModalHelpCard;
