import navigation, {
  HOMES_KEY,
  PERFORMANCE_KEY,
  REFERRALS_KEY,
} from 'features/app/constants/navigation';
import { useEffect, useState } from 'react';
import { useIsDesktop } from 'features/app/hooks/screen';
import { useLocation } from 'react-router-dom';
import { navigationFlags } from 'features/app/utils/flags';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ItemPropsTypes } from './types';
import ItemsSideBar from './components/ItemsSideBar';

import { DesktopMenuSideBar, Items } from './MenuSideBar.styles';

export interface flagsProps {
  summary?: boolean;
  calendar?: boolean;
  response: boolean;
  alreadySet?: boolean;
}

const MenuSideBar: React.FC = () => {
  const isDesktop = useIsDesktop();
  const location = useLocation();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const [navKeys, setNavKeys] = useState([
    REFERRALS_KEY,
    HOMES_KEY,
    PERFORMANCE_KEY,
  ]);
  const [flag, setFlag] = useState<flagsProps>({
    response: false,
    alreadySet: false,
  });

  useEffect(() => {
    const getFlag = async () => {
      const flags = await navigationFlags(
        data?.vendor.id,
        data?.vendor?.markets
      );
      setFlag(flags);
    };

    if (!flag.response) getFlag();
  });

  useEffect(() => {
    if (flag.response && !flag.alreadySet) {
      const navigationKeysTmp = navKeys;
      //   if (flag.summary) navigationKeysTmp.push(SUMMARY_KEY);
      //   if (flag.calendar) navigationKeysTmp.push(CALENDAR_KEY);
      setNavKeys([...navigationKeysTmp]);
      setFlag({ ...flag, alreadySet: true });
    }
  }, [navKeys, flag]);

  const navigationFeature = () => {
    const nav: ItemPropsTypes[] = [];
    navigation.forEach((key) => {
      if (navKeys.includes(key.id)) {
        nav.push(key);
      }
    });
    return nav;
  };

  return (
    <>
      {isDesktop ? (
        <DesktopMenuSideBar>
          <Items>
            <ItemsSideBar items={navigationFeature()} location={location} />
          </Items>
        </DesktopMenuSideBar>
      ) : (
        <></>
      )}
    </>
  );
};

export default MenuSideBar;
