/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import { useTheme } from '@emotion/react';
import ResultsIcon from 'assets/svgs/Results';
import TableLoader from 'features/app/components/Commons/Loaders/TableLoader';
import { HSpacer } from 'features/app/components/Commons/Spacer.styles';
import { TextSm } from 'features/app/components/Commons/Text/Text';
import { FormattedMessage } from 'react-intl';
import { EmptyTableResults } from '../Table/Table.styles';
import CardGroupBy from './components/CardGroupBy/CardGroupBy';

interface dataProps {
  key: any[];
}

interface ListProps {
  groupName: string;
  data: dataProps;
  loading: boolean;
  propId?: number;
}

const GroupBy: React.FC<ListProps> = ({ groupName, data, loading, propId }) => {
  const theme = useTheme();

  if (loading) {
    return <TableLoader />;
  }

  if (Object.keys(data).length === 0) {
    return (
      <EmptyTableResults>
        <ResultsIcon width="50px" height="50px" />
        <HSpacer />
        <TextSm color={theme.color.lightFont}>
          <FormattedMessage id="paylog.empty" />
        </TextSm>
      </EmptyTableResults>
    );
  }

  return (
    <>
      {Object.keys(data).map((group: string, indexKey: number) => (
        <CardGroupBy
          propId={propId}
          key={indexKey}
          data={data}
          group={group}
          indexKey={indexKey}
          groupName={groupName}
        />
      ))}
    </>
  );
};

export default GroupBy;
