import { LogoPropTypes } from '../types';

const HomeWithSlave = ({ width, height, color }: LogoPropTypes) => (
  <svg
    width={width || '206'}
    height={height || '165'}
    viewBox="0 0 206 165"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="151.5" cy="21.5" r="21.5" fill="white" />
    <circle cx="175.5" cy="29.5" r="14.5" fill="white" />
    <circle cx="136" cy="33" r="11" fill="white" />
    <circle cx="195" cy="33" r="11" fill="white" />
    <rect x="136" y="34" width="59" height="10" fill="white" />
    <circle cx="29" cy="43" r="17" fill="white" />
    <circle cx="11" cy="50" r="11" fill="white" />
    <circle cx="46" cy="52" r="9" fill="white" />
    <rect x="11" y="51" width="35" height="10" fill="white" />
    <ellipse cx="98.5" cy="44.3464" rx="58.5" ry="14" fill="#B5B5B5" />
    <ellipse cx="98" cy="49.8464" rx="28" ry="6.5" fill="#A7A7A7" />
    <ellipse cx="98.5" cy="50.3464" rx="23.5" ry="4" fill="white" />
    <ellipse
      cx="94.7777"
      cy="36.0038"
      rx="2.46961"
      ry="0.987844"
      fill="white"
    />
    <ellipse
      cx="96.9992"
      cy="57.4895"
      rx="1.23481"
      ry="0.493922"
      fill="white"
    />
    <ellipse
      cx="114.534"
      cy="36.9917"
      rx="2.22265"
      ry="0.987844"
      transform="rotate(5.86873 114.534 36.9917)"
      fill="white"
    />
    <ellipse
      cx="72.3912"
      cy="36.9668"
      rx="2.22265"
      ry="0.987844"
      transform="rotate(-1.76732 72.3912 36.9668)"
      fill="white"
    />
    <ellipse
      cx="51.2021"
      cy="42.086"
      rx="1.8312"
      ry="0.813865"
      transform="rotate(2.59617 51.2021 42.086)"
      fill="white"
    />
    <ellipse
      cx="131.294"
      cy="40.6729"
      rx="2.10521"
      ry="0.93758"
      transform="rotate(12.2213 131.294 40.6729)"
      fill="white"
    />
    <ellipse
      cx="142.667"
      cy="46.2527"
      rx="1.7831"
      ry="1.00357"
      transform="rotate(5.48108 142.667 46.2527)"
      fill="white"
    />
    <ellipse
      cx="136.916"
      cy="52.8351"
      rx="1.56058"
      ry="0.878331"
      transform="rotate(5.48108 136.916 52.8351)"
      fill="white"
    />
    <ellipse
      cx="53.1946"
      cy="50.1161"
      rx="1.56058"
      ry="0.878331"
      transform="rotate(5.48108 53.1946 50.1161)"
      fill="white"
    />
    <ellipse
      cx="120.094"
      cy="55.6712"
      rx="1.29816"
      ry="0.730633"
      transform="rotate(0.91597 120.094 55.6712)"
      fill="white"
    />
    <ellipse
      cx="67.6862"
      cy="54.5363"
      rx="1.29816"
      ry="0.730633"
      transform="rotate(9.80741 67.6862 54.5363)"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72.4785 32.2374C80.2557 31.328 88.9952 30.8177 98.2325 30.8177C106.458 30.8177 114.29 31.2224 121.398 31.9532C117.255 22.5559 107.889 16 97 16C86.0013 16 76.5575 22.6883 72.4785 32.2374Z"
      fill="#D9D9D9"
    />
    <path
      d="M39 164.346L74.8412 50.3464H121.346L164 164.346H39Z"
      fill="url(#paint0_linear_719_712)"
    />
    <path
      d="M80.9729 89.7834L84.7862 92.9034L99.3459 91.8634L98.6525 87.3568L80.9729 89.7834Z"
      fill="#646464"
    />
    <path
      d="M85.8265 91.5167L99.3462 89.7834L101.773 106.423L89.6398 120.289L75.0801 105.036L85.8265 91.5167Z"
      fill="#D9D9D9"
    />
    <path
      d="M114.252 133.809L89.6392 120.289L101.772 106.423L124.998 120.289L114.252 133.809Z"
      fill="#B9B9B9"
    />
    <path
      d="M102.12 109.89L98.6533 87.3568L123.266 102.61L126.733 124.103L102.12 109.89Z"
      fill="#ACACAC"
    />
    <path
      d="M87.9063 99.4899L79.5864 109.89L84.4397 114.743L92.7595 103.996L87.9063 99.4899Z"
      fill="#797979"
    />
    <defs>
      <linearGradient
        id="paint0_linear_719_712"
        x1="101.5"
        y1="50.3464"
        x2="101.5"
        y2="137.331"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default HomeWithSlave;
