import styled from '@emotion/styled';

export const Content = styled.main`
  align-items: center;
  background-color: ${({ theme }) => theme.color.appBackground};
  color: ${({ theme }) => theme.color.font};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 1.5rem;
  text-align: center;
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Legend = styled.p`
  font-size: 1.875rem;
`;

export const ErrorMessage = styled.p`
  margin: 1.25rem 0;
  width: 50%;
  text-align: left;
`;

export const ReportButton = styled.a`
  background-color: ${({ theme }) => theme.color.altFont};
  border-radius: ${({ theme }) => theme.borderRadius.full};
  box-shadow: 0 0 1.5rem 0.1rem rgba(102, 126, 234, 0.6);
  color: ${({ theme }) => theme.color.white};
  font-weight: 700;
  letter-spacing: ${({ theme }) => theme.letterSpacing.wide};
  margin: 2.5rem 0;
  padding: 0.5rem 1rem;
  width: 10rem;
`;

export const IllustrationWrapper = styled.div`
  height: 210px;
  margin-bottom: 30px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    height: 150px;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  bottom: 50px;
  height: 40px;
`;

export const BrokenLinkWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const BrokenContent = styled.div`
  position: absolute;
  top: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 450px;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  height: 40px;
  width: 70%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 90%;
  }
`;
