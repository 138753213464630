/* eslint-disable react-hooks/exhaustive-deps */
import {
  useFutureWorkPayments,
  useGetLineItemsById,
} from 'features/app/hooks/data';
import { GET_HOME_BY_ID } from 'features/app/store/homeById/homeByIdActions';
import { GET_HOME_MILESTONES } from 'features/app/store/homeMilestones/homeMilestonesActions';
import { GET_HOME_TOTALS } from 'features/app/store/homeTotals/homeTotalsActions';
import { ON_GET_VENDOR_ETA } from 'features/app/store/vendorEta/vendorEtaActions';
import { getHomeIdPath } from 'helpers/path';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Calendar from '../CalendarView/Components/Calendar';

import { HSpacer } from '../Commons/Spacer.styles';
import ProjectLog from './Components/ProjectLogData/Components/Log/Log';
import Cards from './Components/HomeInformationCards/HomeInformationCards';
import ProjectLogHeader from './Components/HeaderProjectLog/ProjectLogHeader';
import CalendarLoader from '../Commons/Loaders/CalendarLoader';

interface HomeViewProps {}

const FullProjectLog: React.FC<HomeViewProps> = () => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const eta = useSelector((state: RootStateOrAny) => state.vendorEta);
  const { homeMilestones, loading: loadingHomeMilestone } = useSelector(
    (state: RootStateOrAny) => state.homeMilestones
  );
  const location = useLocation();
  const [homeId, setHomeId] = useState<number>(getHomeIdPath(location));
  const [refetch, setRefetch] = useState(false);

  const {
    logItems: { lineItems },
    logItems,
  } = useSelector((state: RootStateOrAny) => state.projectLog);
  const { dataHome } = useSelector((state: RootStateOrAny) => state.homeTotals);

  const loadingItem = useSelector(
    (state: RootStateOrAny) => state.projectLog.loading
  );
  const dispatch = useDispatch();
  const futureWorkPayments = useFutureWorkPayments(
    homeId,
    data?.token,
    refetch
  );
  const { data: lineItemStatus } = useGetLineItemsById(
    homeId,
    data?.token,
    refetch
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(ON_GET_VENDOR_ETA({ id: homeId, token: data?.token }));
  }, []);

  useEffect(() => {
    dispatch(GET_HOME_TOTALS({ id: homeId, body: { token: data?.token } }));
  }, []);

  useEffect(() => {
    dispatch(GET_HOME_BY_ID({ id: homeId, token: data?.token }));
  }, []);

  useEffect(() => {
    dispatch(GET_HOME_MILESTONES({ id: homeId, token: data?.token }));
  }, []);

  useEffect(() => {
    const newPath = getHomeIdPath(location);
    if (newPath !== homeId) {
      setHomeId(newPath);
      setRefetch(true);
      window.scroll(0, 0);
    }
  }, [location, homeId]);

  useEffect(() => {
    if (refetch) setRefetch(false);
  }, [refetch]);

  return (
    <>
      <ProjectLogHeader vendorEta={eta} />
      <Cards
        lineItems={lineItems}
        total={logItems.pagy?.count || 0}
        lineItemsLoading={loadingItem}
        futureWork={futureWorkPayments?.data}
        totalScope={dataHome?.total}
        homeTotals={dataHome}
        lineItemsData={lineItemStatus}
      />
      <HSpacer height={40} />
      <ProjectLog fullLineItems={lineItems} />
      <HSpacer height={40} />
      {loadingHomeMilestone ? (
        <CalendarLoader />
      ) : (
        <Calendar events={homeMilestones} showDayDetail showEventDetail />
      )}
    </>
  );
};

export default FullProjectLog;
