import { LogoPropTypes } from './types';

const EarnIcon = ({ width, height }: LogoPropTypes) => (
  <svg
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    width={width || '100%'}
    height={height || '100%'}
  >
    <style>{'.st1{fill:#eaf7ff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        className="st1"
        d="M49 8c-8.1 0-16 2.4-22.8 6.9-6.7 4.5-12 10.9-15.1 18.4C8 40.8 7.2 49 8.8 57S14.3 72.3 20 78c5.7 5.7 13 9.6 21 11.2 8 1.6 16.2.8 23.7-2.3 7.5-3.1 13.9-8.4 18.4-15.1C87.6 65 90 57.1 90 49c0-5.4-1.1-10.7-3.1-15.7-2.1-5-5.1-9.5-8.9-13.3-3.8-3.8-8.3-6.8-13.3-8.9C59.7 9.1 54.4 8 49 8zm0 73.8c-6.5 0-12.8-1.9-18.2-5.5-5.4-3.6-9.6-8.7-12.1-14.7s-3.1-12.6-1.9-19c1.3-6.4 4.4-12.2 9-16.8 4.6-4.6 10.4-7.7 16.8-9 6.4-1.3 13-.6 19 1.9s11.1 6.7 14.7 12.1c3.6 5.4 5.5 11.7 5.5 18.2 0 8.7-3.5 17-9.6 23.2S57.7 81.8 49 81.8z"
      />
      <path
        className="st1"
        d="M49 44.8c-8 0-8-2.6-8-4.2 0-1.5 2.8-4.2 8-4.2s5.6 2.7 5.6 4.2h8c-.1-2.8-1-5.6-2.8-7.8-1.7-2.2-4.2-3.7-6.8-4.2V24h-8v4.5c-8 1.4-12 6.7-12 12.1 0 4.7 2.1 12.5 16 12.5 8 0 8 2.8 8 4.2 0 1.3-2.5 4.2-8 4.2-7.4 0-8-3.6-8-4.2h-8c0 3.8 2.6 10.6 12 12.2V74h8v-4.5c8-1.4 12-6.8 12-12.2 0-4.6-2.1-12.5-16-12.5z"
      />
    </g>
  </svg>
);

export default EarnIcon;
