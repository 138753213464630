export const StatusFilterTypes = {
  All: 'All',
  Completed: 'Completed',
  Active: 'Assigned',
};

export const SortByFilterTypes = {
  None: 'None',
  HighestScopeFirst: 'Highest Scope first',
  LowestScopeFirst: 'Lowest Scope first',
  MostProgressFirst: 'Most progress first',
  LeastProgressFirst: 'Least progress first',
  CloserEtaFirst: 'Closer to ETA first',
  FarToEtaFirst: 'Far to ETA first',
};

export const getStatusFilterWithOutNumber = (status: string) =>
  status.split(' ')[0];

export const getHousesFilteredByStatus = (
  status: string[],
  activeHouses: any[],
  completedHouses: any[]
) => {
  let housesToShow: any[] = [];
  status.forEach((item: string) => {
    if (getStatusFilterWithOutNumber(item) === StatusFilterTypes.All) {
      housesToShow = activeHouses.concat(completedHouses);
    }
    if (getStatusFilterWithOutNumber(item) === StatusFilterTypes.Active) {
      housesToShow = housesToShow.concat(activeHouses);
    }
    if (getStatusFilterWithOutNumber(item) === StatusFilterTypes.Completed) {
      housesToShow = housesToShow.concat(completedHouses);
    }
  });
  return housesToShow;
};

export const SortByHouses = (allHouses: any[], sortBy: string) => {
  if (sortBy === SortByFilterTypes.HighestScopeFirst) {
    allHouses.forEach((item) => {
      if (item?.total?.cents) {
        item.totalSort = item?.total?.cents;
      } else {
        item.totalSort = 0;
      }
    });
    return allHouses.sort((itemA: any, itemB: any) =>
      itemA.totalSort >= itemB.totalSort ? -1 : 1
    );
  }
  if (sortBy === SortByFilterTypes.LowestScopeFirst) {
    allHouses.forEach((item) => {
      if (item?.total?.cents) {
        item.totalSort = item?.total?.cents;
      } else {
        item.totalSort = 0;
      }
    });
    return allHouses.sort((itemA: any, itemB: any) =>
      itemA.totalSort >= itemB.totalSort ? 1 : -1
    );
  }
  return allHouses;
};
