import { OptionsSelect } from 'features/app/components/ProjectLogView/Components/ProjectLogData/Components/Filter/Filter';
import { PROJECT_LOG_TYPES } from './projectLogTypes';

export interface OnChangeLogItemsPros {
  id: number;
  body: {
    token: string;
    startDate: Date | string | undefined;
    endDate: Date | string | undefined;
    page: number | undefined;
    limit: number | undefined;
    name: string;
    status: string[];
    group_by: string;
  };
}

export const ON_SELECT_DATES = (payload: Date[]) => ({
  type: PROJECT_LOG_TYPES.ON_SELECT_DATES,
  payload,
});

export const ON_SEARCH_VALUE = (payload: string) => ({
  type: PROJECT_LOG_TYPES.ON_SEARCH_VALUE,
  payload,
});

export const SELECT_OPTION_GROUP_BY = (payload: OptionsSelect[]) => ({
  type: PROJECT_LOG_TYPES.SELECT_OPTION_GROUP_BY,
  payload,
});

export const ON_MULTI_SELECT_CHANGE = (payload: OptionsSelect[]) => ({
  type: PROJECT_LOG_TYPES.ON_MULTI_SELECT_CHANGE,
  payload,
});

export const CLEAR_ALL_VALUES = () => ({
  type: PROJECT_LOG_TYPES.CLEAR_ALL_VALUES,
});

export const SET_LOADING_FILTERS = (payload: boolean) => ({
  type: PROJECT_LOG_TYPES.SET_LOADING_FILTERS,
  payload,
});

export const SET_LOG_ITEMS = (payload: any) => ({
  type: PROJECT_LOG_TYPES.SET_LOG_ITEMS,
  payload,
});

export const SET_LOG_ITEMS_ERROR = (payload: any) => ({
  type: PROJECT_LOG_TYPES.SET_LOG_ITEMS_ERROR,
  payload,
});

export const SET_PAGE = (payload: number) => ({
  type: PROJECT_LOG_TYPES.SET_PAGE,
  payload,
});

export const SET_LIMIT_PAGE = (payload: number) => ({
  type: PROJECT_LOG_TYPES.SET_LIMIT_PAGE,
  payload,
});

export const OnChageLogItems = (payload: OnChangeLogItemsPros) => ({
  type: PROJECT_LOG_TYPES.ON_CHANGE_LOG_ITEMS,
  payload,
});
