import { LogoPropTypes } from './types';

const PendingIcon = ({ width, height }: LogoPropTypes) => (
  <svg viewBox="0 0 97 97" width={width || '100%'} height={height || '100%'}>
    <defs>
      <path id="a" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="b">
      <use
        xlinkHref="#a"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#b)',
      }}
    >
      <path
        d="M49 10.2c-8.1 0-16 2.4-22.7 6.9S14.4 28 11.3 35.4 7.4 51 9 59c1.6 7.9 5.5 15.2 11.2 20.9 5.7 5.7 13 9.6 20.9 11.2 7.9 1.6 16.1.8 23.6-2.3 7.5-3.1 13.8-8.3 18.3-15 4.5-6.7 6.9-14.6 6.9-22.7 0-5.4-1.1-10.7-3.1-15.6-2.1-5-5.1-9.5-8.9-13.2-3.8-3.8-8.3-6.8-13.2-8.9-5-2.2-10.3-3.2-15.7-3.2zm0 73.5c-6.5 0-12.8-1.9-18.1-5.5s-9.6-8.7-12-14.7c-2.5-6-3.1-12.5-1.9-18.9 1.3-6.3 4.4-12.2 8.9-16.7 4.6-4.6 10.4-7.7 16.7-8.9 6.3-1.3 12.9-.6 18.9 1.9 6 2.5 11.1 6.7 14.7 12 3.6 5.4 5.5 11.7 5.5 18.1 0 8.7-3.4 17-9.6 23.1-6.1 6.1-14.4 9.6-23.1 9.6z"
        style={{
          fill: '#eaf7ff',
        }}
      />
      <path
        d="M46.5 31v22c0 .6.4 1 1 1h22"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 6,
          strokeLinecap: 'round',
        }}
      />
    </g>
  </svg>
);

export default PendingIcon;
