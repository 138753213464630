import { IconPropTypes } from './types';

const CheckIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} viewBox="0 0 24 24" fill="none">
    <path
      d="M22 11.08V12a10 10 0 1 1-5.93-9.14"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 4 12 14.01l-3-3"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CheckIcon;
