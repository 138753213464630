import { MobileSideMenuWrapper } from './MobileSideMenu.styles';

export interface MobileSideMenuProps {
  isLeft?: boolean;
  closeBtnPosition?: number;
  show?: boolean;
  children?: React.ReactNode;
}

const MobileSideMenu: React.FC<MobileSideMenuProps> = ({
  isLeft,
  closeBtnPosition,
  show,
  children,
}) => (
  <MobileSideMenuWrapper isLeft={isLeft} show={show}>
    {children}
  </MobileSideMenuWrapper>
);

export default MobileSideMenu;
