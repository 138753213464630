import Broken from 'assets/svgs/BrokenLink';
import FullLogo from 'assets/svgs/FullLogo';
import { bugsnag } from 'features/app/utils/bugsnag';
import { Component } from 'react';
import { connect, RootStateOrAny } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import PrimaryButton from '../Buttons/PrimaryButton/PrimaryButton';
import { Headline, TextSm } from '../Text/Text';

import {
  ButtonWrapper,
  Content,
  IllustrationWrapper,
  LogoWrapper,
  Wrapper,
} from './ErrorBoundary.styles';
import { HSpacer } from '../Spacer.styles';

interface ErrorBoundaryProps {
  children: React.ReactNode;
  user: RootStateOrAny;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: any;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      error: {},
    };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true, error };
  }

  render() {
    const {
      hasError,
      error: { message },
    } = this.state;

    const { children, user } = this.props;
    const vendor = user?.data?.vendor;

    if (vendor) {
      bugsnag.setUser(vendor?.id, vendor?.contactEmail, vendor?.contactName);
      bugsnag.addMetadata('token', vendor?.token);
    }

    const isProd = process.env.NODE_ENV === 'production';
    const goBackHome: any = () => {
      window.location.href = '/';
    };

    if (hasError) {
      if (isProd) {
        bugsnag.notify(message);
      }
      return (
        <Content>
          <Wrapper>
            <IllustrationWrapper>
              <Broken />
            </IllustrationWrapper>
            <Headline>
              <FormattedMessage id="common.errorBoundaryMessage" />
            </Headline>
            <HSpacer />
            <LogoWrapper>
              <FullLogo />
            </LogoWrapper>
            <ButtonWrapper>
              <PrimaryButton onClick={goBackHome}>
                <TextSm color="#ffffff">
                  <FormattedMessage id="broken.goHome" />
                </TextSm>
              </PrimaryButton>
            </ButtonWrapper>
          </Wrapper>
        </Content>
      );
    }
    return children;
  }
}

const mapStateToProps = (state: RootStateOrAny) => ({
  user: state.auth,
});

export default connect(mapStateToProps)(ErrorBoundary);
