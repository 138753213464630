import ContentLoader from 'react-content-loader';

export const TableLoader: React.FC = () => (
  <ContentLoader width="100%" height="100%" viewBox="0 0 5000 1384">
    <path d="M233.333,166.667c0,-9.199 -7.468,-16.667 -16.666,-16.667l-50,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,50c0,9.198 7.468,16.666 16.667,16.666l50,0c9.198,0 16.666,-7.468 16.666,-16.666l0,-50Z" />
    <path d="M670.833,193.75c0,-21.847 -17.736,-39.583 -39.583,-39.583l-275,-0c-21.847,-0 -39.583,17.736 -39.583,39.583c-0,21.847 17.736,39.583 39.583,39.583l275,0c21.847,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M1525,197.917c0,-21.847 -17.737,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l625,0c21.846,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M2066.67,193.75c-0,-21.847 -17.737,-39.583 -39.584,-39.583l-275,-0c-21.846,-0 -39.583,17.736 -39.583,39.583c0,21.847 17.737,39.583 39.583,39.583l275,0c21.847,0 39.584,-17.736 39.584,-39.583Z" />
    <path d="M2920.83,197.917c0,-21.847 -17.736,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.736,39.583 39.583,39.583l625,0c21.847,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M3437.5,189.583c0,-21.846 -17.737,-39.583 -39.583,-39.583l-275,0c-21.847,0 -39.584,17.737 -39.584,39.583c0,21.847 17.737,39.584 39.584,39.584l275,-0c21.846,-0 39.583,-17.737 39.583,-39.584Z" />
    <path d="M3941.67,185.417c-0,-21.847 -17.737,-39.584 -39.584,-39.584l-275,0c-21.846,0 -39.583,17.737 -39.583,39.584c0,21.846 17.737,39.583 39.583,39.583l275,0c21.847,0 39.584,-17.737 39.584,-39.583Z" />
    <path d="M229.167,404.167c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-50,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,50c0,9.198 7.468,16.666 16.667,16.666l50,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-50Z" />
    <path d="M666.667,431.25c-0,-21.847 -17.737,-39.583 -39.584,-39.583l-275,-0c-21.846,-0 -39.583,17.736 -39.583,39.583c-0,21.847 17.737,39.583 39.583,39.583l275,0c21.847,0 39.584,-17.736 39.584,-39.583Z" />
    <path d="M1520.83,435.417c0,-21.847 -17.736,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.736,39.583 39.583,39.583l625,0c21.847,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M2062.5,431.25c0,-21.847 -17.737,-39.583 -39.583,-39.583l-275,-0c-21.847,-0 -39.584,17.736 -39.584,39.583c0,21.847 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M2916.67,435.417c-0,-21.847 -17.737,-39.584 -39.584,-39.584l-625,0c-21.846,0 -39.583,17.737 -39.583,39.584c0,21.846 17.737,39.583 39.583,39.583l625,0c21.847,0 39.584,-17.737 39.584,-39.583Z" />
    <path d="M3433.33,427.083c0,-21.846 -17.736,-39.583 -39.583,-39.583l-275,0c-21.847,0 -39.583,17.737 -39.583,39.583c-0,21.847 17.736,39.584 39.583,39.584l275,-0c21.847,-0 39.583,-17.737 39.583,-39.584Z" />
    <path d="M3937.5,422.917c0,-21.847 -17.737,-39.584 -39.583,-39.584l-275,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M229.167,662.5c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-50,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,50c0,9.199 7.468,16.667 16.667,16.667l50,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-50Z" />
    <path d="M666.667,689.583c-0,-21.846 -17.737,-39.583 -39.584,-39.583l-275,0c-21.846,0 -39.583,17.737 -39.583,39.583c-0,21.847 17.737,39.584 39.583,39.584l275,-0c21.847,-0 39.584,-17.737 39.584,-39.584Z" />
    <path d="M1520.83,693.75c0,-21.847 -17.736,-39.583 -39.583,-39.583l-625,-0c-21.847,-0 -39.583,17.736 -39.583,39.583c-0,21.847 17.736,39.583 39.583,39.583l625,0c21.847,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M2062.5,689.583c0,-21.846 -17.737,-39.583 -39.583,-39.583l-275,0c-21.847,0 -39.584,17.737 -39.584,39.583c0,21.847 17.737,39.584 39.584,39.584l275,-0c21.846,-0 39.583,-17.737 39.583,-39.584Z" />
    <path d="M2916.67,693.75c-0,-21.847 -17.737,-39.583 -39.584,-39.583l-625,-0c-21.846,-0 -39.583,17.736 -39.583,39.583c0,21.847 17.737,39.583 39.583,39.583l625,0c21.847,0 39.584,-17.736 39.584,-39.583Z" />
    <path d="M3433.33,685.417c0,-21.847 -17.736,-39.584 -39.583,-39.584l-275,0c-21.847,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.736,39.583 39.583,39.583l275,-0c21.847,-0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M3937.5,681.25c0,-21.847 -17.737,-39.583 -39.583,-39.583l-275,-0c-21.847,-0 -39.584,17.736 -39.584,39.583c0,21.847 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M229.167,904.167c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-50,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,50c0,9.198 7.468,16.666 16.667,16.666l50,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-50Z" />
    <path d="M666.667,931.25c-0,-21.847 -17.737,-39.583 -39.584,-39.583l-275,-0c-21.846,-0 -39.583,17.736 -39.583,39.583c-0,21.847 17.737,39.583 39.583,39.583l275,0c21.847,0 39.584,-17.736 39.584,-39.583Z" />
    <path d="M1520.83,935.417c0,-21.847 -17.736,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.736,39.583 39.583,39.583l625,-0c21.847,-0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M2062.5,931.25c0,-21.847 -17.737,-39.583 -39.583,-39.583l-275,-0c-21.847,-0 -39.584,17.736 -39.584,39.583c0,21.847 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M2916.67,935.417c-0,-21.847 -17.737,-39.584 -39.584,-39.584l-625,0c-21.846,0 -39.583,17.737 -39.583,39.584c0,21.846 17.737,39.583 39.583,39.583l625,-0c21.847,-0 39.584,-17.737 39.584,-39.583Z" />
    <path d="M3433.33,927.083c0,-21.846 -17.736,-39.583 -39.583,-39.583l-275,0c-21.847,0 -39.583,17.737 -39.583,39.583c-0,21.847 17.736,39.584 39.583,39.584l275,-0c21.847,-0 39.583,-17.737 39.583,-39.584Z" />
    <path d="M3937.5,922.917c0,-21.847 -17.737,-39.584 -39.583,-39.584l-275,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l275,-0c21.846,-0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M229.167,1166.67c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-50,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,50c0,9.198 7.468,16.666 16.667,16.666l50,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-50Z" />
    <path d="M666.667,1193.75c-0,-21.847 -17.737,-39.583 -39.584,-39.583l-275,-0c-21.846,-0 -39.583,17.736 -39.583,39.583c-0,21.847 17.737,39.583 39.583,39.583l275,0c21.847,0 39.584,-17.736 39.584,-39.583Z" />
    <path d="M1520.83,1197.92c0,-21.847 -17.736,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.736,39.583 39.583,39.583l625,0c21.847,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M2062.5,1193.75c0,-21.847 -17.737,-39.583 -39.583,-39.583l-275,-0c-21.847,-0 -39.584,17.736 -39.584,39.583c0,21.847 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M2916.67,1197.92c-0,-21.847 -17.737,-39.584 -39.584,-39.584l-625,0c-21.846,0 -39.583,17.737 -39.583,39.584c0,21.846 17.737,39.583 39.583,39.583l625,0c21.847,0 39.584,-17.737 39.584,-39.583Z" />
    <path d="M3433.33,1189.58c0,-21.846 -17.736,-39.583 -39.583,-39.583l-275,0c-21.847,0 -39.583,17.737 -39.583,39.583c-0,21.847 17.736,39.584 39.583,39.584l275,-0c21.847,-0 39.583,-17.737 39.583,-39.584Z" />
    <path d="M3937.5,1185.42c0,-21.847 -17.737,-39.584 -39.583,-39.584l-275,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l275,0c21.846,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M4816.67,185.417c-0,-21.847 -17.737,-39.584 -39.584,-39.584l-625,0c-21.846,0 -39.583,17.737 -39.583,39.584c-0,21.846 17.737,39.583 39.583,39.583l625,0c21.847,0 39.584,-17.737 39.584,-39.583Z" />
    <path d="M4812.5,422.917c0,-21.847 -17.737,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l625,0c21.846,0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M4812.5,681.25c0,-21.847 -17.737,-39.583 -39.583,-39.583l-625,-0c-21.847,-0 -39.584,17.736 -39.584,39.583c0,21.847 17.737,39.583 39.584,39.583l625,0c21.846,0 39.583,-17.736 39.583,-39.583Z" />
    <path d="M4812.5,922.917c0,-21.847 -17.737,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l625,-0c21.846,-0 39.583,-17.737 39.583,-39.583Z" />
    <path d="M4812.5,1185.42c0,-21.847 -17.737,-39.584 -39.583,-39.584l-625,0c-21.847,0 -39.584,17.737 -39.584,39.584c0,21.846 17.737,39.583 39.584,39.583l625,0c21.846,0 39.583,-17.737 39.583,-39.583Z" />
  </ContentLoader>
);

export default TableLoader;
