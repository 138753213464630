import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

interface SearchProps {
  showClear: boolean;
}

interface InputProps {
  focus: boolean;
}

export const SearchInput = styled.input<InputProps>`
  all: unset;
  display: relative;
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.name};
  color: ${({ theme }) => theme.color.font};
  width: ${({ focus }) => (focus ? 420 : 350)}px;
  box-sizing: border-box;
  padding: 7px;
  box-shadow: ${({ theme, focus }) =>
    focus ? theme.boxShadow.searchBar : theme.boxShadow.staticCards};
  border: 1px ${({ theme, focus }) => (focus ? theme.color.blue800 : 'none')}
    solid;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 10px;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    color: ${({ theme }) => theme.color.lightFont};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.lightFont};
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.lightFont};
  }
  transition: all 0.3s ease-in-out;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet.max}) {
    width: 100%;
  }
`;

export const NavigationLink = styled(Link)`
  z-index: 1000;
  font-size: ${({ theme }) => theme.fontSize.xxl};
  display: flex;
  margin: 2px;
  padding: 2px;
  &:hover {
    background-color: ${({ theme }) => theme.color.searchInputBackground};
  }
`;

export const SearchStyledInput = styled.div<SearchProps>`
  position: relative;
  z-index: 1;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 100%;
  }

  .left-icon {
    position: absolute;
    left: 5px;
    top: 3px;
    padding: 9px 8px;
    transition: 0.3s;
  }

  .right-icon {
    display: ${({ showClear }) => (showClear ? 'block' : 'none')};
    cursor: pointer;
    position: absolute;
    right: 0px;
    top: 1px;
    padding: 9px 8px;
    transition: all 0.3s ease-in-out;
  }

  input {
    padding-left: 40px;
    padding-right: 40px;
  }
`;
