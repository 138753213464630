export const VENDOR_ETA_TYPES = {
  ON_GET_VENDOR_ETA: 'ON_GET_VENDOR_ETA',
  ON_SET_VENDOR_ETA: 'ON_SET_VENDOR_ETA',
  ON_LOADING_VENDOR_ETA: 'ON_LOADING_VENDOR_ETA',
};

export type OnGetVendorEta = {
  type: typeof VENDOR_ETA_TYPES.ON_GET_VENDOR_ETA;
  payload: any;
};

export type OnSetVendorEta = {
  type: typeof VENDOR_ETA_TYPES.ON_SET_VENDOR_ETA;
  payload: any;
};

export type OnLoadingVendorEta = {
  type: typeof VENDOR_ETA_TYPES.ON_LOADING_VENDOR_ETA;
  payload: boolean;
};

export type vendorEtaActions =
  | OnGetVendorEta
  | OnSetVendorEta
  | OnLoadingVendorEta;
