import { RootStateOrAny, useSelector } from 'react-redux';
import { Text } from '../../../Text/Text';
import VendorIcon from '../VendorIcon/VendorIcon';
import {
  VendorInformationText,
  WrapperVendorInformation,
  VendorIconContainer,
} from './VendorInformation.styles';

export interface VendorInformationProps {
  onClick?: () => void;
  isMobile?: boolean;
}

const VendorInformation: React.FC<VendorInformationProps> = ({
  onClick,
  isMobile,
}) => {
  const {
    auth: {
      data: {
        vendor: { name, contactName },
      },
    },
  } = useSelector((state: RootStateOrAny) => state);
  return (
    <>
      {isMobile && (
        <WrapperVendorInformation>
          <VendorIconContainer onClick={onClick ?? onClick}>
            <VendorIcon
              letter={
                contactName && contactName.length > 0 ? contactName[0] : 'V'
              }
            />
          </VendorIconContainer>
          <VendorInformationText>
            <Text fontSize="14" weight="600" color="black" textAlign="left">
              {contactName}
            </Text>
            <Text fontSize="10" weight="400" color="#777777" textAlign="left">
              {name}
            </Text>
          </VendorInformationText>
        </WrapperVendorInformation>
      )}
      {!isMobile && (
        <WrapperVendorInformation>
          <VendorInformationText>
            <Text fontSize="14" weight="600" color="black" textAlign="right">
              {contactName}
            </Text>
            <Text fontSize="10" weight="400" color="#777777" textAlign="right">
              {name}
            </Text>
          </VendorInformationText>
          <VendorIconContainer onClick={onClick ?? onClick}>
            <VendorIcon
              letter={
                contactName && contactName.length > 0 ? contactName[0] : 'V'
              }
            />
          </VendorIconContainer>
        </WrapperVendorInformation>
      )}
    </>
  );
};

export default VendorInformation;
