import styled from '@emotion/styled';
import { PercentageProps } from './Percentage';

export const LoaderContainer = styled.div`
  height: 4px;
  width: 100%;
  background-color: #f6f6f6;
`;

export const LoaderPercentage = styled.div<PercentageProps>`
  width: ${({ percentage }) => `${percentage}%`};
  background-color: ${({ color }) => color};
  flex: 1;
  height: 4px;
  display: flex;
`;
