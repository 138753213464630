import SelectFilter from 'features/app/components/Commons/Select/SelectFilter/SelectFilter';
import {
  GetHomesFromHomeView,
  SET_PAGE_HOME,
} from 'features/app/store/listOfHomes/ListOfHomesActions';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import ArrowsPagination from './components/ArrowsPagination/ArrowsPagination';
import TotalOfPages from './components/TotalOfPages/TotalOfPages';
import { PaginationWrapper } from './PaginationHome.styles';

export interface PaginationHomeProps {
  token?: string;
  totalOfPages: number;
}

export default function PaginationHome({
  token,
  totalOfPages,
}: PaginationHomeProps) {
  const { page } = useSelector((store: RootStateOrAny) => store.ListOfHomes);
  const dispatch = useDispatch();
  const selectPage = (pageSelected: number | string) => {
    if (pageSelected !== page) {
      dispatch(SET_PAGE_HOME(pageSelected));
      dispatch(GetHomesFromHomeView({ token, page: pageSelected }));
    }
  };

  const optionsToShow = () => {
    const auxArrOptions = [];
    // eslint-disable-next-line no-plusplus
    for (let x = totalOfPages; x > 0; x--) {
      auxArrOptions.push(x);
    }
    return auxArrOptions;
  };
  return (
    <PaginationWrapper>
      <SelectFilter
        width={70}
        fullHeight={false}
        selected={page}
        options={optionsToShow()}
        onSelect={selectPage}
      />
      <TotalOfPages totalOfPages={totalOfPages} />
      <ArrowsPagination token={token} />
    </PaginationWrapper>
  );
}
