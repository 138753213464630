import { IconPropTypes } from './types';

const SlidersIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
    <path
      d="M4 21v-7M4 10V3M12 21v-9M12 8V3M20 21v-5M20 12V3M1 14h6M9 8h6M17 16h6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SlidersIcon;
