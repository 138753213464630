import PeopleIcon from 'assets/svgs/PeopleIcon';
import HomeIcon2 from 'assets/svgs/HomeIcon2';
import PerformanceIcon from 'assets/svgs/PerformanceIcon';

export const SUMMARY_PATH_ID = '/:id';
export const SUMMARY_PATH = '/';
export const CALENDAR_PATH = '/calendar';
export const REFERRALS_PATH = '/referrals';
export const HOME_PATH = '/';
export const HOMES_PATH = '/homes';
export const NO_TOKEN_PATH = '/no-token';
export const SETTINGS_PATH = '/settings';
export const PERFORMANCE_PATH = '/performance';
export const SIGN_IN_PATH = '/sing-in';
export const SIGN_UP_PATH = '/sign-up';
export const FORGOT_PASSWORD_PATH = '/forgot-password';

export const SUMMARY_KEY = 'summary';
export const CALENDAR_KEY = 'calendar';
export const HOMES_KEY = 'homes';
export const REFERRALS_KEY = 'referrals';
export const PERFORMANCE_KEY = 'performance';

const Menu = [
  // {
  //   id: SUMMARY_KEY,
  //   name: 'Summary',
  //   icon: (color: string) => (
  //     <SummaryGlyph
  //       viewBoxWidth={18}
  //       viewBoxHeight={18}
  //       size={20}
  //       color={color}
  //     />
  //   ),
  //   path: SUMMARY_PATH,
  // },
  // {
  //   id: CALENDAR_KEY,
  //   name: 'Calendar',
  //   icon: (color: string) => (
  //     <CalGlyph viewBoxWidth={18} viewBoxHeight={18} size={20} color={color} />
  //   ),
  //   path: CALENDAR_PATH,
  // },
  {
    id: HOMES_KEY,
    name: 'Homes',
    icon: (color: string) => (
      <HomeIcon2 color={color} width={27.5} height={30} />
    ),
    path: HOME_PATH,
    subItems: {
      active_properties: [],
      completed_properties: [],
    },
  },
  {
    id: REFERRALS_KEY,
    name: 'Referrals',
    icon: (color: string) => (
      <PeopleIcon color={color} width={27} height={27} />
    ),
    path: REFERRALS_PATH,
  },
  {
    id: PERFORMANCE_KEY,
    name: 'Performance',
    icon: (color: string) => (
      <PerformanceIcon color={color} width={27} height={32} />
    ),
    path: PERFORMANCE_PATH,
  },
];

export default Menu;
