import { SearchActions, SEARCH_TYPES } from './searchTypes';

const initialState = {
  homesSearch: '',
};

export const SearchReducers = (state = initialState, action: SearchActions) => {
  switch (action.type) {
    case SEARCH_TYPES.ON_SET_SEARCH_VALUE:
      return {
        ...state,
        homesSearch: action.payload,
      };
    default:
      return state;
  }
};
