import { HOMES_PATH } from 'features/app/constants/navigation';
import { FormattedMessage } from 'react-intl';
import { TextBase } from '../../Text/Text';
import { NavigationLink } from './Search.styles';
import {
  NoResult,
  SearchResults,
  ItemResult,
} from './SearchResultsDesktop.styles';

interface SearchResultsDesktopProps {
  foundItems: any[];
  value: string;
  setFocus: (state: boolean) => void;
}

const SearchResultsDesktop: React.FC<SearchResultsDesktopProps> = ({
  foundItems,
  value,
  setFocus,
}) => (
  <SearchResults>
    {foundItems.length > 0 &&
      value.length > 0 &&
      foundItems.map(({ address, id }) => (
        <ItemResult key={id}>
          <NavigationLink
            to={`${HOMES_PATH}/${id}`}
            onClick={() => setFocus(false)}
            className="navigationLink"
          >
            <TextBase>{address}</TextBase>
          </NavigationLink>
        </ItemResult>
      ))}
    {foundItems.length === 0 && value.length > 0 && (
      <NoResult>
        <TextBase weight="bold">
          <FormattedMessage id="search.notFound" />
        </TextBase>
        <TextBase>
          <FormattedMessage id="search.emptyState" />
        </TextBase>
      </NoResult>
    )}
    {foundItems.length === 0 && value.length === 0 && (
      <NoResult>
        <TextBase>
          <FormattedMessage id="search.emptyState" />
        </TextBase>
      </NoResult>
    )}
  </SearchResults>
);

export default SearchResultsDesktop;
