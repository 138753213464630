import {
  Bar,
  FillPrimary,
  FillPrimaryWrapper,
  FillSecondary,
  FillSecondaryWrapper,
} from './ProgressBar.styles';

interface ProgressBarProps {
  firstProgress: number;
  secondProgress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  firstProgress,
  secondProgress,
}) => (
  <Bar>
    <FillPrimaryWrapper>
      <FillPrimary progress={firstProgress} />
    </FillPrimaryWrapper>
    <FillSecondaryWrapper>
      <FillSecondary progress={secondProgress} />
    </FillSecondaryWrapper>
  </Bar>
);
export default ProgressBar;
