import styled from '@emotion/styled';
import Loading from '../Loading';

interface InputContainerProps {
  hasError: boolean;
}

interface StylesProps {
  styles?: any;
}

export const Button = styled.button`
  background-color: ${(props) => props.theme.color.altFont};
  border-radius: ${(props) => props.theme.borderRadius.base};
  box-shadow: ${(props) => props.theme.boxShadow.buttons};
  color: ${(props) => props.theme.color.white};
  font-weight: 500;
  letter-spacing: ${(props) => props.theme.letterSpacing.wide};
  margin-bottom: 1.5rem;
  margin-top: 2.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: ${(props) => props.theme.color.altFont};
  }

  &:disabled {
    background-color: ${(props) => props.theme.color.buttons};
    box-shadow: ${(props) => props.theme.boxShadow.none};
  }
`;

export const Error = styled.span`
  color: ${(props) => props.theme.color.error};
  display: block;
  font-size: ${(props) => props.theme.fontSize.sm};
  max-width: fit-content;
`;

export const FormContent = styled.form<StylesProps>`
  ${(props) => props.styles};
`;

export const Input = styled.input`
  background-color: ${(props) => props.theme.color.appBackground};
  border-radius: ${(props) => props.theme.borderRadius.base};
  border-width: 1px;
  color: ${(props) => props.theme.color.lightFont};
  font-size: ${(props) => props.theme.fontSize.sm};
  height: 2.5rem;
  line-height: 1.25;
  max-width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 16rem;

  &:focus {
    border-color: ${(props) => props.theme.color.appBackground};
    outline: 0;
  }
`;

export const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: 0.75rem;
  margin-top: ${({ hasError }) => hasError && '0.25rem'};

  > input {
    border-color: ${({ hasError, theme }) =>
      hasError ? theme.color.error : theme.color.appBackground};

    &:focus {
      outline: ${({ hasError }) => hasError && '0'};
      border-color: ${({ hasError, theme }) => hasError && theme.color.error};
    }
  }
`;

export const Label = styled.label`
  color: ${(props) => props.theme.color.lightFont};
  display: block;
  font-size: ${(props) => props.theme.fontSize.sm};
  font-weight: 400;
`;

export const LabelContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CustomLoading = styled(Loading)`
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
`;

export const Select = styled.select`
  background-color: ${(props) => props.theme.color.appBackground};
  border-radius: ${(props) => props.theme.borderRadius.base};
  border-width: 1px;
  color: ${(props) => props.theme.color.lightFont};
  height: 2.5rem;
  line-height: 1.25;
  max-width: 100%;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  width: 16rem;

  &:focus {
    border-color: ${(props) => props.theme.color.appBackground};
    outline: 0;
  }
`;

export const Span = styled.span`
  display: block;
  margin-bottom: 0.5rem;
`;
