import Homes from 'features/app/components/HomeView';

interface HomesPageProps {
  hideLayout: (active: boolean) => void;
}

const HomesPage: React.FC<HomesPageProps> = ({ hideLayout }) => {
  hideLayout(false);
  return <Homes />;
};

export default HomesPage;
