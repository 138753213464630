// import { HomeProp } from 'features/app/utils/types/home';
import HomeCard from './Components/HomeCard/Components/Card/HomeCard';
import { EmptyHouse, HomesList } from './HomesList.styles';
import MessageNoHome from '../MessageNoHomes/MessageNoHome';
// import { getHousesFilteredByStatus, SortByHouses } from './helper';

const HomeList = (addressFilter: string, selected: string, data: any) => (
  <>
    {selected === 'all' && data?.length > 0 ? (
      <HomesList>
        {data.map((item: any) => (
          <HomeCard key={item.id} {...item} completed={item.completed} />
        ))}
      </HomesList>
    ) : (
      <EmptyHouse>
        <MessageNoHome
          title="That home is not here!"
          description="Try typing another address"
        />
      </EmptyHouse>
    )}
  </>
);
export default HomeList;
