import { CardStyles } from './Card.styles';

interface CardProps {
  customPadding?: number;
  maxWidth?: string;
  overrideColor?: string;
  customMargin?: number;
  textOverflow?: string;
  ref?: React.LegacyRef<HTMLDivElement> | undefined;
}
const Card: React.FC<CardProps> = ({
  children,
  customPadding = 13,
  ref,
  ...props
}) => (
  <CardStyles {...props} customPadding={customPadding} ref={ref}>
    {children}
  </CardStyles>
);

export default Card;
