import { IconPropTypes } from './types';

const OnGoingIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 20 20"
    xmlSpace="preserve"
  >
    <defs>
      <path id="a" d="M1 1h18v18H1z" />
    </defs>
    <clipPath id="b">
      <use
        xlinkHref="#a"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#b)',
      }}
    >
      <path
        d="M10.9 15.4c.5 0 .9.2 1.3.5.3.3.5.8.5 1.3s-.2.9-.5 1.3c-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5c-.3-.3-.5-.8-.5-1.3s.2-.9.5-1.3c.4-.3.8-.5 1.3-.5zm-5.6-2.7c.6 0 1.2.2 1.6.7.4.4.7 1 .7 1.6s-.2 1.2-.7 1.6c-.4.4-1 .7-1.6.7s-1.2-.3-1.6-.8c-.4-.4-.7-1-.7-1.6s.2-1.2.7-1.6c.4-.4 1-.6 1.6-.6zm10.4.4c.5 0 .9.2 1.3.5.3.3.5.8.5 1.3s-.2.9-.5 1.3c-.3.3-.8.5-1.3.5s-.9-.2-1.3-.5c-.3-.3-.5-.8-.5-1.3s.2-.9.5-1.3c.4-.3.8-.5 1.3-.5zm1.9-3.7c.4 0 .7.1 1 .4.3.3.4.6.4 1s-.1.7-.4 1c-.3.3-.6.4-1 .4s-.7-.1-1-.4-.4-.6-.4-1 .1-.7.4-1c.3-.3.7-.4 1-.4zm-14.4-3c.6 0 1.2.2 1.6.7s.7 1 .7 1.6c0 .6-.2 1.2-.7 1.6-.4.4-1 .7-1.6.7s-1.2-.2-1.6-.7C1.2 9.8 1 9.2 1 8.6c0-.6.2-1.2.7-1.6s1-.6 1.5-.6zM17 5.7c.2 0 .5.1.6.3.2.2.3.4.3.6 0 .2-.1.5-.3.6-.2.2-.4.3-.6.3-.2 0-.5-.1-.6-.3-.2-.2-.3-.4-.3-.6 0-.2.1-.5.3-.6.1-.2.4-.3.6-.3zM8.2 1a2.732 2.732 0 0 1 2.7 2.7 2.732 2.732 0 0 1-2.7 2.7 2.732 2.732 0 0 1-2.7-2.7A2.732 2.732 0 0 1 8.2 1zm6.7 2.7c.1 0 .2 0 .3.1s.1.2.1.3 0 .2-.1.3c-.1.1-.2.1-.3.1s-.2 0-.3-.1c-.1-.1-.1-.2-.1-.3s0-.2.1-.3c.1-.1.2-.1.3-.1z"
        style={{
          fillRule: 'evenodd',
          clipRule: 'evenodd',
          fill: color || '#5252cc',
        }}
      />
    </g>
  </svg>
);

export default OnGoingIcon;
