import styled from '@emotion/styled';

export const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  @media (max-width: 768px) {
    margin-block: 10px;
  }
`;

export const TextPaginationContainer = styled.div``;

export const IconPaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ItemPaginationContainer = styled.div`
  cursor: pointer;
`;
