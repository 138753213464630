/* eslint-disable new-cap */
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import * as XLSX from 'xlsx';
import autoTable from 'jspdf-autotable';

export const CSV_KEY = 'csv';
export const XLSX_KEY = 'xlsx';
export const PDF = 'pdf';

export const exportOptions = [
  { key: CSV_KEY, value: 'CSV' },
  { key: XLSX_KEY, value: 'XLSX' },
];

export const exportToXlsx = (fileName: string, data: any[]) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const dataBlob = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(dataBlob, `${fileName}.xlsx`);
};

export const exportToCsv = (fileName: string, data: any[]) => {
  const headers = Object.keys(data[0]);
  let csvContent = '';
  csvContent += `${headers.join(',')} \n`;
  data.forEach((row) => {
    headers.forEach((header, index) => {
      const value = row[header];
      csvContent += value !== null ? `"${value}"` : '';
      if (index < headers.length - 1) {
        csvContent += ',';
      } else {
        csvContent += '\n';
      }
    });
  });
  const dataBlob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  FileSaver.saveAs(dataBlob, `${fileName}.csv`);
};

export const exportToPDF = (fileName: string, data: any[]) => {
  /* const doc = new jsPDF('landscape');
  const headers = Object.keys(data[0]);
  doc.text(
    `${headers[0]} ${headers[1]} ${headers[2]} ${headers[3]} ${headers[4]} ${headers[5]} ${headers[6]}`,
    20,
    10
  );
  /* headers.forEach((header, i) => {
    doc.text(`First Name: ${employee.toString()}`, 20, 10 + i * 10);
  }); */
  // doc.save(fileName);
  const doc = new jsPDF('p', 'pt');
  const dataToTable: any[] = [];
  data.forEach((itemData: any) => {
    dataToTable.push(Object.values(itemData));
  });
  autoTable(doc, {
    head: [Object.keys(data[0])],
    body: dataToTable,
  });
  doc.save(`${fileName}.pdf`);
};
