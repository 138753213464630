import { Text } from '../../../Commons/Text/Text';
import { HeaderWrapper } from './HeaderHome.styles';

export default function HeaderHome() {
  return (
    <HeaderWrapper>
      <Text weight="300" fontSize="28" color="#010101">
        Homes
      </Text>
      <Text weight="400" fontSize="14" color="#767676">
        Review all your work in progress or completed.
      </Text>
    </HeaderWrapper>
  );
}
