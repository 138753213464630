import { IconPropTypes } from './types';

const FilterIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <g clipPath="url(#clip0_826_1895)">
      <path
        d="M2 5H15"
        stroke={color || '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M4.5 9H12.5"
        stroke={color || '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M7.5 13H9.5"
        stroke={color || '#212121'}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_826_1895">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default FilterIcon;
