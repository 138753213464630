import { getFutureWorkPayments } from 'features/app/api/homes';
import { all, put, takeLatest, call } from 'redux-saga/effects';
import {
  FutureWorkPaymentsProps,
  SET_FUTURE_WORK_PAYMENTS,
} from './futureWorkPaymentsActions';
import { FUTURE_WORK_PAYMENTS_TYPES } from './futureWorkPaymentsTypes';

function* getFutureWorkPaymentsApi(payload: FutureWorkPaymentsProps | any) {
  // loading
  const data: FutureWorkPaymentsProps = yield call(
    getFutureWorkPayments,
    payload.id,
    payload.token
  );
  yield put(SET_FUTURE_WORK_PAYMENTS(data));
}

export default function* futureWorkPaymentsDataSaga(): any {
  yield all([
    yield takeLatest(
      FUTURE_WORK_PAYMENTS_TYPES.GET_FUTURE_WORK_PAYMENTS,
      getFutureWorkPaymentsApi
    ),
  ]);
}
