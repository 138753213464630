import { IconPropTypes } from './types';

const TimeMoneyIcon = ({ width, height }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 43 42"
    fill="none"
  >
    <path
      d="M17.8503 34.8316C27.1565 34.8316 34.7006 27.4515 34.7006 18.3476C34.7006 9.24378 27.1565 1.86365 17.8503 1.86365C8.54414 1.86365 1 9.24378 1 18.3476C1 27.4515 8.54414 34.8316 17.8503 34.8316Z"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.8501 8.45746V18.3479L24.5902 21.6447"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="32.6818"
      cy="31.6818"
      r="9.31818"
      fill="white"
      stroke="#009FDD"
      strokeWidth="2"
    />
    <path
      d="M32.9358 26.091V37.2728"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.4774 28.1239H31.6654C31.1936 28.1239 30.7411 28.3113 30.4075 28.6449C30.0739 28.9785 29.8865 29.431 29.8865 29.9028C29.8865 30.3746 30.0739 30.8271 30.4075 31.1607C30.7411 31.4943 31.1936 31.6818 31.6654 31.6818H34.2067C34.6785 31.6818 35.131 31.8692 35.4646 32.2028C35.7982 32.5364 35.9857 32.9889 35.9857 33.4607C35.9857 33.9325 35.7982 34.385 35.4646 34.7186C35.131 35.0522 34.6785 35.2396 34.2067 35.2396H29.8865"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimeMoneyIcon;
