import { IconPropTypes } from './types';

const HomeIcon2 = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 28 22"
    fill="none"
  >
    <path
      d="M22 21.432V5.89863L16.2 0.0986328H12.4267L19.3334 7.0053V21.432H22ZM27.3334 21.432V3.6853L23.7467 0.0986328H19.9734L24.6667 4.79197V21.432H27.3334ZM8.66669 0.0986328L0.666687 8.09863V21.432H7.33335V14.7653H10V21.432H16.6667V8.09863L8.66669 0.0986328ZM10 12.0986H7.33335V9.43197H10V12.0986Z"
      fill={color}
    />
  </svg>
);

export default HomeIcon2;
