import { IconPropTypes } from './types';

const RightIconMenu = ({ width, height }: IconPropTypes) => (
  <svg
    width={width || '48'}
    height={height || '48'}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="34" height="34" rx="5" fill="#E7E7E7" />
    <path
      d="M17 7L20.09 13.26L27 14.27L22 19.14L23.18 26.02L17 22.77L10.82 26.02L12 19.14L7 14.27L13.91 13.26L17 7Z"
      stroke="#646464"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RightIconMenu;
