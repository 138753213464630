import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export const ContactsCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  min-width: 370px;
  padding: 20px;
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 20px;
`;

export const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 10px;
`;

export const EmailPhoneWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
`;

export const Clickable = styled.a`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin-left: 7px;
`;

export const IconWrapper = styled.div`
  height: 22px;
  width: 22px;
`;

export const Button = styled.button`
  unset: all;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.color.blue500};
  color: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: 5px;
  height: 40px;

  &:focus {
    outline: none;
  }
  &:disabled {
    background-color: ${({ theme }) => theme.color.gray300};
    cursor: not-allowed;
  }
`;

const keyframeRotation = keyframes`
  to {
     transform: rotate(1turn);
  }
  `;

export const SpinnerContact = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ffffff);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 9px), #000 0);
  animation: ${keyframeRotation} 1s infinite linear;
`;

export const SpinnerWrapper = styled.div`
  unset: all;
  display: flex;
  white-space: nowrap;
  align-items: center;
  padding: 5px 15px;
  background-color: ${({ theme }) => theme.color.blue500};
  color: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: 5px;
  height: 40px;
`;
