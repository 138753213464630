export const RECENT_SEARCH_TYPE = {
  SET_NEW_ZIP_RECENT_SEARCH: 'SET_NEW_ZIP_RECENT_SEARCH',
  SET_NEW_CITY_RECENT_SEARCH: 'SET_NEW_CITY_RECENT_SEARCH',
};

type setNewZipRecent = {
  type: typeof RECENT_SEARCH_TYPE.SET_NEW_CITY_RECENT_SEARCH;
  payload: string;
};

type setNewCityRecent = {
  type: typeof RECENT_SEARCH_TYPE.SET_NEW_ZIP_RECENT_SEARCH;
  payload: string;
};

export type recentSearchesActions = setNewCityRecent | setNewZipRecent;
