import {
  applyMiddleware,
  combineReducers,
  createStore as createReduxStore,
} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
import userReducer from './auth/reducers';
import { futureWorkPaymentsReducer } from './futureWorkPayments/futureWorkPaymentsReducers';
import { ModalsReducers } from './modals/modalsReducers';
import { ProjectLogReducers } from './projectLog/projectLogReducers';
import { SearchReducers } from './search/searchReducers';
import sagas from './sagas';
import { HomeTotalsReducer } from './homeTotals/homeTotalsReducer';
import { HomeByIdReducer } from './homeById/homeByIdReducer';
import { homeMilestonesReducer } from './homeMilestones/homeMilestonesReducres';
import { vendorEtaReducers } from './vendorEta/vendorEtaReducers';
import { darkLightModeReducer } from './darkLightMode/darkLightModeReducers';
import HomeFiltersReducer from './homesFilters/homeFiltersReducer';
import { keysStorage } from './keys';
import RecentSearchReducer from './recentSearches/RecentSearchesReducer';
import ListOfHomesReducer from './listOfHomes/ListOfHomesReducer';

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [sagaMiddleware];

  const persistConfigRecentSearches = {
    key: keysStorage.recentSearches,
    storage: sessionStorage,
  };

  const RecentSearchReducerPersisted = persistReducer(
    persistConfigRecentSearches,
    RecentSearchReducer
  );

  const combinedReducers = combineReducers({
    auth: userReducer,
    projectLog: ProjectLogReducers,
    futureWorkPayments: futureWorkPaymentsReducer,
    homeTotals: HomeTotalsReducer,
    modals: ModalsReducers,
    search: SearchReducers,
    homeById: HomeByIdReducer,
    homeMilestones: homeMilestonesReducer,
    vendorEta: vendorEtaReducers,
    darkMode: darkLightModeReducer,
    homeFilters: HomeFiltersReducer,
    recentSearches: RecentSearchReducerPersisted,
    ListOfHomes: ListOfHomesReducer,
  });
  const store = createReduxStore(
    combinedReducers,
    composeWithDevTools(applyMiddleware(...middleware))
  );
  const persistor = persistStore(store);
  sagaMiddleware.run(sagas);

  return { store, persistor };
};

export const { store, persistor } = createStore();
