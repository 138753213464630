import { FUTURE_WORK_PAYMENTS_TYPES } from './futureWorkPaymentsTypes';

export interface FutureWorkPaymentsProps {
  id: number;
  token: string;
}

export const GET_FUTURE_WORK_PAYMENTS = (payload: FutureWorkPaymentsProps) => ({
  type: FUTURE_WORK_PAYMENTS_TYPES.GET_FUTURE_WORK_PAYMENTS,
  payload,
});

export const SET_FUTURE_WORK_PAYMENTS = (payload: any) => ({
  type: FUTURE_WORK_PAYMENTS_TYPES.SET_FUTURE_WORK_PAYMENTS,
  payload,
});
