import CloseMenuMobileIcon from 'assets/svgs/CloseMenuMobileIcon';
import LogoWithLetter from 'assets/svgs/LogoWithLetter';
import ItemsSideBar from 'features/app/components/Commons/MenuSideBar/components/ItemsSideBar';
import { flagsProps } from 'features/app/components/Commons/MenuSideBar/MenuSideBar';
import { ItemPropsTypes } from 'features/app/components/Commons/MobileSideMenu/types/types';
import navigation, {
  HOMES_KEY,
  PERFORMANCE_KEY,
  REFERRALS_KEY,
} from 'features/app/constants/navigation';
import { navigationFlags } from 'features/app/utils/flags';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import VendorInformation from '../../../HeaderDesktop/components/VendorInformation/VendorInformation';
import { Text } from '../../../Text/Text';
import SignOutMobile from './components/SignOutMobile';
import {
  CloseBtnSideMenuLeftWrapper,
  Items,
  SideMenuLeftWrapper,
  VendorWrapper,
  VendorHelpInfoContainer,
  VendorHelpInfoTextContainer,
  VendorPlusLinksContainer,
} from './SideMenuLeft.styles';

export interface SideMenuProps {
  onClose: () => void;
}

const SideMenuLeft: React.FC<SideMenuProps> = ({ onClose }) => {
  const location = useLocation();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const [navKeys, setNavKeys] = useState([
    REFERRALS_KEY,
    HOMES_KEY,
    PERFORMANCE_KEY,
  ]);
  const [flag, setFlag] = useState<flagsProps>({
    response: false,
    alreadySet: false,
  });

  useEffect(() => {
    const getFlag = async () => {
      const flags = await navigationFlags(
        data?.vendor.id,
        data?.vendor?.markets
      );
      setFlag(flags);
    };

    if (!flag.response) getFlag();
  });

  useEffect(() => {
    if (flag.response && !flag.alreadySet) {
      const navigationKeysTmp = navKeys;
      setNavKeys([...navigationKeysTmp]);
      setFlag({ ...flag, alreadySet: true });
    }
  }, [navKeys, flag]);

  const navigationFeature = () => {
    const nav: ItemPropsTypes[] = [];
    navigation.forEach((key) => {
      if (navKeys.includes(key.id)) {
        nav.push(key);
      }
    });
    return nav;
  };

  const onPathChange = () => {
    onClose();
  };

  return (
    <SideMenuLeftWrapper>
      <VendorPlusLinksContainer>
        <VendorWrapper>
          <CloseBtnSideMenuLeftWrapper onClick={onClose}>
            <CloseMenuMobileIcon width={33} height={33} />
          </CloseBtnSideMenuLeftWrapper>
          <LogoWithLetter width={91} height={24} />
          <VendorInformation isMobile />
        </VendorWrapper>
        <Items>
          <ItemsSideBar
            onPathChange={onPathChange}
            items={navigationFeature()}
            location={location}
          />
        </Items>
      </VendorPlusLinksContainer>
      <VendorHelpInfoContainer>
        <SignOutMobile />
        <VendorHelpInfoTextContainer>
          <Text
            fontSize="14"
            weight="600"
            style={{ textTransform: 'uppercase' }}
          >
            Need help?
          </Text>
          <Text fontSize="14">Contact your VM Advocate</Text>
        </VendorHelpInfoTextContainer>
        <Text fontSize="14" weight="600">
          Mark Twain
        </Text>
        <Text fontSize="14" style={{ opacity: '.5   ' }}>
          mark.twain@sfr3.com 987 654 3210
        </Text>
      </VendorHelpInfoContainer>
    </SideMenuLeftWrapper>
  );
};

export default SideMenuLeft;
