import { ReactElement } from 'react';
import {
  Circle,
  Container,
  FilledCircle,
  Text,
  TextContainer,
} from './ProgressCircle.styles';

interface ProgressCircleProps {
  progress: number;
  width?: string;
  milestones: ReactElement;
}

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  progress,
  milestones,
  width = '100%',
}) => {
  const strokeWidth = 4;
  const radius = 100 / 2 - strokeWidth * 2;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <Container>
      <svg
        aria-valuenow={progress}
        height={width}
        role="progressbar"
        width={width}
        viewBox="0 0 100 100"
      >
        <Circle cx="50" cy="50" r={radius} strokeWidth={strokeWidth} />

        <FilledCircle
          cx="50"
          cy="50"
          data-testid="progress-bar-bar"
          r={radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          strokeWidth={strokeWidth}
        />
      </svg>
      <TextContainer>
        <Text data-testid="progress-bar-text">{milestones}</Text>
      </TextContainer>
    </Container>
  );
};

export default ProgressCircle;
