import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import ProgressCircle from '../../../../../Commons/ProgressCircle/ProgressCircle';
import { TextX4l, TextLg } from '../../../../../Commons/Text/Text';
import {
  CircularProgressBarWrapper,
  NumberWrapper,
  Text,
  ProgressWrapper,
} from './LineItemsCard.styles';
import { StateLineItemProps } from '../../HomeInformationCards';
import Card from '../../../../../HomeView/Components/HomesCardsList/Components/HomeCard/Card';

interface LineItemsCardProps {
  lineItemsData: StateLineItemProps;
}

const LineItemsCard: React.FC<LineItemsCardProps> = ({ lineItemsData }) => {
  const theme = useTheme();

  const calculateProgress = () => {
    if (lineItemsData?.totalScope === 0) {
      return 0;
    }
    return Math.floor(
      (lineItemsData?.approved / lineItemsData?.totalScope) * 100
    );
  };

  return (
    <Card>
      <CircularProgressBarWrapper>
        <ProgressWrapper>
          <ProgressCircle
            progress={calculateProgress()}
            milestones={
              <NumberWrapper>
                <TextX4l weight={theme.fontWeight.bold}>
                  {lineItemsData?.approved || 0}
                </TextX4l>
                <TextLg weight={theme.fontWeight.bold}>
                  /{lineItemsData?.totalScope || 0}
                </TextLg>
              </NumberWrapper>
            }
          />
        </ProgressWrapper>
        <Text>
          <FormattedMessage id="milestones.text" />
        </Text>
      </CircularProgressBarWrapper>
    </Card>
  );
};
export default LineItemsCard;
