/* eslint-disable camelcase */
import axios from 'axios';
import httpClient from '../services/httpClient';

export const URL_PASSWORDLESS = {
  SEND_CODE: 'https://gateway.sfr3.com/sfr3/auth/send',
  LOGIN_WITH_CODE: 'https://gateway.sfr3.com/sfr3/auth/login',
  REFRESH_TOKEN: 'https://gateway.sfr3.com/sfr3/auth/refresh',
};

export interface loginWithCodeProps {
  phone_or_email: string;
  auth: string;
}

export interface responseLoginWithCodeProps {
  access: string;
  refresh: string;
}

export const getAuth = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await httpClient.get('/auth', config);
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const sendCodeToLogin = async (phone_or_email: string) => {
  try {
    const res = await axios.post(URL_PASSWORDLESS.SEND_CODE, null, {
      params: {
        phone_or_email,
      },
    });
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const loginWithCodeCall = async ({
  payload,
}: loginWithCodeProps | any) => {
  try {
    const res = await axios.post(URL_PASSWORDLESS.LOGIN_WITH_CODE, null, {
      params: payload,
    });
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const refreshTokenCall = async (refresh: any) => {
  try {
    const res = await axios.post(URL_PASSWORDLESS.REFRESH_TOKEN, null, {
      params: { refresh: refresh.payload },
    });
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};
