export const HOME_FILTERS_TYPE = {
  SET_ZIP_CITY: 'SET_ZIP_CITY',
  SET_ZIP_CITY_SEARCH_VAL: 'SET_ZIP_CITY_SEARCH_VAL',
  ADD_STATUS: 'ADD_STATUS',
  REMOVE_STATUS: 'REMOVE_STATUS',
  SELECT_SORT_BY: 'SELECT_SORT_BY',
  SHOW_FILTERS: 'SHOW_FILTERS',
  CLEAR_STATUS: 'CLEAR_STATUS',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  SET_NEW_ZIP_RECENT_SEARCH: 'SET_NEW_ZIP_RECENT_SEARCH',
  SET_NEW_CITY_RECENT_SEARCH: 'SET_NEW_CITY_RECENT_SEARCH',
  SET_RIGHT_NAVBAR_FILTER: 'SET_RIGHT_NAVBAR_FILTER',
};

export type clearAllFilters = {
  type: typeof HOME_FILTERS_TYPE.CLEAR_ALL_FILTERS;
  payload: any;
};

export type clearStatus = {
  type: typeof HOME_FILTERS_TYPE.CLEAR_STATUS;
  payload: string[];
};

export type showFilters = {
  type: typeof HOME_FILTERS_TYPE.SHOW_FILTERS;
  payload: boolean;
};

export type setZipCity = {
  type: typeof HOME_FILTERS_TYPE.SET_ZIP_CITY;
  payload: string;
};

export type setZipCitySearchVal = {
  type: typeof HOME_FILTERS_TYPE.SET_ZIP_CITY_SEARCH_VAL;
  payload: string;
};

export type addStatus = {
  type: typeof HOME_FILTERS_TYPE.ADD_STATUS;
  payload: string;
};

export type removeStatus = {
  type: typeof HOME_FILTERS_TYPE.REMOVE_STATUS;
  payload: string;
};

export type selectSortBy = {
  type: typeof HOME_FILTERS_TYPE.SELECT_SORT_BY;
  payload: string;
};

export type setNewZipRecentSearch = {
  type: typeof HOME_FILTERS_TYPE.SET_NEW_ZIP_RECENT_SEARCH;
  payload: string;
};

export type setNewCityRecentSearch = {
  type: typeof HOME_FILTERS_TYPE.SET_NEW_CITY_RECENT_SEARCH;
  payload: string;
};

export type setRightNavbarFilter = {
  type: typeof HOME_FILTERS_TYPE.SET_RIGHT_NAVBAR_FILTER;
  payload: string;
};

export type HomeFiltersActions =
  | setZipCity
  | setZipCitySearchVal
  | addStatus
  | removeStatus
  | selectSortBy
  | showFilters
  | clearStatus
  | clearAllFilters
  | setNewZipRecentSearch
  | setNewCityRecentSearch
  | setRightNavbarFilter;
