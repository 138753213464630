import { getPaylogDataProps } from 'features/app/api/paylog';
import { HOME_TOTALS_TYPES } from './homeTotalsTypes';

export interface getHomeTotalsProps {
  id: number;
  body: getPaylogDataProps;
}

export interface HomeTotalInformationProps {
  completedPaymentsAmount: number;
  futurePayableJobsAmount: number;
  pendingPaymentsAmount: number;
  total: number;
}

export const GET_HOME_TOTALS = (payload: getHomeTotalsProps | any) => ({
  type: HOME_TOTALS_TYPES.GET_HOME_TOTALS,
  payload,
});

export const SET_HOME_INFORMATION = (payload: HomeTotalInformationProps) => ({
  type: HOME_TOTALS_TYPES.SET_HOME_INFORMATION,
  payload,
});

export const LOADING_HOME_INFORMATION = (payload: boolean) => ({
  type: HOME_TOTALS_TYPES.LOADING_HOME_INFORMATION,
  payload,
});
