import styled from '@emotion/styled';

import loginBackground from '../../../../assets/svgs/loginBackground.svg';

interface PropsLoginInputContainer {
  error: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-position-y: 100%;
  background-position-x: 100%;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.color.backgroundLogin};
  background-image: url(${loginBackground});
  background-size: 60%;
  text-align: center;
`;

export const Card = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.color.white};
  height: 70%;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow.staticCards};
  padding-inline: 160px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    padding-inline: 20px;
    height: 100%;
    width: 100%;
  }
  justify-content: space-between;
`;

export const LogoWrapper = styled.div`
  margin: 0px;
  width: 70%;
  align-items: center;
  align-self: center;
`;

export const Line = styled.div`
  height: 3px;
  width: 20%;
  background-color: ${({ theme }) => theme.color.loginButton};
  align-self: center;
`;

export const LoginInput = styled.input`
  width: 100%;
  height: 37px;
  font-size: 14px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.gray300};
  &:focus {
    outline: none;
  }
`;

export const LoginInputContainer = styled.div<PropsLoginInputContainer>`
  display: flex;
  background-color: ${({ theme }) => theme.color.gray300};
  align-items: center;
  width: 100%;
  padding-inline: 10px;
  border-radius: 4px;
  border-width: ${({ error }) => error && '1px'};
  border-color: ${({ theme, error }) => error && theme.color.error};
`;

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonBack = styled.button`
  font-size: 14px;
  color: ${({ theme }) => theme.color.blue500};
  text-decoration: underline;
  &:focus {
    outline: none;
  }
`;

export const CopyrightWrapper = styled.div``;
