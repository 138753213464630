import styled from '@emotion/styled';

interface ArrowProps {
  open: boolean;
}

interface HomeCardProps {
  open: boolean;
}

export const SummaryPaylogContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 800px;
  padding: 20px 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 100%;
    padding: 10px;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 10px 0 10px;
  }
`;

export const AmountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 150px;
  margin-right: 10px;
`;

export const ArrowWrapper = styled.div<ArrowProps>`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease-in-out;
`;

export const CardWrapper = styled.div`
  width: 100%;
  margin: 5px 0;
`;

export const HomeCard = styled.div<HomeCardProps>`
  height: ${({ open }) => (open ? '100%' : '40px')};
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  transition: height 0.3s ease-in-out;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    padding: 0 10px;
  }
`;

export const HomeCardHeader = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 10px 0px;
`;

export const RightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const TableWrapper = styled.div`
  padding: 10px;
  width: 100%;
`;

export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
  z-index: 20;
`;

export const DatePickerInput = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  min-width: 130px;
  margin: 0 10px 0 5px;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    margin-left: 0;
  }
`;

export const ClearButton = styled.button`
  unset: all;
  cursor: pointer;
  height: 18px;
  width: 18px;
  align-item: center;
  border-radius: 50%;
  margin-right: 5px;
`;
