import styled from '@emotion/styled';

export const ArrowPaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  border: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 0px 5px 5px 0px;
`;

export const IconContainerPagination = styled.div`
  margin-inline: 6px;
  cursor: pointer;
`;

export const ArrowLineDivisor = styled.div`
  height: 100%;
  width: 1px;
  background-color: #d9d9d9;
`;
