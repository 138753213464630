import {
  recentSearchesActions,
  RECENT_SEARCH_TYPE,
} from './RecentSearchesTypes';

const initialState: any = {
  recentZipSearch: [],
  recentCitySearch: [],
};

const RecentSearchReducer = (
  state = initialState,
  action: recentSearchesActions | any
) => {
  switch (action.type) {
    case RECENT_SEARCH_TYPE.SET_NEW_ZIP_RECENT_SEARCH: {
      const arr = state.recentZipSearch;
      if (state.recentZipSearch.length > 2) {
        arr.shift();
      }
      return {
        ...state,
        recentZipSearch: [...arr, action.payload],
      };
    }
    case RECENT_SEARCH_TYPE.SET_NEW_CITY_RECENT_SEARCH: {
      const arr = state.recentCitySearch;
      if (state.recentCitySearch.length > 2) {
        arr.shift();
      }
      return {
        ...state,
        recentCitySearch: [...arr, action.payload],
      };
    }
    default:
      return state;
  }
};

export default RecentSearchReducer;
