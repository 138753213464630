import { IconPropTypes } from './types';

const ResultsIcon: React.FC<IconPropTypes> = ({ width, height }) => (
  <svg
    viewBox="0 0 250.16 252.42"
    width={width || '100%'}
    height={height || '100%'}
  >
    <defs>
      <style>
        {'.cls-2{fill:#d3d9dd}.cls-3{fill:#bec6cb}.cls-4{fill:#fff}'}
      </style>
    </defs>
    <title>results</title>
    <ellipse
      cx={124.82}
      cy={126.22}
      rx={120}
      ry={121}
      style={{
        fill: '#eff0f5',
      }}
      id="Layer_2"
      data-name="Layer 2"
    />
    <g id="Layer_3" data-name="Layer 3">
      <path
        className="cls-2"
        d="M57.93 89.22h42.54l5.27 9.04h25.6l-5.65 11.67 5.65 9.79-6.4 8.66 6.4 17.31-6.02 16.94 6.02 24.48H57.93V89.22z"
      />
      <path
        className="cls-2"
        d="m130.49 98.26-4.51 11.48 4.51 10.17-7.51 9.6 7.51 16.56-6.58 17.13 6.58 23.91h60.05V98.26h-60.05z"
      />
      <path className="cls-3" d="M57.93 89.22V78.49h11.72l-.71 10.73H57.93z" />
      <path
        className="cls-4"
        d="m66.97 68.61 68.61-2.26-7.23 14.12 2.99 17.79h-25.6l-5.27-9.04h-33.5V68.61z"
      />
      <path className="cls-3" d="M174.92 78.49h15.62v19.76h-15.62z" />
      <path
        className="cls-4"
        d="m130.49 98.26-2.82-17.79 7.91-14.12 41.22-1.41v33.32h-46.31z"
      />
    </g>
  </svg>
);

export default ResultsIcon;
