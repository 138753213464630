/* eslint-disable no-plusplus */
import ChevronDownIcon from 'assets/svgs/ChevronDownIcon';
import { Background } from 'features/app/components/Commons/Inputs/MultiSelect/MultiSelect.styles';
import { Text } from 'features/app/components/Commons/Text/Text';
import { useState } from 'react';
import {
  DivisorOptionPage,
  OptionIndividualContainer,
  OptionsWrapper,
  SelectContainer,
  SelectPageWrapper,
  TextContainerPageSelector,
} from './SelectFilter.styles';

export interface SelectPageProps {
  selected: number | string;
  options: string[] | number[];
  onSelect: (item: string | number) => void;
  fullHeight: boolean;
  isDown?: boolean;
  border?: boolean;
  width: number;
}

function SelectFilter({
  selected,
  options,
  onSelect,
  fullHeight,
  isDown = false,
  border = true,
  width,
}: SelectPageProps) {
  const [openOptions, setOpenOptions] = useState(false);

  const OpenOptions = () => {
    setOpenOptions(!openOptions);
  };

  const closeOptions = () => {
    if (openOptions) {
      setOpenOptions(false);
    }
  };

  const onSelectOption = (item: string | number) => {
    onSelect(item);
    closeOptions();
  };

  return (
    <>
      {openOptions && <Background onClick={closeOptions} />}
      <SelectPageWrapper onBlur={closeOptions}>
        <SelectContainer
          width={width}
          border={border}
          selected={openOptions}
          onClick={OpenOptions}
        >
          <Text fontSize="14" weight={isDown ? '300' : '500'} color="#212121">
            {selected < 10 && '0'}
            {selected}
          </Text>
          <ChevronDownIcon height={15} width={15} color="#212121" />
        </SelectContainer>
        <OptionsWrapper
          width={width}
          isDown={isDown}
          fullHeight={fullHeight}
          totalPages={options.length}
          hide={!openOptions}
        >
          {options.map((item: string | number, index: number) => (
            <OptionIndividualContainer
              key={item}
              onClick={() => onSelectOption(item)}
            >
              <TextContainerPageSelector>
                <Text
                  fontSize="14"
                  weight={isDown ? '300' : '500'}
                  color="#212121"
                >
                  {item < 10 && '0'}
                  {item}
                </Text>
              </TextContainerPageSelector>
              {index + 1 < options.length && (
                <DivisorOptionPage width={width} />
              )}
            </OptionIndividualContainer>
          ))}
        </OptionsWrapper>
      </SelectPageWrapper>
    </>
  );
}

export default SelectFilter;
