import { useRef, useEffect } from 'react';
import { useIsMobile, useIsTablet } from 'features/app/hooks/screen';
import { CloseGlyph } from 'assets/glyphs';
import { BackgroundInner, CloseWrapper, ModalWrapper } from './Modal.styles';
import Portal from '../FreePositionModal/Portal';
import { Blur } from '../../Blur.styles';

interface ModalProps {
  setVisibility: (visibility: boolean) => void;
  fullWidth?: boolean;
  visibility?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  setVisibility,
  fullWidth,
}) => {
  const backgroundRef = useRef<any>();
  const modalWrapperRef = useRef<any>();

  useEffect(() => {
    window.setTimeout(() => {
      if (backgroundRef.current && modalWrapperRef.current) {
        backgroundRef.current.style.opacity = 1;
        modalWrapperRef.current.style.transform = 'translateY(0)';
        window.setTimeout(() => {
          if (modalWrapperRef.current) {
            modalWrapperRef.current.style.transform = 'none';
          }
        }, 1000);
      }
    }, 50);
  }, []);

  const isTablet = useIsTablet();
  const isMobile = useIsMobile();

  const dismiss = () => {
    if (backgroundRef.current && modalWrapperRef.current) {
      backgroundRef.current.style.opacity = null;
      modalWrapperRef.current.style.transform = null;
    }
    setVisibility(false);
    document.body.style.overflowY = 'scroll';
  };

  document.body.style.overflowY = 'hidden';

  return (
    <Portal>
      <Blur ref={backgroundRef} onClick={() => dismiss()} animation>
        <BackgroundInner>
          <ModalWrapper
            ref={modalWrapperRef}
            isMobile={isMobile || isTablet || false}
            fullWidth={fullWidth}
            onClick={(e) => e.stopPropagation()}
          >
            <CloseWrapper onClick={() => dismiss()}>
              <CloseGlyph size={20} viewBoxHeight={36} viewBoxWidth={36} />
            </CloseWrapper>
            {children}
          </ModalWrapper>
        </BackgroundInner>
      </Blur>
    </Portal>
  );
};

export default Modal;
