import styled from '@emotion/styled';

export const IconWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    width: auto;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    justify-content: center;
  }
`;
