import { HOME_FILTERS_TYPE } from './homeFiltersType';

export const SET_ZIP_CITY = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SET_ZIP_CITY,
  payload,
});

export const SET_ZIP_CITY_SEARCH_VAL = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SET_ZIP_CITY_SEARCH_VAL,
  payload,
});

export const ADD_STATUS = (payload: string) => ({
  type: HOME_FILTERS_TYPE.ADD_STATUS,
  payload,
});

export const REMOVE_STATUS = (payload: string) => ({
  type: HOME_FILTERS_TYPE.REMOVE_STATUS,
  payload,
});

export const CLEAR_STATUS = () => ({
  type: HOME_FILTERS_TYPE.CLEAR_STATUS,
});

export const SELECT_SORT_BY = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SELECT_SORT_BY,
  payload,
});

export const SHOW_FILTERS = (payload: boolean) => ({
  type: HOME_FILTERS_TYPE.SHOW_FILTERS,
  payload,
});

export const CLEAR_ALL_FILTERS = (payload: string) => ({
  type: HOME_FILTERS_TYPE.CLEAR_ALL_FILTERS,
  payload,
});

export const SET_NEW_ZIP_RECENT_SEARCH = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SET_NEW_ZIP_RECENT_SEARCH,
  payload,
});

export const SET_NEW_CITY_RECENT_SEARCH = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SET_NEW_CITY_RECENT_SEARCH,
  payload,
});

export const SET_RIGHT_NAVBAR_FILTER = (payload: string) => ({
  type: HOME_FILTERS_TYPE.SET_RIGHT_NAVBAR_FILTER,
  payload,
});
