import { StyledButton } from './PrimaryButton.styles';

interface BtnProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const PrimaryButton = ({ children, onClick, ...props }: BtnProps) => (
  <StyledButton {...props} onClick={onClick}>
    {children}
  </StyledButton>
);

export default PrimaryButton;
