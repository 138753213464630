import styled from '@emotion/styled';

interface ChipWrapperProps {
  overrideBackgroundColor?: string;
  textColor?: string;
  weight?: string;
  customPadding?: string;
}
export const ChipWrapper = styled.div<ChipWrapperProps>`
  align-items: center;
  display: flex;
  margin-inline: 5px;
  justify-content: center;
  padding: 2px 10px;
  width: fit-content;
  background-color: ${({ theme, overrideBackgroundColor }) =>
    overrideBackgroundColor || theme.color.appBackground};
  color: ${({ theme, textColor }) => textColor || theme.color.font};
  border-radius: ${({ theme }) => theme.borderRadius.xxl};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.medium};
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    padding: 2px 8px;
  }
`;

export const CloseButton = styled.button`
  display: flex;
`;
