import {
  futureWorkPaymentsActions,
  FUTURE_WORK_PAYMENTS_TYPES,
} from './futureWorkPaymentsTypes';

const initialState = {
  loading: false,
  futurePayments: [],
  error: false,
};

export const futureWorkPaymentsReducer = (
  state = initialState,
  action: futureWorkPaymentsActions
) => {
  switch (action.type) {
    case FUTURE_WORK_PAYMENTS_TYPES.SET_FUTURE_WORK_PAYMENTS:
      return {
        ...state,
        futurePayments: action.payload,
      };
    default:
      return state;
  }
};
