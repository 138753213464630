/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable global-require */
import Alert from 'assets/svgs/Alert';
import FullLogo from 'assets/svgs/FullLogo';
import {
  loginWithCode,
  sendCode,
  verificationPage,
} from 'features/app/store/auth/actions';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import theme from 'theme';
import { Button } from '../Commons/Form/Form.styles';
import { TextSm, TextXs } from '../Commons/Text/Text';
import VerificationCodeInput from '../Commons/VerificationCodeInput/VerificationCodeInput';
import {
  ButtonBack,
  Card,
  CopyrightWrapper,
  Line,
  LoginFormWrapper,
  LoginInput,
  LoginInputContainer,
  LogoWrapper,
  Wrapper,
} from './Login.styles';

const Login: React.FC<{}> = () => {
  const { passwordLess } = useSelector((store: RootStateOrAny) => store.auth);
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const dispatch = useDispatch();
  const goToVerification = () => {
    let phoneOrEmail =
      email && email.replace('(', '').replace(')', '').replace('-', '').trim();
    phoneOrEmail = parseInt(phoneOrEmail) ? phoneOrEmail : email;
    dispatch(sendCode(phoneOrEmail));
  };

  const LoginWithCode = (value: string) => {
    const phoneOrEmail = email
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')
      .trim();
    dispatch(
      loginWithCode({
        auth: value,
        phone_or_email: parseInt(phoneOrEmail) ? phoneOrEmail : email,
      })
    );
  };

  const onChangeEmail = (e: any) => {
    if (
      parseInt(
        e.target.value.replace('(', '').replace(')', '').replace('-', '').trim()
      )
    ) {
      const containLetter = /[a-zA-Z]/;
      if (containLetter.test(e.target.value)) {
        const value = e.target.value
          .replace('(', '')
          .replace(')', '')
          .replace('-', '')
          .replace(' ', '')
          .trim();
        setEmail(value);
      } else {
        const value = normalizeInput(e);
        setEmail(value);
      }
    } else {
      setEmail(e.target.value);
    }
  };

  const normalizeInput = (value: any) => {
    if (!value.target.value) return value.target.value;
    const currentValue = value.target.value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length;

    if (!email || value.target.value.length > email.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  const goBack = () => {
    dispatch(verificationPage(false));
  };

  return (
    <Wrapper>
      <Card>
        <LoginFormWrapper />
        <LoginFormWrapper>
          <LogoWrapper>
            <FullLogo />
          </LogoWrapper>
          <TextSm
            style={{ marginTop: 20, marginBottom: 10 }}
            weight={theme.fontWeight.bold}
          >
            <FormattedMessage
              id={
                passwordLess.verificationPage
                  ? 'verificationLogin.welcome'
                  : 'login.welcome'
              }
            />
            <br />
            <FormattedMessage
              id={
                passwordLess.verificationPage
                  ? 'verificationLogin.sent'
                  : 'login.SFR3'
              }
            />
          </TextSm>
          <Line />
          <TextSm style={{ marginBottom: 20, marginTop: 10 }}>
            <FormattedMessage
              id={
                passwordLess.verificationPage
                  ? 'verificationLogin.please'
                  : 'login.loginText'
              }
            />
            <br />
            <FormattedMessage
              id={
                passwordLess.verificationPage
                  ? 'verificationLogin.code'
                  : 'login.below'
              }
            />
          </TextSm>
          {passwordLess.verificationPage ? (
            <VerificationCodeInput
              error={passwordLess.error}
              onChange={(e: string) => setCode(e)}
              loginWithCode={LoginWithCode}
            />
          ) : (
            <LoginInputContainer error={passwordLess.error}>
              <LoginInput
                autoCapitalize="none"
                value={email}
                placeholder="Email or phone number"
                onChange={onChangeEmail}
              />
              {passwordLess.error && <Alert height={20} width={20} />}
            </LoginInputContainer>
          )}
          {passwordLess.error && (
            <TextXs
              weight={theme.fontWeight.regular}
              color={theme.color.error}
              textAlign="left"
              style={{ marginTop: 5 }}
            >
              {!passwordLess.verificationPage
                ? 'Please provide a valid email or phone'
                : 'Please provide a valid verification code'}
            </TextXs>
          )}
          <Button
            style={{
              backgroundColor: passwordLess.loading
                ? theme.color.gray300
                : theme.color.loginButton,
              marginTop: 15,
              fontWeight: 300,
            }}
            disabled={passwordLess.loading}
            onClick={
              passwordLess.verificationPage
                ? () => LoginWithCode(code)
                : goToVerification
            }
          >
            <FormattedMessage id="login.continue" />
          </Button>
          {passwordLess.verificationPage && (
            <ButtonBack onClick={goBack}>Go Back</ButtonBack>
          )}
        </LoginFormWrapper>
        <CopyrightWrapper>
          <TextSm
            weight={theme.fontWeight.regular}
            color={theme.color.black}
            style={{
              marginBottom: 15,
            }}
          >
            Copyright SFR3 2022 | Privacy Policy
          </TextSm>
        </CopyrightWrapper>
      </Card>
    </Wrapper>
  );
};

export default Login;
