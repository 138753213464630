import styled from '@emotion/styled';

interface ModalProps {
  isMobile: boolean;
  fullWidth?: boolean;
}

export const BackgroundInner = styled.div`
  z-index: ${({ theme }) => theme.zIndex.modal.background};
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

export const ModalWrapper = styled.div<ModalProps>`
  z-index: ${({ theme }) => theme.zIndex.modal.content};
  top: ${({ isMobile }) => (isMobile ? 'auto' : 0)};
  bottom: ${({ isMobile }) => (isMobile ? 0 : 'auto')};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  max-width: ${({ isMobile }) => (isMobile ? 'none' : '500px')};
  position: relative;
  max-height: 500px;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: none;
  transition-property: transform;
  transition-duration: 500ms;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e6ecf1;
    border-radius: 10px;
  }
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    right: 15px;
    top: 10px;
  }
`;
