import styled from '@emotion/styled';

interface MainContainerProps {
  disabledLayout?: boolean | null;
  showRightNavBar?: boolean;
}

export const MainContainer = styled.main<MainContainerProps>`
  min-height: 100vh;
  width: 100%;
  padding-left: ${({ theme, disabledLayout }) =>
    disabledLayout ? 0 : theme.width.sideBar + 30}px;
  padding-right: ${({ theme, disabledLayout, showRightNavBar }) =>
    disabledLayout || !showRightNavBar ? 0 : theme.width.sideBarRight + 30}px;
  background-color: #f6f6f6;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet.max}) {
    padding-left: 0;
  }
`;

export const Main = styled.div`
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: ${({ theme }) => theme.color.appBackground};
`;

export const ContentWrapperNavBar = styled.div`
  padding-top: 60px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;
