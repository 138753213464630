export const DARK_LIGHT_MODE_TYPES = {
  ON_SET_DARK_LIGHT_MODE: 'ON_SET_DARK_LIGHT_MODE',
};

export type OnSetDarkLightMode = {
  type: typeof DARK_LIGHT_MODE_TYPES.ON_SET_DARK_LIGHT_MODE;
  payload: boolean;
};

export type darkLightModeActions = OnSetDarkLightMode;
