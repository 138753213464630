import { getHomeById } from 'features/app/api/homes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  HomeByIdProps,
  LOADING_HOME_BY_ID,
  SET_HOME_BY_ID,
} from './homeByIdActions';
import { HOME_BY_ID_TYPES } from './homeByIdTypes';

function* getHomeByIdApi({ payload }: HomeByIdProps | any) {
  yield put(LOADING_HOME_BY_ID(true));
  try {
    const data: HomeByIdProps = yield call(
      getHomeById,
      payload.id,
      payload.token
    );
    yield put(SET_HOME_BY_ID(data));
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
}

export default function* HomeByIdDataSaga(): any {
  yield all([
    yield takeLatest(HOME_BY_ID_TYPES.GET_HOME_BY_ID, getHomeByIdApi),
  ]);
}
