import { LegacyRef } from 'react';
import { Text } from 'features/app/components/Commons/Text/Text';
// import { RootStateOrAny, useSelector } from 'react-redux';
import { ModalCardWrapper, ModalRowContainer } from './ModalUserCard.styles';
import LogoutButton from '../../../LogoutButton';

interface ModalUserCardProps {
  elRef?: LegacyRef<HTMLDivElement> | undefined;
}

const ModalUserCard: React.FC<ModalUserCardProps> = ({ elRef }) => (
  <ModalCardWrapper ref={elRef}>
    <ModalRowContainer>
      <Text fontSize="15" weight="500">
        My profile
      </Text>
    </ModalRowContainer>
    <ModalRowContainer>
      <LogoutButton />
    </ModalRowContainer>
  </ModalCardWrapper>
);

export default ModalUserCard;
