import { IconPropTypes } from './types';

const TaskIcon = ({ width, height }: IconPropTypes) => (
  <svg
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    width={width || '100%'}
    height={height || '100%'}
    xmlSpace="preserve"
  >
    <style>{'.st1{fill:#eaf7ff'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        className="st1"
        d="M49.5 9.8c-8 0-15.8 2.4-22.5 6.8-6.7 4.5-11.9 10.8-14.9 18.2C9 42.3 8.2 50.4 9.8 58.3s5.4 15.1 11.1 20.8c5.7 5.7 12.9 9.5 20.7 11.1 7.9 1.6 16 .8 23.4-2.3 7.4-3.1 13.7-8.3 18.2-15 4.5-6.7 6.8-14.5 6.8-22.6 0-5.3-1-10.6-3.1-15.5-2-4.9-5-9.4-8.8-13.2-3.8-3.8-8.2-6.8-13.1-8.8s-10.2-3-15.5-3zm0 73.1c-6.4 0-12.7-1.9-18-5.5-5.3-3.6-9.5-8.6-11.9-14.6-2.5-5.9-3.1-12.5-1.8-18.8 1.3-6.3 4.3-12.1 8.9-16.6 4.5-4.5 10.3-7.6 16.6-8.9 6.3-1.3 12.8-.6 18.7 1.8 5.9 2.5 11 6.6 14.5 12 3.6 5.3 5.5 11.6 5.5 18.1 0 8.6-3.4 16.9-9.5 23-6.2 6.1-14.4 9.5-23 9.5z"
      />
      <path
        d="M53.3 41.2c-.3.3-.5.8-.5 1.3s.2.9.5 1.3l2.9 2.9c.3.3.8.5 1.3.5s.9-.2 1.3-.5l6.8-6.8c.9 2 1.2 4.2.8 6.4-.4 2.2-1.4 4.2-3 5.7-1.6 1.6-3.5 2.6-5.7 3-2.2.4-4.4.1-6.4-.8L38.7 66.6c-.7.7-1.7 1.1-2.7 1.1-1 0-2-.4-2.7-1.1-.7-.7-1.1-1.7-1.1-2.7 0-1 .4-2 1.1-2.7l12.4-12.5c-.9-2-1.2-4.2-.8-6.4.4-2.2 1.4-4.2 3-5.7 1.6-1.6 3.5-2.6 5.7-3 2.2-.4 4.4-.1 6.4.8l-6.7 6.8h0z"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        className="st1"
        d="m45.7 48.8-13 13.5c-1.7 4.3 2.5 5.4 4.9 5.4l13.5-13.5 7.6 1.1c1.4-1.1 4.8-3.9 6.5-6.5 1.7-2.6.7-6.9 0-8.7l-6.5 7h-1.6l-4.3-3.8v-1.6l7.6-7c0-1.3-3.8-1.6-9.2 0-5.5 1.6-6.7 10.6-5.5 14.1z"
      />
    </g>
  </svg>
);

export default TaskIcon;
