import ContentLoader from 'react-content-loader';

const ListLoader = () => (
  <ContentLoader width="100%" height="100%" viewBox="0 0 1250 1700">
    <path d="M329.167,83.333l-0,4.167l-0,-4.167Z" />
    <path d="M325,487.5l0,4.167l0,-4.167Z" />
    <path d="M316.667,895.833l-0,4.167l-0,-4.167Z" />
    <path d="M312.5,1291.67l0,4.166l0,-4.166Z" />
    <path d="M637.5,87.5c0,-9.199 -7.468,-16.667 -16.667,-16.667l-516.666,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l516.666,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M633.333,491.667c0,-9.199 -7.468,-16.667 -16.666,-16.667l-516.667,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l516.667,0c9.198,0 16.666,-7.468 16.666,-16.667l0,-4.166Z" />
    <path d="M625,900c0,-9.199 -7.468,-16.667 -16.667,-16.667l-516.666,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l516.666,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M620.833,1295.83c0,-9.198 -7.468,-16.666 -16.666,-16.666l-516.667,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l516.667,-0c9.198,-0 16.666,-7.468 16.666,-16.667l0,-4.167Z" />
    <path d="M429.167,172.917c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-308.333,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l308.333,0c9.199,0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M425,577.083c0,-9.198 -7.468,-16.666 -16.667,-16.666l-308.333,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l308.333,-0c9.199,-0 16.667,-7.468 16.667,-16.667l0,-4.167Z" />
    <path d="M416.667,985.417c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-308.333,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l308.333,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M412.5,1381.25c0,-9.199 -7.468,-16.667 -16.667,-16.667l-308.333,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l308.333,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M320.833,255.591c0,-9.199 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l-0,4.166c-0,9.199 7.468,16.667 16.667,16.667l200,0c9.198,0 16.666,-7.468 16.666,-16.667l0,-4.166Z" />
    <path d="M316.667,659.757c-0,-9.198 -7.468,-16.666 -16.667,-16.666l-200,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l200,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-4.167Z" />
    <path d="M308.333,1068.09c0,-9.199 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l-0,4.166c-0,9.199 7.468,16.667 16.667,16.667l200,0c9.198,0 16.666,-7.468 16.666,-16.667l0,-4.166Z" />
    <path d="M304.167,1463.92c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-4.167Z" />
    <path d="M320.833,332.451c0,-9.198 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.469 -16.667,16.667l-0,4.167c-0,9.198 7.468,16.666 16.667,16.666l200,0c9.198,0 16.666,-7.468 16.666,-16.666l0,-4.167Z" />
    <path d="M316.667,736.618c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M308.333,1144.95c0,-9.198 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.469 -16.667,16.667l-0,4.167c-0,9.198 7.468,16.666 16.667,16.666l200,0c9.198,0 16.666,-7.468 16.666,-16.666l0,-4.167Z" />
    <path d="M304.167,1540.78c-0,-9.198 -7.468,-16.666 -16.667,-16.666l-200,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l200,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-4.167Z" />
    <path d="M752.819,255.591c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M748.652,659.757c-0,-9.198 -7.468,-16.666 -16.667,-16.666l-200,-0c-9.198,-0 -16.666,7.468 -16.666,16.666l-0,4.167c-0,9.199 7.468,16.667 16.666,16.667l200,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-4.167Z" />
    <path d="M740.319,1068.09c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M736.152,1463.92c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.468 -16.666,16.667l-0,4.167c-0,9.198 7.468,16.666 16.666,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-4.167Z" />
    <path d="M752.819,332.451c-0,-9.198 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.469 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-4.167Z" />
    <path d="M748.652,736.618c-0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.468 -16.666,16.667l-0,4.166c-0,9.199 7.468,16.667 16.666,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l-0,-4.166Z" />
    <path d="M740.319,1144.95c-0,-9.198 -7.468,-16.667 -16.667,-16.667l-200,0c-9.199,0 -16.667,7.469 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l-0,-4.167Z" />
    <path d="M736.152,1540.78c-0,-9.198 -7.468,-16.666 -16.667,-16.666l-200,-0c-9.198,-0 -16.666,7.468 -16.666,16.666l-0,4.167c-0,9.199 7.468,16.667 16.666,16.667l200,-0c9.199,-0 16.667,-7.468 16.667,-16.667l-0,-4.167Z" />
    <path d="M1173.75,255.591c0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.468 -16.666,16.667l-0,4.166c-0,9.199 7.468,16.667 16.666,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l0,-4.166Z" />
    <path d="M1169.58,659.757c0,-9.198 -7.468,-16.666 -16.666,-16.666l-200,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l200,-0c9.198,-0 16.666,-7.468 16.666,-16.667l0,-4.167Z" />
    <path d="M1161.25,1068.09c0,-9.199 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.468 -16.666,16.667l-0,4.166c-0,9.199 7.468,16.667 16.666,16.667l200,0c9.199,0 16.667,-7.468 16.667,-16.667l0,-4.166Z" />
    <path d="M1157.08,1463.92c0,-9.199 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l200,0c9.198,0 16.666,-7.468 16.666,-16.666l0,-4.167Z" />
    <path d="M1173.75,332.451c0,-9.198 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.469 -16.666,16.667l-0,4.167c-0,9.198 7.468,16.666 16.666,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M1169.58,736.618c0,-9.199 -7.468,-16.667 -16.666,-16.667l-200,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l200,0c9.198,0 16.666,-7.468 16.666,-16.667l0,-4.166Z" />
    <path d="M1161.25,1144.95c0,-9.198 -7.468,-16.667 -16.667,-16.667l-200,0c-9.198,0 -16.666,7.469 -16.666,16.667l-0,4.167c-0,9.198 7.468,16.666 16.666,16.666l200,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M1157.08,1540.78c0,-9.198 -7.468,-16.666 -16.666,-16.666l-200,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l200,-0c9.198,-0 16.666,-7.468 16.666,-16.667l0,-4.167Z" />
    <path d="M1173.75,172.917c0,-9.199 -7.468,-16.667 -16.667,-16.667l-341.666,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l341.666,0c9.199,0 16.667,-7.468 16.667,-16.667l0,-4.166Z" />
    <path d="M1169.58,577.083c0,-9.198 -7.468,-16.666 -16.666,-16.666l-341.667,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l341.667,-0c9.198,-0 16.666,-7.468 16.666,-16.667l0,-4.167Z" />
    <path d="M1161.25,985.417c0,-9.199 -7.468,-16.667 -16.667,-16.667l-341.666,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l341.666,-0c9.199,-0 16.667,-7.468 16.667,-16.667l0,-4.166Z" />
    <path d="M1157.08,1381.25c0,-9.199 -7.468,-16.667 -16.666,-16.667l-341.667,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l341.667,0c9.198,0 16.666,-7.468 16.666,-16.666l0,-4.167Z" />
    <path d="M1173.75,87.5c0,-9.199 -7.468,-16.667 -16.667,-16.667l-304.166,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l304.166,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M1169.58,491.667c0,-9.199 -7.468,-16.667 -16.666,-16.667l-304.167,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.166c0,9.199 7.468,16.667 16.667,16.667l304.167,0c9.198,0 16.666,-7.468 16.666,-16.667l0,-4.166Z" />
    <path d="M1161.25,900c0,-9.199 -7.468,-16.667 -16.667,-16.667l-304.166,0c-9.199,0 -16.667,7.468 -16.667,16.667l0,4.167c0,9.198 7.468,16.666 16.667,16.666l304.166,0c9.199,0 16.667,-7.468 16.667,-16.666l0,-4.167Z" />
    <path d="M1157.08,1295.83c0,-9.198 -7.468,-16.666 -16.666,-16.666l-304.167,-0c-9.199,-0 -16.667,7.468 -16.667,16.666l0,4.167c0,9.199 7.468,16.667 16.667,16.667l304.167,-0c9.198,-0 16.666,-7.468 16.666,-16.667l0,-4.167Z" />
    <path d="M1229.17,404.167c-0,-4.6 -3.734,-8.334 -8.334,-8.334l-1175,0c-4.599,0 -8.333,3.734 -8.333,8.334c0,4.599 3.734,8.333 8.333,8.333l1175,-0c4.6,-0 8.334,-3.734 8.334,-8.333Z" />
    <path d="M1225,808.333c-0,-4.599 -3.734,-8.333 -8.333,-8.333l-1175,0c-4.6,0 -8.334,3.734 -8.334,8.333c0,4.6 3.734,8.334 8.334,8.334l1175,-0c4.599,-0 8.333,-3.734 8.333,-8.334Z" />
    <path d="M1216.67,1216.67c-0,-4.6 -3.734,-8.334 -8.334,-8.334l-1175,0c-4.599,0 -8.333,3.734 -8.333,8.334c0,4.599 3.734,8.333 8.333,8.333l1175,0c4.6,0 8.334,-3.734 8.334,-8.333Z" />
    <path d="M1212.5,1612.5c-0,-4.599 -3.734,-8.333 -8.333,-8.333l-1175,-0c-4.6,-0 -8.334,3.734 -8.334,8.333c0,4.599 3.734,8.333 8.334,8.333l1175,0c4.599,0 8.333,-3.734 8.333,-8.333Z" />
  </ContentLoader>
);

export default ListLoader;
