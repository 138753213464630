import styled from '@emotion/styled';

interface OptionItemProps {
  isMobile: boolean;
}

interface SelectProps {
  color?: string;
  isMobile: boolean;
  borderRadius?: string;
}

interface Wrapper {
  width?: string;
}

interface UlProps {
  borderRadius?: string;
}

export const SelectWrapper = styled.div<Wrapper>`
  width: ${({ width }) => width || '100%'};
  position: relative;
`;

export const Select = styled.div<SelectProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: ${({ isMobile }) => (isMobile ? '250px' : '100%')};
  padding: 0px 10px;
  border-radius: ${({ borderRadius }) => borderRadius || '10px'};
  cursor: default;
  background-color: ${({ color }) => color || 'transparent'};
  color: ${({ theme, color }) => color || theme.color.font};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    color: rgb(20, 40, 64);
    cursor: pointer;
  }
  &:focus {
    outline: 5px auto -webkit-focus-ring-color;
    -webkit-outline: 5px auto -webkit-focus-ring-color;
    -moz-outline: 5px auto -webkit-focus-ring-color;
    -ms-outline: 5px auto -webkit-focus-ring-color;
    -o-outline: 5px auto -webkit-focus-ring-color;
    border: 1px solid rgba(0, 0, 0, 0);
  }
`;

export const OptionList = styled.ul<UlProps>`
  position: absolute;
  border-radius: ${({ borderRadius }) => borderRadius || '10px'};
  padding: 10px;
  top: 25px;
  width: 100%;
  z-index: ${({ theme }) => theme.zIndex.dropdown.content};
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.boxShadow.dropdowns};
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  color: ${({ theme }) => theme.color.font};
  overflow-y: auto;
`;

export const OptionItem = styled.li<OptionItemProps>`
  display: flex;
  align-items: center;
  max-width: ${({ isMobile }) => (isMobile ? '150px' : '100%')};
  box-sizing: border-box;
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  padding: 8px 10px;
  line-height: 1.6rem;
  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease;
  background: ${({ theme }) => theme.color.white};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.color.appBackground};
    > p {
      color: ${({ theme }) => theme.color.font};
    }
  }
  > p {
    transition: color 0.3s ease;
    padding-left: 10px;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.dropdown.background};
`;
