import { LogoPropTypes } from './types';

const Broken = ({ width, height }: LogoPropTypes) => (
  <svg
    viewBox="0 0 250.16 252.42"
    width={width || '100%'}
    height={height || '100%'}
  >
    <defs>
      <style>
        {'.cls-3{fill:#d3d9dd}.cls-4{fill:#bec6cb}.cls-5{fill:#fff}'}
      </style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <ellipse
        cx={124.82}
        cy={126.22}
        rx={120}
        ry={121}
        style={{
          fill: '#eff0f5',
        }}
      />
      <ellipse
        cx={124.82}
        cy={130.42}
        rx={48.41}
        ry={48.82}
        style={{
          fill: '#a7acaf',
        }}
      />
    </g>
    <g id="Layer_1" data-name="Layer 1">
      <path
        className="cls-3"
        d="M51.67 89.22h42.54l5.27 9.04h25.6l-5.64 11.67 5.64 9.79-6.4 8.66 6.4 17.31-6.02 16.94 6.02 24.48H51.67V89.22zM133.84 98.26l-4.52 11.48 4.52 10.17-7.52 9.6 7.52 16.56-6.59 17.13 6.59 23.91h60.04V98.26h-60.04z"
      />
    </g>
    <g id="Layer_3" data-name="Layer 3">
      <path className="cls-4" d="M51.67 89.22V78.49h11.72l-.71 10.73H51.67z" />
      <path
        className="cls-5"
        d="m60.71 68.61 68.61-2.26-7.23 14.12 2.99 17.79h-25.6l-5.27-9.04h-33.5V68.61z"
      />
      <path className="cls-4" d="M178.26 78.49h15.62v19.76h-15.62z" />
      <path
        className="cls-5"
        d="m133.84 98.26-2.83-17.79 7.91-14.12 41.22-1.41v33.32h-46.3z"
      />
    </g>
  </svg>
);

export default Broken;
