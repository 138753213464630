/* eslint-disable camelcase */
import httpClient from '../services/httpClient';
import { getStatus } from './helper';

export interface getPaylogDataProps {
  token: string;
  startDate: string;
  endDate: string;
  page: number;
  limit: number;
  name: string;
  status: string | string[];
  group_by: string;
}

export const getLogData = async (id: number, body: getPaylogDataProps) => {
  const { token, startDate, endDate, page, limit, name, status, group_by } =
    body;
  const params: any = {
    start_date: startDate,
    end_date: endDate,
    name,
    status,
    group_by,
  };
  if (!params.name) {
    delete params.name;
  }
  if (params.status.length === 0) {
    delete params.status;
  }
  if (!params.group_by) {
    delete params.group_by;
  }
  const config = {
    headers: {
      Authorization: token,
    },
    params,
  };
  const pagination = page && limit ? `/?page=${page}&items=${limit}` : '';
  try {
    const payments = await httpClient.get(
      `properties/${id}/line_items${pagination}`,
      config
    );
    return {
      lineItems: getStatus(payments.data.data),
      pagy: payments.data.pagy,
    };
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};
