import styled from '@emotion/styled';

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: 38% 38% 1fr;
  width: 100%;
  grid-gap: 20px;
  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
    grid-template-columns: 1fr;
  }
`;
