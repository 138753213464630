import { IconPropTypes } from './types';

const ChevronUpIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width || '100%'} height={height || '100%'} viewBox="0 0 24 24">
    <path
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m4 15 8-8 8 8"
    />
  </svg>
);

export default ChevronUpIcon;
