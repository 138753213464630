import ContentLoader from 'react-content-loader';

export const HomeCardLoader: React.FC = () => (
  <ContentLoader width="100%" height={80}>
    <circle cx="34" cy="49" r="29" />
    <path d="M352,40.5c0,-4.691 -3.809,-8.5 -8.5,-8.5l-253,0c-4.691,0 -8.5,3.809 -8.5,8.5l0,17c0,4.691 3.809,8.5 8.5,8.5l253,0c4.691,0 8.5,-3.809 8.5,-8.5l0,-17Z" />
  </ContentLoader>
);

export default HomeCardLoader;
