import styled from '@emotion/styled';

export const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.color.black};

  .fc {
    width: 100%;
    height: 100%;
  }

  .fc-daygrid {
    background-color: ${({ theme }) => theme.color.white};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .fc-col-header {
    background-color: ${({ theme }) => theme.color.appBackground};
  }

  .fc-daygrid-day-frame.fc-day-today {
    background-color: ${({ theme }) => theme.color.white};
  }

  .fc
    .fc-scrollgrid-section
    .fc-scrollgrid-section-body.
    fc-scrollgrid-section-liquid {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .fc-event-main-frame {
    padding-left: 10px;
    border-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .fc .fc-toolbar {
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
      justify-content: space-between;
    }
  }

  .fc .fc-toolbar .fc-toolbar-chunk {
    display: flex;
    flex-direction: row-reverse;
    white-space: nowrap;
  }

  .fc-prev-button.fc-button.fc-button-primary {
    border: none;
    :focus {
      outline: none;
    }
  }

  .fc-next-button.fc-button.fc-button-primary {
    border: none;
    :focus {
      outline: none;
    }
  }

  .fc .fc-button-primary {
    border-radius: ${({ theme }) => theme.borderRadius.lg};
    background-color: transparent;
    color: ${({ theme }) => theme.color.calendar.darkBlue};
    border: 1px solid ${({ theme }) => theme.color.calendar.darkBlue};
    text-transform: capitalize;
    font-family: ${({ theme }) => theme.fontFamily.name};
    font-size: ${({ theme }) => theme.fontSize.sm};
    :disabled {
      background-color: transparent;
      color: ${({ theme }) => theme.color.calendar.darkBlue};
      padding: 1px 10px;
      :hover {
        background-color: transparent;
        cursor: default;
        color: ${({ theme }) => theme.color.calendar.darkBlue};
      }
    }
    :hover {
      background-color: ${({ theme }) => theme.color.calendar.darkBlue};
      color: ${({ theme }) => theme.color.white};
    }
    :active {
      background-color: ${({ theme }) =>
        theme.color.calendar.darkBlue} !important;
    }
    :focus {
      outline: none;
    }
  }

  .fc-prev-button .fc-button .fc-button-primary {
    color: ${({ theme }) => theme.color.calendar.darkBlue};
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin-bottom: 10px;
  }

  .fc .fc-toolbar-title {
    font-size: ${({ theme }) => theme.fontSize.base};
    font-weight: bold;
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-start {
    border-top-left-radius: ${({ theme }) => theme.borderRadius.lg};
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .fc-direction-ltr .fc-daygrid-event.fc-event-end {
    border-top-right-radius: ${({ theme }) => theme.borderRadius.lg};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius.lg};
  }

  .fc .fc-daygrid-day.fc-day-today {
    background-color: ${({ theme }) => theme.color.calendar.lightBlue};
    .fc-daygrid-day-top a {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      color: ${({ theme }) => theme.color.calendar.darkBlue};
    }
  }

  .fc .fc-col-header-cell {
    font-weight: lighter;
    font-size: ${({ theme }) => theme.fontSize.sm};
    border: none;
  }
`;
