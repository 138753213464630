import HomeCardLoader from 'features/app/components/Commons/Loaders/HomeCardLoader';
import { HomesList } from './Loader.styles';

const Loader = () => {
  const loadingHomes = {
    active: [1, 2, 3, 4, 5, 6],
  };
  return (
    <HomesList>
      {loadingHomes.active.map((item: any) => (
        <HomeCardLoader key={item.id} />
      ))}
    </HomesList>
  );
};

export default Loader;
