import styled from '@emotion/styled';

export const VendorInfoWrapper = styled.div`
  margin-bottom: 32px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const VendorLogo = styled.img`
  width: 49px;
  height: 49px;
  object-fit: cover;
  border-radius: 5px;
`;
