import styled from '@emotion/styled';

export const StatusFilterWrapper = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
`;

export const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    width: 23%;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  margin-left: 10px;
`;

export const DropArrowIconContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 90%;
  width: fit-content;
  height: fit-content;
  pointer-events: none;
`;
