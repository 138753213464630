import { IconPropTypes } from './types';

const ChevronEndRightIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
    <path
      d="m8 17 5-5-5-5M16 7v10"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronEndRightIcon;
