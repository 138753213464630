/* eslint-disable no-underscore-dangle */
import { useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { centsToDollars } from 'helpers/centsToDollars';
import { slEvents } from 'features/app/constants/smartlookEvents';
import { logEventSL } from 'features/app/utils/smartlook';
import interactionPlugin from '@fullcalendar/interaction';
import {
  useWindowSize,
  useIsTablet,
  useIsMobile,
} from 'features/app/hooks/screen';
import { useTheme } from '@emotion/react';
import FreePositionModal from 'features/app/components/Commons/Modals/FreePositionModal/FreePositionModal';
import { CalendarWrapper } from './Calendar.styles';
import Modal from '../../../Commons/Modals/Modal';
import { EventProps } from './types';
import MileStoneDetail from './Components/MilestoneDetails/MilestoneDetail';
import TasksDetail from './Components/TasksDetails/TasksDetail';

interface ModalProps {
  clientX: number;
  clientY: number;
  eventData: any;
}

interface DayModalProps {
  open: boolean;
  date: Date;
  clientX: number;
  clientY: number;
  dateEvents: EventProps[];
}

interface CalendarProps {
  events: EventProps[];
  showEventDetail?: boolean;
  showDayDetail?: boolean;
}

const Calendar: React.FunctionComponent<CalendarProps> = ({
  events,
  showDayDetail,
  showEventDetail,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const windowSize = useWindowSize();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [modal, setModal] = useState<ModalProps>({
    clientX: 0,
    clientY: 0,
    eventData: {},
  });
  const [dayInfoModal, setDayInfoModal] = useState<DayModalProps>({
    open: false,
    date: new Date(),
    clientX: 0,
    clientY: 0,
    dateEvents: [],
  });

  const getEventWithColor = () => {
    const coloredEvents: any = [];
    events.forEach((event) => {
      if (event.done) {
        coloredEvents.push({
          ...event,
          title: `${event.name} - ${centsToDollars(event.totalCentCost)}`,
          color: theme.color.calendar.lightGreen,
          borderColor: theme.color.calendar.darkGreen,
          textColor: theme.color.black,
        });
      } else {
        coloredEvents.push({
          ...event,
          title: `${event.name} - ${centsToDollars(event.totalCentCost)}`,
          color: theme.color.calendar.lightPurple,
          borderColor: theme.color.calendar.darkPurple,
          textColor: theme.color.black,
        });
      }
    });
    return coloredEvents;
  };

  const onEventClick = (event: any) => {
    let eventData = event.event._def;
    eventData = events.find(
      (item) => item.id === parseInt(eventData.publicId, 10)
    );
    logEventSL(slEvents.homeMilestone, eventData);
    const client = event.jsEvent;
    if (windowSize) {
      setModal({
        clientX:
          client.clientX + 220 > windowSize.width
            ? windowSize.width - 220
            : client.clientX,
        clientY:
          client.clientY + 300 > windowSize.height
            ? windowSize.height - 300
            : client.clientY,
        eventData,
      });
    } else {
      setModal({ clientX: client.clientX, clientY: client.clientY, eventData });
    }
    if (showEventDetail) {
      setOpenModal(true);
    }
  };

  const onDateClick = (event: any) => {
    const { date, jsEvent } = event;
    const dateEvents: EventProps[] = [];
    logEventSL(slEvents.homeDate, date);
    events.forEach((item) => {
      if (new Date(item.start) <= date && new Date(item.end) >= date) {
        dateEvents.push(item);
      }
    });

    if (dateEvents.length > 0 && showDayDetail) {
      if (windowSize && jsEvent.clientX + 120 > windowSize.width) {
        setDayInfoModal({
          open: true,
          date,
          clientX: jsEvent.clientX - 120,
          clientY: jsEvent.clientY,
          dateEvents,
        });
      } else {
        setDayInfoModal({
          open: true,
          date,
          clientX: jsEvent.clientX,
          clientY: jsEvent.clientY,
          dateEvents,
        });
      }
    }
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setModal({ clientX: 0, clientY: 0, eventData: {} });
  };

  const onCloseDayModal = () => {
    setDayInfoModal({
      open: false,
      date: new Date(),
      clientX: 0,
      clientY: 0,
      dateEvents: [],
    });
  };

  return (
    <CalendarWrapper>
      <FullCalendar
        events={getEventWithColor()}
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        height="100vh"
        eventClick={onEventClick}
        dateClick={onDateClick}
      />
      {openModal &&
        (isMobile || isTablet ? (
          <Modal setVisibility={onCloseModal}>
            <MileStoneDetail milestone={modal.eventData} />
          </Modal>
        ) : (
          <FreePositionModal
            clientX={modal.clientX}
            clientY={modal.clientY}
            setVisibility={onCloseModal}
            blur={!!isMobile}
          >
            <MileStoneDetail milestone={modal.eventData} />
          </FreePositionModal>
        ))}
      {dayInfoModal.open &&
        (isMobile || isTablet ? (
          <Modal setVisibility={onCloseDayModal}>
            <TasksDetail
              events={dayInfoModal.dateEvents}
              date={dayInfoModal.date}
            />
          </Modal>
        ) : (
          <FreePositionModal
            clientX={dayInfoModal.clientX}
            clientY={dayInfoModal.clientY}
            setVisibility={onCloseDayModal}
            blur={!!isMobile}
          >
            <TasksDetail
              events={dayInfoModal.dateEvents}
              date={dayInfoModal.date}
            />
          </FreePositionModal>
        ))}
    </CalendarWrapper>
  );
};

export default Calendar;
