import styled from '@emotion/styled';

interface FilterContentProp {
  fixed?: boolean;
}

interface WrapperProps {
  open?: boolean;
}

export const FilterHeadMobile = styled.div<WrapperProps>`
  unset: all;
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  border-bottom: 2px solid
    ${({ theme, open }) => (open ? theme.color.table.border : 'transparent')};
  padding: 10px;
  background-color: ${({ theme }) => theme.color.gray300};
  justify-content: space-between;
  &:focus {
    outline: none;
  }
`;

export const FilterColumnWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const FiltersWrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 80px;
  padding: 0 15px;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.gray300};
  z-index: 2px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column;
    height: 100%;
    display: ${({ open }) => (open ? 'flex' : 'none')};
    align-items: center;
    padding: 10px 15px;
  }
`;

export const FilterContent = styled.div<FilterContentProp>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${({ fixed }) => (fixed ? 150 : 300)}px;
  margin-right: 10px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    margin-right: 0;
    margin-bottom: 10px;
    max-width: none;
  }
`;

export const ClearButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.font};
  z-index: 1;
  &:focus {
    outline: none;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    height: 30px;
    align-self: center;
    width: 100%;
    border-width: 1px;
    border-color: ${({ theme }) => theme.color.black};
    color: ${({ theme }) => theme.color.black};
    border-radius: ${({ theme }) => theme.borderRadius.lg};
  }
`;

export const ChipsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: ${({ theme }) => theme.color.gray300};
  padding: 10px;
  align-items: center;
`;

export const ChipsSubWrapper = styled.div`
  display: flex;
  overflow-x: auto;
  width: auto;
`;

export const SelectedFilterWrapper = styled.div`
  display: flex;
`;

export const TextWrapper = styled.div`
  margin-right: 10px;
  margin-left: 8px; ;
`;
