import { useTheme } from '@emotion/react';
import SlidersIcon from 'assets/svgs/SlidersIcon';
import { useIsMobile } from 'features/app/hooks/screen';
import {
  CLEAR_ALL_VALUES,
  OnChageLogItems,
} from 'features/app/store/projectLog/projectLogActions';
import { getHomeIdPath } from 'helpers/path';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { WSpacer } from '../../../../../Commons/Spacer.styles';
import { TextBase } from '../../../../../Commons/Text/Text';
import Filter from './Filter';
import {
  ClearButton,
  FilterColumnWrapper,
  FilterHeadMobile,
} from './Filter.styles';

interface FilterProps {
  page: number | undefined;
  pageLimit: number | undefined;
}

const Index: React.FC<FilterProps> = ({ page, pageLimit }) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const location = useLocation();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const { dates, search, multiselect, groupBy } = useSelector(
    (store: RootStateOrAny) => store.projectLog
  );
  const dispatch = useDispatch();
  const searchIsApplied = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    if (search.length > 0) {
      return 1;
    }
    return 0;
  };

  const datesIsApplied = () => {
    if (dates && dates[0] && dates[1]) {
      return 1;
    }
    return 0;
  };

  const ClearFilters = () => {
    if (
      multiselect.length !== 0 ||
      dates.length !== 0 ||
      search.length !== 0 ||
      groupBy.length !== 0
    ) {
      dispatch(CLEAR_ALL_VALUES());
      dispatch(
        OnChageLogItems({
          id: getHomeIdPath(location),
          body: {
            token: data.token,
            startDate: undefined,
            endDate: undefined,
            page,
            limit: pageLimit,
            name: '',
            status: [],
            group_by: '',
          },
        })
      );
    }
  };

  return isMobile ? (
    <>
      <FilterHeadMobile open={open}>
        <FilterColumnWrapper onClick={() => setOpen(!open)}>
          <SlidersIcon width={20} height={20} color={theme.color.font} />
          <WSpacer width={10} />
          <TextBase>
            <FormattedMessage id="filter.title" />
          </TextBase>
        </FilterColumnWrapper>
        {datesIsApplied() +
          multiselect.length +
          searchIsApplied() +
          groupBy.length !==
          0 && (
          <FilterColumnWrapper>
            <TextBase fullWidth>
              {datesIsApplied() +
                multiselect.length +
                searchIsApplied() +
                groupBy.length}{' '}
              applied
            </TextBase>
            <ClearButton onClick={ClearFilters}>
              <FormattedMessage id="filter.clearFilterMobile" />
            </ClearButton>
          </FilterColumnWrapper>
        )}
      </FilterHeadMobile>
      <Filter page={page} pageLimit={pageLimit} open={open} />
    </>
  ) : (
    <Filter page={page} pageLimit={pageLimit} />
  );
};

export default Index;
