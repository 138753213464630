import { useTheme } from '@emotion/react';
import { DownChevronGlyph, UpChevronGlyph } from 'assets/glyphs';
import { useState } from 'react';
import { TextSm } from 'features/app/components/Commons/Text/Text';
import { useIntl } from 'react-intl';
import { useIsMobile } from 'features/app/hooks/screen';
import {
  Select,
  OptionList,
  OptionItem,
  SelectWrapper,
  Background,
} from './Dropdown.styles';
import { WSpacer } from '../../Spacer.styles';

interface OptionsProps {
  id: string;
  name: string;
}

interface DropdownProps {
  options: OptionsProps[];
  placeholder?: string;
  color?: string;
  backgroundColor?: string;
  borderRadius?: string;
  width?: string;
  arrowSize?: number;
  bold?: boolean;
  onChange: (value: string) => void;
  preValue?: {
    id: number;
    name: string;
  };
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  placeholder,
  color,
  backgroundColor,
  borderRadius,
  width,
  bold,
  arrowSize,
  onChange,
  preValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState({
    id: preValue?.id || '',
    name: preValue?.name || '',
  });
  const isMobile = useIsMobile();
  const theme = useTheme();
  const intl = useIntl();

  const onOptionChange = (option: OptionsProps) => {
    setIsOpen(false);
    onChange(option.id);
    setSelected(option);
  };

  return (
    <>
      {isOpen && <Background onClick={() => setIsOpen(false)} />}
      <SelectWrapper width={width}>
        <Select
          onClick={() => setIsOpen(!isOpen)}
          isMobile={!!isMobile}
          color={backgroundColor}
          borderRadius={borderRadius}
        >
          <TextSm
            weight={bold ? theme.fontWeight.bold : theme.fontWeight.regular}
            color={color || theme.color.font}
          >
            {selected.name === ''
              ? intl.formatMessage({ id: placeholder || 'common.selectOption' })
              : selected.name}
          </TextSm>
          <WSpacer width={10} />
          {isOpen ? (
            <UpChevronGlyph
              color={color || theme.color.icons}
              size={arrowSize || undefined}
            />
          ) : (
            <DownChevronGlyph
              color={color || theme.color.icons}
              size={arrowSize || undefined}
            />
          )}
        </Select>
        {isOpen && (
          <OptionList borderRadius={borderRadius}>
            {options.map(({ id, name }) => (
              <OptionItem
                key={id}
                onClick={() => onOptionChange({ id, name })}
                isMobile={!!isMobile}
              >
                {name}
              </OptionItem>
            ))}
          </OptionList>
        )}
      </SelectWrapper>
    </>
  );
};

export default Dropdown;
