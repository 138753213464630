import { HomeFiltersActions, HOME_FILTERS_TYPE } from './homeFiltersType';

const initialState: any = {
  showFilters: false,
  zipCitySelect: '',
  zipCitySearchVal: '',
  status: [],
  sortBy: 'None',
  rightNavBarSelection: '',
};

const HomeFiltersReducer = (
  state = initialState,
  action: HomeFiltersActions
) => {
  switch (action.type) {
    case HOME_FILTERS_TYPE.SELECT_SORT_BY:
      return {
        ...state,
        sortBy: action.payload,
      };
    case HOME_FILTERS_TYPE.ADD_STATUS:
      return {
        ...state,
        status: state.status.concat([action.payload]),
      };
    case HOME_FILTERS_TYPE.SET_ZIP_CITY:
      return {
        ...state,
        zipCitySelect: action.payload,
      };
    case HOME_FILTERS_TYPE.SET_ZIP_CITY_SEARCH_VAL:
      return {
        ...state,
        zipCitySearchVal: action.payload,
      };
    case HOME_FILTERS_TYPE.REMOVE_STATUS:
      return {
        ...state,
        status: [
          ...state.status.filter((item: string) => item !== action.payload),
        ],
      };
    case HOME_FILTERS_TYPE.SHOW_FILTERS:
      return {
        ...state,
        showFilters: action.payload,
      };
    case HOME_FILTERS_TYPE.CLEAR_STATUS:
      return {
        ...state,
        status: [],
      };
    case HOME_FILTERS_TYPE.SET_RIGHT_NAVBAR_FILTER:
      return {
        ...state,
        rightNavBarSelection: action.payload,
      };
    case HOME_FILTERS_TYPE.CLEAR_ALL_FILTERS:
      return {
        ...state,
        zipCitySelect: '',
        zipCitySearchVal: '',
        status: [action.payload],
        sortBy: 'None',
      };
    default:
      return state;
  }
};

export default HomeFiltersReducer;
