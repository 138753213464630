import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const HttpClient = () => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      accept: '*/*',
    },
    params: {},
  });
  return client;
};

export default applyCaseMiddleware(HttpClient());
