import TimeMoneyIcon from 'assets/svgs/TimeMoneyIcon';

import { usePaymentsSummary } from 'features/app/hooks/data';
import { RootStateOrAny, useSelector } from 'react-redux';
import { CardWrapperRightNavBar } from '../BonusCard/BonusCard.styles';
import SideMenuCard from '../SideMenuCard';

interface PendingPaymentCardProps {
  onClick: () => void;
  isSelected: boolean;
  onCancel: () => void;
}

const PendingPaymentCard: React.FC<PendingPaymentCardProps> = ({
  onClick,
  isSelected,
  onCancel,
}) => {
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  const paymentSummary = usePaymentsSummary(data?.token);

  return (
    <CardWrapperRightNavBar isSelected={isSelected}>
      <SideMenuCard
        title="Pending payment"
        description="Money you will receive by Wednesday"
        amount={paymentSummary.data?.pendingPaymentsAmount}
        icon={<TimeMoneyIcon width={40} height={42} />}
        onClick={onClick}
        isSelected={isSelected}
        onCancel={onCancel}
      />
    </CardWrapperRightNavBar>
  );
};

export default PendingPaymentCard;
