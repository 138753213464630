const translations = {
  common: {
    accountSettings: 'Account settings',
    alreadyHaveAnAccount: 'Already have an account?',
    brandName: 'SFR3',
    changePasswordSuccess: 'Your password has been changed successfully',
    confirm: 'Submit',
    confirmPassword: 'Confirm password',
    createAccount: 'Create one here',
    currentPassword: 'Current Password',
    dontHaveAccountQuestion: `Don't have an account?`,
    earned: 'Earned',
    email: 'Email',
    errorBoundaryMessage: 'An error occurred, please try again',
    errorMessage: 'There was an error',
    firstName: 'First Name',
    forgotPassword: 'Forgot password?',
    forgotPasswordChange: 'Step 3: Enter your new password',
    forgotPasswordEmailResend: `Didn't get a code?`,
    forgotPasswordEmailSent:
      'Step 2: Enter the verification code we just sent to your email address',
    forgotPasswordLegend: `Step 1: Enter your email and we'll help you reset your password`,
    goBackHome: 'Go to home',
    invalidEmail: 'Invalid email',
    lastName: 'Last Name',
    lengthToken: 'Must have six digits',
    locale: 'Language',
    newPassword: 'New Password',
    next: 'Next',
    pageNotFoundLegend: 'Page not found',
    pageNotFoundTitle: '404',
    pageNotFoundSubtitle:
      'The page you are looking for does not exist or an other error occurred. Go back to home page.',
    password: 'Password',
    passwordsDontMatch: `The passwords don't match`,
    reportError: 'Report error',
    required: 'Required',
    resetPassword: 'Reset Password',
    resetPasswordSuccess: 'Your password has been updated successfully',
    selectLanguage: 'Select language',
    selectOption: 'Select an option',
    shortPassword: 'Password too short, minimum length is 8 characters',
    signIn: 'Sign in',
    signOut: 'Sign out',
    signUp: 'Sign up',
    token: 'Code',
    updatePassword: 'Update Password',
    updateSettings: 'Update Settings',
    updateSettingsSuccess: 'Your settings have been updated successfully',
    searchPlaceholder: 'Search',
    status: 'Status',
    estimated: 'Estimated',
    completed: 'Completed',
    inProgress: 'In Progress',
    total: 'Total',
    period: 'Period',
    address: 'Address',
    description: 'Description',
    welcome: 'Welcome to the SFR3 Vendor Portal',
    youNeedLink:
      'You will receive a link to access. If you need help, contact us at ',
    helpMail: 'vendors@sfr3.com',
    summary: 'Summary',
    calendar: 'Current & Upcoming Work',
    homes: 'Homes',
    noDate: 'No date',
    noPhone: 'No phone',
    noEmail: 'No email',
    today: 'Today',
    milestones: 'Milestones',
    exportAs: 'Export as',
  },
  calendar: {
    daysToCompletion: 'Days to completion',
    viewMore: 'View more',
    dayTask: '{day} Tasks',
    activeHomes: 'Active Homes',
    milestonesOnGoing: 'Milestones on going',
    futureHomes: 'Future Homes',
    futureTasks: 'Future Tasks',
    totalProjects: 'Total Projects',
    totalTasks: 'Total Tasks',
    onGoing: 'On Going',
    viewSummary: 'View Summary',
  },
  contact: {
    shortRoleRm: 'PM',
    shortRoleMd: 'RD',
    shortRoleRd: 'RD',
    roleRm: 'Project Manager',
    roleMd: 'Renovation Director',
    roleRd: 'Renovation Director',
    title: 'Contact',
  },
  homes: {
    title: 'Homes',
    completed: 'Completed',
    active: 'Active',
    all: 'All',
    noCompleted: 'No homes completed',
    noActive: 'No homes active',
    noCompletedSub: 'You will see your finished homes here',
    totalScope: 'Total scope for this home',
  },
  milestones: {
    text: 'Line items approved',
    button: 'View details',
  },
  filter: {
    title: 'Filters',
    selectDate: 'Select date',
    search: 'Search',
    status: 'Status',
    select: 'Select',
    clearFilters: 'Clear filters',
    period: 'Period',
    selectedFilters: 'Selected filters',
    clearFilterMobile: 'Clear',
    groupBy: 'Group By',
    periodLabel: 'Payment Period',
  },
  project: {
    assignedJobsMilestone: 'of assigned jobs',
    completedMilestones: 'Milestones',
    forThisHome: 'For this home',
    futureWork: 'Future work',
    milestonesWithMonth: 'Milestones ({date})',
    projectEta: 'Project ETA',
    pendingPayment: 'Pending payment',
    pending: 'Pending',
    thisMonth: 'This month',
    viewPayLog: 'View Pay Log',
    vendorEta: 'Vendor ETA',
  },
  paylog: {
    title: 'Project Log',
    task: 'Task',
    amount: 'Amount',
    status: 'Status',
    markedComplete: 'Completed',
    paymentId: 'ID',
    paymentDate: 'Payment',
    workOrderId: 'Work Order #',
    woId: 'WO #',
    invoiceId: 'Invoice #',
    milestoneName: 'Milestone',
    lineItem: 'Line Item',
    pending: 'Pending',
    searchByTask: 'Search by Task',
    searchBy: 'Search by Task or Home',
    selectAddress: 'Select Address',
    selectPeriod: 'Select Period',
    empty: 'No items have been found.',
    linesPerPage: 'Lines per page:',
    info: '+Info',
    infoTitle: 'Additional information',
    infoDescription: 'Description',
    infoLocation: 'Location',
    of: 'of',
    statusNames: {
      cancelled: 'Cancelled',
      paymentProcessing: 'Payment processing',
      workRemaining: 'Work remaining',
      paid: 'Paid',
      payment: 'Payments',
      workorder: 'Work Order',
      received: 'Received',
      awaitingPayment: 'Awaiting payment',
      approvalNeeded: 'Approval Needed',
    },
  },
  paylogCard: {
    title: 'Payment Info',
    paymentProcessing: 'Payment Processing',
    workRemaining: 'Work Remaining',
    paid: 'Paid',
  },
  search: {
    notFound: 'No results found.',
    placeholder: 'Search',
    emptyState: 'Try searching for homes addresses.',
  },
  summary: {
    currentProjects: 'Current projects',
    nextPayment: 'Next payment: {date}',
    viewPaylog: 'View Project Log',
    viewStatus: 'View Project Details',
    viewMore: 'View more',
  },
  broken: {
    invalid: 'Invalid Link',
    message:
      'This link may be expired. Please contact the administrator or refresh the page.',
    reload: 'Reload Page',
    goHome: 'Go back home',
    woErrorTitle: 'This link is not working',
    woErrorText: `We're sorry for the inconvenience, please search your email for the details to this work order.`,
  },
  login: {
    welcome: 'Welcome to',
    SFR3: 'SFR3 Vendors Portal',
    loginText: 'Login filling the',
    below: 'form bellow',
    emailOrPhone: 'Email or phone number',
    continue: 'Continue',
  },
  verificationLogin: {
    welcome: 'Your verification code',
    sent: 'has been sent!',
    please: 'Please, write below the verification',
    code: 'code that you received via SMS',
  },
};

export default translations;
