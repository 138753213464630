import { getVendorEta } from 'features/app/api/homes';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getVendorEtaProps,
  ON_LOADING_VENDOR_ETA,
  ON_SET_VENDOR_ETA,
  VendorEtaData,
} from './vendorEtaActions';
import { VENDOR_ETA_TYPES } from './vendorEtaTypes';

function* getVendorEtaApi({ payload }: VendorEtaData | any) {
  try {
    yield put(ON_LOADING_VENDOR_ETA(true));
    const data: getVendorEtaProps = yield call(
      getVendorEta,
      payload.id,
      payload.token
    );
    yield put(ON_SET_VENDOR_ETA(data));
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  } finally {
    yield put(ON_LOADING_VENDOR_ETA(false));
  }
}

export default function* getVendorEtaDataSagas(): any {
  yield all([
    yield takeLatest(VENDOR_ETA_TYPES.ON_GET_VENDOR_ETA, getVendorEtaApi),
  ]);
}
