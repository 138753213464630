import { Text } from 'features/app/components/Commons/Text/Text';
import { TotalOfPagesWrapper } from './TotalOfPages.styles';

export interface TotalOfPagesProps {
  totalOfPages: string | number;
}

function TotalOfPages({ totalOfPages }: TotalOfPagesProps) {
  return (
    <TotalOfPagesWrapper>
      <Text fontSize="14" weight="300" color="#767676">
        of {totalOfPages} pages
      </Text>
    </TotalOfPagesWrapper>
  );
}

export default TotalOfPages;
