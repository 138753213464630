import { useTheme } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useIsMobile } from 'features/app/hooks/screen';
import { RootStateOrAny, useSelector } from 'react-redux';
import EtaLoader from '../../../Commons/Loaders/EtaLoader';
import TitleLoader from '../../../Commons/Loaders/TitleLoader/TitleLoader';
import { Headline, TextSm } from '../../../Commons/Text/Text';
import {
  Wrapper,
  Eta,
  EtasWrapper,
  EtasContactWrapper,
  HeadlineWrapper,
  DivisorLine,
} from './ProjectLogHeader.styles';
import { HSpacer } from '../../../Commons/Spacer.styles';
import Contacts from './Components/ContactInformation/Contacts';
import { formatContacts, FormatDate } from './helper';

interface ProjectLogHeaderProps {
  vendorEta: any;
  loading?: boolean;
  error?: boolean;
}

const ProjectLogHeader: React.FC<ProjectLogHeaderProps> = ({ vendorEta }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const {
    dataHomeInformation: { property },
    loading,
  } = useSelector((store: RootStateOrAny) => store.homeById);
  return (
    <Wrapper>
      <HeadlineWrapper>
        <Headline fontSize="22">
          {loading ? <TitleLoader /> : property?.address}
        </Headline>
      </HeadlineWrapper>
      {isMobile && (
        <>
          <DivisorLine />
          <HSpacer />
        </>
      )}
      <EtasContactWrapper>
        <EtasWrapper>
          {vendorEta.loading ? (
            <EtaLoader />
          ) : (
            <Eta isVendorEta>
              <TextSm color={theme.color.white} weight={theme.fontWeight.bold}>
                {FormatDate(vendorEta?.eta)}
              </TextSm>
              <TextSm color={theme.color.white}>
                <FormattedMessage id="project.vendorEta" />
              </TextSm>
            </Eta>
          )}
          {loading ? (
            <EtaLoader />
          ) : (
            <Eta>
              <TextSm weight={theme.fontWeight.bold}>
                {FormatDate(property?.projectEta)}
              </TextSm>
              <TextSm>
                <FormattedMessage id="project.projectEta" />
              </TextSm>
            </Eta>
          )}
        </EtasWrapper>
        <Contacts loading={loading} contacts={formatContacts(property)} />
      </EtasContactWrapper>
    </Wrapper>
  );
};

export default ProjectLogHeader;
