import { marketChecker } from 'helpers/marketChecker';
import { CALENDAR_KEY, SUMMARY_KEY } from '../constants/navigation';
import experiment from './experimentAmplitude';
import { Market } from './types/vendor';

export const navigationFlags = async (userId: number, markets: Market[]) => {
  const isProd = process.env.REACT_APP_ENV === 'production';
  const experimentAmplitude = await experiment(userId);
  const summaryFlag = await experimentAmplitude.variant(
    isProd ? `${SUMMARY_KEY}-prod` : `${SUMMARY_KEY}-stg`
  );

  const calendarFlag = await experimentAmplitude.variant(
    isProd ? `${CALENDAR_KEY}-prod` : `${CALENDAR_KEY}-stg`
  );

  return {
    [SUMMARY_KEY]: marketChecker(markets, summaryFlag?.payload?.markets),
    [CALENDAR_KEY]: marketChecker(markets, calendarFlag?.payload?.markets),
    response: true,
  };
};
