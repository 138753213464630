import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';

const RenoBackend = () => {
  const client = axios.create({
    baseURL: 'https://planner.sfr3.com/',
    headers: {
      'Content-Type': 'application/json',
      accept: '*/*',
    },
    params: {},
  });
  return client;
};

export default applyCaseMiddleware(RenoBackend());
