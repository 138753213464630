import Alert from 'assets/svgs/Alert';
import Percentage from 'features/app/components/Commons/Loader';
import Tooltip from 'features/app/components/Commons/Tooltip';
import AdressTooltip from 'features/app/components/Commons/Tooltip/TooltipContent/AddressContent/AdressTooltip';
import WarningTooltip from 'features/app/components/Commons/Tooltip/TooltipContent/WarningContent/WarningTooltip';
import Progress from 'features/app/components/HomeView/Components/HomesCardsList/Components/HomeCard/Components/Progress';
import TotalScope from 'features/app/components/HomeView/Components/HomesCardsList/Components/HomeCard/Components/TotalScope';
import { HOMES_PATH } from 'features/app/constants/navigation';
import { useIsDesktop } from 'features/app/hooks/screen';
import { CLEAR_STATUS } from 'features/app/store/homesFilters/homeFiltersActions';
import { useEffect, useRef, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Text } from '../../../../../../../Commons/Text/Text';
import Card from '../../Card';
import { getColorOfProgressBar } from './helper';
import {
  FooterContainer,
  HomeName,
  HomeRow,
  HomeRow2,
  HomesItem,
  LoaderContainer,
  StarIconContainer,
} from './HomeCard.styles';
import { HomeItemProps } from './HomeCardTypes';

const HomeCard: React.FC<HomeItemProps> = ({
  address,
  city,
  id,
  lineItemsScope,
  moneyScope,
  sfr3Id,
  state,
  zipcode,
  infoCard,
  warnings,
}) => {
  const dispatch = useDispatch();
  const { rightNavBarSelection } = useSelector(
    (store: RootStateOrAny) => store.homeFilters
  );
  const isDesktop = useIsDesktop();
  const warningRef = useRef<any>();
  const [clickedOutSide, setClickedOutSide] = useState(true);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (warningRef.current && !warningRef.current.contains(event.target)) {
        setClickedOutSide(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      setClickedOutSide(true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [clickedOutSide]);

  return (
    <>
      <Card textOverflow="hidden">
        <HomeRow>
          <HomesItem
            to={`${HOMES_PATH}/${id}`}
            onClick={() => dispatch(CLEAR_STATUS())}
          >
            <HomeName data-for={address} data-tip={address}>
              <Text weight="600">{address}</Text>
            </HomeName>
          </HomesItem>
          {warnings.length > 0 && (
            <StarIconContainer
              data-for={`${id}description`}
              data-tip={`${id}description`}
              ref={warningRef}
            >
              <Alert color="#FAC661" width={20} height={19} />
            </StarIconContainer>
          )}
        </HomeRow>
        <HomeRow2>
          <Text color="#767676" weight="400" fontSize="12">
            {city}, {state}. {zipcode}
          </Text>
        </HomeRow2>
        <LoaderContainer>
          <Percentage
            percentage={
              lineItemsScope.totalScope !== 0
                ? (
                    (lineItemsScope.approved / lineItemsScope.totalScope) *
                    100
                  ).toFixed(0)
                : 0
            }
            color={getColorOfProgressBar(
              lineItemsScope,
              infoCard,
              warnings.length > 0
            )}
          />
        </LoaderContainer>
        <FooterContainer>
          <TotalScope
            rightNavBarSelection={rightNavBarSelection}
            total={infoCard.total}
            lineItemsScope={lineItemsScope}
            infoCard={infoCard}
          />
          <Progress
            rightNavBarSelection={rightNavBarSelection}
            scope={lineItemsScope}
            lineItemsScope={lineItemsScope}
            infoCard={infoCard}
          />
        </FooterContainer>
        <Tooltip id={address} Content={<AdressTooltip text={address} />} />
        {clickedOutSide !== false && (
          <Tooltip
            id={`${id}description`}
            clickeable={!isDesktop}
            ref={warningRef}
            Content={
              <WarningTooltip
                text={warnings.length > 0 ? warnings[0] : ''}
                ref={warningRef}
              />
            }
          />
        )}
      </Card>
    </>
  );
};

export default HomeCard;
