import { useTheme } from '@emotion/react';
import { ClearGlyph, DownArrowGlyph, DownChevronGlyph } from 'assets/glyphs';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { format } from 'date-fns';
import {
  HomesPaymentsProp,
  ItemProp,
  PaymentItemProp,
} from 'features/app/utils/types/payments';
import { centsToDollars } from 'helpers/centsToDollars';
import { HomeProp } from 'features/app/utils/types/homes';
import { useIsMobile } from 'features/app/hooks/screen';
import { isEqual } from 'lodash';
import DatePicker from 'react-datepicker';
import ResultsIcon from 'assets/svgs/Results';
import { useSummaryPaylogData } from 'features/app/hooks/data';
import { DatePickerWrapper } from 'features/app/components/ProjectLogView/Components/ProjectLogData/Components/Log/Log.styles';
import { COMPLETED_PROJECT_LOG } from 'features/app/constants/lineItemStatus';
import { Title, TextBase, TextSm } from '../../../Commons/Text/Text';
import {
  HeaderWrapper,
  HomeCard,
  HomeCardHeader,
  SummaryThisMonthContainer,
  CardWrapper,
  AmountWrapper,
  ArrowWrapper,
  TableWrapper,
  RightWrapper,
  DropdownWrapper,
  DatePickerInput,
  ClearButton,
} from './SummaryThisMonth.styles';
import Table from '../Table';
import Search from '../../../Commons/Inputs/Search';
import { HSpacer, WSpacer } from '../../../Commons/Spacer.styles';
import { groupHomes } from './helper';
import { EmptyTableResults } from '../Table/Table.styles';
import { orderPayments } from '../common/helpers';
import { THIS_MONTH } from '../../SummaryView';
import Card from '../../../HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import TableLoader from '../../../Commons/Loaders/TableLoader';

interface SummaryThisMonthProps {
  addresses: HomeProp[];
  start?: Date;
  end?: Date;
  from?: String;
}

const SummaryThisMonth: React.FC<SummaryThisMonthProps> = ({
  addresses,
  start,
  end,
  from,
}) => {
  const theme = useTheme();
  const [refetch, setRefetch] = useState(false);
  const [openPicker, setOpenPicker] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date | undefined>(
    start || new Date()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(end || undefined);
  const [searchResults, setSearchResults] = useState<
    HomesPaymentsProp[] | undefined
  >();
  const isMobile = useIsMobile();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const [payments, setPayments] = useState<HomesPaymentsProp[] | undefined>();
  const paylog = useSummaryPaylogData(data?.token, startDate, endDate, refetch);
  const [open, setOpen] = useState(-1);

  useEffect(() => {
    if (refetch) {
      setRefetch(false);
      setPayments(undefined);
    }
  }, [refetch]);

  useEffect(() => {
    if (endDate && startDate) setOpenPicker(false);
  }, [endDate, startDate]);

  useEffect(() => {
    if (!paylog.loading && paylog.data && !payments) {
      setPayments([...groupHomes(addresses, paylog.data)]);
    }
  }, [paylog, addresses, payments]);

  useEffect(() => {
    if (paylog.data) {
      if (!isEqual(groupHomes(addresses, paylog.data), payments)) {
        setPayments([...groupHomes(addresses, paylog.data)]);
      }
    }
  }, [paylog, addresses, payments]);

  const onHomeClick = (id: number) => {
    if (open === id) {
      setOpen(-1);
    } else {
      setOpen(id);
    }
  };

  const getLineItems = (id: number) => {
    const addr = payments?.find((home: HomesPaymentsProp) => home.id === id);
    return orderPayments(addr?.payments);
  };

  const onSearchTask = (value: string) => {
    if (payments && payments?.length > 0) {
      const regex = new RegExp(value.toLowerCase(), 'g');
      const filtered: HomesPaymentsProp[] = [];
      payments.forEach((home: HomesPaymentsProp) => {
        let result: ItemProp[];
        if (home.payments.length > 0) {
          result = home.payments.filter((item: ItemProp) =>
            item.lineItems.find((e: string) => e?.toLowerCase()?.match(regex))
          );
          if (result) {
            filtered.push({ ...home, payments: result });
          }
        }
      });
      setSearchResults([...filtered]);
    }
  };

  const onClearSearch = () => {
    setSearchResults(undefined);
  };

  const getFormattedDate = () => {
    if (startDate && endDate) {
      return `${format(startDate, 'MMM d')} - ${format(endDate, 'MMM d')}`;
    }
    return <FormattedMessage id="paylog.selectPeriod" />;
  };

  const onClearPeriod = () => {
    setStartDate(new Date());
    setEndDate(undefined);
    setRefetch(true);
  };

  const onPeriodChange = (dates: Date[] | null) => {
    if (dates) {
      const [startD, endD] = dates;
      setStartDate(startD);
      setEndDate(endD);
      if (startD && endD) {
        setRefetch(true);
      }
    }
  };

  const onClickOutsidePicker = () => {
    if (openPicker) setOpenPicker(false);
  };

  const homesWithPayments = () => {
    if (searchResults) {
      return searchResults?.filter(
        (x: HomesPaymentsProp) => x.payments.length > 0
      );
    }
    return payments?.filter((x: HomesPaymentsProp) => x.payments.length > 0);
  };

  const getTotalPaids = (id: Number) => {
    const allLineItems = payments?.find(
      (homeSearch: HomesPaymentsProp) => homeSearch.id === id
    );
    let sum = 0;
    allLineItems?.payments.forEach((itemPayment: PaymentItemProp) => {
      if (itemPayment.status === COMPLETED_PROJECT_LOG) {
        sum += itemPayment.amountCents;
      }
    });
    return sum;
  };

  return (
    <SummaryThisMonthContainer onClick={() => onClickOutsidePicker()}>
      <HeaderWrapper>
        <Title>
          <FormattedMessage id="paylog.title" />
        </Title>
        {isMobile && <HSpacer />}
        <Search
          placeholder="paylog.searchByTask"
          onChange={onSearchTask}
          onClear={onClearSearch}
        />
      </HeaderWrapper>
      <DropdownWrapper>
        {!isMobile && (
          <TextSm overflow="unset">
            <FormattedMessage id="common.period" />:
          </TextSm>
        )}
        <DatePickerInput onClick={() => setOpenPicker(!openPicker)}>
          <TextSm weight={theme.fontWeight.bold} color={theme.color.altFont}>
            {getFormattedDate()}
          </TextSm>
          <WSpacer width={5} />
          {endDate && (
            <ClearButton onClick={(e) => e.stopPropagation()}>
              <ClearGlyph
                onClick={onClearPeriod}
                color={theme.color.blue800}
                size={18}
                viewBoxWidth={32}
                viewBoxHeight={32}
              />
            </ClearButton>
          )}
          <DownChevronGlyph color={theme.color.altFont} />
        </DatePickerInput>
        {openPicker && (
          <DatePickerWrapper onClick={(e) => e.stopPropagation()}>
            <DatePicker
              selected={startDate}
              startDate={startDate}
              endDate={endDate}
              onChange={(dates: any) => onPeriodChange(dates)}
              selectsRange
              inline
            />
          </DatePickerWrapper>
        )}
      </DropdownWrapper>
      {paylog.loading && <TableLoader />}
      {!paylog.loading && homesWithPayments()?.length === 0 && (
        <EmptyTableResults>
          <ResultsIcon width="50px" height="50px" />
          <HSpacer />
          <TextSm color={theme.color.lightFont}>
            <FormattedMessage id="paylog.empty" />
          </TextSm>
        </EmptyTableResults>
      )}
      {!paylog.loading &&
        payments &&
        homesWithPayments()?.map((home) => (
          <CardWrapper key={home.id}>
            <Card>
              <HomeCard
                onClick={() => onHomeClick(home.id)}
                open={open === home.id}
              >
                <HomeCardHeader>
                  <TextBase weight={theme.fontWeight.bold} fullWidth>
                    {home.address}
                  </TextBase>
                  <RightWrapper>
                    <AmountWrapper>
                      <TextBase>
                        <FormattedMessage id="common.total" />
                      </TextBase>
                      <WSpacer width={10} />
                      <TextBase weight={theme.fontWeight.bold}>
                        {from === THIS_MONTH
                          ? centsToDollars(getTotalPaids(home.id))
                          : centsToDollars(home.total.cents)}
                      </TextBase>
                    </AmountWrapper>
                    <ArrowWrapper open={open === home.id}>
                      <DownArrowGlyph
                        color={theme.color.sideMenuFont}
                        size={12}
                        viewBoxHeight={24}
                        viewBoxWidth={24}
                      />
                    </ArrowWrapper>
                  </RightWrapper>
                </HomeCardHeader>
                {open === home.id && (
                  <TableWrapper onClick={(e) => e.stopPropagation()}>
                    {paylog.loading ? (
                      <TableLoader />
                    ) : (
                      <Table data={getLineItems(home.id)} />
                    )}
                  </TableWrapper>
                )}
              </HomeCard>
            </Card>
          </CardWrapper>
        ))}
    </SummaryThisMonthContainer>
  );
};

export default SummaryThisMonth;
