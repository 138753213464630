import {
  GetHomesFromHomeView,
  SET_PAGE_HOME,
} from 'features/app/store/listOfHomes/ListOfHomesActions';
import { sortByOptionsApi } from './SortByFilter/types';

export const helperFilterStatus = (
  e: string,
  selectedItems: string[],
  options: string[]
) => {
  let auxArray = selectedItems;
  if (auxArray.indexOf(e) === -1) {
    auxArray.push(e);
    if (auxArray.indexOf(options[0]) !== -1) {
      auxArray = auxArray.filter((item: string) => item !== options[0]);
    }
    if (e === options[0]) {
      auxArray = [e];
    }
  } else if (auxArray.indexOf(e) !== -1) {
    auxArray = auxArray.filter((item: string) => item !== e);
    if (auxArray.length === 0) {
      auxArray.push(options[0]);
    }
  }
  return auxArray;
};

export const getBodyToDispatchStatusBy = (arrayOfStatus: string[]) => {
  const bodyToDispatch: any = {};
  const status =
    arrayOfStatus.filter((item: string) => item !== 'With warnings').length ===
      2 ||
    arrayOfStatus.filter((item: string) => item !== 'With warnings')[0] ===
      'All'
      ? null
      : arrayOfStatus.filter((item: string) => item !== 'With warnings')[0];
  const warning =
    arrayOfStatus.filter((item: string) => item === 'With warnings').length > 0;
  if (status) {
    bodyToDispatch.status = status === 'Completed' ? 'stopped' : 'active';
  }
  if (warning) {
    bodyToDispatch.warning = true;
  }
  return bodyToDispatch;
};

export const sortByField = (sortBy: string) => {
  if (sortBy === 'None') {
    return sortByOptionsApi.none;
  }
  if (sortBy === 'Most recent activity') {
    return sortByOptionsApi.mostRecentActivity;
  }
  if (sortBy === 'Largest Scope size first') {
    return sortByOptionsApi.largestScopeSize;
  }
  if (sortBy === 'Most Progress first') {
    return sortByOptionsApi.mostProgress;
  }
  return sortByOptionsApi.dueDate;
};

export const helperSelectFilter = (
  dispatch: any,
  status: string[],
  sortBy: string,
  paymentCriteria: string,
  token: string
) => {
  let bodyToDispatchStatus: any = {
    token,
    page: 1,
  };
  if (status) {
    bodyToDispatchStatus = { ...status, ...bodyToDispatchStatus };
  }
  if (sortBy !== sortByOptionsApi.none) {
    bodyToDispatchStatus.sortBy = sortBy;
  }
  if (paymentCriteria.length > 0) {
    bodyToDispatchStatus.payment_criteria = paymentCriteria;
  }
  dispatch(SET_PAGE_HOME(1));
  dispatch(GetHomesFromHomeView(bodyToDispatchStatus));
};
