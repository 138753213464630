import { FutureWorkPaymentsProps } from './futureWorkPaymentsActions';

export const FUTURE_WORK_PAYMENTS_TYPES = {
  GET_FUTURE_WORK_PAYMENTS: 'GET_FUTURE_WORK_PAYMENTS',
  SET_FUTURE_WORK_PAYMENTS: 'SET_FUTURE_WORK_PAYMENTS',
};

export type getFutureWorkPayments = {
  type: typeof FUTURE_WORK_PAYMENTS_TYPES.GET_FUTURE_WORK_PAYMENTS;
  payload: FutureWorkPaymentsProps;
};

export type setFutureWorkPayments = {
  type: typeof FUTURE_WORK_PAYMENTS_TYPES.SET_FUTURE_WORK_PAYMENTS;
  payload: setFutureWorkPayments;
};

export type futureWorkPaymentsActions =
  | getFutureWorkPayments
  | setFutureWorkPayments;
