import VerificationInput from 'react-verification-input';
import './VerificationCodeInput.css';

export interface VerificationCodeInputProps {
  loginWithCode: (value: string) => void;
  error: boolean;
  onChange: (value: string) => void;
}

const VerificationCodeInput = ({
  loginWithCode,
  error,
  onChange,
}: VerificationCodeInputProps) => {
  const completeVerificationCode = (e: any) => {
    onChange(e);
    if (e.length === 6) {
      loginWithCode(e);
    }
  };

  return (
    <VerificationInput
      onChange={completeVerificationCode}
      removeDefaultStyles
      classNames={{
        character: error ? 'character-wrong' : 'character',
        container: 'container',
      }}
    />
  );
};

export default VerificationCodeInput;
