import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

export const NoMatchWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  padding: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    flex-direction: column-reverse;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 500px;
  min-width: 200px;
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    align-items: center;
    text-align: center;
  }
`;

export const IconContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin-left: 20px;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    justify-content: center;
    margin-left: 0;
  }
`;

export const IconWrapper = styled.div`
  height: 150px;
  width: 150px;
  margin-right: 20px;

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet.max}) {
    height: 120px;
    width: 120px;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    margin-right: 0;
  }
`;

export const NumberError = styled.h1`
  font-size: 100px;
  font-weight: 700;
  color: ${({ theme }) => theme.color.disabledFont};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.tablet.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet.max}) {
    font-size: 90px;
  }

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    display: none;
  }
`;

export const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 10px;
  background-color: ${({ theme }) => theme.color.blue800};
  color: ${({ theme }) => theme.color.white};
`;

export const LogoWrapper = styled.div`
  height: 30px;
  position: absolute;
  bottom: 40px;
`;
