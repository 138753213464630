/* eslint-disable no-restricted-imports */
import React from 'react';
import bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const client = bugsnag.createClient({
  apiKey:
    process.env.REACT_APP_BUGSNAG_API_KEY || '723897a03541ffbd70b14f8c4c0a4fca',
  appVersion: process.env.REACT_APP_VERSION,
  releaseStage: process.env.NODE_ENV,
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production', 'staging'],
});

const BugsnagErrorBoundary = client
  ?.getPlugin('react')
  ?.createErrorBoundary(React);

export { client as bugsnag, BugsnagErrorBoundary };
