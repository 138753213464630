export const SEARCH_TYPES = {
  ON_SET_SEARCH_VALUE: 'OnSetSearchValue',
};

export type SetOnHomeSearch = {
  type: typeof SEARCH_TYPES.ON_SET_SEARCH_VALUE;
  payload: string;
};

export type SearchActions = SetOnHomeSearch;
