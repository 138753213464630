import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Dispatch } from 'redux';
import { logoutAction } from 'features/app/store/auth/actions';
import { TOKEN, REFRESH_TOKEN } from 'features/app/constants/token';

import LogoutIcon from 'assets/svgs/LogoutIcon';
import { Text } from 'features/app/components/Commons/Text/Text';
import { SignOutWrapper } from './SignOut.styles';

const SignOutMobile = () => {
  const dispatch = useDispatch<Dispatch>();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);

  const handleLogout = () => {
    const localToken = window.localStorage.getItem(TOKEN);
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN);

    if (data.token || localToken) {
      localStorage.removeItem(TOKEN);
      if (refreshToken) localStorage.removeItem(REFRESH_TOKEN);

      dispatch(logoutAction());
    }
  };

  return (
    <SignOutWrapper onClick={handleLogout}>
      <LogoutIcon width={30} height={33} color="#777777" />
      <Text
        fontSize="13"
        style={{ color: '777777', textTransform: 'uppercase' }}
      >
        sign out
      </Text>
    </SignOutWrapper>
  );
};

export default SignOutMobile;
