import styled from '@emotion/styled';

interface ProgressBarProps {
  progress?: number;
}

export const Bar = styled.div`
  background: ${({ theme }) => theme.color.progressBar.base};
  position: relative;
  height: 5px;
  width: 100%;
`;

export const FillPrimaryWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
`;

export const FillSecondaryWrapper = styled.div`
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
`;

export const FillPrimary = styled.div<ProgressBarProps>`
  background: ${({ theme }) => theme.color.progressBar.primary};
  height: 5px;
  width: ${({ progress }) => progress}%;
  transition: width 1s ease-in-out;
`;

export const FillSecondary = styled.div<ProgressBarProps>`
  background: ${({ theme }) => theme.color.progressBar.secondary};
  height: 5px;
  width: ${({ progress }) => progress || 0}%;
  transition: width 0.7s ease-in-out;
`;
