import { InfoCardProps, LineItemsScopeProps } from './HomeCardTypes';

export const getColorOfProgressBar = (
  lineItems: LineItemsScopeProps,
  InfoCardPay: InfoCardProps,
  warning: boolean
) => {
  if (warning) {
    return '#F7C460';
  }
  if (
    lineItems.approved === lineItems.totalScope &&
    InfoCardPay.pendingPaymentsAmount === 0
  ) {
    return '#3BD48B';
  }
  return '#009FDD';
};
