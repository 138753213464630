import { LogoPropTypes } from './types';

function LogoutIcon({ width, height, color }: LogoPropTypes) {
  return (
    <svg
      viewBox="0 0 34 34"
      width={width || '100%'}
      height={height || '100%'}
      strokeMiterlimit={2}
    >
      <rect width="34" height="34" fill="#FFF" rx="5" />
      <path
        stroke={color || '#000'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
        fill="#FFF"
        d="M14 26h-4a2 2 0 01-2-2V10a2 2 0 012-2h4M21 22l5-5-5-5M26 17H14"
      />
    </svg>
  );
}

export default LogoutIcon;
