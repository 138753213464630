import { ProjectLogActions, PROJECT_LOG_TYPES } from './projectLogTypes';

const initialState = {
  dates: [],
  search: '',
  multiselect: [],
  loading: false,
  logItems: [],
  error: false,
  groupBy: [],
  page: 1,
  limitPage: 100,
};

export const ProjectLogReducers = (
  state = initialState,
  action: ProjectLogActions
) => {
  switch (action.type) {
    case PROJECT_LOG_TYPES.ON_MULTI_SELECT_CHANGE:
      return {
        ...state,
        multiselect: action.payload,
      };
    case PROJECT_LOG_TYPES.ON_SEARCH_VALUE:
      return {
        ...state,
        search: action.payload,
      };
    case PROJECT_LOG_TYPES.ON_SELECT_DATES:
      return {
        ...state,
        dates: action.payload,
      };
    case PROJECT_LOG_TYPES.SET_LOADING_FILTERS:
      return {
        ...state,
        loading: action.payload,
      };
    case PROJECT_LOG_TYPES.SET_LOG_ITEMS:
      return {
        ...state,
        logItems: action.payload,
      };
    case PROJECT_LOG_TYPES.SET_LOG_ITEMS_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case PROJECT_LOG_TYPES.SELECT_OPTION_GROUP_BY:
      return {
        ...state,
        groupBy: action.payload,
      };
    case PROJECT_LOG_TYPES.SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case PROJECT_LOG_TYPES.SET_LIMIT_PAGE:
      return {
        ...state,
        limitPage: action.payload,
      };
    case PROJECT_LOG_TYPES.CLEAR_ALL_VALUES:
      return {
        ...state,
        dates: [],
        search: '',
        multiselect: [],
        error: false,
        groupBy: [],
        page: 1,
        limitPage: 100,
        loading: true,
      };
    default:
      return state;
  }
};
