import { IconPropTypes } from './types';

const CloseSimpleIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} viewBox="0 0 18 18" fill="none">
    <path
      d="M13.5 4.5L4.5 13.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 4.5L13.5 13.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CloseSimpleIcon;
