import { useState } from 'react';
import { useTheme } from '@emotion/react';
import EarnIcon from 'assets/svgs/EarnIcon';
import FutureIcon from 'assets/svgs/FutureIcon';
import PendingIcon from 'assets/svgs/PendingIcon';
import { useIsDesktop, useIsMobile } from 'features/app/hooks/screen';
import { FormattedMessage, useIntl } from 'react-intl';
import CardIcon from 'features/app/components/HomeView/Components/HomesCardsList/Components/HomeCard/Components/Card/CardIcon';
import { HomeProp } from 'features/app/utils/types/homes';
import { format } from 'date-fns';
import { usePaymentsSummary } from 'features/app/hooks/data';
import { RootStateOrAny, useSelector } from 'react-redux';
import Calendar from '../CalendarView/Components/Calendar';
import { HSpacer } from '../Commons/Spacer.styles';
import {
  CardWrapper,
  CardWrapperMobile,
  DesktopView,
  Line,
  MobileView,
  SummaryViewWrapper,
} from './SummaryView.styles';
import { Headline } from '../Commons/Text/Text';
import SummaryPaylog from './Paylog/SummaryPaylog';
import Modal from '../Commons/Modals/Modal';

import { EventProps } from '../CalendarView/Components/Calendar/types';
import SummaryThisMonth from './Paylog/SummaryThisMonth';
import CalendarLoader from '../Commons/Loaders/CalendarLoader';
import FreePositionModal from '../Commons/Modals/FreePositionModal/FreePositionModal';

export const THIS_MONTH = 'thisMonth';
export const FUTURE_WORK = 'futureWork';

interface ModalInfoProp {
  x: number;
  y: number;
  startDate?: Date;
  endDate?: Date;
  from?: String;
}

const SummaryView: React.FC<{}> = () => {
  const { data, loading } = useSelector((state: RootStateOrAny) => state.auth);
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isDesktop = useIsDesktop();
  const { formatMessage } = useIntl();
  const [showPaylog, setShowPaylog] = useState(false);
  const [modalInformation, setModalInformation] = useState<ModalInfoProp>({
    x: 0,
    y: 0,
  });
  const paymentSummary = usePaymentsSummary(data?.token);

  // console.log(paymentSummary);

  const formatHomes = () => {
    const homes: EventProps[] = [];
    if (!data) return homes;

    // console.log('data abajo');
    // console.log(data);

    const { activeProperties, completedProperties } = data;
    activeProperties.forEach((home: HomeProp) => {
      if (home?.total?.cents) {
        homes.push({
          id: home.id,
          done: false,
          name: home.address,
          totalCentCost: home.total.cents,
          start: home.startDate,
          end: home.endDate,
        });
      }
    });
    completedProperties.forEach((home: HomeProp) => {
      if (home?.total?.cents) {
        homes.push({
          id: home.id,
          done: true,
          name: home.address,
          totalCentCost: home.total.cents,
          start: home.startDate,
          end: home.endDate,
        });
      }
    });
    return homes;
  };

  const getNextWednesday = () => {
    const today = new Date();
    const day = today.getDay();
    let diff = 0;
    if (day === 3) {
      return formatMessage({ id: 'common.today' });
    }
    if (day < 3) diff = 3 - day;
    else diff = 7 - day + (3 % 7) || 7;
    const nextWednesday = new Date(
      today.getTime() + diff * 24 * 60 * 60 * 1000
    );
    return format(nextWednesday, 'MMM d, yyyy');
  };

  const onPaylogClick = (e: any, button?: string) => {
    if (button === THIS_MONTH) {
      const now = new Date();
      const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
      const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
      setModalInformation({
        from: THIS_MONTH,
        x: e.clientX,
        y: e.clientY,
        startDate: firstDay,
        endDate: lastDay,
      });
    } else if (button === FUTURE_WORK) {
      setModalInformation({
        from: FUTURE_WORK,
        x: e.clientX - 700,
        y: e.clientY,
        startDate: undefined,
        endDate: undefined,
      });
    }
    setShowPaylog(!showPaylog);
  };

  return (
    <SummaryViewWrapper>
      <Headline>
        <FormattedMessage id="common.summary" />
      </Headline>
      <HSpacer />
      {isMobile ? (
        <>
          <MobileView>
            <CardWrapperMobile>
              <CardIcon
                customMargin={5}
                title="project.thisMonth"
                icon={<EarnIcon />}
                buttonMessage="summary.viewMore"
                number={
                  paymentSummary.data?.currentMonthCompletedPaymentsAmount
                }
                subtitle="common.earned"
                onClick={(e) => onPaylogClick(e, THIS_MONTH)}
                overrideColor={theme.color.success}
                alignRight
              />
            </CardWrapperMobile>
            <CardWrapperMobile>
              <CardIcon
                customMargin={5}
                title="project.pending"
                icon={<PendingIcon />}
                buttonMessage="summary.viewStatus"
                number={paymentSummary.data?.pendingPaymentsAmount}
                subtitle="summary.nextPayment"
                date={getNextWednesday()}
                alignRight
              />
            </CardWrapperMobile>
          </MobileView>
          <CardIcon
            title="project.futureWork"
            icon={<FutureIcon />}
            buttonMessage="summary.viewPaylog"
            onClick={(e) => onPaylogClick(e, FUTURE_WORK)}
            number={paymentSummary.data?.futurePayableJobsAmount}
            subtitle="summary.currentProjects"
            alignRight
          />
          <HSpacer height={30} />
        </>
      ) : (
        <>
          <DesktopView>
            <CardWrapper>
              <CardIcon
                title="project.thisMonth"
                icon={<EarnIcon />}
                buttonMessage="summary.viewMore"
                number={
                  paymentSummary.data?.currentMonthCompletedPaymentsAmount
                }
                subtitle="common.earned"
                onClick={(e) => onPaylogClick(e, THIS_MONTH)}
                overrideColor={theme.color.success}
                alignRight
              />
            </CardWrapper>
            <CardWrapper>
              <CardIcon
                title="project.pendingPayment"
                icon={<PendingIcon />}
                buttonMessage="summary.viewStatus"
                number={paymentSummary.data?.pendingPaymentsAmount}
                subtitle="summary.nextPayment"
                date={getNextWednesday()}
                alignRight
              />
            </CardWrapper>
            <CardWrapper>
              <CardIcon
                title="project.futureWork"
                icon={<FutureIcon />}
                buttonMessage="summary.viewPaylog"
                onClick={(e) => onPaylogClick(e, FUTURE_WORK)}
                number={paymentSummary.data?.futurePayableJobsAmount}
                subtitle="summary.currentProjects"
                alignRight
              />
            </CardWrapper>
          </DesktopView>
          <HSpacer height={40} />
          <Line />
          <HSpacer height={40} />
        </>
      )}
      {loading ? (
        <CalendarLoader />
      ) : (
        <Calendar events={formatHomes()} showEventDetail />
      )}
      {showPaylog &&
        (isDesktop ? (
          <FreePositionModal
            setVisibility={setShowPaylog}
            clientY={modalInformation.y}
            clientX={modalInformation.x}
            maxWidth
          >
            {modalInformation.from === FUTURE_WORK && (
              <SummaryPaylog
                addresses={[...data?.activeProperties]}
                start={modalInformation?.startDate}
                end={modalInformation?.endDate}
                from={modalInformation.from}
              />
            )}
            {modalInformation.from === THIS_MONTH && (
              <SummaryThisMonth
                addresses={[
                  ...data?.activeProperties,
                  ...data?.completedProperties,
                ]}
                start={modalInformation?.startDate}
                end={modalInformation?.endDate}
                from={modalInformation.from}
              />
            )}
          </FreePositionModal>
        ) : (
          <Modal setVisibility={setShowPaylog} fullWidth>
            {modalInformation.from === FUTURE_WORK && (
              <SummaryPaylog
                addresses={[...data?.activeProperties]}
                start={modalInformation?.startDate}
                end={modalInformation?.endDate}
                from={modalInformation.from}
              />
            )}
            {modalInformation.from === THIS_MONTH && (
              <SummaryThisMonth
                addresses={[
                  ...data?.activeProperties,
                  ...data?.completedProperties,
                ]}
                start={modalInformation?.startDate}
                end={modalInformation?.endDate}
                from={modalInformation.from}
              />
            )}
          </Modal>
        ))}
    </SummaryViewWrapper>
  );
};
export default SummaryView;
