import { groupBy } from 'lodash';
import { WorkOrder } from 'features/app/utils/types/workorder';
import { HomesWithLineItems } from 'features/app/utils/types/payments';

export const groupLineItemsPerWorkOrder = (
  homes: HomesWithLineItems[],
  selectedId: number
) => {
  const workOrders: WorkOrder[] = [];
  const homeSelected = homes.filter((home) => home.id === selectedId)[0];
  const allItemsByWorkOrderId = groupBy(homeSelected.lineItems, 'workOrderId');

  Object.entries(allItemsByWorkOrderId).forEach(([key, value]) => {
    const id: number = parseInt(key, 10);
    let woTotalCost: number = 0;

    value.forEach((el) => {
      woTotalCost += el.costCents;

      return woTotalCost;
    });

    const workOrder: WorkOrder = {
      id,
      lineItems: value,
      costCents: woTotalCost,
    };

    workOrders.push(workOrder);
  });

  return workOrders;
};
