import httpClient from '../services/httpClient';

export const getPaymentsSummary = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await httpClient.get(`/vendors/payments/summary`, config);
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};
