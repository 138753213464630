import styled from '@emotion/styled';

interface InputProps {
  focus: boolean;
}

export const SearchInput = styled.input<InputProps>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.name};
  color: ${({ theme }) => theme.color.font};
  width: 100%;
  padding: 8px 10px;
  text-align: left;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  border: 1px solid
    ${({ theme, focus }) => (focus ? theme.color.blue800 : 'none')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  background-color: ${({ theme }) => theme.color.white};
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1; /* Firefox */
    color: ${({ theme }) => theme.color.lightFont};
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme }) => theme.color.lightFont};
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme }) => theme.color.lightFont};
  }
`;

export const Label = styled.label`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.color.font};
  margin-bottom: 2px;
`;

export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
`;
