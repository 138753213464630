import { LogoPropTypes } from '../types';

const ShadowOfSlave = ({ width, height, color }: LogoPropTypes) => (
  <svg
    width={width || '469'}
    height={height || '23'}
    viewBox="0 0 469 23"
    fill={color || 'none'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M468.593 19H0C59.7279 7.26861 142.642 0 234.296 0C325.951 0 408.865 7.26861 468.593 19Z"
      fill="url(#paint0_linear_719_713)"
    />
    <ellipse
      rx="30.5"
      ry="4"
      transform="matrix(1 0 0 -1 234.5 19)"
      fill="#D9D9D9"
    />
    <defs>
      <linearGradient
        id="paint0_linear_719_713"
        x1="234.296"
        y1="-15.5"
        x2="234.296"
        y2="19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="1" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default ShadowOfSlave;
