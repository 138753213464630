import styled from '@emotion/styled';

interface RowProps {
  header?: boolean;
  last?: boolean;
}

interface GroupProps {
  column?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Ul = styled.ul`
  width: 100%;
`;

export const Li = styled.li`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
`;

export const Row = styled.div<RowProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, header }) =>
    header ? theme.color.white : theme.color.table.background};
  padding: 5px 10px;
  height: auto;
  transition: height 2s ease;
  border-bottom: ${({ theme, last }) =>
    last ? 'none' : `1px solid ${theme.color.table.border}`};
`;

export const Group = styled.div<GroupProps>`
  display: flex;
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  height: ${({ column }) => (column ? '45px' : 'auto')};
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const EndLine = styled.div`
  height: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray300};
`;

export interface showProps {
  showDescription?: boolean;
}

export interface CostLimitProps {
  alignSelf?: string;
}

export const NameLimit = styled.div<showProps>`
  display: flex;
  flex: 7;
  white-space: ${(props) => (props.showDescription ? 'normal' : 'nowrap')};
  text-overflow: ellipsis;
  overflow: hidden;
  transition: 2s ease;
`;

export const CostLimit = styled.div<CostLimitProps>`
  display: flex;
  flex: 1;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'flex-start')};
  justify-content: flex-end;
`;

export const ButtonIcon = styled.button``;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

export interface RowDivWOProps {
  textDecoration?: boolean;
}

export const RowDivWO = styled.div<RowDivWOProps>`
  text-decoration: ${({ textDecoration }) => textDecoration && 'underline'};
  cursor: ${({ textDecoration }) => textDecoration && 'pointer'};
  & > p:hover {
    color: ${({ textDecoration }) => textDecoration && '#0076a4'};
    text-decoration: ${({ textDecoration }) => textDecoration && 'underline'};
    text-decoration-color: #0076a4;
  }
`;

export const EllipsisWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-self: flex-start;
`;

export const EmptyListResults = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 200px;
`;

export const GroupName = styled.div`
  display: flex;
  padding: 10px;
  width: 100%;
  background-color: ${({ theme }) => theme.color.gray300};
`;

export const MoreInfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MoreInfoWrapperMobile = styled.div`
  margin-left: 10px;
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
`;

export const MoreInfoModalWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 24px;
  width: 500px;
  height: fit-content;
`;

export const MoreInfoModalMobileWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px 24px;
  width: 350px;
  max-width: 500px;
  height: fit-content;
`;

export const LineInfoModal = styled.div`
  margin-top: 22px;
  margin-bottom: 13px;
  width: 100%;
  height: 2px;
  background-color: #f0f0f0;
`;
