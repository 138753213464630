import PerformanceView from '../components/PerformanceView/PerformanceView';

interface PerformancePageProps {
  hideLayout: (active: boolean) => void;
}

const PerformancePage: React.FC<PerformancePageProps> = ({ hideLayout }) => {
  hideLayout(false);
  return <PerformanceView />;
};

export default PerformancePage;
