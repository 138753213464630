import { IconPropTypes } from './types';

const InfoIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} viewBox="0 0 23 28" fill="none">
    <path
      d="M11.3333 0.666992C5.08 0.666992 0 5.74699 0 12.0003C0 18.2537 5.08 23.3337 11.3333 23.3337H12V27.3337C18.48 24.2137 22.6667 18.0003 22.6667 12.0003C22.6667 5.74699 17.5867 0.666992 11.3333 0.666992ZM12.6667 20.0003H10V17.3337H12.6667V20.0003ZM12.6667 15.3337H10C10 11.0003 14 11.3337 14 8.66699C14 7.20033 12.8 6.00033 11.3333 6.00033C9.86667 6.00033 8.66667 7.20033 8.66667 8.66699H6C6 5.72032 8.38667 3.33366 11.3333 3.33366C14.28 3.33366 16.6667 5.72032 16.6667 8.66699C16.6667 12.0003 12.6667 12.3337 12.6667 15.3337Z"
      fill="#666666"
    />
  </svg>
);

export default InfoIcon;
