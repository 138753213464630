import styled from '@emotion/styled';

interface Props {
  weight?: string;
  color?: string;
  fullWidth?: boolean;
  overflow?: string;
  textOverflow?: string;
  textAlign?: string;
  fontSize?: string;
  lineHeight?: string;
  marginVertical?: number;
  textDecoration?: string;
}

export const Text = styled.p<Props>`
  margin: 0px;
  margin-block: ${({ marginVertical }) => `${marginVertical}px` || 0};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.lg}px;
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};
  line-height: ${({ lineHeight }) => lineHeight || null};
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.base};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  text-decoration: ${({ textDecoration }) => textDecoration};
`;

export const TextBase = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.base};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const TextSm = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const TextXs = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const TextLg = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.lg};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.base};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;
export const TextXl = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.xxl};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
  overflow: ${({ overflow }) => overflow || 'hidden'};
  text-overflow: ${({ textOverflow }) => textOverflow || 'ellipsis'};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const TextX4l = styled.p<Props>`
  margin: 0;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme }) => theme.fontSize.x4l};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.regular};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const Headline = styled.p<Props>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.x3l}px;
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.bold};
  color: ${({ theme, color }) => color || theme.color.font};
  text-align: ${({ textAlign }) => textAlign || 'inherit'};
  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.xxl};
  }
`;

export const Title = styled.p<Props>`
  margin: 0;
  font-family: ${({ theme }) => theme.fontFamily.name};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize.xl};
  font-weight: ${({ theme, weight }) => weight || theme.fontWeight.bold};
  color: ${({ theme, color }) => color || theme.color.font};

  @media (min-width: ${({ theme }) =>
      theme.breakpoints.mobile.min}) and (max-width: ${({ theme }) =>
      theme.breakpoints.mobile.max}) {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
`;
