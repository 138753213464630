import HomeWithSlave from 'assets/svgs/NoHomesFounded/HomeWithSlave';
import ShadowOfSlave from 'assets/svgs/NoHomesFounded/ShadowOfSlave';
import { Text } from 'features/app/components/Commons/Text/Text';
import {
  DescriptionWrapper,
  TitleWrapper,
  WrapperNoHome,
} from './MessageNoHome.styles';
import { MessageNoHomeProps } from './types';

export default function MessageNoHome({
  title,
  description,
}: MessageNoHomeProps) {
  return (
    <WrapperNoHome>
      <HomeWithSlave />
      <TitleWrapper>
        <Text fontSize="20" weight="900" color="#212121">
          {title}
        </Text>
      </TitleWrapper>
      <DescriptionWrapper>
        <Text fontSize="14" weight="400" color="#212121">
          {description}
        </Text>
      </DescriptionWrapper>
      <ShadowOfSlave />
    </WrapperNoHome>
  );
}
