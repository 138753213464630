import { getAuth } from './auth';
import {
  getFutureWorkPayments,
  getHomeById,
  getHomeMilestones,
  getLineItemsApprovedById,
  getLineItemsByHomeId,
  getVendorEta,
} from './homes';
import { getPaylogData, getSummaryPaylog, getTotals } from './paylog';
import { getPaymentsSummary } from './summary';
import { getVendorsSummary } from './calendar';
import { getLogData } from './log';

const API_ROUTES = {
  GET_AUTH: getAuth,
  GET_HOME_BY_ID: getHomeById,
  GET_HOME_MILESTONES: getHomeMilestones,
  GET_VENDOR_ETA: getVendorEta,
  GET_PAYLOG_DATA: getPaylogData,
  GET_FUTURE_WORK_PAYMENTS: getFutureWorkPayments,
  GET_PAYMENTS_SUMMARY: getPaymentsSummary,
  GET_SUMMARY_PAYLOG: getSummaryPaylog,
  GET_VENDORS_SUMMARY: getVendorsSummary,
  GET_PROJECT_LOG: getLogData,
  GET_TOTALS: getTotals,
  GET_LINE_ITEMS_BY_HOME_ID: getLineItemsByHomeId,
  GET_LINE_ITEMS_APPROVED_BY_ID: getLineItemsApprovedById,
};

export default API_ROUTES;
