import { useRef, useEffect } from 'react';
import { portalRootId } from 'features/app/constants/modal';
import { createPortal } from 'react-dom';

const Portal: React.FC<{}> = ({ children }) => {
  const divRef = useRef(document.createElement('div'));
  const div = divRef.current;
  const root = document.getElementById(portalRootId);

  useEffect(() => {
    if (root) {
      root.appendChild(div);
    }

    const remove: () => void = () => {
      if (root) {
        root.removeChild(div);
      }
    };

    return () => remove();
  }, [div, root]);

  return createPortal(children, div);
};

export default Portal;
