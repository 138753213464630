import { IconPropTypes } from './types';

const HomeIcon = ({ width, height }: IconPropTypes) => (
  <svg
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    xmlSpace="preserve"
    height={height || '100%'}
    width={width || '100%'}
  >
    <style>{'.st1{fill:#eaf7ff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        className="st1"
        d="M48.5 8.1c-8 0-15.8 2.4-22.5 6.8S14.2 25.7 11.2 33.1c-3.1 7.4-3.9 15.6-2.3 23.4 1.6 7.9 5.4 15.1 11.1 20.8 5.7 5.7 12.9 9.5 20.7 11.1 7.8 1.6 16 .8 23.4-2.3 7.4-3.1 13.7-8.3 18.1-14.9 4.4-6.7 6.8-14.5 6.8-22.5 0-5.3-1-10.6-3.1-15.5-2-4.9-5-9.4-8.8-13.2-3.8-3.8-8.2-6.8-13.1-8.8-4.9-2.1-10.2-3.1-15.5-3.1zm0 73c-6.4 0-12.6-1.9-18-5.5C25.2 72 21.1 67 18.6 61c-2.4-5.9-3.1-12.4-1.8-18.7s4.3-12.1 8.8-16.6c4.5-4.5 10.3-7.6 16.6-8.9 6.3-1.3 12.8-.6 18.7 1.8 5.9 2.5 11 6.6 14.5 11.9 3.6 5.3 5.4 11.6 5.4 18 0 8.6-3.4 16.8-9.5 22.9-6 6.2-14.2 9.7-22.8 9.7z"
      />
      <path
        className="st1"
        d="M68.1 45.1 48.5 25.3c-.1-.1-.3-.3-.5-.3-.2-.1-.4-.1-.6-.1-.2 0-.4 0-.6.1-.2.1-.4.2-.5.3L26.8 45.1c-1.2 1.3-1.3 3.5.6 4.9.3.2.6.3 1 .3H31v15.6h32.9V50.3H66c.6 0 1.2-.2 1.7-.5s.9-.8 1.1-1.4c.2-.6.3-1.2.2-1.8-.2-.6-.5-1.1-.9-1.5zm-17 17.4h-7.4v-9.1c0-.4.2-.8.4-1.1.3-.3.7-.4 1.1-.4h4.4c.4 0 .8.2 1.1.4.3.3.4.7.4 1.1v9.1z"
      />
    </g>
  </svg>
);

export default HomeIcon;
