import { OnBonusModalVisibilityChange } from 'features/app/store/modals/modalsActions';
import { CloseGlyph } from 'assets/glyphs';
import BonusEarningIcon from 'assets/svgs/BonusEarningIcon';
import CircleIcon from 'assets/svgs/CircleIcon';
import DoneIcon from 'assets/svgs/DoneIcon';
import { Text } from 'features/app/components/Commons/Text/Text';
import { useDispatch } from 'react-redux';
import {
  BonusEarningContainer,
  BonusSection,
  BonusTotal,
  CloseWrapper,
  DoneWrapper,
  Headline,
  HomeBonus,
  HomesForBonus,
  NexBonusWrapper,
  ProjectBonusContainer,
  ProjectBonusWrapper,
  UnfinishedWrapper,
} from './ProjectBonusModal.styles';

const ProjectBonusModal = () => {
  const homesForBonus = [{ id: 1, value: '611 South Terrace Avenue' }];
  const dispatch = useDispatch();

  const closeModal = () => dispatch(OnBonusModalVisibilityChange(false));

  return (
    <ProjectBonusWrapper onClick={closeModal}>
      <ProjectBonusContainer onClick={(e) => e.stopPropagation()}>
        <BonusSection>
          <Headline>
            <Text weight="600" fontSize="28">
              Project Bonus
            </Text>
            <CloseWrapper onClick={closeModal}>
              <CloseGlyph size={32} viewBoxHeight={36} viewBoxWidth={36} />
            </CloseWrapper>
          </Headline>
          <BonusEarningContainer>
            <BonusEarningIcon width="26" height="41" />
            <BonusTotal>
              <Text weight="700" fontSize="22" color="#FFF">
                $ 2,300
              </Text>
              <Text weight="400" fontSize="14" color="#FFF">
                lifetime bonus earnings
              </Text>
            </BonusTotal>
          </BonusEarningContainer>
        </BonusSection>
        <BonusSection>
          <Text weight="400" fontSize="14">
            Each vendor has a monetary bonus each time you finish X homes. If
            you want to know more about how Milestones bonuses works you can
            read our Terms and conditions.
          </Text>
        </BonusSection>
        <BonusSection style={{ marginTop: '15px' }}>
          <Text weight="700" fontSize="16">
            Completed homes to achieve the next bonus
          </Text>
          <HomesForBonus>
            {homesForBonus.map((el) => (
              <HomeBonus key={el.id}>
                <DoneWrapper>
                  <DoneIcon width="16" height="12" />
                </DoneWrapper>
                <Text weight="400" fontSize="16">
                  {el.value}
                </Text>
              </HomeBonus>
            ))}
            <HomeBonus>
              <UnfinishedWrapper>
                <CircleIcon width="16" height="16" />
              </UnfinishedWrapper>
              <Text weight="400" fontSize="16" color="#767676">
                Home unfinished
              </Text>
            </HomeBonus>
            <HomeBonus>
              <UnfinishedWrapper>
                <CircleIcon width="16" height="16" />
              </UnfinishedWrapper>
              <Text weight="400" fontSize="16" color="#767676">
                Home unfinished
              </Text>
            </HomeBonus>
            <HomeBonus>
              <NexBonusWrapper>
                <BonusEarningIcon width="19" height="30" />
              </NexBonusWrapper>
              <Text weight="400" fontSize="16" color="#767676">
                $ 500 Next Bonus
              </Text>
            </HomeBonus>
          </HomesForBonus>
        </BonusSection>
      </ProjectBonusContainer>
    </ProjectBonusWrapper>
  );
};

export default ProjectBonusModal;
