import { Experiment } from '@amplitude/experiment-js-client';
import { osName, osVersion } from 'react-device-detect';

const ExperimentAmplitude = async (userId: number) => {
  const experiment = Experiment.initialize(
    process.env.REACT_APP_AMPLITUDE_API_KEY || ''
  );
  const res = await experiment.fetch({
    user_id: `${userId}`,
    device_id: `${osName}-${osVersion}`,
  });
  return res;
};

export default ExperimentAmplitude;
