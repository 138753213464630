import { IconPropTypes } from './types';

const HomeMoneyIcon = ({ width, height }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 38 40"
    fill="none"
  >
    <path
      d="M1 11.7561L14.6098 1L28.2195 11.7561V28.6585C28.2195 29.4736 27.9009 30.2553 27.3337 30.8316C26.7665 31.4079 25.9972 31.7317 25.1951 31.7317H4.02439C3.22227 31.7317 2.45301 31.4079 1.88582 30.8316C1.31864 30.2553 1 29.4736 1 28.6585V11.7561Z"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.78049 31.7317V16.8049H19.439V31.7317"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="28.2195"
      cy="29.9756"
      r="8.78049"
      fill="white"
      stroke="#009FDD"
      strokeWidth="2"
    />
    <path
      d="M28.4586 24.7073V35.2439"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.8536 26.6229H27.2615C26.817 26.6229 26.3906 26.7995 26.0762 27.1139C25.7619 27.4283 25.5853 27.8546 25.5853 28.2992C25.5853 28.7438 25.7619 29.1701 26.0762 29.4845C26.3906 29.7989 26.817 29.9755 27.2615 29.9755H29.6562C30.1008 29.9755 30.5272 30.1521 30.8415 30.4664C31.1559 30.7808 31.3325 31.2072 31.3325 31.6517C31.3325 32.0963 31.1559 32.5227 30.8415 32.8371C30.5272 33.1514 30.1008 33.328 29.6562 33.328H25.5853"
      stroke="#009FDD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HomeMoneyIcon;
