import { call, put, all, takeLatest } from 'redux-saga/effects';
import { User } from 'features/app/db/schemas/user';
import {
  getAuth,
  loginWithCodeCall,
  refreshTokenCall,
  responseLoginWithCodeProps,
  sendCodeToLogin,
} from 'features/app/api/auth';
import { REFRESH_TOKEN } from 'features/app/constants/token';
import types, { AuthWithToken } from './types';

import {
  authErrorAction,
  authSuccessAction,
  setLoginLoadingAction,
  SET_ERROR_PASSWORDLESS,
  SET_LOADING_PASSWORDLESS,
  verificationPage,
} from './actions';

function* authWithToken({ payload: { token } }: AuthWithToken) {
  try {
    yield put(setLoginLoadingAction(true));
    const data: User = yield call(getAuth, token);
    yield put(authSuccessAction(data, token));
  } catch (error) {
    yield put(authErrorAction(error));
  }
}

function* sendCode({ payload }: any) {
  try {
    yield put(SET_LOADING_PASSWORDLESS(true));
    yield call(sendCodeToLogin, payload);
    yield put(SET_LOADING_PASSWORDLESS(false));
    yield put(verificationPage(true));
  } catch (error) {
    yield put(SET_ERROR_PASSWORDLESS(true));
    yield put(SET_LOADING_PASSWORDLESS(false));
  }
}

function* refreshToken(payload: any) {
  try {
    yield put(setLoginLoadingAction(true));
    const data: responseLoginWithCodeProps = yield call(
      refreshTokenCall,
      payload
    );
    if (data.access) {
      try {
        window.localStorage.setItem(REFRESH_TOKEN, data.refresh);
        const dataUser: User = yield call(getAuth, `Bearer ${data.access}`);
        yield put(authSuccessAction(dataUser, `Bearer ${data.access}`));
      } catch (error) {
        yield put(authErrorAction(error));
      }
    }
  } catch (err) {
    yield put(verificationPage(false));
  } finally {
    yield put(setLoginLoadingAction(false));
  }
}

function* loginWithCodeApiCall(payload: any) {
  try {
    yield put(SET_LOADING_PASSWORDLESS(true));
    const data: responseLoginWithCodeProps = yield call(
      loginWithCodeCall,
      payload
    );
    if (data.access) {
      try {
        yield put(setLoginLoadingAction(true));
        window.localStorage.setItem(REFRESH_TOKEN, data.refresh);
        const dataUser: User = yield call(getAuth, `Bearer ${data.access}`);
        yield put(authSuccessAction(dataUser, `Bearer ${data.access}`));
      } catch (error) {
        yield put(authErrorAction(error));
      }
    }
  } catch (err) {
    yield put(SET_ERROR_PASSWORDLESS(true));
    yield put(SET_LOADING_PASSWORDLESS(false));
  }
}

export default function* userSaga(): any {
  yield all([
    yield takeLatest(types.AUTH_WITH_TOKEN, authWithToken),
    yield takeLatest(types.SEND_CODE, sendCode),
    yield takeLatest(types.LOGIN_WITH_CODE, loginWithCodeApiCall),
    yield takeLatest(types.SAVE_REFRESH_TOKEN, refreshToken),
  ]);
}
