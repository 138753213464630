import { IconPropTypes } from './types';

const FutureTaskIcon = ({ width, height }: IconPropTypes) => (
  <svg
    id="Layer_1"
    x={0}
    y={0}
    viewBox="0 0 97 97"
    xmlSpace="preserve"
    height={height || '100%'}
    width={width || '100%'}
  >
    <style>{'.st2{fill:#eaf7ff}'}</style>
    <defs>
      <path id="SVGID_1_" d="M0 0h97v97H0z" />
    </defs>
    <clipPath id="SVGID_2_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath: 'url(#SVGID_2_)',
      }}
    >
      <path
        d="M50.2 41c-.3.3-.5.7-.5 1.1 0 .4.2.8.5 1.1l2.6 2.6c.3.3.7.5 1.1.5.4 0 .8-.2 1.1-.5l6.2-6.2c.8 1.8 1.1 3.8.7 5.8-.4 2-1.3 3.8-2.7 5.2C57.8 52 56 53 54 53.3c-2 .4-4 .1-5.8-.7L37 64.1c-.6.7-1.5 1-2.5 1-.9 0-1.8-.4-2.5-1-.6-.7-1-1.5-1-2.5 0-.9.4-1.8 1-2.5l11.3-11.3c-.7-1.8-.9-3.8-.6-5.8.4-2 1.3-3.8 2.7-5.2 1.4-1.4 3.2-2.4 5.2-2.7 2-.4 4-.1 5.8.7L50.2 41h0z"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 2,
          strokeLinecap: 'round',
          strokeLinejoin: 'round',
        }}
      />
      <path
        className="st2"
        d="M43.4 47.9 31.6 60.2c-1.6 3.9 2.3 4.9 4.4 4.9l12.2-12.3 6.9 1c1.3-1 4.3-3.5 5.9-5.9 1.6-2.4.7-6.2 0-7.9l-5.9 6.4h-1.5L49.7 43v-1.5l6.9-6.4c0-1.1-3.4-1.5-8.3 0s-6.1 9.7-4.9 12.8zM58.6 13.7c.2-.1.3-.2.4-.4.1-.2.1-.3.1-.5s0-.4-.1-.5c-.1-.2-.2-.3-.4-.4L43.8 1.4c-.2-.1-.4-.2-.6-.2-.2 0-.4 0-.6.1-.2.1-.3.2-.4.4-.1.2-.2.4-.2.6v20.8c0 .2.1.4.2.6.1.2.3.3.4.4.2.1.4.1.6.1.2 0 .4-.1.6-.2l14.8-10.3z"
      />
      <path
        d="M71.1 20.2c7 5.5 10.4 10.5 12.7 18.9 2.3 8.4 1.5 17.4-2.2 25.2-3.7 7.9-10.1 14.2-18 17.8-7.9 3.6-16.8 4.2-25.2 1.8-8.3-2.4-15.6-7.7-20.3-15s-6.8-16-5.7-24.7S17.6 27.6 24 21.7s14.7-9.3 23.4-9.6"
        style={{
          fill: 'none',
          stroke: '#eaf7ff',
          strokeWidth: 8,
          strokeLinecap: 'round',
        }}
      />
    </g>
  </svg>
);

export default FutureTaskIcon;
