import styled from '@emotion/styled';

export const WrapperNoHome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  margin-top: 20px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: 15px;
`;
