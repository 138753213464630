import { IconPropTypes } from './types';

const PeopleIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height || '100%'}
    width={width || '100%'}
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M8.42665 18.112C9.70665 18.1386 10.8933 18.7786 11.6933 19.8986C12.6666 21.272 14.28 22.0986 16 22.0986C17.72 22.0986 19.3333 21.272 20.3066 19.8853C21.1066 18.7653 22.2933 18.1253 23.5733 18.0986C22.6133 16.472 18.7733 15.432 16 15.432C13.24 15.432 9.38665 16.472 8.42665 18.112ZM5.33331 18.0986C7.54665 18.0986 9.33331 16.312 9.33331 14.0986C9.33331 11.8853 7.54665 10.0986 5.33331 10.0986C3.11998 10.0986 1.33331 11.8853 1.33331 14.0986C1.33331 16.312 3.11998 18.0986 5.33331 18.0986ZM26.6666 18.0986C28.88 18.0986 30.6666 16.312 30.6666 14.0986C30.6666 11.8853 28.88 10.0986 26.6666 10.0986C24.4533 10.0986 22.6666 11.8853 22.6666 14.0986C22.6666 16.312 24.4533 18.0986 26.6666 18.0986ZM16 14.0986C18.2133 14.0986 20 12.312 20 10.0986C20 7.8853 18.2133 6.09863 16 6.09863C13.7866 6.09863 12 7.8853 12 10.0986C12 12.312 13.7866 14.0986 16 14.0986Z"
      fill={color}
    />
    <path
      d="M28 19.4326H23.64C22.6133 19.4326 21.84 20.0326 21.4 20.6593C21.3466 20.7393 19.5866 23.4326 16 23.4326C14.0933 23.4326 11.96 22.5793 10.6 20.6593C10.08 19.926 9.26665 19.4326 8.35998 19.4326H3.99998C2.53331 19.4326 1.33331 20.6326 1.33331 22.0993V27.4326H10.6666V24.4193C12.2 25.486 14.0533 26.0993 16 26.0993C17.9466 26.0993 19.8 25.486 21.3333 24.4193V27.4326H30.6666V22.0993C30.6666 20.6326 29.4666 19.4326 28 19.4326Z"
      fill={color}
    />
  </svg>
);

export default PeopleIcon;
