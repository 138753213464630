import { useTheme } from '@emotion/react';
import {
  BACKEND_STATUS,
  CANCELED,
  COMPLETED,
  PAY_PROCESSING,
  WORK_REMAINING,
} from 'features/app/constants/lineItemStatus';
import { useIntl } from 'react-intl';
import { formatDate } from 'features/app/utils/date';
import { LineItem } from 'features/app/utils/types/lineItems';
import { centsToDollars } from 'helpers/centsToDollars';
import {
  getPlannerAccessToken,
  getWorkOrderLink,
} from 'features/app/api/homes';
import Chip from '../Commons/Chip';

export const accessLinkWO = 'accessLinkWO';

export const getStatus = (item: LineItem) => {
  const { payment, status, paid } = item;
  if (payment) {
    if (
      payment.status === BACKEND_STATUS.approval_needed ||
      payment.status === BACKEND_STATUS.pending
    ) {
      return PAY_PROCESSING;
    }
    if (payment.status === BACKEND_STATUS.completed) {
      return COMPLETED;
    }
    if (payment.status === BACKEND_STATUS.cancelled) {
      return CANCELED;
    }
  } else {
    if (status === BACKEND_STATUS.cancelled) {
      return CANCELED;
    }
    if (
      status === BACKEND_STATUS.unassigned ||
      status === BACKEND_STATUS.assigned ||
      status === BACKEND_STATUS.rejected ||
      status === BACKEND_STATUS.provisional
    ) {
      return WORK_REMAINING;
    }
    if (
      status === BACKEND_STATUS.completed ||
      status === BACKEND_STATUS.approved ||
      !paid
    ) {
      return PAY_PROCESSING;
    }
  }
  return WORK_REMAINING;
};

export const GetStatusChip = (item: LineItem) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  if (getStatus(item) === PAY_PROCESSING) {
    return (
      <Chip
        close={false}
        textColor={theme.color.workStatus.payProcessing}
        text={formatMessage({ id: 'paylog.statusNames.paymentProcessing' })}
        color={theme.color.workStatus.payProcessingBg}
      />
    );
  }
  if (getStatus(item) === CANCELED) {
    return (
      <Chip
        close={false}
        textColor={theme.color.workStatus.canceled}
        text={formatMessage({ id: 'paylog.statusNames.cancelled' })}
        color={theme.color.workStatus.canceledBg}
      />
    );
  }
  if (getStatus(item) === COMPLETED) {
    return (
      <Chip
        close={false}
        textColor={theme.color.workStatus.paid}
        text={formatMessage({ id: 'paylog.statusNames.paid' })}
        color={theme.color.workStatus.paidBg}
      />
    );
  }
  return (
    <Chip
      close={false}
      textColor={theme.color.workStatus.workRemaining}
      text={formatMessage({ id: 'paylog.statusNames.workRemaining' })}
      color={theme.color.workStatus.workRemainingBg}
    />
  );
};

export const formatDataToExport = (data: LineItem[]) => {
  const formattedData: any[] = [];
  data?.forEach((item: LineItem) => {
    formattedData.push({
      'Work Order #': item.workOrderId,
      'Line Item': item.name,
      'Invoice #': item?.payment?.id || '---',
      Amount: centsToDollars(item.costCents),
      'Marked Complete': formatDate(item?.approvedAt),
      'Payment Date': formatDate(item?.payment?.completedAt),
      Status: getStatus(item),
    });
  });
  return formattedData;
};

export const orderBy = (payments?: LineItem[]) =>
  payments?.sort((a, b) => {
    if (a?.payment?.approvedAt && b.payment?.approvedAt) {
      const aDate = new Date(a?.payment?.approvedAt);
      const bDate = new Date(b?.payment?.approvedAt);
      return bDate.getTime() - aDate.getTime();
    }
    return 0;
  });

export const handleWorkOrderLink = async (
  homeId: number,
  woId: number,
  token: string,
  setIsOpen: (isOpen: boolean) => void
) => {
  if (token.includes('Bearer')) {
    const response = await getWorkOrderLink(homeId, woId, token);
    window.open(response);
  } else {
    try {
      const tokenLocalSession = sessionStorage.getItem(accessLinkWO);
      let response;
      if (!tokenLocalSession) {
        const tokenPlanner = await getPlannerAccessToken(token);
        sessionStorage.setItem(accessLinkWO, tokenPlanner);
        response = await getWorkOrderLink(homeId, woId, tokenPlanner);
      } else {
        response = await getWorkOrderLink(homeId, woId, tokenLocalSession);
      }
      window.open(response);
    } catch (err) {
      setIsOpen(true);
    }
  }
};
