import { Text } from '../../../Text/Text';
import { VendorIconContainer } from './VendorIcon.styles';

export interface VendorIconProps {
  letter: string;
}

function VendorIcon({ letter }: VendorIconProps) {
  return (
    <VendorIconContainer>
      <Text fontSize="14" weight="700" color="black">
        {letter}
      </Text>
    </VendorIconContainer>
  );
}

export default VendorIcon;
