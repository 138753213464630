import { useTheme } from '@emotion/react';
import { TextSm } from 'features/app/components/Commons/Text/Text';
import { RootStateOrAny, useSelector } from 'react-redux';
import vendorImgExmple from '../../../../../../../assets/svgs/vendorTCKExample.svg';

import { VendorInfoWrapper, VendorLogo } from './VendorInfo.styles';

const VendorInfo: React.FC = () => {
  const { auth } = useSelector((state: RootStateOrAny) => state);
  const theme = useTheme();

  return (
    <VendorInfoWrapper>
      <TextSm weight={theme.fontWeight.regular} color={theme.color.black}>
        {auth?.data?.vendor?.name}
      </TextSm>
      <VendorLogo src={vendorImgExmple} />
    </VendorInfoWrapper>
  );
};

export default VendorInfo;
