export const HOME_MILESTONES_TYPES = {
  GET_HOME_MILESTONES: 'GET_HOME_MILESTONES',
  SET_HOME_MILESTONES: 'SET_HOME_MILESTONES',
  SET_LOADING_HOME_MILESTONES: 'SET_LOADING_HOME_MILESTONES',
};

export type OnGetHomeMilestones = {
  type: typeof HOME_MILESTONES_TYPES.GET_HOME_MILESTONES;
  payload: any;
};
export type OnSetHomeMilestones = {
  type: typeof HOME_MILESTONES_TYPES.SET_HOME_MILESTONES;
  payload: any;
};
export type OnLoadingHomeMilestone = {
  type: typeof HOME_MILESTONES_TYPES.SET_LOADING_HOME_MILESTONES;
  payload: boolean;
};

export type homeMilestonesActions =
  | OnGetHomeMilestones
  | OnSetHomeMilestones
  | OnLoadingHomeMilestone;
