import { LineItem } from 'features/app/utils/types/lineItems';
import LineItemsCard from './Components/LineItemsCard';
import PaylogCard from './Components/PaylogCard/PaylogCard';
import { CardsWrapper } from './HomeInformationCards.styles';
import TotalScopeCard from './Components/TotalScopeCard';

interface TotalsProps {
  completedPaymentsAmount: number;
  futurePayableJobsAmount: number;
  pendingPaymentsAmount: number;
  total: number;
}

export interface StateLineItemProps {
  approved: number;
  totalScope: number;
}

interface CardsProps {
  futureWork?: number;
  lineItems?: LineItem[];
  total: number;
  lineItemsLoading?: boolean;
  totalScope: number;
  homeTotals?: TotalsProps;
  lineItemsData: StateLineItemProps;
}

const HomeInformationCards: React.FC<CardsProps> = ({
  lineItems,
  total,
  lineItemsLoading,
  totalScope,
  homeTotals,
  lineItemsData,
}) => (
  <CardsWrapper>
    <TotalScopeCard total={totalScope} />
    <PaylogCard
      paid={homeTotals?.completedPaymentsAmount}
      workRemaining={homeTotals?.futurePayableJobsAmount}
      paymentProcessing={homeTotals?.pendingPaymentsAmount}
      total={homeTotals?.total}
    />
    <LineItemsCard lineItemsData={lineItemsData} />
  </CardsWrapper>
);

export default HomeInformationCards;
