import InfoIcon from 'assets/svgs/InfoIcon';

import { InfoButtonWrapper } from './InfoButton.styles';

const InfoButton = () => (
  <InfoButtonWrapper>
    <InfoIcon width={24} height={27} />
  </InfoButtonWrapper>
);

export default InfoButton;
