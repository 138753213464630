import styled from '@emotion/styled';
import checkIcon from '../../../../../assets/svgs/check-icon.svg';

interface CheckedBoxProps {
  isSelected: boolean;
}

export const SelectComponent = styled.select`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding-inline: 12px;

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
`;

export const OptionOfSelect = styled.option``;

export const SelectInput = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: white;
`;

export const SelectOptionsContainer = styled.div`
  box-sizing: border-box;
  position: absolute;
  padding: 10px 0;
  margin-top: 12px;
  z-index: 10;
  top: 100%;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  @media (max-width: 768px) {
    margin-left: auto;
    width: 75%;
  }
`;

export const SelectOption = styled.div`
  box-sizing: border-box;
  padding: 10px 10px;
  width: 100%;
  min-height: 30px;
  background-color: white;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: #effbff;
  }
`;

export const SelectValue = styled.div`
  padding-left: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
`;

export const CheckedBox = styled.div<CheckedBoxProps>`
  box-sizing: border-box;
  width: 16px;
  height: 16px;
  border: ${({ isSelected }) => (!isSelected ? `1px solid #e9e9e9` : '')};
  background: ${({ isSelected }) =>
    isSelected ? `url(${checkIcon}) no-repeat center` : ''};
  background-size: 100%;
  border-radius: 2px;
`;
