import { CLEAR_REDUCERS } from 'features/app/utils/globalTypes';
import types, {
  UserActions,
  UserInitialStateType,
  UserStateType,
} from './types';

const initialState: UserInitialStateType = {
  data: undefined,
  loading: false,
  error: null,
  passwordLess: {
    loading: false,
    refreshToken: undefined,
    error: null,
    verificationPage: false,
  },
};

const userReducer = (
  state = initialState,
  action: UserActions
): UserStateType | UserInitialStateType => {
  switch (action.type) {
    case types.SET_LOGIN_LOADING:
      return {
        ...state,
        ...action.payload,
      };

    case types.CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case types.AUTH_SUCCESS:
      return {
        ...state,
        data: { ...action.payload.data, token: action.payload.token },
        loading: false,
        error: null,
      };

    case types.AUTH_ERROR:
      return {
        ...state,
        ...action.payload,
      };

    case types.LOGOUT:
      return {
        ...state,
        data: undefined,
        passwordLess: {
          loading: false,
          refreshToken: undefined,
          error: null,
          verificationPage: false,
        },
      };

    case types.SET_LOADING_PASSWORDLESS:
      return {
        ...state,
        passwordLess: {
          ...state.passwordLess,
          loading: action.payload,
        },
      };
    case types.SET_ERROR_PASSWORDLESS:
      return {
        ...state,
        passwordLess: {
          ...state.passwordLess,
          error: true,
        },
      };
    case types.VERIFICATION_PAGE:
      return {
        ...state,
        passwordLess: {
          ...state.passwordLess,
          verificationPage: action.payload,
          error: false,
        },
      };
    case CLEAR_REDUCERS:
      return initialState; // it clears the store on logout
    default:
      return state;
  }
};

export default userReducer;
