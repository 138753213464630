import { useTheme } from '@emotion/react';
import { DownArrowGlyph } from 'assets/glyphs';
import { TextBase, TextSm } from 'features/app/components/Commons/Text/Text';
import {
  COMPLETED,
  APPROVAL_NEEDED,
  PENDING,
  COMPLETED_PROJECT_LOG,
  ASSIGNED,
} from 'features/app/constants/lineItemStatus';
import { useIsMobile } from 'features/app/hooks/screen';
import { centsToDollars } from 'helpers/centsToDollars';
import ResultsIcon from 'assets/svgs/Results';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { WorkOrder } from 'features/app/utils/types/workorder';

import {
  Dot,
  TableTd,
  TableTh,
  TableTr,
  TableWrapper,
  StatusWrapper,
  DescriptionWrapper,
  TableTdDescription,
  ArrowWrapper,
  EmptyTableResults,
  WorkOrderCostWrapper,
} from './SummaryPaylogTable.styles';
import { HSpacer, WSpacer } from '../../../../Commons/Spacer.styles';

interface TableProps {
  data?: WorkOrder[];
}

const Table: React.FC<TableProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState<null | number>();
  const headerWorkOrder = [{ id: 1, name: 'paylog.woId' }];
  const headersLineItems = isMobile
    ? [
        { id: 1, name: 'paylog.status' },
        { id: 2, name: 'common.description' },
        { id: 3, name: 'paylog.amount' },
      ]
    : [
        { id: 1, name: 'paylog.status' },
        { id: 2, name: 'common.description' },
        { id: 3, name: 'paylog.amount' },
      ];

  const onOpenRow = (id: number) => {
    if (isOpen === id) {
      setIsOpen(null);
    } else {
      setIsOpen(id);
    }
  };

  const checkIsOpen = (id: number) => isOpen === id;

  const getStatus = (status: string) => {
    if (status === COMPLETED || status === COMPLETED_PROJECT_LOG) {
      return {
        text: 'paylog.statusNames.paid',
        color: theme.color.success,
      };
    }
    if (status === PENDING) {
      return {
        text: 'paylog.statusNames.awaitingPayment',
        color: theme.color.contactIcons,
      };
    }
    if (status === ASSIGNED) {
      return {
        text: 'paylog.statusNames.workRemaining',
        color: theme.color.contactIcons,
      };
    }
    if (status === APPROVAL_NEEDED) {
      return {
        text: 'paylog.statusNames.approvalNeeded',
        color: theme.color.contactIcons,
      };
    }
    return {
      text: 'paylog.statusNames.cancelled',
      color: theme.color.error,
    };
  };

  return (
    <>
      {data?.length === 0 ? (
        <EmptyTableResults>
          <ResultsIcon width="50px" height="50px" />
          <HSpacer />
          <TextSm color={theme.color.lightFont}>
            <FormattedMessage id="paylog.empty" />
          </TextSm>
        </EmptyTableResults>
      ) : (
        <TableWrapper>
          <thead>
            <TableTr>
              <TableTh key={headerWorkOrder[0].id}>
                <TextBase color={theme.color.lightFont}>
                  <FormattedMessage id={headerWorkOrder[0].name} />
                </TextBase>
              </TableTh>
            </TableTr>
          </thead>
          {data?.map((workOrder) => (
            <tbody key={workOrder.id}>
              <TableTr onClick={() => onOpenRow(workOrder?.id)}>
                <TableTd open={checkIsOpen(workOrder.id)}>
                  <TextSm color={theme.color.lightFont}>{workOrder?.id}</TextSm>
                  <WorkOrderCostWrapper>
                    <TextBase>
                      <FormattedMessage id="common.total" />
                    </TextBase>
                    <WSpacer width={10} />
                    <TextSm>{centsToDollars(workOrder?.costCents)}</TextSm>
                  </WorkOrderCostWrapper>
                  <ArrowWrapper open={checkIsOpen(workOrder?.id)}>
                    <DownArrowGlyph
                      size={12}
                      viewBoxHeight={24}
                      viewBoxWidth={24}
                      color={theme.color.lightFont}
                    />
                  </ArrowWrapper>
                </TableTd>
              </TableTr>
              {checkIsOpen(workOrder?.id) && (
                <TableTdDescription colSpan={7}>
                  <DescriptionWrapper>
                    <TableTr>
                      {headersLineItems.map(({ id, name }, i) => (
                        <TableTh key={id}>
                          <TextBase color={theme.color.lightFont}>
                            {name && <FormattedMessage id={name} />}
                          </TextBase>
                        </TableTh>
                      ))}
                      {workOrder.lineItems?.map((item) => (
                        <TableTr key={item?.id}>
                          <TableTd open={checkIsOpen(item?.id)}>
                            <StatusWrapper>
                              <Dot color={getStatus(item?.status).color} />
                              <WSpacer width={5} />
                              <TextSm
                                color={theme.color.lightFont}
                                style={{
                                  whiteSpace: isMobile
                                    ? 'break-spaces'
                                    : 'nowrap',
                                }}
                              >
                                <FormattedMessage
                                  id={getStatus(item?.status).text}
                                />
                              </TextSm>
                            </StatusWrapper>
                          </TableTd>
                          <TableTd open={checkIsOpen(item.id)}>
                            <TextSm
                              style={{
                                width: isMobile ? '100px' : '410px',
                                whiteSpace: 'break-spaces',
                              }}
                              // textOverflow="none"
                            >
                              {item?.name}
                            </TextSm>
                          </TableTd>
                          <TableTd open={checkIsOpen(item.id)}>
                            <TextSm>{centsToDollars(item.costCents)}</TextSm>
                          </TableTd>
                        </TableTr>
                      ))}
                    </TableTr>
                  </DescriptionWrapper>
                </TableTdDescription>
              )}
            </tbody>
          ))}
        </TableWrapper>
      )}
    </>
  );
};

export default Table;
