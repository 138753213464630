import { useState, useEffect } from 'react';
import API from 'features/app/api';
import { HomeProp } from '../utils/types/homes';

interface useDataProps {
  service: any;
  params?: any;
  body?: any;
  refetch?: boolean;
}

export const useData = ({ service, params, body, refetch }: useDataProps) => {
  const [data, setData] = useState<any>();
  const [called, setCalled] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setCalled(true);
      try {
        const response = await service(params, body);
        setData(response);
      } catch ({ message }) {
        setError(message);
      }

      setLoading(false);
    };

    if (!called) fetchData();
    if (refetch && called) setCalled(false);
  }, [body, params, service, called, refetch]);

  if (loading) return { loading };
  if (error) return { error };
  return { data };
};

export const useHomeMilestones = (
  id?: number,
  token?: string,
  refetch?: boolean
) =>
  useData({
    service: API.GET_HOME_MILESTONES,
    params: id,
    body: token,
    refetch,
  });

export const useVendorEta = (id?: number, token?: string, refetch?: boolean) =>
  useData({
    service: API.GET_VENDOR_ETA,
    params: id,
    body: token,
    refetch,
  });

export const usePaylogData = (
  id?: number,
  token?: string,
  startDate?: Date,
  endDate?: Date,
  refetch?: boolean
) =>
  useData({
    service: API.GET_PAYLOG_DATA,
    params: id,
    body: {
      token,
      startDate,
      endDate,
    },
    refetch,
  });

export const useFutureWorkPayments = (
  id?: number,
  token?: string,
  refetch?: boolean
) =>
  useData({
    service: API.GET_FUTURE_WORK_PAYMENTS,
    params: id,
    body: token,
    refetch,
  });

export const useGetHomeById = (id: number, token: string, refetch: boolean) =>
  useData({
    service: API.GET_HOME_BY_ID,
    params: id,
    body: token,
    refetch,
  });

export const useGetLineItemsById = (
  id: number,
  token: string,
  refetch: boolean
) =>
  useData({
    service: API.GET_LINE_ITEMS_APPROVED_BY_ID,
    params: id,
    body: token,
    refetch,
  });

export const usePaymentsSummary = (token?: string, refetch?: boolean) =>
  useData({
    service: API.GET_PAYMENTS_SUMMARY,
    body: token,
    refetch,
  });

export const useSummaryPaylogData = (
  token?: string,
  startDate?: Date,
  endDate?: Date,
  refetch?: boolean
) =>
  useData({
    service: API.GET_SUMMARY_PAYLOG,
    params: {
      token,
      startDate,
      endDate,
    },
    refetch,
  });

export const useVendorsSummaryData = (token?: string, refetch?: boolean) =>
  useData({
    service: API.GET_VENDORS_SUMMARY,
    params: token,
    refetch,
  });

export const useProjectLogData = (
  id?: number,
  token?: string,
  refetch?: boolean,
  startDate?: Date,
  endDate?: Date,
  page?: number,
  limit?: number
) =>
  useData({
    params: id,
    body: {
      token,
      startDate,
      endDate,
      limit,
      page,
    },
    refetch,
    service: API.GET_PROJECT_LOG,
  });

export const useHomeTotals = (id: number, token: string, refetch?: boolean) =>
  useData({
    service: API.GET_TOTALS,
    params: id,
    body: {
      token,
    },
    refetch,
  });

export const useGetLineItemsByHomeId = (
  data: HomeProp[],
  token: string,
  refetch: boolean
) =>
  useData({
    service: API.GET_LINE_ITEMS_BY_HOME_ID,
    params: data,
    body: token,
    refetch,
  });
