import React, { StrictMode } from 'react'; // eslint-disable-line no-restricted-imports
import ReactDOM, { render } from 'react-dom';
import { ThemeProvider } from '@emotion/react';
import { BugsnagErrorBoundary } from 'features/app/utils/bugsnag';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from 'loop-react-auth';
import httpClient from 'features/app/services/httpClient';
import App from 'features/app/components/App';
import * as serviceWorker from 'serviceWorker';
import theme from 'theme';
import ReduxProvider from 'features/app/utils/ReduxProvider';
import { initSL } from 'features/app/utils/smartlook';

const isProd = process.env.NODE_ENV === 'production';

if (!isProd) {
  import('react-axe').then((axe) => axe.default(React, ReactDOM, 1000));
}

initSL();

const children = (
  <StrictMode>
    <ReduxProvider>
      <ThemeProvider theme={theme}>
        <AuthProvider httpClient={httpClient}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </ReduxProvider>
  </StrictMode>
);

if (BugsnagErrorBoundary) {
  render(
    <BugsnagErrorBoundary>{children}</BugsnagErrorBoundary>,
    document.getElementById('root')
  );
} else {
  render(children, document.getElementById('root'));
}

serviceWorker.unregister();
