import { useTheme } from '@emotion/react';
import { HouseUsdGlyph } from 'assets/glyphs';
import Card from 'features/app/components/HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import { Headline, TextBase } from 'features/app/components/Commons/Text/Text';
import { centsToDollars } from 'helpers/centsToDollars';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContentWrapper, IconWrapper, TextArea } from './TotalScopeCard.styles';

interface TotalScopeCardProps {
  total: number;
}

const TotalScopeCard: React.FC<TotalScopeCardProps> = ({ total }) => {
  const theme = useTheme();

  return (
    <Card overrideColor={theme.color.blue500}>
      <ContentWrapper>
        <IconWrapper>
          <HouseUsdGlyph size={70} color={theme.color.white} />
        </IconWrapper>
        <TextArea>
          <Headline
            weight={theme.fontWeight.bold}
            textAlign="left"
            fontSize="26"
            color={theme.color.white}
          >
            {total ? centsToDollars(total) : '$ --'}
          </Headline>
          <TextBase
            fontSize="16px"
            color={theme.color.white}
            textAlign="left"
            weight="300"
          >
            <FormattedMessage id="homes.totalScope" />
          </TextBase>
        </TextArea>
      </ContentWrapper>
    </Card>
  );
};
export default memo(TotalScopeCard);
