export interface getListOfHomesProps {
  token: string;
  page: string;
  status?: 'active' | 'stopped';
  payment_criteria?: 'pending_payment' | 'future_work' | 'earned_this_month';
  warning?: boolean;
  sortBy?: 'scope_size' | 'due_date' | 'progress' | 'recent_activity';
}

export const listOfHomesTypes = {
  SET_PAGE_HOME: 'SET_PAGE_HOME',
  LOADING_HOMES: 'LOADING_HOMES',
  GET_HOMES_FROM_HOME_VIEW: 'GET_HOMES_FROM_HOME_VIEW',
  SET_INFORMATION_HOME: 'SET_INFORMATION_HOME',
};

export type setPageHome = {
  type: typeof listOfHomesTypes.SET_PAGE_HOME;
  payload: string | number;
};

export type loadingHomes = {
  type: typeof listOfHomesTypes.LOADING_HOMES;
  payload: boolean;
};

export type getHomesFromHomeView = {
  type: typeof listOfHomesTypes.GET_HOMES_FROM_HOME_VIEW;
  payload: getListOfHomesProps;
};

export type setHomesInformation = {
  type: typeof listOfHomesTypes.SET_INFORMATION_HOME;
  payload: any;
};

export type HomesListActions =
  | setPageHome
  | loadingHomes
  | getHomesFromHomeView
  | setHomesInformation;
