import { loginWithCodeProps } from 'features/app/api/auth';
import { User } from 'features/app/db/schemas/user';
import { ErrorType, CLEAR_REDUCERS } from 'features/app/utils/globalTypes';

// ACTION TYPES
const SET_LOGIN_LOADING: 'SET_LOGIN_LOADING' = 'SET_LOGIN_LOADING';
const AUTH_WITH_TOKEN: 'AUTH_WITH_TOKEN' = 'AUTH_WITH_TOKEN';
const AUTH_SUCCESS: 'AUTH_SUCCESS' = 'AUTH_SUCCESS';
const AUTH_ERROR: 'AUTH_ERROR' = 'AUTH_ERROR';
const LOGOUT: 'LOGOUT' = 'LOGOUT';
const CLEAR_ERRORS: 'CLEAR_ERRORS' = 'CLEAR_ERRORS';
const SEND_CODE: 'SEND_CODE' = 'SEND_CODE';
const LOGIN_WITH_CODE: 'LOGIN_WITH_CODE' = 'LOGIN_WITH_CODE';
const SET_LOADING_PASSWORDLESS: 'SET_LOADING_PASSWORDLESS' =
  'SET_LOADING_PASSWORDLESS';
const SET_ERROR_PASSWORDLESS: 'SET_ERROR_PASSWORDLESS' =
  'SET_ERROR_PASSWORDLESS';
const VERIFICATION_PAGE: 'VERIFICATION_PAGE' = 'VERIFICATION_PAGE';
const SAVE_REFRESH_TOKEN: 'SAVE_REFRESH_TOKEN' = 'SAVE_REFRESH_TOKEN';
export type sendCodeAction = {
  type: typeof SEND_CODE;
  payload: string;
};

export type saveRefreshAction = {
  type: typeof SAVE_REFRESH_TOKEN;
  payload: string;
};

export type verificationPageAction = {
  type: typeof VERIFICATION_PAGE;
  payload: boolean;
};

export type passwordlessLoading = {
  type: typeof SET_LOADING_PASSWORDLESS;
  payload: boolean;
};

export type loginWithCodeAction = {
  type: typeof LOGIN_WITH_CODE;
  payload: loginWithCodeProps;
};

export type SetLoginLoadingAction = {
  type: typeof SET_LOGIN_LOADING;
  payload: {
    error: null;
    loading: boolean;
  };
};

export type AuthWithToken = {
  type: typeof AUTH_WITH_TOKEN;
  payload: {
    token: string;
  };
};

export type AuthSuccessAction = {
  type: typeof AUTH_SUCCESS;
  payload: {
    token: string;
    data: any;
  };
};

export type AuthErrorAction = {
  type: typeof AUTH_ERROR;
  payload: {
    loading: boolean;
    error: any;
  };
};

export type LogoutAction = {
  type: typeof LOGOUT;
};

export type LogoutSuccessAction = {
  type: typeof CLEAR_REDUCERS;
};

export type ClearErrorsActions = {
  type: typeof CLEAR_ERRORS;
};

export type setErrorPasswordless = {
  type: typeof SET_ERROR_PASSWORDLESS;
  payload: any;
};

export type UserActions =
  | SetLoginLoadingAction
  | AuthWithToken
  | AuthSuccessAction
  | AuthErrorAction
  | LogoutSuccessAction
  | ClearErrorsActions
  | loginWithCodeAction
  | LogoutAction
  | passwordlessLoading
  | setErrorPasswordless
  | verificationPageAction
  | saveRefreshAction
  | sendCodeAction;

export type passwordlessProps = {
  loading: boolean;
  refreshToken: string | undefined;
  error: null | boolean;
  verificationPage: boolean;
};

export type UserStateType = {
  data: User;
  loading: boolean;
  error: ErrorType | null;
  passwordLess: passwordlessProps;
};

export type UserInitialStateType = {
  data?: User;
  loading: boolean;
  error: ErrorType | null;
  passwordLess: passwordlessProps;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  SET_LOGIN_LOADING,
  AUTH_WITH_TOKEN,
  AUTH_SUCCESS,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_ERRORS,
  SEND_CODE,
  LOGIN_WITH_CODE,
  SET_LOADING_PASSWORDLESS,
  SET_ERROR_PASSWORDLESS,
  VERIFICATION_PAGE,
  SAVE_REFRESH_TOKEN,
};
