import { BACKEND_STATUS } from 'features/app/constants/lineItemStatus';
import httpClient from '../services/httpClient';
import renoBackend from '../services/renoBackend';
import { getListOfHomesProps } from '../store/listOfHomes/ListOfHomestypes';
import { HomeProp } from '../utils/types/homes';
import { HomesWithLineItems } from '../utils/types/payments';
import { renameDates } from './helper';

export const getHomeById = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await httpClient.get(`/properties/${id}`, config);
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getLineItemsApprovedById = async (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };
  try {
    const res = await httpClient.get(
      `vendors/properties/${id}/line_items/summary`,
      config
    );
    return res.data;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getHomeMilestones = async (id: number, token: string) => {
  if (!id || !token) return null;
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await httpClient.get(`properties/${id}/milestones`, config);
    const { projectsMilestones } = res?.data;
    if (projectsMilestones?.length > 0) {
      return renameDates(projectsMilestones);
    }

    return projectsMilestones;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getVendorEta = async (propertyId: number, token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await httpClient.get(
      `vendors/properties/${propertyId}/eta`,
      config
    );

    return res.data.vendor.eta;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getFutureWorkPayments = async (id: number, token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const res = await httpClient.get(
      `properties/${id}/payments/summary`,
      config
    );
    return res.data?.futurePayableJobsAmount;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getLineItemsByHomeId = async (data: HomeProp[], token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  const grouped: HomesWithLineItems[] = [];

  try {
    const promises = data.map(async (prop) => {
      let totalCost: number = 0;

      const res = await httpClient.get(
        `/properties/${prop.id}/line_items`,
        config
      );

      const lineItems: any[] = [];

      lineItems.push(...res.data.data);

      const futurePayments = lineItems.filter(
        ({ status }) =>
          status !== BACKEND_STATUS.approved &&
          status !== BACKEND_STATUS.cancelled &&
          status !== BACKEND_STATUS.completed
      );

      if (futurePayments.length > 0) {
        futurePayments.forEach((el) => {
          totalCost += el.costCents;
        });

        prop.total.cents = totalCost;
        grouped.push({
          ...prop,
          lineItems: futurePayments,
        });
      }
    });

    await Promise.all(promises);

    return grouped;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getPlannerAccessToken = async (token: string) => {
  const config = {
    headers: {
      Authorization: `${token}`,
    },
  };
  try {
    const res = await renoBackend.get(
      `planner/auth/rb-magic-link?token=${token}`,
      config
    );
    return res.data?.access;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getWorkOrderLink = async (
  propId: number,
  woId: number,
  token: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  try {
    const res = await renoBackend.get(
      `planner/vendor/get-link?propID=${propId}&wo=${woId}`,
      config
    );
    return `${res.data?.link}&wo=${woId}`;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};

export const getListOfHomes = async (payload: getListOfHomesProps) => {
  const config = {
    headers: {
      Authorization: payload.token,
    },
  };
  const { page } = payload;
  try {
    let url = `/properties?page=${page}&items=30`;
    if (payload.payment_criteria) {
      url += `&filter_by[payment_criteria]=${payload.payment_criteria}`;
    }
    if (payload.status) {
      url += `&filter_by[status]=${payload.status}`;
    }
    if (payload.warning) {
      url += `&filter_by[warning]=true`;
    }
    if (payload.sortBy) {
      url += `&sort_by=${payload.sortBy}`;
    }
    const res = await httpClient.get(url, {
      ...config,
    });
    return res;
  } catch (err) {
    if (err instanceof Error) {
      throw new Error(err.message);
    }
    throw new Error('Unknown error, something went wrong');
  }
};
