import { getLogData, getPaylogDataProps } from 'features/app/api/log';
import { LineItem } from 'features/app/utils/types/lineItems';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  SET_LOADING_FILTERS,
  SET_LOG_ITEMS,
  SET_LOG_ITEMS_ERROR,
} from './projectLogActions';
import { PROJECT_LOG_TYPES } from './projectLogTypes';

export interface getLogDataProps {
  lineItems: LineItem[];
  pagy: number;
}

export interface PayloadProjectLogData {
  id: number;
  body: getPaylogDataProps;
}

function* getProjectLogData({ payload }: PayloadProjectLogData | any) {
  try {
    yield put(SET_LOADING_FILTERS(true));
    const data: getLogDataProps = yield call(
      getLogData,
      payload.id,
      payload.body
    );
    yield put(SET_LOG_ITEMS(data));
  } catch (error) {
    yield put(SET_LOG_ITEMS_ERROR(error));
  } finally {
    yield put(SET_LOADING_FILTERS(false));
  }
}

export default function* getProjectLogDataSaga(): any {
  yield all([
    yield takeLatest(PROJECT_LOG_TYPES.ON_CHANGE_LOG_ITEMS, getProjectLogData),
  ]);
}
