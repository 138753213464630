import { useTheme } from '@emotion/react';
import { ContactGlyph, EmailGlyph, PhoneGlyph, UserGlyph } from 'assets/glyphs';
import { FormattedMessage } from 'react-intl';
import { useIsDesktop } from 'features/app/hooks/screen';
import { useState } from 'react';
import Modal from 'features/app/components/Commons/Modals/Modal';
import {
  HSpacer,
  WSpacer,
} from 'features/app/components/Commons/Spacer.styles';
import {
  TextBase,
  TextSm,
  TextXl,
  TextXs,
} from 'features/app/components/Commons/Text/Text';
import {
  ContactsCardWrapper,
  Contact,
  EmailPhoneWrapper,
  Clickable,
  ContactInfo,
  Button,
  SpinnerContact,
  SpinnerWrapper,
} from './Contacts.styles';
import { ContactCardProps, ContactProps } from './types';

const Contacts: React.FC<ContactCardProps> = ({ contacts, loading }) => {
  const theme = useTheme();
  const isDesktop = useIsDesktop();
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <>
      <>
        {!loading ? (
          <Button
            onClick={openModal}
            disabled={
              contacts.filter((item: ContactProps) => item.user).length === 0
            }
          >
            <>
              <ContactGlyph color={theme.color.white} size={24} />
              {isDesktop && (
                <>
                  <WSpacer width={5} />
                  <TextSm color={theme.color.white}>
                    <FormattedMessage id="contact.title" />
                  </TextSm>
                </>
              )}
            </>
          </Button>
        ) : (
          <SpinnerWrapper>
            <SpinnerContact />
          </SpinnerWrapper>
        )}
      </>
      {isOpen && (
        <Modal setVisibility={closeModal}>
          <ContactsCardWrapper>
            <TextXl>
              <FormattedMessage id="contact.title" />
            </TextXl>
            <HSpacer />
            {contacts.map((contact) => (
              <Contact key={contact.id}>
                <UserGlyph size={48} color={theme.color.lightFont} />
                <ContactInfo>
                  <TextBase weight={theme.fontWeight.bold}>
                    {contact?.user?.name}
                  </TextBase>
                  <TextXs>
                    <FormattedMessage id={contact.role} />
                  </TextXs>
                  <HSpacer height={5} />
                  <EmailPhoneWrapper>
                    <EmailGlyph size={20} color={theme.color.lightFont} />
                    <Clickable href={`mailto:${contact?.user?.email}`}>
                      <TextSm>{contact?.user?.email}</TextSm>
                    </Clickable>
                  </EmailPhoneWrapper>
                  <EmailPhoneWrapper>
                    <PhoneGlyph size={20} color={theme.color.lightFont} />
                    <Clickable href={`tel:${contact?.user?.phone}`}>
                      <TextSm>{contact?.user?.phone}</TextSm>
                    </Clickable>
                  </EmailPhoneWrapper>
                </ContactInfo>
              </Contact>
            ))}
          </ContactsCardWrapper>
        </Modal>
      )}
    </>
  );
};

export default Contacts;
