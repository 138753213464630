export const PROJECT_LOG_TYPES = {
  ON_SELECT_DATES: 'ON_SELECT_DATES',
  ON_SEARCH_VALUE: 'ON_SEARCH_VALUE',
  ON_MULTI_SELECT_CHANGE: 'ON_MULTI_SELECT_CHANGE',
  CLEAR_ALL_VALUES: 'CLEAR_ALL_VALUES',
  SET_LOADING_FILTERS: 'SET_LOADING_FILTERS',
  SET_LOG_ITEMS: 'SET_LOG_ITEMS',
  ON_CHANGE_LOG_ITEMS: 'ON_CHANGE_LOG_ITEMS',
  SET_LOG_ITEMS_ERROR: 'SET_LOG_ITEMS_ERROR',
  SELECT_OPTION_GROUP_BY: 'SELECT_OPTION_GROUP_BY',
  SET_PAGE: 'SET_PAGE',
  SET_LIMIT_PAGE: 'SET_LIMIT_PAGE',
};

export type OnSelectDates = {
  type: typeof PROJECT_LOG_TYPES.ON_SELECT_DATES;
  payload: Date[];
};

export type OnSetPageLimit = {
  type: typeof PROJECT_LOG_TYPES.SET_LIMIT_PAGE;
  payload: number;
};

export type OnSetPage = {
  type: typeof PROJECT_LOG_TYPES.SET_PAGE;
  payload: number;
};

export type OnSelectOptionGroupBy = {
  type: typeof PROJECT_LOG_TYPES.SELECT_OPTION_GROUP_BY;
  payload: any;
};

export type getLogItemsError = {
  type: typeof PROJECT_LOG_TYPES.SET_LOG_ITEMS_ERROR;
  payload: any;
};

export type OnLoading = {
  type: typeof PROJECT_LOG_TYPES.SET_LOADING_FILTERS;
  payload: boolean;
};

export type setLogItems = {
  type: typeof PROJECT_LOG_TYPES.SET_LOG_ITEMS;
  payload: any;
};

export type OnSearchValue = {
  type: typeof PROJECT_LOG_TYPES.ON_SEARCH_VALUE;
  payload: string;
};

export type OnMultiSelectChange = {
  type: typeof PROJECT_LOG_TYPES.ON_MULTI_SELECT_CHANGE;
  payload: any;
};

export type ClearAllValues = {
  type: typeof PROJECT_LOG_TYPES.CLEAR_ALL_VALUES;
  payload: undefined;
};

export type ProjectLogActions =
  | OnSetPageLimit
  | OnSetPage
  | OnSelectDates
  | OnSearchValue
  | OnMultiSelectChange
  | ClearAllValues
  | OnLoading
  | setLogItems
  | getLogItemsError
  | OnSelectOptionGroupBy;
