import Content from '../components/Commons/Content.styles';
import SummaryView from '../components/SummaryView';

interface SummaryPageProps {
  hideLayout: (active: boolean) => void;
}

const SummaryPage: React.FC<SummaryPageProps> = ({ hideLayout }) => {
  hideLayout(false);
  return (
    <Content>
      <SummaryView />
    </Content>
  );
};

export default SummaryPage;
