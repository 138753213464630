import { ModalsActions, MODALS_TYPES } from './modalsTypes';

const initialState = {
  bonusModalVisibility: false,
  helpInfoModalVisibility: false,
  userInfoModalVisibility: false,
};

export const ModalsReducers = (state = initialState, action: ModalsActions) => {
  switch (action.type) {
    case MODALS_TYPES.ON_BONUS_MODAL_VISIBILITY_CHANGE:
      return {
        ...state,
        bonusModalVisibility: action.payload,
      };
    case MODALS_TYPES.ON_HELP_MODAL_VISIBILITY_CHANGE:
      return {
        ...state,
        helpInfoModalVisibility: action.payload,
      };
    case MODALS_TYPES.ON_USER_MODAL_VISIBILITY_CHANGE:
      return {
        ...state,
        userInfoModalVisibility: action.payload,
      };
    default:
      return state;
  }
};
