import styled from '@emotion/styled';

export const SignOutWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
  left: -5px;
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
`;
