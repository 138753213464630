import { IconPropTypes } from './types';

const BrokenLinkWOIcon = ({ width, height, color }: IconPropTypes) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
  >
    <rect width="40" height="40" rx="20" fill="#E9E9E9" />
    <path
      d="M22 14.75H26C26.6566 14.75 27.3068 14.8793 27.9134 15.1306C28.52 15.3819 29.0712 15.7502 29.5355 16.2145C29.9998 16.6788 30.3681 17.23 30.6194 17.8366C30.8707 18.4432 31 19.0934 31 19.75C31 20.4066 30.8707 21.0568 30.6194 21.6634C30.3681 22.27 29.9998 22.8212 29.5355 23.2855C29.0712 23.7498 28.52 24.1181 27.9134 24.3694M22 24.75H23.5M18 24.75H14C13.3434 24.75 12.6932 24.6207 12.0866 24.3694C11.48 24.1181 10.9288 23.7498 10.4645 23.2855C9.52678 22.3479 9 21.0761 9 19.75C9 18.4239 9.52678 17.1521 10.4645 16.2145C11.2216 15.4573 12.1967 14.968 13.2422 14.8077"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 19.75H18.5"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24 19.75H25"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 10.25L28.5 29.75"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default BrokenLinkWOIcon;
