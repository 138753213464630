import { useState } from 'react';
import { TextXs } from '../../Text/Text';
import { Button, Item, List, Wrapper } from './DropdownButton.styles';

interface DropdownButtonProps {
  onClick: (key: string) => void;
  items: { key: string; value: string }[];
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  children,
  items,
  onClick,
}) => {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    if (!clicked) {
      setOpen(true);
      setClicked(true);
    }
    if (clicked && open) {
      setOpen(false);
      setClicked(false);
    }
  };

  const handleMouseLeave = () => {
    if (!clicked) setOpen(false);
  };

  const handleItemClick = (item: string) => {
    onClick(item);
    setOpen(false);
    setClicked(false);
  };

  return (
    <Wrapper onMouseEnter={() => setOpen(true)} onMouseLeave={handleMouseLeave}>
      <Button onClick={handleClick}>{children}</Button>
      <List open={open}>
        {items.map((item, index) => (
          <Item
            key={item.key}
            onClick={() => handleItemClick(item.key)}
            start={index === 0}
            end={index === items.length - 1}
          >
            <TextXs>{item.value}</TextXs>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default DropdownButton;
