const PlusIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9998 24.3334C20.6022 24.3334 24.3332 20.6025 24.3332 16.0001C24.3332 11.3977 20.6022 7.66675 15.9998 7.66675C11.3975 7.66675 7.6665 11.3977 7.6665 16.0001C7.6665 20.6025 11.3975 24.3334 15.9998 24.3334Z"
      stroke="#373737"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16 12.6667V19.3334"
      stroke="#373737"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6665 16H19.3332"
      stroke="#373737"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PlusIcon;
