import styled from '@emotion/styled';

interface ModalFreePosProps {
  clientX: number;
  clientY: number;
  isMobile: boolean;
  maxWidth: boolean;
  customWidth?: boolean;
}

export const BackgroundOuter = styled.div`
  z-index: ${({ theme }) => theme.zIndex.modal.background};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 100ms;
`;

export const ModalWrapper = styled.div<ModalFreePosProps>`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.modal.content};
  top: ${({ clientY }) => clientY}px;
  left: ${({ clientX }) => clientX}px;
  width: ${({ customWidth }) => (customWidth ? '28%' : 'auto')};
  max-width: ${({ isMobile, maxWidth }) =>
    isMobile || maxWidth ? 'none' : '500px'};
  max-height: 500px;
  box-sizing: border-box;
  border-radius: 10px;
  background: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.boxShadow.modals};
  transition-property: transform;
  transition-duration: 100ms;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e6ecf1;
    border-radius: 10px;
  }
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    right: 15px;
    top: 10px;
  }
`;
