import CalendarView from '../components/CalendarView/CalendarView';

interface CalendarPageProps {
  hideLayout: (active: boolean) => void;
}

const CalendarPage: React.FC<CalendarPageProps> = ({ hideLayout }) => {
  hideLayout(false);
  return <CalendarView />;
};

export default CalendarPage;
