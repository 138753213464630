import { useTheme } from '@emotion/react';
import { DownArrowGlyph } from 'assets/glyphs';
import { format } from 'date-fns';
import { TextBase, TextSm } from 'features/app/components/Commons/Text/Text';
import {
  COMPLETED,
  APPROVAL_NEEDED,
  PENDING,
  COMPLETED_PROJECT_LOG,
} from 'features/app/constants/lineItemStatus';
import { ItemProp } from 'features/app/utils/types/payments';
import { useIsMobile } from 'features/app/hooks/screen';
import { centsToDollars } from 'helpers/centsToDollars';
import ResultsIcon from 'assets/svgs/Results';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Dot,
  TableTd,
  TableTh,
  TableTr,
  TableWrapper,
  StatusWrapper,
  DescriptionWrapper,
  TableTdDescription,
  DescriptionContent,
  TextFlex,
  ArrowWrapper,
  EmptyTableResults,
} from './Table.styles';
import { HSpacer, WSpacer } from '../../../Commons/Spacer.styles';

interface TableProps {
  data?: ItemProp[];
}

const Table: React.FC<TableProps> = ({ data }) => {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState<null | number>();
  const formatDate = (date: string) => format(new Date(date), 'MM/dd/yyyy');
  const headers = isMobile
    ? [
        { id: 1, name: 'paylog.paymentId' },
        { id: 3, name: 'paylog.status' },
        { id: 4, name: 'paylog.paymentDate' },
        { id: 6, name: 'paylog.amount' },
      ]
    : [
        { id: 1, name: 'paylog.paymentId' },
        { id: 2, name: 'paylog.status' },
        // { id: 3, name: 'paylog.markedComplete' },
        { id: 4, name: 'paylog.paymentDate' },
        { id: 5, name: 'paylog.amount' },
        { id: 6 },
      ];

  const onOpenRow = (id: number) => {
    if (isOpen === id) {
      setIsOpen(null);
    } else {
      setIsOpen(id);
    }
  };

  const checkIsOpen = (id: number) => isOpen === id;

  const getStatus = (status: string) => {
    if (status === COMPLETED || status === COMPLETED_PROJECT_LOG) {
      return {
        text: 'paylog.statusNames.paid',
        color: theme.color.success,
      };
    }
    if (status === PENDING) {
      return {
        text: 'paylog.statusNames.awaitingPayment',
        color: theme.color.contactIcons,
      };
    }
    if (status === APPROVAL_NEEDED) {
      return {
        text: 'paylog.statusNames.approvalNeeded',
        color: theme.color.contactIcons,
      };
    }
    return {
      text: 'paylog.statusNames.cancelled',
      color: theme.color.error,
    };
  };

  return (
    <>
      {data?.length === 0 ? (
        <EmptyTableResults>
          <ResultsIcon width="50px" height="50px" />
          <HSpacer />
          <TextSm color={theme.color.lightFont}>
            <FormattedMessage id="paylog.empty" />
          </TextSm>
        </EmptyTableResults>
      ) : (
        <TableWrapper>
          <thead>
            <TableTr>
              {headers.map(({ id, name }) => (
                <TableTh key={id}>
                  <TextBase color={theme.color.lightFont}>
                    {name && <FormattedMessage id={name} />}
                  </TextBase>
                </TableTh>
              ))}
            </TableTr>
          </thead>
          {data?.map((item) => (
            <tbody key={item.id}>
              <TableTr onClick={() => onOpenRow(item?.id)}>
                <TableTd open={checkIsOpen(item.id)}>
                  <TextSm color={theme.color.lightFont}>{item?.id}</TextSm>
                </TableTd>
                <TableTd open={checkIsOpen(item?.id)}>
                  <StatusWrapper>
                    <Dot color={getStatus(item?.status).color} />
                    <WSpacer width={5} />
                    {!isMobile && (
                      <TextSm color={theme.color.lightFont} fullWidth>
                        <FormattedMessage id={getStatus(item?.status).text} />
                      </TextSm>
                    )}
                  </StatusWrapper>
                </TableTd>
                {/* Disabled until get a solution for Marked Complete dates  */}
                {/* <TableTd open={checkIsOpen(item?.id)}>
                  <TextSm color={theme.color.lightFont}>
                    {item?.approvedAt ? formatDate(item?.approvedAt) : '--'}
                  </TextSm>
                </TableTd> */}
                <TableTd open={checkIsOpen(item?.id)}>
                  <TextSm color={theme.color.lightFont}>
                    {item?.completedAt ? formatDate(item?.completedAt) : '--'}
                  </TextSm>
                </TableTd>
                <TableTd open={checkIsOpen(item?.id)}>
                  <TextSm
                    weight={theme.fontWeight.bold}
                    color={theme.color.lightFont}
                  >
                    {centsToDollars(item?.amountCents)}
                  </TextSm>
                </TableTd>
                {!isMobile && (
                  <TableTd open={checkIsOpen(item?.id)}>
                    <ArrowWrapper open={checkIsOpen(item?.id)}>
                      <DownArrowGlyph
                        size={12}
                        viewBoxHeight={24}
                        viewBoxWidth={24}
                        color={theme.color.lightFont}
                      />
                    </ArrowWrapper>
                  </TableTd>
                )}
              </TableTr>
              {checkIsOpen(item?.id) && (
                <TableTdDescription colSpan={7}>
                  <DescriptionWrapper>
                    <DescriptionContent>
                      <TextFlex>
                        <TextSm color={theme.color.lightFont}>
                          <FormattedMessage id="common.description" />
                        </TextSm>
                      </TextFlex>
                      <TextFlex>
                        {item?.lineItems?.map((line) => (
                          <TextSm color={theme.color.lightFont} key={line}>
                            - {line}
                          </TextSm>
                        ))}
                      </TextFlex>
                    </DescriptionContent>
                  </DescriptionWrapper>
                </TableTdDescription>
              )}
            </tbody>
          ))}
        </TableWrapper>
      )}
    </>
  );
};

export default Table;
