import CenterIconMenu from 'assets/svgs/CenterIconMenu';
import LeftIconMenu from 'assets/svgs/LeftIconMenu';
import RightIconMenu from 'assets/svgs/RightIconMenu';
import { HeaderMobileWrapper, WrapperIcon } from './HeaderMobile.styles';

export interface HeaderMobileProps {
  onOpenLeft: () => void;
  onOpenRight: () => void;
}

export default function HeaderMobile({
  onOpenLeft,
  onOpenRight,
}: HeaderMobileProps) {
  return (
    <HeaderMobileWrapper>
      <WrapperIcon onClick={onOpenLeft}>
        <LeftIconMenu />
      </WrapperIcon>
      <WrapperIcon>
        <CenterIconMenu />
      </WrapperIcon>
      <WrapperIcon onClick={onOpenRight}>
        <RightIconMenu />
      </WrapperIcon>
    </HeaderMobileWrapper>
  );
}
