import { useTheme } from '@emotion/react';
import { useIsMobile } from 'features/app/hooks/screen';
import { centsToDollars } from 'helpers/centsToDollars';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Card from '../../../../../HomeView/Components/HomesCardsList/Components/HomeCard/Card';
import Chip from '../../../../../Commons/Chip';
import { HSpacer } from '../../../../../Commons/Spacer.styles';
import ProgressBar from '../../../../../Commons/ProgressBar/ProgressBar';
import { TextBase } from '../../../../../Commons/Text/Text';
import {
  PaylogCardWrapper,
  PaymentStatus,
  Payments,
  StatusTitle,
  Amount,
  Amounts,
} from './PaylogCard.styles';

interface PaylogCardProps {
  paid?: number;
  workRemaining?: number;
  paymentProcessing?: number;
  total?: number;
}

const PaylogCard: React.FC<PaylogCardProps> = ({
  paid,
  workRemaining,
  paymentProcessing,
  total,
}) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();
  const firstProgress =
    paid !== undefined && total !== undefined ? (paid / total) * 100 : 0;
  const secondProgress =
    paid !== undefined && paymentProcessing !== undefined && total !== undefined
      ? ((paid + paymentProcessing) / total) * 100
      : 0;

  const status = [
    {
      id: 'paid',
      title: formatMessage({ id: 'paylogCard.paid' }),
      color: theme.color.workStatus.paid,
      backgroundColor: theme.color.workStatus.paidBg,
      amount: paid,
    },
    {
      id: 'paymentProcessing',
      title: formatMessage({ id: 'paylogCard.paymentProcessing' }),
      color: theme.color.workStatus.payProcessing,
      backgroundColor: theme.color.workStatus.payProcessingBg,
      amount: paymentProcessing,
    },
    {
      id: 'workRemaining',
      title: formatMessage({ id: 'paylogCard.workRemaining' }),
      color: theme.color.workStatus.workRemaining,
      backgroundColor: theme.color.workStatus.workRemainingBg,
      amount: workRemaining,
    },
  ];

  return (
    <Card customPadding={0}>
      <PaylogCardWrapper>
        {isMobile && <HSpacer height={10} />}
        <TextBase>
          <FormattedMessage id="paylogCard.title" />
        </TextBase>
        <HSpacer height={10} />
        <ProgressBar
          firstProgress={firstProgress}
          secondProgress={secondProgress}
        />
        <Payments>
          <PaymentStatus>
            {status.map(({ id, title, color, backgroundColor }) => (
              <StatusTitle key={id}>
                <Chip
                  text={title}
                  textColor={color}
                  color={backgroundColor}
                  close={false}
                />
              </StatusTitle>
            ))}
          </PaymentStatus>
          <Amounts>
            {status.map(({ amount, id }) => (
              <Amount key={id}>{centsToDollars(amount)}</Amount>
            ))}
          </Amounts>
        </Payments>
      </PaylogCardWrapper>
    </Card>
  );
};

export default memo(PaylogCard);
