import FilterIcon from 'assets/svgs/FilterIcon';
import { Text } from 'features/app/components/Commons/Text/Text';
import { useIsDesktop } from 'features/app/hooks/screen';
import { SHOW_FILTERS } from 'features/app/store/homesFilters/homeFiltersActions';
import { useDispatch } from 'react-redux';
import { FilterButtonWrapper, NumberWrapper } from './FilterButton.styles';
import { FilterButtonProps } from './types';

const FilterButton: React.FC<FilterButtonProps> = ({
  showFilters,
  selectedItems,
}) => {
  const dispatch = useDispatch();
  const clickOnShowFilter = () => {
    dispatch(SHOW_FILTERS(!showFilters));
  };
  const isDesktop = useIsDesktop();

  return (
    <FilterButtonWrapper
      selected={selectedItems > 0}
      onClick={clickOnShowFilter}
    >
      <FilterIcon
        color={selectedItems > 0 ? 'white' : undefined}
        width={18}
        height={18}
      />
      {!showFilters
        ? isDesktop && (
            <Text
              color={selectedItems === 0 ? '#212121' : 'white'}
              fontSize="14"
            >
              Show filters
            </Text>
          )
        : isDesktop && (
            <Text
              color={selectedItems === 0 ? '#212121' : 'white'}
              fontSize="14"
            >
              Hide filters
            </Text>
          )}
      {selectedItems !== 0 && (
        <NumberWrapper>
          <Text fontSize="12">{selectedItems}</Text>
        </NumberWrapper>
      )}
    </FilterButtonWrapper>
  );
};

export default FilterButton;
