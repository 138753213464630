import styled from '@emotion/styled';

export const ProjectBonusWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(62, 62, 62, 0.2);
`;

export const ProjectBonusContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 18px 23px;
  width: 585px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 5px;

  @media (max-width: 768px) {
    margin-top: 80px;
    width: 300px;
  }
`;

export const CloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 11px;
  top: 13px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobile.max}) {
    right: 15px;
    top: 10px;
  }
`;

export const BonusSection = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
`;

export const Headline = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.x5l};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-family: ${({ theme }) => theme.fontFamily.name};
  color: ${({ theme }) => theme.color.font};
`;

export const BonusEarningContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 71px;
  padding-top: 15px;
  padding-left: 21px;
  display: flex;
  gap: 14px;
  background-color: ${({ theme }) => theme.color.progressBar.secondary};
  border-radius: 5px;
`;

export const BonusTotal = styled.div`
  position: relative;
  top: -5px;
`;

export const HomesForBonus = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

export const HomeBonus = styled.div`
  width: 260px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DoneWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #38df7b;
`;

export const UnfinishedWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: #ebebeb;
`;

export const NexBonusWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.progressBar.secondary};
`;
