import styled from '@emotion/styled';

interface BlurProps {
  animation?: boolean;
}

export const Blur = styled.div<BlurProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ animation }) => (animation ? 0 : 1)};
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px) saturate(200%);
  -webkit-backdrop-filter: blur(2px) saturate(200%);
  z-index: 10;
  transition-property: opacity;
  transition-duration: 400ms;
`;
