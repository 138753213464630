import styled from '@emotion/styled';
import { FilterButtonWrapperProps } from './types';

export const FilterButtonWrapper = styled.div<FilterButtonWrapperProps>`
  box-sizing: border-box;
  height: 34px;
  padding: 0 10px;
  display: flex;
  gap: 7px;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
  cursor: pointer;
  background-color: ${(props) => props.selected && '#009FDD'};
`;

export const NumberWrapper = styled.div`
  background-color: white;
  border-radius: 60px;
  padding-inline: 6px;
  padding-block: 1px;
`;
