import { useState } from 'react';

import MenuSideBar from 'features/app/components/Commons/MenuSideBar';
import MenuRightSideBar from 'features/app/components/Commons/MenuRightSideBar';
import MobileSideMenu from 'features/app/components/Commons/MobileSideMenu';
import SideMenuLeft from 'features/app/components/Commons/MobileSideMenu/components/SideMenuLeft';
import SideMenuRight from 'features/app/components/Commons/MobileSideMenu/components/SideMenuRight';
import { Blur } from 'features/app/components/Commons/Blur.styles';
import HeaderMobile from 'features/app/components/Commons/HeaderMobile';
import { useIsDesktop, useShowRightNavBar } from 'features/app/hooks/screen';
import HeaderDesktop from 'features/app/components/Commons/HeaderDesktop/HeaderDesktop';
import { MainContainer, Main, ContentWrapperNavBar } from './MainLayout.styles';

interface MainLayoutProps {
  disable?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children, disable }) => {
  const [showLeftMenu, setShowLeftMenu] = useState(false);
  const [showRightMenu, setShowRightMenu] = useState(false);

  const isDesktop = useIsDesktop();

  const onOpenLeftMenuMobile = () => {
    setShowLeftMenu(true);
  };

  const onOpenRightMenuMobile = () => {
    setShowRightMenu(true);
  };

  const onCloseLeft = () => {
    setShowLeftMenu(false);
  };

  const onCloseRight = () => {
    setShowRightMenu(false);
  };

  const closeCorrectMenu = () => {
    if (showRightMenu) {
      setShowRightMenu(false);
    } else {
      setShowLeftMenu(false);
    }
  };

  const showRightNavBar = useShowRightNavBar();

  return (
    <Main>
      {isDesktop && <HeaderDesktop />}
      {!isDesktop && (
        <HeaderMobile
          onOpenLeft={onOpenLeftMenuMobile}
          onOpenRight={onOpenRightMenuMobile}
        />
      )}
      <ContentWrapperNavBar>
        <MenuSideBar />
        {(showRightMenu || showLeftMenu) && <Blur onClick={closeCorrectMenu} />}
        <MainContainer
          showRightNavBar={showRightNavBar}
          disabledLayout={!isDesktop}
        >
          {children}
        </MainContainer>
        {isDesktop && showRightNavBar && <MenuRightSideBar />}
      </ContentWrapperNavBar>
      {/* Mobile Menus */}
      {!isDesktop && (
        <MobileSideMenu isLeft show={showLeftMenu}>
          <SideMenuLeft onClose={onCloseLeft} />
        </MobileSideMenu>
      )}
      {!isDesktop && (
        <MobileSideMenu isLeft={false} show={showRightMenu}>
          <SideMenuRight onClose={onCloseRight} />
        </MobileSideMenu>
      )}
    </Main>
  );
};

export default MainLayout;
