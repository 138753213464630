import styled from '@emotion/styled';

export const WrapperProgress = styled.div``;

export const PercentageProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const TextContainer = styled.div`
  margin-inline: 3px;
`;
