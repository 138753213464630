/* eslint-disable no-nested-ternary */
import { useTheme } from '@emotion/react';
import CheckIcon from 'assets/svgs/CheckIcon';
import { format } from 'date-fns';
import {
  CANCELED,
  COMPLETED,
  GROUP_BY_OPTIONS,
  PAY_PROCESSING,
  WORK_REMAINING,
} from 'features/app/constants/lineItemStatus';
import { useIsMobile } from 'features/app/hooks/screen';
import {
  CLEAR_ALL_VALUES,
  OnChageLogItems,
  ON_MULTI_SELECT_CHANGE,
  ON_SEARCH_VALUE,
  ON_SELECT_DATES,
  SELECT_OPTION_GROUP_BY,
} from 'features/app/store/projectLog/projectLogActions';
import { getHomeIdPath } from 'helpers/path';
import { FormattedMessage, useIntl } from 'react-intl';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import DatePicker from '../../../../../Commons/Inputs/DatePicker';
import MultiSelect from '../../../../../Commons/Inputs/MultiSelect';
import FilterSearch from '../../../../../Commons/Inputs/Search/FilterSearch';
import FilterSelect from '../../../../../Commons/Inputs/Select/FilterSelect/FilterSelect';
import Chip from '../../../../../Commons/Chip';
import { WSpacer } from '../../../../../Commons/Spacer.styles';
import { TextSm } from '../../../../../Commons/Text/Text';
import {
  ChipsSubWrapper,
  ChipsWrapper,
  ClearButton,
  FilterContent,
  FiltersWrapper,
  SelectedFilterWrapper,
} from './Filter.styles';

interface FilterProps {
  open?: boolean;
  page: number | undefined;
  pageLimit: number | undefined;
}

export interface OptionsSelect {
  value: string;
  label: string;
}

export const getStatusAndPaymentStatusFilter = (
  multiSelection: OptionsSelect[]
) => {
  const status: string[] = [];
  multiSelection.forEach(({ value }: { value: string }) => {
    status.push(value);
  });
  return status;
};

const Filter: React.FC<FilterProps> = ({ open }) => {
  const { dates, search, multiselect, groupBy, page, limitPage } = useSelector(
    (store: RootStateOrAny) => store.projectLog
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const options = [
    {
      label: formatMessage({ id: 'paylog.statusNames.paymentProcessing' }),
      value: PAY_PROCESSING,
    },
    {
      label: formatMessage({ id: 'paylog.statusNames.paid' }),
      value: COMPLETED,
    },
    {
      label: formatMessage({ id: 'paylog.statusNames.cancelled' }),
      value: CANCELED,
    },
    {
      label: formatMessage({ id: 'paylog.statusNames.workRemaining' }),
      value: WORK_REMAINING,
    },
  ];

  const optionGroupBy = [
    {
      label: formatMessage({ id: 'paylog.statusNames.payment' }),
      value: GROUP_BY_OPTIONS.PAYMENTS,
    },
    {
      label: formatMessage({ id: 'paylog.statusNames.workorder' }),
      value: GROUP_BY_OPTIONS.WORK_ORDERS,
    },
  ];

  const hasMultiSelect = multiselect?.length > 0;

  const onDateChange = (datesValue: Date[]) => {
    const [start, end] = datesValue;
    if (start && end) {
      dispatch(ON_SELECT_DATES(datesValue));
      const auxToDispatch = getStatusAndPaymentStatusFilter(multiselect);
      const groupByData = groupBy.map((item: OptionsSelect) => item.value);
      dispatch(
        OnChageLogItems({
          id: getHomeIdPath(location),
          body: {
            token: data.token,
            startDate: start,
            endDate: end,
            page,
            limit: limitPage,
            name: search,
            status: auxToDispatch,
            group_by: groupByData.length > 0 ? groupByData[0] : '',
          },
        })
      );
    }
  };

  const onSearchChange = (searchValue: string) => {
    dispatch(ON_SEARCH_VALUE(searchValue));
    const groupByData = groupBy.map((item: OptionsSelect) => item.value);
    const auxToDispatch = getStatusAndPaymentStatusFilter(multiselect);
    dispatch(
      OnChageLogItems({
        id: getHomeIdPath(location),
        body: {
          token: data.token,
          startDate: dates.length > 0 ? dates[0] : undefined,
          endDate: dates.length > 0 ? dates[1] : undefined,
          page,
          limit: limitPage,
          name: searchValue,
          status: auxToDispatch,
          group_by: groupByData.length > 0 ? groupByData[0] : '',
        },
      })
    );
  };

  const onSelectOptionGroupBy = (selectOptionGroup: OptionsSelect) => {
    const auxToDispatch = getStatusAndPaymentStatusFilter(multiselect);
    if (
      groupBy.filter(
        (optionSelected: OptionsSelect) =>
          optionSelected.label === selectOptionGroup.label
      ).length > 0
    ) {
      dispatch(SELECT_OPTION_GROUP_BY([]));
      dispatch(
        OnChageLogItems({
          id: getHomeIdPath(location),
          body: {
            token: data.token,
            startDate: dates.length > 0 ? dates[0] : undefined,
            endDate: dates.length > 0 ? dates[1] : undefined,
            page,
            limit: limitPage,
            name: search,
            status: auxToDispatch,
            group_by: '',
          },
        })
      );
    } else {
      dispatch(SELECT_OPTION_GROUP_BY([selectOptionGroup]));
      dispatch(
        OnChageLogItems({
          id: getHomeIdPath(location),
          body: {
            token: data.token,
            startDate: dates.length > 0 ? dates[0] : undefined,
            endDate: dates.length > 0 ? dates[1] : undefined,
            page,
            limit: limitPage,
            name: search,
            status: auxToDispatch,
            group_by: selectOptionGroup.value,
          },
        })
      );
    }
  };

  const onMultiSelectChange = (multiSelect: OptionsSelect[]) => {
    dispatch(ON_MULTI_SELECT_CHANGE(multiSelect));
    const auxToDispatch = getStatusAndPaymentStatusFilter(multiSelect);
    const groupByData = groupBy.map((item: OptionsSelect) => item.value);
    dispatch(
      OnChageLogItems({
        id: getHomeIdPath(location),
        body: {
          token: data.token,
          startDate: dates.length > 0 ? dates[0] : undefined,
          endDate: dates.length > 0 ? dates[1] : undefined,
          page,
          limit: limitPage,
          name: search,
          status: auxToDispatch,
          group_by: groupByData.length > 0 ? groupByData[0] : '',
        },
      })
    );
  };

  const clearChips = (key: string, value: string | OptionsSelect) => {
    if (key === 'multiSelect') {
      const aux = multiselect;
      const findIndex = aux?.findIndex((x: any) => x === value);
      aux?.splice(findIndex || 0, 1);
    }
    if (key === 'date') {
      dispatch(ON_SELECT_DATES([]));
    }
    if (key === 'search') {
      onSearchChange('');
    }
    if (key === 'groupBy') {
      dispatch(SELECT_OPTION_GROUP_BY([]));
    }
    //
    const auxToDispatch = getStatusAndPaymentStatusFilter(multiselect);
    const groupByData = groupBy.map((item: OptionsSelect) => item.value);
    dispatch(
      OnChageLogItems({
        id: getHomeIdPath(location),
        body: {
          token: data.token,
          startDate:
            key === 'date'
              ? undefined
              : dates.length > 0
              ? dates[0]
              : undefined,
          endDate:
            key === 'date'
              ? undefined
              : dates.length > 0
              ? dates[1]
              : undefined,
          page,
          limit: limitPage,
          name: key === 'search' ? '' : search,
          status: auxToDispatch,
          group_by: key === 'groupBy' ? '' : groupByData[0],
        },
      })
    );
  };

  const ClearFilters = () => {
    if (
      multiselect.length !== 0 ||
      dates.length !== 0 ||
      search.length !== 0 ||
      groupBy.length !== 0
    ) {
      dispatch(CLEAR_ALL_VALUES());
      dispatch(
        OnChageLogItems({
          id: getHomeIdPath(location),
          body: {
            token: data.token,
            startDate: undefined,
            endDate: undefined,
            page,
            limit: limitPage,
            name: '',
            status: [],
            group_by: '',
          },
        })
      );
    }
  };

  return (
    <>
      <FiltersWrapper open={open}>
        <FilterContent>
          <DatePicker valueDate={dates} onChange={onDateChange} />
        </FilterContent>
        <FilterContent>
          <MultiSelect
            onChange={onMultiSelectChange}
            icon={<CheckIcon width={16} height={16} color={theme.color.font} />}
            label="filter.status"
            options={options}
            multiSelectValues={multiselect}
          />
        </FilterContent>
        <FilterContent>
          <FilterSearch
            onChange={onSearchChange}
            placeholder="filter.search"
            valueSearch={search}
          />
        </FilterContent>
        <FilterContent>
          <FilterSelect
            onChange={onSelectOptionGroupBy}
            groupByData={groupBy}
            icon={<CheckIcon width={16} height={16} color={theme.color.font} />}
            label="filter.groupBy"
            options={optionGroupBy}
          />
        </FilterContent>
        {!isMobile && (
          <FilterContent fixed>
            <ClearButton onClick={ClearFilters}>
              <FormattedMessage id="filter.clearFilters" />
            </ClearButton>
          </FilterContent>
        )}
      </FiltersWrapper>
      {(hasMultiSelect || dates.length > 0 || search || groupBy.length > 0) && (
        <ChipsWrapper>
          <ChipsSubWrapper>
            <SelectedFilterWrapper>
              <TextSm style={{ whiteSpace: 'nowrap' }}>Selected Filters</TextSm>
            </SelectedFilterWrapper>
            {dates.length > 0 && dates[1] && (
              <>
                <Chip
                  weight={theme.fontWeight.light}
                  text={
                    format(dates[0], 'MMM d - ') + format(dates[1], 'MMM d')
                  }
                  color={theme.color.white}
                  textColor={theme.color.chipsFont}
                  close
                  onClose={() => clearChips('date', dates)}
                />
                <WSpacer />
              </>
            )}
            {multiselect.map((e: any) => (
              <>
                <Chip
                  weight={theme.fontWeight.light}
                  text={e.label}
                  color={theme.color.white}
                  textColor={theme.color.chipsFont}
                  close
                  onClose={() => clearChips('multiSelect', e)}
                />
                <WSpacer />
              </>
            ))}
            {search && (
              <>
                <Chip
                  weight={theme.fontWeight.light}
                  text={search}
                  color={theme.color.white}
                  textColor={theme.color.chipsFont}
                  close
                  onClose={() => clearChips('search', search)}
                />
                <WSpacer />
              </>
            )}
            {groupBy.map((e: any) => (
              <>
                <Chip
                  weight={theme.fontWeight.light}
                  text={e.label}
                  color={theme.color.white}
                  textColor={theme.color.chipsFont}
                  close
                  onClose={() => clearChips('groupBy', e)}
                />
                <WSpacer />
              </>
            ))}
          </ChipsSubWrapper>
        </ChipsWrapper>
      )}
    </>
  );
};

export default Filter;
