import { IconPropTypes } from './types';

const ChevronLeftIcon = ({ width, height, color }: IconPropTypes) => (
  <svg width={width} height={height} fill="none" viewBox="0 0 24 24">
    <path
      d="m15 18-6-6 6-6"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ChevronLeftIcon;
