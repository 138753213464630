import {
  darkLightModeActions,
  DARK_LIGHT_MODE_TYPES,
} from './darkLightModeTypes';

const initalState = { darkMode: false };

export const darkLightModeReducer = (
  state = initalState,
  action: darkLightModeActions
) => {
  switch (action.type) {
    case DARK_LIGHT_MODE_TYPES.ON_SET_DARK_LIGHT_MODE:
      return {
        ...state,
        darkMode: action.payload,
      };
    default:
      return state;
  }
};
