import styled from '@emotion/styled';

export const SideMenuRightWrapper = styled.div`
  height: 100vh;
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  background-color: ${({ theme }) => theme.color.white};
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const VendorInfoPlusCloseMenu = styled.div`
  margin-bottom: 20px;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 25px;

  & > div {
    margin: 0;
  }
`;
