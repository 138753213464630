import {
  CheckedBox,
  SelectInput,
  SelectOption,
  SelectOptionsContainer,
  SelectValue,
} from './Select.styles';

interface SelectProps {
  options: string[];
  showOptions: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
  onSelectOption: (e: string) => void;
  multipleOption: boolean;
  selectedValue: string | string[];
}

export default function Select({
  options,
  showOptions,
  onClick,
  onSelectOption,
  multipleOption,
  selectedValue,
}: SelectProps) {
  return (
    <SelectInput>
      {multipleOption && (
        <SelectValue onClick={onClick}>
          {selectedValue.length <= 1
            ? selectedValue
            : `${selectedValue.length} items selected`}
        </SelectValue>
      )}
      {!multipleOption && (
        <SelectValue onClick={onClick}>
          {selectedValue === '' ? options[0] : selectedValue}
        </SelectValue>
      )}
      {showOptions !== false && (
        <SelectOptionsContainer>
          {options.map((el) => (
            <SelectOption key={el} onClick={() => onSelectOption(el)}>
              <CheckedBox
                isSelected={
                  multipleOption
                    ? selectedValue.indexOf(el) !== -1 && true
                    : el === selectedValue && true
                }
              />
              {el}
            </SelectOption>
          ))}
        </SelectOptionsContainer>
      )}
    </SelectInput>
  );
}
