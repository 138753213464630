import { useTheme } from '@emotion/react';
import FutureHomeIcon from 'assets/svgs/FutureHomeIcon';
import FutureTaskIcon from 'assets/svgs/FutureTaskIcon';
import HomeIcon from 'assets/svgs/HomeIcon';
import TaskIcon from 'assets/svgs/TaskIcon';
import { useVendorsSummaryData } from 'features/app/hooks/data';
import { useIsMobile } from 'features/app/hooks/screen';
import { FormattedMessage } from 'react-intl';
import { RootStateOrAny, useSelector } from 'react-redux';
import Calendar from './Components/Calendar';
import CardIcon from '../HomeView/Components/HomesCardsList/Components/HomeCard/Components/Card/CardIcon';
import { HSpacer } from '../Commons/Spacer.styles';
import { Headline } from '../Commons/Text/Text';
import {
  CalendarViewHeader,
  CalendarViewWrapper,
  CardIconWrapper,
  DivisorLine,
} from './CalendarView.styles';

const CalendarView: React.FC<{}> = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { data } = useSelector((state: RootStateOrAny) => state.auth);
  const vendorsSummary = useVendorsSummaryData(data?.token);
  const cards = [
    {
      id: 1,
      title: 'calendar.activeHomes',
      icon: <HomeIcon />,
      number: vendorsSummary?.data?.activeHomes || 0,
      subtitle: 'calendar.totalProjects',
      buttonMsg: 'calendar.viewSummary',
      color: theme.color.success,
    },
    {
      id: 2,
      title: 'calendar.milestonesOnGoing',
      icon: <TaskIcon />,
      number: vendorsSummary?.data?.tasksOnGoing || 0,
      subtitle: 'calendar.onGoing',
      buttonMsg: 'calendar.viewMore',
      color: theme.color.calendar.extraDarkPurple,
    },
    {
      id: 3,
      title: 'calendar.futureHomes',
      icon: <FutureHomeIcon />,
      number: vendorsSummary?.data?.futureHomes || 0,
      subtitle: 'calendar.totalProjects',
      buttonMsg: 'calendar.viewMore',
    },
    {
      id: 4,
      title: 'calendar.futureTasks',
      icon: <FutureTaskIcon />,
      number: vendorsSummary?.data?.futureTasks || 0,
      subtitle: 'calendar.totalTasks',
      buttonMsg: 'calendar.viewMore',
    },
  ];

  return (
    <CalendarViewWrapper>
      <Headline>
        <FormattedMessage id="common.calendar" />
      </Headline>
      <HSpacer />
      <CalendarViewHeader>
        {cards.map(
          ({ id, title, icon, number, subtitle, buttonMsg, color }) => (
            <CardIconWrapper key={id}>
              <CardIcon
                key={id}
                title={title}
                icon={icon}
                buttonMessage={buttonMsg}
                number={`${number}`}
                subtitle={subtitle}
                overrideColor={color}
                alignRight
              />
            </CardIconWrapper>
          )
        )}
      </CalendarViewHeader>
      {!isMobile && (
        <>
          <HSpacer height={40} />
          <DivisorLine />
        </>
      )}
      <HSpacer height={30} />
      <Calendar events={[]} />
    </CalendarViewWrapper>
  );
};

export default CalendarView;
