/* eslint-disable react/no-array-index-key */
/* eslint-disable radix */
import { TextBase } from 'features/app/components/Commons/Text/Text';
import { LineItem } from 'features/app/utils/types/lineItems';
import { isArray } from 'lodash';
import theme from 'theme';
import { GroupName, Ul } from '../../List.styles';
import CardMobile from './components/CardMobile/CardMobile';

export interface CardGroupByProps {
  data: object;
  group: any;
  indexKey: number;
  groupName: string;
  propId?: number;
}

export default function CardGroupBy({
  data,
  group,
  indexKey,
  groupName,
  propId,
}: CardGroupByProps) {
  const getDataValues = (index: number) => {
    if (isArray(Object.values(data)[index])) {
      return Object.values(data)[index];
    }
    return [Object.values(data)[index]];
  };

  return (
    <>
      <GroupName>
        <TextBase weight={theme.fontWeight.bold}>
          {groupName} # {group}
        </TextBase>
      </GroupName>
      <Ul key={group}>
        {getDataValues(indexKey).map((item: LineItem, index: number) => (
          <CardMobile
            propId={propId}
            key={index}
            item={item}
            index={index}
            dataLength={getDataValues(indexKey).length}
          />
        ))}
      </Ul>
    </>
  );
}
