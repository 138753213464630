import { HomeProp } from 'features/app/utils/types/homes';
import { HomesPaymentsProp, ItemProp } from 'features/app/utils/types/payments';

export const groupHomes = (addresses: HomeProp[], payments: ItemProp[]) => {
  const grouped: HomesPaymentsProp[] = [];
  addresses.forEach((address: HomeProp) => {
    const pays = payments.filter(({ propertyId }) => propertyId === address.id);
    grouped.push({ ...address, payments: pays });
  });
  return grouped;
};
